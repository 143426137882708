import OneFieldCard from './OneFieldCard';
import TwoFieldCards from './TwoFieldCards';
import ThreeFieldCards from './ThreeFieldCards';

function FinancialWrapper() {
  return (
    <div className=" tw-h-max tw-hi-[469px]">
      <div className="tw-text-lg">Financial Overview</div>

      <div className=" tw-flex tw-flex-wrap tw-gap-5">
        <OneFieldCard
          count={100}
          name={'Monthly Billing'}
        />
        <ThreeFieldCards
          primaryName={'Monthly Collection'}
          primaryCount={100}
          secondaryName1={'New Collectables'}
          secondaryCount1={34}
          secondaryName2={'Old Collectables'}
          secondaryCount2={45}
        />
        <OneFieldCard
          count={100}
          name={'Monthly Fresh Collection'}
        />
        <OneFieldCard
          count={100}
          name={'Monthly Old Collection'}
        />
        <OneFieldCard
          count={100}
          name={'Droppedout Amount'}
        />
        <TwoFieldCards
          secondaryName={'Monthly Refunds'}
          primaryName={'Overall Refunds'}
        />
      </div>
    </div>
  );
}

export default FinancialWrapper;
