import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ipaddress, ipaddress2 } from '../App';
import { toast } from 'react-toastify';
import { Button, Form, Modal } from 'react-bootstrap';

const Student_login = () => {
  const [passwordtype, setPasswordtype] = useState(true);

  const [userid, setUserid] = useState('');
  const [password, setpassword] = useState('');
  const navigate = useNavigate();

  const [studentID, setStudentID] = useState();

  const [loading, setLoading] = useState();

  const handlesubmit = e => {
    e.preventDefault();
    setLoading(true);
    console.log({
      email: userid,
      password: password,
    });
    const formdata = new FormData();
    formdata.append('username', userid);
    formdata.append('password', password);
    axios
      .post(`${ipaddress2}/StudentLogin/`, {
        email: userid,
        password: password,
      })
      .then(r => {
        console.log('Login Successfull', r.data);

        sessionStorage.setItem('user', JSON.stringify(r.data));
        navigate('/student_dashboard');
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        console.log('Login Error');
        toast.error('Invalid Email and Password', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
        setLoading(false);
      });
  };

  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');

  const [show, setshow] = useState(false);
  const [pass_state, setpass_state] = useState(false);
  const [backendotp, setbackendotp] = useState('');
  const [otp, setotp] = useState('');
  const [newpassword, setnewpassword] = useState('');
  const [retypepassword, setretypepassword] = useState('');

  const [load3, setload3] = useState(false);

  const sendotp = async () => {
    try {
      setload3(true); // Show loading state
      const response = await axios.post(`${ipaddress}/send_otp/`, {
        email: email,
      });

      console.log('OTP', response.data);
      setbackendotp(response.data.otp);
      setStudentID(response.data.emp_id);
      toast.success('OTP Sent Successfully', {
        autoClose: 1000,
        theme: 'colored',
        position: 'top-center',
      });

      setTimeout(() => {
        setshow(true);
      }, 1000);

      setShowModal(false);
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error('Failed to send OTP. Please try again.', {
        autoClose: 1000,
        theme: 'colored',
        position: 'top-center',
      });
    } finally {
      setload3(false);
    }
  };

  const [load2, setload2] = useState(false);

  const validate_otp = () => {
    setload2(true);
    console.log('BACKEND OTP', backendotp);

    if (otp === backendotp) {
      toast.success('OTP Validated successfully', {
        autoClose: 1000,
        theme: 'colored',
        position: 'top-center',
      });
      setpass_state(true);
      setload2(false);
      setotp('');
    } else {
      toast.error('Invalid OTP', {
        autoClose: 1000,
        theme: 'colored',
        position: 'top-center',
      });
      setpass_state(false);
      setload2(false);
    }
  };

  const [load, setload] = useState(false);

  const update_password = () => {
    setload(true);
    setTimeout(() => {
      update();
    }, 1000);
  };

  const update = () => {
    if (newpassword === retypepassword) {
      axios
        .post(`${ipaddress}/StudentPasswordChange/`, {
          password: newpassword,
          customer_id: studentID,
        })
        .then(r => {
          toast.success('Password updated successfully!', {
            autoClose: 1000,
            theme: 'colored',
            position: 'top-center',
          });
          setshow(false);
          setload(false);
        });
    } else {
      toast.error('Password Mismatching', {
        autoClose: 1000,
        theme: 'colored',
        position: 'top-center',
      });
      setload(false);
    }
  };

  return (
    <div
      className="bg-light student-login"
      style={{ height: '100vh' }}
    >
      <div className="row m-0 h-100">
        <div className="col-lg-6 d-flex justify-content-center align-items-center">
          <div className="login-form px-3 py-5 d-flex flex-column align-items-center justify-content-center">
            <h3
              className="text-center mb-4 fw-bold mt-3 fs-2"
              style={{ color: '#002147' }}
            >
              Welcome Back
            </h3>
            <form
              action=""
              className="p-2 py px-2 w-100 mt-2"
              onSubmit={handlesubmit}
            >
              <div class="mb-3">
                <label
                  htmlFor=""
                  className="text-dark fw-bold"
                >
                  Email
                </label>
                <div
                  class="input-group mb-3 mt-2 border border-secondary py-3"
                  style={{ backgroundColor: '#fff' }}
                >
                  <input
                    type="text"
                    class="form-control border-0 bg-transparent shadow-none login-input"
                    onChange={e => {
                      setUserid(e.target.value);
                    }}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
              <div class="mb-3 mt-4">
                <label
                  htmlFor=""
                  className="text-dark fw-bold"
                >
                  Password
                </label>
                <div
                  class="input-group mb-3 mt-2 border border-secondary py-3"
                  style={{ backgroundColor: '#fff' }}
                >
                  <input
                    type={passwordtype ? 'password' : 'text'}
                    onChange={e => {
                      setpassword(e.target.value);
                    }}
                    class="form-control border-0 bg-transparent country-input shadow-none login-input"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setPasswordtype(!passwordtype);
                    }}
                    class="input-group-text border-0 bg-transparent"
                    id="basic-addon1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#8E9696"
                      class="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="text-end mb-4">
                <p
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="fw-bold"
                  style={{ color: '#FF845D', textDecoration: 'none' }}
                >
                  Forgot Password?
                </p>
              </div>
              <div class="text-center">
                <button
                  type="submit"
                  className="btn Login-btn btn-md py-3 fw-bold px-5 text-white fw-medium w-100"
                  style={{ backgroundColor: '#033EA3', height: '61px' }}
                >
                  {loading ? 'Loading...' : 'Login Now'}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="col-lg-6 d-flex align-items-center justify-content-center">
          <img
            src={require('../images/student/SLA_logo_White 3.png')}
            style={{ width: '50%' }}
            alt=""
          />
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Button
              className="w-100"
              onClick={sendotp}
              disabled={load3} // Disable button during loading
            >
              {load3 ? 'Sending...' : 'Send OTP'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={() => {
          setshow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-0">
            {!pass_state && (
              <div className="col-12 d-flex align-items-end">
                <div className="w-75">
                  <label htmlFor="">Enter your OTP</label>
                  <input
                    type="number"
                    value={otp}
                    onChange={e => {
                      setotp(Number(e.target.value));
                    }}
                    className="form-control shadow-none border-secondary-subtle mt-2 py-3"
                  />
                </div>
                <button
                  className="ms-3 btn btn-sm btn-primary"
                  onClick={() => {
                    validate_otp();
                  }}
                >
                  {load2 ? 'Validating...' : 'Validate OTP'}
                </button>
              </div>
            )}
          </div>

          <div className={`row m-0 mt-4 ${pass_state ? '' : 'd-none'}`}>
            <div className="col-12">
              <div className="">
                <label htmlFor="">Enter your Password</label>
                <input
                  type="text"
                  value={newpassword}
                  onChange={e => {
                    setnewpassword(e.target.value);
                  }}
                  className="form-control shadow-none border-secondary-subtle mt-2 py-3"
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="">
                <label htmlFor="">Retype Password</label>
                <input
                  type="text"
                  value={retypepassword}
                  onChange={e => {
                    setretypepassword(e.target.value);
                  }}
                  className="form-control shadow-none border-secondary-subtle mt-2 py-3"
                />
              </div>
            </div>

            <div className="text-end mt-4">
              <button
                className="btn btn-primary px-4"
                onClick={() => {
                  update_password();
                }}
              >
                {load ? 'Updating...' : 'Update'}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Student_login;
