import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ipaddress } from '../App';
import { toast } from 'react-toastify';
import { Button, Form, Modal } from 'react-bootstrap';

const LoginPage = () => {
  const [passwordType, setPasswordType] = useState(true);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');

  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [backendOtp, setBackendOtp] = useState('');
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${ipaddress}/Login/`, { username: userId, password })
      .then(res => {
        if (res.data && res.data.user_details) {
          sessionStorage.setItem('user', JSON.stringify(res.data.user_details));
          navigate('/dashboard');
        } else {
          toast.error('Invalid Email and Password', {
            autoClose: 2000,
            theme: 'colored',
            position: 'top-center',
          });
        }
      })
      .catch(err => {
        console.error(err);
        toast.error('Login Failed. Please try again.', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
      })
      .finally(() => setLoading(false));
  };

  const sendOtp = () => {
    console.log(email);
    if (!email) {
      toast.error('Please enter your email address.', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
      return;
    }
    setOtpSent(true);
    axios
      .post(`${ipaddress}/send_otp/`, { email })
      .then(res => {
        setBackendOtp(res.data.otp);
        setUser(res.data.emp_id);
        console.log(res.data.emp_id);
        toast.success('OTP sent successfully.', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
        setShowOtpModal(true);
      })
      .catch(err => {
        console.error(err);
        toast.error('Failed to send OTP. Try again later.', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
      })
      .finally(() => setOtpSent(false));
  };

  const validateOtp = () => {
    console.log(otp, backendOtp);
    if (+otp === backendOtp) {
      toast.success('OTP validated successfully.', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
      setOtpValidated(true);
    } else {
      toast.error('Invalid OTP. Please try again.', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
    }
  };

  const updatePassword = () => {
    if (newPassword !== retypePassword) {
      toast.error('Passwords do not match.', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
      return;
    }
    axios
      .put(`${ipaddress}/AddEmployeeView/?empid=${user}`, {
        password: newPassword,
      })
      .then(() => {
        toast.success('Password updated successfully!', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
        setShowOtpModal(false);
        setOtpValidated(false);
      })
      .catch(err => {
        console.error(err);
        toast.error('Failed to update password. Try again.', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
      });
  };

  return (
    <div
      className="login-page"
      style={{ height: '100vh' }}
    >
      <div className="row m-0 h-100">
        <div className="col-lg-6 login-div d-flex align-items-center justify-content-center">
          <img
            src={require('../images/SLA_logo_White 2.png')}
            style={{ width: '30%' }}
            alt="Logo"
          />
        </div>

        <div className="col-lg-6 d-flex justify-content-center align-items-center">
          <div className="login-form px-3 py-5">
            <h3
              className="text-center mb-4 fw-bold"
              style={{ color: '#002147' }}
            >
              Sign In
            </h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="fw-bold">Username</label>
                <input
                  type="text"
                  className="form-control shadow-none"
                  value={userId}
                  onChange={e => setUserId(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="fw-bold">Password</label>
                <input
                  type={passwordType ? 'password' : 'text'}
                  className="form-control shadow-none"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                />
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => setPasswordType(!passwordType)}
                >
                  {passwordType ? 'Show' : 'Hide'}
                </span>
              </div>
              <div className="text-end mb-4">
                <p
                  className="fw-bold"
                  style={{ color: '#FF845D', cursor: 'pointer' }}
                  onClick={() => setShowModal(true)}
                >
                  Forgot Password?
                </p>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Sign In'}
              </button>
            </form>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Button
              className="w-100"
              onClick={sendOtp}
              disabled={otpSent}
            >
              {otpSent ? 'Sending...' : 'Send OTP'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showOtpModal}
        onHide={() => setShowOtpModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Validate OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {otpValidated ? (
            <>
              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Retype Password</Form.Label>
                <Form.Control
                  type="password"
                  value={retypePassword}
                  onChange={e => setRetypePassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Button
                className="w-100"
                onClick={updatePassword}
              >
                Update Password
              </Button>
            </>
          ) : (
            <>
              <Form.Group className="mb-3">
                <Form.Label>OTP</Form.Label>
                <Form.Control
                  type="text"
                  value={otp}
                  onChange={e => setOtp(e.target.value)}
                  required
                />
              </Form.Group>
              <Button
                className="w-100"
                onClick={validateOtp}
              >
                Validate OTP
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LoginPage;
