import React, { useState } from 'react';
import { createContext } from 'react';

export const Context = createContext();

const Context_provider = prop => {
  const [register_value, setregister_value] = useState('');
  const [course_id, setcourse_id] = useState();

  const values = { register_value, setregister_value, course_id, setcourse_id };
  return <Context.Provider value={values}>{prop.children}</Context.Provider>;
};

export default Context_provider;
