import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ipaddress } from '../App';
import axios from 'axios';

const Customerwalkinregistrationform = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));

  const [availablecourses, setAvailablecourses] = useState([]);
  const [lead_levels, setlead_levels] = useState([]);

  const [bach_perc, setbach_perc] = useState(true);
  const [mast_perc, setmast_perc] = useState(true);

  const [bach_degrees, setbach_degrees] = useState([]);
  const [bach_specific, setbach_specific] = useState([]);

  const [master_degrees, setmaster_degrees] = useState([]);
  const [master_specific, setmaster_specific] = useState([]);

  useEffect(() => {
    axios(`${ipaddress}/bachelor-degrees/`)
      .then(r => {
        console.log('Bach degrees successfully fetched', r.data);
        setbach_degrees(r.data);
      })
      .catch(err => {
        console.log('Bach degrees fetching Error', err);
      });

    axios(`${ipaddress}/MasterDegrees/`)
      .then(r => {
        console.log('Mast degrees successfully fetched', r.data);
        setmaster_degrees(r.data);
      })
      .catch(err => {
        console.log('Mast degrees fetching Error', err);
      });

    axios
      .get(`${ipaddress}/AllcoursesnameView/`)
      .then(r => {
        console.log('Courses successfully fetched', r.data);
        setAvailablecourses(r.data.coursename);
      })
      .catch(err => {
        console.log('Courses fetching Error', err);
      });

    axios
      .get(`${ipaddress}/Allleadlevels/`)
      .then(r => {
        console.log('Lead levels successfully fetched', r.data);
        setlead_levels(r.data.leadlevels);
      })
      .catch(err => {
        console.log('Lead levels fetching Error', err);
      });
  }, []);

  const sourcedata = e => {
    if (e.target.value === 'walkin') {
      document.getElementById('contactedpersondiv').style.display = 'block';
    }
    if (e.target.value === 'other') {
      document.getElementById('othersource').style.display = 'block';
    }
  };

  const fetchspecifications = id => {
    axios(`${ipaddress}/bachelor-degrees/${id}/specifications/`)
      .then(r => {
        console.log('Bach specific successfully fetched', r.data);
        setbach_specific(r.data);
      })
      .catch(err => {
        console.log('Bach specific fetching Error', err);
      });
  };

  const fetchspecifications2 = id => {
    axios(`${ipaddress}/MasterDegrees/${id}/specifications/`)
      .then(r => {
        console.log('Mast specific successfully fetched', r.data);
        setmaster_specific(r.data);
      })
      .catch(err => {
        console.log('Mast specific fetching Error', err);
      });
  };

  const [formData, setFormData] = useState({
    emp_id: '',
    name: '',
    email: '',
    contact_number: '',
    gender: '',
    bachelor_degree: '',
    bachelor_specification: '',
    percentage_bachelor: '',
    master_degree: '',
    master_specification: '',
    percentage_master: '',
    course_enquired: '',
    upcoming_batch_id: '',
    enquiry_location: '',
    source: 'walkin',
    contacted_person_name: '',
    contacted_person_number: '',
    technical_skills_known: [],
    soft_skills_known: [],
    priority: '',
    level_lead: 'walkin',
    preferred_course: '',
    preferred_batch_type: '',
    preferred_batch_start_time: '',
    preferred_class_type: '',
    expected_registration_date: '',
    tentative_batch_start_date: '',
    created_at: '',
    othercourseenquired: '',
    remarks: '',
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = e => {
    const { name, checked, value } = e.target;
    setFormData(prevState => {
      const skills = prevState[name];
      if (checked) {
        skills.push(value);
      } else {
        const index = skills.indexOf(value);
        if (index > -1) {
          skills.splice(index, 1);
        }
      }
      return { ...prevState, [name]: skills };
    });
  };

  const navigate = useNavigate();

  // const[]

  const [loading, setloading] = useState(false);
  const [mail, setmail] = useState('');
  const [showstate, setshowstate] = useState(false);
  const [exists, setExists] = useState('');

  const senddetails = e => {
    e.preventDefault();

    setloading(true);
    formData.technical_skills_known = technical_skills;
    formData.soft_skills_known = soft_skills;
    formData.email = mail;
    formData.course_enquired = course_name;

    console.log('SCANNED', {
      ...formData,
      level_lead: 'walkin',
    });
    axios
      .post(`${ipaddress}/ScannedCustomerLeadsView/`, {
        ...formData,
        level_lead: 'walkin',
      })
      .then(r => {
        console.log('Successfully registered', r.data);
        console.log(formData);
        toast.success('Successfully sent', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        setloading(false);
        setshowstate(true);
      })
      .catch(err => {
        console.log('Lead registration error', err);
        setloading(false);
      });
    // }
  };

  const [formstate, setformstate] = useState(false);
  const [otpstate, setotpstate] = useState(false);
  const [backendotp, setbackendotp] = useState('');
  const [otp, setotp] = useState('');

  const sendotp = () => {
    // e.preventDefault()
    axios
      .post(`${ipaddress}/send-otp/`, {
        email: formData.email,
      })
      .then(r => {
        console.log('Otp sent suceessfully', r.data);
        setbackendotp(r.data.otp);
      })
      .catch(err => {
        console.log('Otp sending error', err);
      });
  };

  // Phone number verification
  const [load2, setload2] = useState();
  const [message2, setmessage2] = useState();

  const verify_phone = phone => {
    if (phone.length == 10) {
      setload2(true);
      setTimeout(() => {
        verify2(phone);
      }, 1000);
    }
  };

  const verify2 = phone => {
    axios
      .post(`${ipaddress}/MobileNumberValidation/`, {
        mobile_number: phone,
        customer: 'Customer',
      })
      .then(r => {
        console.log('Phone number verified', r.data);
        setload2(false);
        setmessage2(true);
        formData.contact_number = phone;
        console.log(formData);

        if (r.data.emp_id) {
          setExists(r.data.emp_id);
          setFormData(prev => ({
            ...prev,
            ...r.data,
            level_lead: 6,
          }));
        }
      })
      .catch(err => {
        setmessage2(false);
        setload2(false);
      });
  };

  // Email verification
  const [load, setload] = useState();
  const [message, setmessage] = useState();

  const verify_email = email => {
    if (email.length > 0) {
      setload(true);
      setTimeout(() => {
        verify(email);
      }, 1000);
    }
  };

  const verify = email => {
    axios.get(`${ipaddress}/EmailCheck/${email}/`).then(r => {
      console.log('Email verified', r.data);
      setload(false);

      if (r.data.message === 'mail_notexist_customerlead') {
        setmessage(true);
        setmail(email);
      } else {
        setmessage(false);
      }
    });
  };

  const verifyotp = () => {
    console.log(otp, backendotp);
    if (otp === backendotp) {
      toast.success('OTP verified successfully', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
      setotpstate(false);
      setformstate(true);
      setotp('');
    } else {
      toast.error('Invalid OTP', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
    }
  };

  const [technical_skills, settechnical_skills] = useState(['']);

  const handleAddsubcourses = () => {
    settechnical_skills([...technical_skills, '']);
  };

  const handlesubcoursesChange = (index, event) => {
    const newCourses = technical_skills.map((course, courseIndex) =>
      courseIndex === index ? event.target.value : course,
    );
    settechnical_skills(newCourses);
  };

  const [soft_skills, setsoft_skills] = useState(['']);

  const handleAddsubcourses2 = () => {
    setsoft_skills([...soft_skills, '']);
  };

  const handlesubcoursesChange2 = (index, event) => {
    const newCourses = soft_skills.map((course, courseIndex) =>
      courseIndex === index ? event.target.value : course,
    );
    setsoft_skills(newCourses);
  };

  const handle_email = email => {
    const atIndex = email.indexOf('@');

    if (atIndex !== -1 && email.length > atIndex + 1) {
      verify_email(email);
    } else {
      setload(false);
    }
  };

  const [batches, setbatches] = useState([]);
  const [course_name, setcourse_name] = useState('');

  const fetch_batch = id => {
    const data = availablecourses.filter(x => x.id == id);
    // console.log("course detail",data)
    setcourse_name(data[0].course_name);

    axios
      .get(`${ipaddress}/upcomingBatchesView/basedoncourse/?course_id=${id}`)
      .then(r => {
        console.log('Batches', r.data);
        setbatches(r.data.results);
      });
  };

  return (
    <div
      className=" bg-light"
      style={{ minHeight: '100vh' }}
    >
      <div
        className={`container-fluid row m-0 pb-4 pt-3 animate__animated animate__fadeIn ${
          showstate ? 'd-none' : ''
        }`}
      >
        <div className="mb-2 d-flex">
          <Link
            className="text-dark"
            to="/customerleads"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
              />
            </svg>
          </Link>
          <h5 className="text-success mx-auto">CUSTOMER REGISTRATION FORM</h5>
        </div>
        <div className="col-12 bg-white py-3">
          <form
            className=""
            onSubmit={senddetails}
          >
            {/* ---------------------------------PERSONAL DETAILS--------------------------------------------------------- */}
            <div className="row m-0 border-bottom pb-2">
              <h6 className="text-primary">Personal Details</h6>
              <div className="col-md-6 col-lg-3 mb-3">
                <label
                  for="inputEmail4"
                  className="form-label"
                >
                  Name <span className="required-field">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control shadow-none bg-light py-2"
                  id="inputEmail4"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              {/* <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Contact Number <span className='required-field'>*</span></label>
    <input required type="tel" className="form-control shadow-none bg-light py-2" maxLength={10} id="inputPassword4" name='contact_number' value={formData.contact_number} onChange={handleInputChange}/>
  </div> */}

              <div className="col-md-6 col-lg-3 mb-3">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Contact Number <span className="required-field">*</span>
                </label>
                <div class="input-group rounded bg-light border">
                  <input
                    type="tel"
                    maxLength={10}
                    className="form-control shadow-none bg-light py-2 border-0 bg-transparent"
                    id="inputPassword4"
                    name="contact_number"
                    onChange={e => {
                      verify_phone(e.target.value);
                    }}
                  />
                  <span
                    class="input-group-text border-0 bg-transparent"
                    id="basic-addon2"
                  >
                    <div
                      class={`spinner-border spinner-border-sm text-success ${
                        load2 ? '' : 'd-none'
                      }`}
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#198754"
                      class={`bi bi-check2-circle ${
                        !load2 && message2 == true ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                    </svg>
                  </span>
                </div>
                <p
                  className={`text-danger mt-1 ${
                    message2 == false ? '' : 'd-none'
                  }`}
                  style={{ fontSize: '13px' }}
                >
                  *Mobile Number already exist
                </p>
              </div>

              <div className="col-md-6 col-lg-3 mb-3">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Email<span className="required-field">*</span>
                </label>
                <div class="input-group rounded bg-light border">
                  <input
                    type="email"
                    className="form-control shadow-none bg-light py-2 border-0 bg-transparent"
                    id="inputPassword4"
                    name="email"
                    required
                    onChange={e => {
                      handle_email(e.target.value);
                    }}
                  />
                  <span
                    class="input-group-text border-0 bg-transparent"
                    id="basic-addon2"
                  >
                    <div
                      class={`spinner-border spinner-border-sm text-success ${
                        load ? '' : 'd-none'
                      }`}
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#198754"
                      class={`bi bi-check2-circle ${
                        !load && message == true ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                    </svg>
                  </span>
                </div>
                <p
                  className={`text-danger mt-1 ${
                    message == false ? '' : 'd-none'
                  }`}
                  style={{ fontSize: '13px' }}
                >
                  *Email already exist
                </p>
              </div>

              <div className="col-md-6 col-lg-3 mb-3">
                <label
                  for="inputState"
                  className="form-label shadow-none"
                >
                  Gender <span className="required-field">*</span>
                </label>
                <select
                  required
                  id="inputState"
                  className="form-select shadow-none bg-light py-2"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <option selected>Choose...</option>
                  <option>Male</option>
                  <option>Female</option>
                  <option>Transgender</option>
                </select>
              </div>
            </div>

            <div className={`${formstate ? '' : ''}`}>
              {/* ----------------------------------EDUCATIONAL DETAILS----------------------------------------------------------- */}
              <div className="row m-0 py-3">
                <h6 className="text-primary">Educational Details</h6>

                <div className="col-md-6 col-lg-3 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Bachelor Degree{' '}
                  </label>
                  <select
                    required
                    id="inputState"
                    className="form-select shadow-none bg-light py-2"
                    name="bachelor_degree"
                    value={formData.bachelor_degree}
                    onChange={e => {
                      setbach_perc(false);
                      fetchspecifications(e.target.value);
                      const { name, value } = e.target;

                      setFormData(prev => ({
                        ...prev,
                        [name]: value,
                      }));
                    }}
                  >
                    <option selected>Choose...</option>
                    {bach_degrees.map(x => {
                      return (
                        <>
                          <option value={x}>{x}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Bachelor Specification
                  </label>
                  <select
                    required
                    id="inputState"
                    className="form-select shadow-none bg-light py-2"
                    onChange={e => {
                      if (e.target.value === 'other') {
                        document.getElementById('bachother').style.display =
                          'block';
                      } else {
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                        document.getElementById('bachother').style.display =
                          'none';
                      }
                    }}
                  >
                    <option selected>Choose...</option>
                    <option value="other">Other</option>
                    {bach_specific.map(x => {
                      return (
                        <>
                          <option value={x}>{x}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Percentage Achieved
                  </label>
                  <input
                    disabled={bach_perc}
                    required
                    type="number"
                    className="form-control shadow-none bg-light py-2"
                    id="inputPassword4"
                    value={formData.percentage_bachelor}
                    name="percentage_bachelor"
                    onChange={handleInputChange}
                  />
                </div>
                <div
                  className="col-md-6 col-lg-3 mb-3"
                  id="bachother"
                  style={{ display: 'none' }}
                >
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Enter your Specification
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none bg-light"
                    id="inputPassword4"
                    value={formData.bachelor_specification}
                    name="bachelor_specification"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row m-0 border-bottom pb-3">
                <div className="col-md-6 col-lg-3 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Master Degree
                  </label>
                  <select
                    id="inputState"
                    className="form-select shadow-none bg-light py-2"
                    onChange={e => {
                      setmast_perc(false);
                      fetchspecifications2(e.target.value);
                      const { name, value } = e.target;

                      setFormData(prev => ({
                        ...prev,
                        [name]: value,
                      }));
                    }}
                  >
                    <option selected>Choose...</option>
                    {master_degrees.map(x => {
                      return (
                        <>
                          <option value={x}>{x}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Master Specification
                  </label>
                  <select
                    id="inputState"
                    className="form-select shadow-none bg-light py-2"
                    onChange={e => {
                      if (e.target.value === 'other') {
                        document.getElementById('masterother').style.display =
                          'block';
                      } else {
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                        document.getElementById('masterother').style.display =
                          'none';
                      }
                    }}
                  >
                    <option selected>Choose...</option>
                    <option value="other">Other</option>
                    {master_specific.map(x => {
                      return (
                        <>
                          <option value={x}>{x}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Percentage Achieved
                  </label>
                  <input
                    type="number"
                    disabled={mast_perc}
                    className="form-control shadow-none bg-light py-2"
                    id="inputPassword4"
                    value={formData.percentage_master}
                    name="percentage_master"
                    onChange={handleInputChange}
                  />
                </div>
                <div
                  className="col-md-6 col-lg-3 mb-3"
                  id="masterother"
                  style={{ display: 'none' }}
                >
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Enter your Specification
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none bg-light"
                    id="inputPassword4"
                    value={formData.master_specification}
                    name="master_specification"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* ----------COURSE DETAILS----------------------- */}
              <div className="row m-0 border-bottom py-3">
                <h6 className="text-primary">Course Details</h6>
                <div className="col-md-6 col-lg-3 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Course Enquired <span className="required-field">*</span>
                  </label>
                  <select
                    id="inputState"
                    className="form-select shadow-none bg-light py-2"
                    value={formData.course_enquired}
                    name="course_enquired"
                    onChange={e => {
                      if (e.target.value === 'other') {
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: Number(value),
                        }));
                        document.getElementById('other-course').style.display =
                          'block';
                      } else {
                        fetch_batch(e.target.value);
                        document.getElementById('other-course').style.display =
                          'none';
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: Number(value),
                        }));
                      }
                    }}
                  >
                    <option value="">Choose...</option>
                    {availablecourses.map(x => {
                      return (
                        <>
                          <option value={x.id}>{x.course_name}</option>
                        </>
                      );
                    })}
                    <option value="other">Other</option>
                  </select>
                </div>

                {formData.course_enquired ? (
                  <div className="col-md-6 col-lg-3 mb-3">
                    <label
                      for="inputState"
                      className="form-label shadow-none"
                    >
                      Batches Available
                    </label>
                    <select
                      id="inputState"
                      className={`form-select shadow-none bg-light py-2 `}
                      value={formData.upcoming_batch_id}
                      name="upcoming_batch_id"
                      onChange={e => {
                        document.getElementById('other-course').style.display =
                          'none';
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                      }}
                    >
                      <option value="">Choose...</option>
                      {batches.map(x => {
                        return (
                          <>
                            <option value={x.id}>{x.batch_name}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                ) : (
                  <></>
                )}

                {/* Other course */}
                <div
                  className="col-md-6 col-lg-3 mb-3"
                  id="other-course"
                  style={{ display: 'none' }}
                >
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Enter the course
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none bg-light py-2"
                    id="inputPassword4"
                    name="othercourseenquired"
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-6 col-lg-3 mb-3">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Enquiry Location <span className="required-field">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control shadow-none bg-light py-2"
                    id="inputPassword4"
                    value={formData.enquiry_location}
                    name="enquiry_location"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                  <label
                    for="source"
                    className="form-label shadow-none"
                  >
                    Source <span className="required-field">*</span>
                  </label>
                  <select
                    required
                    id="source"
                    className="form-select shadow-none bg-light py-2"
                    value={formData.source}
                    disabled
                    name="source"
                    onChange={e => {
                      if (e.target.value === 'walkin') {
                        document.getElementById(
                          'contactedpersondiv',
                        ).style.display = 'block';
                        document.getElementById('other-source').style.display =
                          'none';
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                      } else if (e.target.value === 'other') {
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                        document.getElementById('other-source').style.display =
                          'block';
                        document.getElementById(
                          'contactedpersondiv',
                        ).style.display = 'none';
                      } else {
                        document.getElementById(
                          'contactedpersondiv',
                        ).style.display = 'none';
                        document.getElementById('other-source').style.display =
                          'none';
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                      }
                    }}
                  >
                    <option
                      value="none"
                      selected
                    >
                      Choose...
                    </option>
                    <option value="other">Other</option>
                    <option value="walkin">Walkin</option>
                    <option value="naukri">Naukri</option>
                    <option value="linkedin">Linkedin</option>
                    <option value="just dial">Just Dial</option>
                    <option value="whatsapp">Whatsapp</option>
                    <option value="facebook">Facebook</option>
                    <option value="instagram">Instagram</option>
                  </select>
                </div>

                <div
                  style={{ display: 'none' }}
                  id="contactedpersondiv"
                >
                  <div className="col-md-6 col-lg-4 mb-3">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Contacted Person's Name
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none bg-light py-2"
                      id="inputPassword4"
                      onChange={handleInputChange}
                      value={formData.contacted_person_name}
                      name="contacted_person_name"
                    />
                  </div>

                  <div className="col-md-6 col-lg-3 mb-3">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Contacted Person's Number
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none bg-light py-2"
                      id="inputPassword4"
                      onChange={handleInputChange}
                      value={formData.contacted_person_number}
                      name="contacted_person_number"
                    />
                  </div>
                </div>
                {/* Other source */}
                <div
                  className="col-md-6 col-lg-3 mb-3"
                  id="other-source"
                  style={{ display: 'none' }}
                >
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Enter the source
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none bg-light py-2"
                    id="inputPassword4"
                    name="source"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* --------------------------------TECHNOLOGIES KNOWN-------------------- */}
              <div className="row m-0 mb-3">
                <div className="col-12 mb-3">
                  <span className="text-primary fw-medium me-4">
                    Technical Skills Known
                    <span className="required-field">*</span>
                  </span>

                  <div
                    className="row m-0 mb-2 p-0"
                    style={{ marginBottom: '20px', borderRadius: '10px' }}
                  >
                    {technical_skills.map((q, qIndex) => (
                      <div
                        className="col-lg-2 px-2"
                        key={qIndex}
                      >
                        <input
                          className="add-option form-control py-2 shadow-none bg-light border-secondary-subtle my-2"
                          type="text"
                          placeholder="Type your skills..."
                          onChange={e => handlesubcoursesChange(qIndex, e)}
                          required
                        />
                      </div>
                    ))}
                  </div>
                  <div className="d-flex justify-content-end mt-4">
                    {/* <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip4}
    > */}
                    <button
                      type="button"
                      className="btn btn-sm btn-primary text-white d-flex align-items-center"
                      onClick={handleAddsubcourses}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="white"
                        className="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                      <span
                        className="ms-1"
                        style={{ fontSize: '12px' }}
                      >
                        Add More Skills
                      </span>
                    </button>
                    {/* </OverlayTrigger> */}
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <span className="text-primary fw-medium me-5">
                    Soft Skills Known<span className="required-field">*</span>
                  </span>
                  <div
                    className="row m-0 mb-2 p-0"
                    style={{ marginBottom: '20px', borderRadius: '10px' }}
                  >
                    {soft_skills.map((q, qIndex) => (
                      <div
                        className="col-lg-2 px-2"
                        key={qIndex}
                      >
                        <input
                          className="add-option form-control py-2 shadow-none bg-light border-secondary-subtle my-2"
                          type="text"
                          placeholder="Type your skills..."
                          onChange={e => handlesubcoursesChange2(qIndex, e)}
                          required
                        />
                      </div>
                    ))}
                  </div>
                  <div className="d-flex justify-content-end mt-4">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary text-white d-flex align-items-center"
                      onClick={handleAddsubcourses2}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="white"
                        className="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                      <span
                        className="ms-1"
                        style={{ fontSize: '12px' }}
                      >
                        Add More Skills
                      </span>
                    </button>
                  </div>
                </div>

                {/* <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Level of Lead <span className="required-field">*</span>
                  </label>
                  <input
                    required
                    id="inputState"
                    disabled="true"
                    className="form-control shadow-none bg-light py-2"
                    name="level_lead"
                    value={formData.level_lead > 0 ? 'walkin' : ''}
                    onChange={handleInputChange}
                  />
                </div> */}
              </div>

              {/* <div className="row m-0 border-bottom py-3">
<div className="col-12 mb-3">
    <label for="inputPassword4" className="form-label">Remarks</label>
    <textarea type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" name='remarks' value={formData.remarks} onChange={handleInputChange}/>
  </div>
</div> */}

              <div className="col-12 text-end mt-3">
                <button
                  type="submit"
                  className="btn btn-primary text-white fw-medium px-2 px-lg-5"
                >
                  {loading ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Successfull Message */}
      <div
        className={`row m-0 rounded mx-auto leave-div justify-content-center py-5 shadow-sm mt-3 animate__animated animate__jello ${
          showstate ? '' : 'd-none'
        }`}
        style={{ backgroundColor: '#f0f1f9' }}
      >
        <div className="col-md-8 d-flex align-items-center mt-4">
          <div className="w-100 d-flex flex-column align-items-center">
            <img
              src={require('../images/student/Group.png')}
              style={{ width: '70%' }}
              alt=""
            />
            <p className="fw-bold mt-5 fs-4">Successfully Registered</p>
            <p
              className="m-0"
              style={{ fontSize: '15px' }}
            >
              We got your details!
            </p>
            {/* <button className='btn text-white mt-2 px-4 py-2' style={{backgroundColor:'#57309C'}}>Back to Dashboard</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customerwalkinregistrationform;
