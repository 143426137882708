// import { useEffect, useState } from 'react';
// import { ipaddress, socketType } from '../../App';
// import notification from '../../Audio/Tone.mp3';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import Lead_details from '../Employee_leads/Lead_details';

// function MessageAlert() {
//   const [message, setMessage] = useState('');
//   const [isOpen, setIsOpen] = useState(false);
//   const [leadDetails, setLeadDetails] = useState(false);
//   const [leadID, setLeadID] = useState();

//   const [refresh, setRefresh] = useState(0);

//   const navigate = useNavigate();

//   const user = JSON.parse(sessionStorage.getItem('user'));

//   const emp_user = sessionStorage.getItem('user');

//   const emp_id = JSON.parse(emp_user).emp_id;

//   useEffect(() => {
//     const IPADDRESS = ipaddress.split('//')[1];
//     const socket = new WebSocket(
//       `${socketType}://${IPADDRESS}/ws/notifications/?emp_id=${emp_id}`,
//     );

//     socket.onopen = () => console.log('WebSocket connection established!');

//     socket.onmessage = e => {
//       const data = JSON.parse(e.data);
//       if (data) {
//         setMessage(data);
//         playAudio();
//       }
//     };

//     socket.onerror = e => console.error('WebSocket error:', e);

//     socket.onclose = () => console.log('WebSocket connection closed');

//     return () => socket.close();
//   }, []);

//   const handleIconClick = e => {
//     e.stopPropagation();
//     fetchNotification();
//     setIsOpen(prev => !prev);
//     navigate(
//       `/view_employee_leads/${user.emp_id}/today_followup?start_date=&end_date=`,
//     );
//   };

//   const playAudio = () => {
//     let documentId = document.getElementById('Audio');
//     documentId
//       .play()
//       .then(() => console.log('Audio is playing'))
//       .catch(console.error);
//   };

//   const [notifications, setNotifications] = useState([]);

//   const fetchNotification = () => {
//     axios
//       .get(`${ipaddress}/DummyNotification/?emp_id=${emp_id}`)
//       .then(res => {
//         console.log('NOTIFIC', res.data);
//         setNotifications(res.data);
//       })
//       .catch(console.error);
//   };
//   const deleteNotification = id => {
//     axios
//       .delete(`${ipaddress}/DummyNotification/${id}/?emp_id=${emp_id}`)
//       .then(res => {
//         console.log('NOTIFIC', res.data);
//       })
//       .catch(console.error);
//   };

//   useEffect(() => fetchNotification(), [refresh]);

//   return (
//     <div className="">
//       {/* Notification Icon */}
//       <div
//         className={`${
//           message ? 'tw-bg-red-500' : ''
//         } tw-rounded-md tw-px-2 tw-py-2 tw-shadow tw-cursor-pointer tw-flex tw-items-center tw-justify-center`}
//         onClick={handleIconClick}
//       >
//         <audio
//           src={notification}
//           id="Audio"
//         />
//         <i className="fa-solid fa-envelope-open text-2xl" />
//       </div>

//       {/* Side Modal for Notifications */}
//       {isOpen && (
//         <div className="tw-fixed tw-top-20 tw-right-0 tw-h-full tw-w-[300px] tw-bg-white tw-shadow-lg tw-z-50 tw-rounded-l-lg ">
//           <div className="tw-p-4 tw-flex tw-items-center tw-justify-between tw-border-b">
//             <h6 className="tw-text-lg tw-font-bold">Notifications</h6>
//             <button
//               className="tw-text-gray-600 tw-font-bold"
//               onClick={() => fetchNotification()}
//             >
//               <i className="fa-solid fa-arrows-rotate"></i>
//             </button>
//             <button
//               className="tw-text-gray-600 tw-font-bold"
//               onClick={() => setIsOpen(false)}
//             >
//               Close
//             </button>
//             <button
//               onClick={() => {
//                 notifications.map(item => deleteNotification(item.id));
//                 fetchNotification();
//               }}
//             >
//               Del
//             </button>
//           </div>

//           <div className="tw-p-4 tw-overflow-y-scroll tw-h-[calc(100vh-80px)]">
//             {notifications?.length > 0 ? (
//               notifications?.reverse().map((notif, idx) => (
//                 <div
//                   key={idx}
//                   className="tw-mb-3 tw-bg-gray-200 tw-rounded-lg tw-px-4 tw-py-2 tw-relative"
//                 >
//                   <p
//                     className="tw-text-gray-800 tw-text-base"
//                     onClick={() => {
//                       setLeadID(notif.customer_lead_id || notif.client_lead_id);
//                       setLeadDetails(true);
//                     }}
//                   >
//                     {notif.notification}
//                   </p>
//                   <button
//                     className="tw-absolute tw-bottom-0 tw-right-0 p-2"
//                     onClick={() => {
//                       console.log(notif);
//                       deleteNotification(notif.id);
//                       fetchNotification();
//                     }}
//                   >
//                     <i className="fa-solid fa-trash"></i>
//                   </button>
//                 </div>
//               ))
//             ) : (
//               <p className="tw-text-center tw-text-gray-500 tw-py-4">
//                 No Notifications at the moment
//               </p>
//             )}
//           </div>
//         </div>
//       )}

//       <Lead_details
//         setRefresh={setRefresh}
//         show={leadDetails}
//         setshow={setLeadDetails}
//         id={leadID}
//       />
//     </div>
//   );
// }

// export default MessageAlert;
function MessageAlert() {
  return null;
}

export default MessageAlert;
