import { useQueryState } from 'nuqs';
import { useNavLoc } from '../../Hooks/useNavLoc';

function SubSection({ name, count, navTo }) {
  const isDataAvailable = count !== undefined && count !== null;
  // const [type, setType] = useQueryState('type');

  const navWithLoc = useNavLoc();

  return (
    <div
      onClick={() => {
        navWithLoc(navTo);
        // if (name === 'This Month') {
        //   setType('CurrentMonth');
        // } else if (name === 'Previous Month') {
        //   setType('previousMonth');
        // } else {
        //   setType(null);
        // }
      }}
      className="tw-bg-green-30 tw-gap-3 tw-w-full tw-cursor-pointer tw-p-4 tw-flex tw-flex-col hover:tw-bg-slate-200"
    >
      <h2 className="tw-h-[50%]  tw-text-sm">{name}</h2>
      <p className="tw-text-3xl">{isDataAvailable ? count : null}</p>
    </div>
  );
}

export default SubSection;
