import { APIQueryString } from '../../utils/QueryBuilder';
import { axiosInstance } from '../Axios/AxiosClient';

export const fetchDashData = ({ startDate, endDate, emp_id }) => {
  const queryParams = {
    start_date: startDate,
    end_date: endDate,
    employee_id: emp_id,
  };
  return axiosInstance.get(
    `/NewAdminB2CLeadsCountDisplay/${APIQueryString(queryParams)}`,
  );
};
