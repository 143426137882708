import React, { useState, useEffect, useCallback } from 'react';
import { ipaddress } from '../../../App';
import axios from 'axios';
import TableExampleOrig from '../../../Trials/TableExample';
import EmployeeAssign from '../AssignAnyone.jsx/AssignEmployees';
import DateSelector from '../DateSelector/DateSelector';
import MainLayout from './MainLayout';
import { buildQueryString } from '../../../utils/QueryBuilder';
import RegisSummaryTable from '../../RegisSummary/RegisSummaryTable';

const CustomerRegistrationSum = ({ designation, settab }) => {
  settab('registered');

  const user = JSON.parse(sessionStorage.getItem('user'));

  const [loading, setLoading] = useState(true);

  const [regisSum, setRegisSum] = useState();

  const [leads, setLeads] = useState([]);

  const [selectedLeads, setSelectedLeads] = useState([]);

  const [months, setMonths] = useState({
    startDate: '',
    endDate: '',
    todayDate: '',
  });

  const fetchRegistrationsSummary = useCallback(() => {
    setLoading(true);

    const queryParams = {
      // emp_id: id,
      start_date: months.startDate,
      end_date: months.endDate,
      date: months.todayDate,
    };

    axios
      .get(
        `${ipaddress}/RegistrationsSourceWiseAndCourseWise/?${buildQueryString(
          queryParams,
        )}`,
      )
      .then(res => {
        console.log('REGISSUM', res.data);
        setRegisSum(res.data);

        setLoading(false);
      })
      .catch(err => {
        console.log('all leads error', err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchRegistrationsSummary();
  }, [fetchRegistrationsSummary]);

  const handleCheckboxChange = (status, id) => {
    if (status) {
      setSelectedLeads(prev => [...prev, id]);
    } else {
      setSelectedLeads(prev => prev.filter(leadId => leadId !== id));
    }
  };

  const fetchRegistrationDetails = (course, source) => {
    const queryParams = {
      // emp_id: id,
      //   start_date: months.startDate,
      //   end_date: months.endDate,
      //   date: months.todayDate,

      course_name: course,
      source: source,
    };

    const IPADDRESS = `${ipaddress}/RegistrationDetailsBySourceAndCourse/?${buildQueryString(
      queryParams,
    )}`;

    console.log(IPADDRESS);

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('MUIZ', res.data);
        setLeads(res.data);
      })
      .catch(err => console.log(err));
  };

  return (
    <MainLayout loading={loading}>
      <div className=" tw-flex tw-justify-between">
        <DateSelector
          months={months}
          setMonths={setMonths}
        />

        <EmployeeAssign
          selectedLeads={selectedLeads}
          setSelectedLeads={setSelectedLeads}
        />
      </div>
      <RegisSummaryTable
        regisSum={regisSum}
        fetchRegistrationDetails={fetchRegistrationDetails}
      />

      <TableExampleOrig
        tableData={leads}
        handleCheckboxChange={handleCheckboxChange}
        selectedLeads={selectedLeads}
      />
    </MainLayout>
  );
};

export default CustomerRegistrationSum;
