import { parseAsInteger, useQueryState } from 'nuqs';
import { useEffect } from 'react';

const useLeadsQueryParams = () => {
  const [filter] = useQueryState('filter', {
    parse: value => (value ? JSON.parse(value) : {}),
  });

  const [startDate] = useQueryState('startDate');
  const [endDate] = useQueryState('endDate');
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));

  const category = filter?.filterBy || 'something';
  const value = filter?.filterOn || null;

  useEffect(() => {
    if (category) {
      setPage(1);
    }
  }, [category, setPage]);

  return {
    queryParamsObj: {
      [category]: value || '',
      page: page,
      start_date: startDate || '',
      end_date: endDate || '',
    },
  };
};

export default useLeadsQueryParams;
