import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import GenerateQuotation from './GenerateQuotation';
import InvoiceGenerated from './InvoiceGenerated';
import ViewReciept from './ViewInvoice';

function ExtraButtons({ rowData, setRefreshData }) {
  const user = JSON.parse(sessionStorage.getItem('user'));

  return (
    <div
      className=" tw-flex tw-gap-x-4 tw-items-center"
      onClick={() => {
        console.log(user);
        console.log('DDAATAA 22', rowData.client_lead_id);
        console.log('ROWDATA', rowData);
      }}
    >
      <EditButton
        rowData={rowData}
        setRefreshData={setRefreshData}
      />
      {user.designation === 'admin' && rowData.customer_lead_id && (
        <DeleteButton
          rowData={rowData}
          setRefreshData={setRefreshData}
        />
      )}
      {rowData.level_lead_name === 'registered' && (
        <InvoiceGenerated rowData={rowData} />
      )}
      {rowData.level_lead_name === 'registered' && (
        <ViewReciept rowData={rowData} />
      )}

      {rowData.client_lead_id && <GenerateQuotation data={rowData} />}
    </div>
  );
}

export default ExtraButtons;
