import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useFieldArray, useForm } from 'react-hook-form';
import { ipaddress } from '../App';
import QuotationProducts from './QuotationProducts';
import TermsAndConds from './Terms&Conds';

const Generate_quotation = ({
  show,
  setshow,
  lead_id,
  fetchnewleads,
  service,
  service_amount,
  setshow1,
  show1,
  quotation_id,
  quoteData,
  setQuoteData,
}) => {
  const alter_quotation = async data => {
    try {
      const response = await axios.put(`${ipaddress}/alter-quotation`, data);
      toast.success('Quotation altered successfully!');
      fetchnewleads();
      setshow1(false);
    } catch (error) {
      toast.error('Failed to alter quotation!');
    }
  };
  const [showTerms, setShowTerms] = useState(false);

  return (
    <div>
      <QuotationProducts
        setQuoteData
        quoteData={quoteData}
        lead_id={lead_id}
        setshow={setshow}
        show={show}
        quotation_id={quotation_id}
        showTerms={showTerms}
        setShowTerms={setShowTerms}
      />
    </div>
  );
};

export default Generate_quotation;
