import { FormProvider, useForm } from 'react-hook-form';
import { GetRenderCount } from '../../utils/GetRenderCount';
import { useQueryState } from 'nuqs';
import { TextField } from './CategoriesControls/TextField';
import DatesForm from './DatesForm';
import CoursesDropdown from './CoursesDropdown';
import { useLocation } from 'react-router-dom';

function AllFilters() {
  const [filterByCat, setFilterByCat] = useQueryState('filter', {
    parse: value => (value ? JSON.parse(value) : {}),
    serialize: value => JSON.stringify(value),
  });

  const location = useLocation();

  const isDash = location.pathname === '/dashboard';
  console.log(location);

  const [startDate, setStartDate] = useQueryState('startDate');
  const [endDate, setEndDate] = useQueryState('endDate');
  const methods = useForm({
    defaultValues: {
      filterBy: filterByCat?.filterBy,
      filterOn: filterByCat?.filterOn,
      startDate: '',
      endDate: '',
      isRange: '',
    },
  });

  const { register, handleSubmit, watch, reset } = methods;

  const filterBy = watch('filterBy');

  const onSubmit = formData => {
    setEndDate(null);
    setStartDate(null);
    setFilterByCat({
      filterBy: formData.filterBy,
      filterOn: formData.filterOn,
    });
    console.log('FILTER BY ', formData);
  };

  return (
    <div className=" tw-flex tw-gap-4  tw-bg-gren-300 tw-h-[62px]">
      {/* <GetRenderCount /> */}
      <FormProvider {...methods}>
        <form
          className="tw-flex tw-gap-4 tw-items-end tw-justify-end"
          onSubmit={handleSubmit(onSubmit)}
        >
          <select
            className={` tw-p-3 tw-text-md tw-bg-slate-300 tw-h-max `}
            onChange={() => {
              setFilterByCat(null);
            }}
            {...register('filterBy')}
          >
            <option value="">Select</option>
            {!isDash &&
              options.map((each, idx) => {
                return (
                  <option
                    key={idx}
                    value={each.value}
                  >
                    {each.name}
                  </option>
                );
              })}
            {isDash &&
              Dashoptions.map((each, idx) => {
                return (
                  <option
                    key={idx}
                    value={each.value}
                  >
                    {each.name}
                  </option>
                );
              })}
          </select>
          {filterBy &&
            filterBy !== 'date' &&
            filterBy !== 'course_enquired' && (
              <TextField {...register('filterOn')} />
            )}

          {filterBy === 'course_enquired' && <CoursesDropdown />}

          {filterBy === 'date' && (
            <DatesForm
              startDate={startDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
            />
          )}

          {filterBy !== 'date' && (
            <>
              <button
                className="tw-p-2 tw-bg-slate-400 tw-rounded-lg"
                type="submit"
              >
                Filter
              </button>
              <button
                type="button"
                className="tw-p-2 tw-bg-slate-400 tw-rounded-lg  "
                onClick={() => {
                  reset();
                  setFilterByCat(null);
                }}
              >
                Clear
              </button>
            </>
          )}
        </form>
      </FormProvider>
    </div>
  );
}

export default AllFilters;
const options = [
  { name: 'Name', value: 'name' },
  { name: 'Phone No', value: 'contact_number' },
  { name: 'Email', value: 'email' },
  { name: 'Courses', value: 'course_enquired' },
  { name: 'Sources', value: 'source' },
  { name: 'Employees', value: 'employees' },
  { name: 'Priority', value: 'priority' },
  { name: 'Date', value: 'date' },
];
const Dashoptions = [{ name: 'Date', value: 'date' }];
