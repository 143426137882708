import { forwardRef } from 'react';

export const DateField = forwardRef(
  (
    {
      type = 'date',
      label,
      isRequired = false,
      error,
      className = '',
      ...other
    },
    ref,
  ) => {
    return (
      <div className="tw-mb-4">
        {label && (
          <label className="tw-block tw-text-lg tw-font-medium tw-text-gray-700">
            {label}
            {isRequired && <span className="tw-text-red-500"> *</span>}
          </label>
        )}
        <input
          ref={ref}
          type={type}
          className={`tw-w-full tw-px-4 tw-py-2 tw-text-base tw-border tw-border-gray-300 tw-rounded-lg tw-shadow-sm tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:border-transparent ${className}`}
          {...other}
        />
        {error && (
          <p className="tw-mt-1 tw-text-red-500 tw-text-xs">{error.message}</p>
        )}
      </div>
    );
  },
);
