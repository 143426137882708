import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ipaddress2 } from '../App';
import { Document, Page } from 'react-pdf';
// import { Document, Page } from 'react-pdf';

const ViewAnswerSheet = () => {
  const { student_id } = useParams();
  const { batch_id } = useParams();
  const [pdfData, setPdfData] = useState('');

  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageScale, setPageScale] = useState(1.3);

  //   function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
  //     setNumPages(numPages);
  //   }

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${ipaddress2}/view-answer-sheets/${batch_id}/${student_id}/`)
      .then(response => {
        console.log('PDF URL Response:', response.data);
        if (response.data && response.data.length > 0) {
          const pdfUrl = response.data[0].pdf;
          console.log('PDF URL:', pdfUrl);
          setPdfData(pdfUrl);
        } else {
          console.log('No PDF URL found in the response.');
        }
      })
      .catch(error => {
        console.error('Error fetching PDF URL:', error);
      });
  }, [batch_id, student_id]);

  return (
    <div className="py-4 bg-light">
      <h6 className="container text-primary pb-4 d-flex align-items-center">
        <svg
          onClick={() => {
            navigate(`/assign_students_marks/${batch_id}`);
          }}
          style={{ cursor: 'pointer' }}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          class="bi bi-arrow-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
          />
        </svg>{' '}
        <span className="ms-2">View Answer Sheet</span>
      </h6>
      <div className="row m-0 justify-content-center">
        <div className="col-lg-10 d-flex justify-content-center">
          <div
            id="pdf-container"
            className="d-flex justify-content-center bg-white rounded shadow-sm"
            style={{ overflowY: 'scroll', height: '100vh' }}
          >
            <Document
              file={pdfData}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from({ length: numPages }, (_, index) => (
                <Page
                  key={index + 1}
                  pageNumber={index + 1}
                  scale={pageScale}
                  style={{
                    display: index + 1 === currentPage ? 'block' : 'none',
                  }}
                />
              ))}
            </Document>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAnswerSheet;
