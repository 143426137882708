import { useLocation } from 'react-router-dom';
import EmployeeCardsMain from '../../EmployeeCardsMain';
import EmployeeLeadsData from './EmployeeLeadsData';
import { getUser } from '../../../utils/GetUser';

function EmployeeAllLeadsData() {
  const { pathName, search } = useLocation();

  const queryParams = new URLSearchParams(search);

  const type = queryParams.get('type');

  const isScanned = queryParams.get('isScanned');

  const followUpType = queryParams.get('followUpType');

  const paramsFromURL = {
    type: type,
    isScanned: isScanned,
    followUpType: followUpType,
  };

  const id = getUser();

  return (
    <div className="">
      <EmployeeCardsMain employeeID={id} />
      <EmployeeLeadsData refresh={paramsFromURL} />
    </div>
  );
}

export default EmployeeAllLeadsData;
