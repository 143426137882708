import { parseAsInteger, useQueryState } from 'nuqs';

function PaginatedButtons({ isNext, isPrevious, isFetching }) {
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));

  return (
    <div className="tw-flex tw-justify-between tw-mt-14 tw-items-center tw-bg-slate-200 tw-py-2 tw-px-3 tw-my-2 tw-rounded-lg tw-w-[80%] tw-mx-auto ">
      <button
        disabled={!isPrevious}
        className={`${
          !isPrevious
            ? 'tw-bg-slate-500 tw-cursor-not-allowed tw-text-white'
            : 'tw-bg-blue-300 tw-cursor-pointer'
        } tw-p-2 tw-rounded-lg`}
        onClick={() => {
          setPage(prev => prev - 1);
        }}
      >
        Previous
      </button>
      <div>
        <p
          className={`${
            isFetching ? 'tw-bg-red-400' : 'tw-bg-green-500'
          } tw-p-2 tw-rounded-lg`}
        >
          Current Page: {page}
        </p>
      </div>
      <button
        // style={{ cursor: !isNext && 'not-allowed' }}
        disabled={!isNext}
        className={` ${
          !isNext
            ? 'tw-bg-slate-500 tw-cursor-not-allowed tw-text-white'
            : 'tw-bg-blue-300 tw-cursor-pointer'
        } tw-py-2 tw-px-5 tw-rounded-lg`}
        onClick={() => {
          setPage(prev => prev + 1);
        }}
      >
        Next
      </button>
    </div>
  );
}

export default PaginatedButtons;
