import { useEffect } from 'react';
import MainLayout from '../../Admin_panel/All-leads/CustomerAllLeads/MainLayout';
import useLeadsQueryParams from '../../Hooks/useLeadsQueryParams';
import { parseAsBoolean, parseAsString, useQueryState } from 'nuqs';
import { getUser } from '../../utils/GetUser';
import useLeadsQuery from '../../Hooks/useLeadsQuery';
import { fetchAllB2BLeads } from '../../API/FetchFns/AllB2BLeads';
import ToggleScanDirect from '../../Admin_panel/All-leads/CustomerAllLeads/Walkins/ToggleScanDirect';
import AllFilters from '../FilterForms/AllFilters';
import AllB2BTable from '../../Admin_panel/All-leads/CustomerAllLeads/All/AllB2BTable';
import PaginatedButtons from '../../Admin_panel/All-leads/CustomerAllLeads/All/PaginatedButtons';

const B2BWalkins = ({ settab }) => {
  useEffect(() => {
    settab('walkin');
  }, [settab]);

  const { queryParamsObj } = useLeadsQueryParams();

  const [stage] = useQueryState('stage', parseAsString.withDefault('walkins'));

  const [isScanned] = useQueryState(
    'isScanned',
    parseAsBoolean.withDefault(false),
  );

  const queryParams = {
    ...queryParamsObj,
    stage: stage,
    employee_id: getUser(),
    lt: isScanned,
  };

  const { data, error, isError, isFetching, isLoading } = useLeadsQuery({
    queryParams,
    fetchFn: fetchAllB2BLeads,
    fetchKey: 'WalkinB2BLeadsAdmin',
  });

  data && console.log('WALKK', data?.data);

  isError && console.log(error);

  const isPrevious = Boolean(
    data?.data?.previous || data?.data?.previous || data?.data?.previous,
  );
  const isNext = Boolean(
    data?.data?.next || data?.data?.next || data?.data?.next,
  );

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <ToggleScanDirect />
      <AllFilters />
      <MainLayout loading={isLoading}>
        <AllB2BTable
          leads={data?.data?.results}
          count={data?.data?.count}
        />
        <PaginatedButtons
          isFetching={isFetching}
          isNext={isNext}
          isPrevious={isPrevious}
        />
      </MainLayout>
    </div>
  );
};

export default B2BWalkins;
