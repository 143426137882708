function WalkinHistoryCard({ walkinHistory }) {
  return (
    <>
      <div className="tw-px-2 tw-mb-2 tw-w-full tw-font-semibold ">
        <p>
          Total Walkins Scheduled:
          <span className="tw-mx-2">{walkinHistory?.length}</span>
        </p>
      </div>
      <div className="walkin-history tw-mb-3 tw-max-h-52 tw-overflow-y-auto tw-border tw-rounded-md tw-p-4 tw-shadow-md">
        {walkinHistory?.map((walkin, index) => (
          <div
            key={walkin.id}
            className="walkin-entry tw-border tw-rounded-md tw-p-4 tw-mb-4 tw-shadow-sm"
          >
            <div className="tw-font-semibold tw-text-lg">
              Walk-in {index + 1}
            </div>
            <div className="tw-text-sm tw-mb-2">
              <strong>Expected Walk-in Date:</strong>{' '}
              {new Date(walkin.expected_walkin_date).toLocaleString()}
            </div>
            <div className="tw-text-sm tw-mb-2">
              <strong>Remarks:</strong> {walkin.Remarks}
            </div>
            <div className="tw-text-sm tw-mb-2">
              <strong>Meeting:</strong> {walkin.meeting ? 'Yes' : 'No'}
            </div>
            <div className="tw-text-sm">
              <strong>Meeting Remarks:</strong> {walkin.meeting_remarks}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default WalkinHistoryCard;
