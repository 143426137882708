import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ipaddress, ipaddress2 } from '../../App';
import { toast } from 'react-toastify';

const Add_upcoming_batch = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));

  const [batchtypes, setbatchtypes] = useState([]);
  const [classrooms, setclassrooms] = useState([]);
  const [trainers, settrainers] = useState([]);
  const [available_courses, setavailable_courses] = useState([]);
  const [subcourses, setsubcourses] = useState();

  useEffect(() => {
    fetch_details();
  }, []);

  const fetch_details = () => {
    axios.get(`${ipaddress2}/AddBatchType/${user.email}/`).then(r => {
      console.log('Batch types', r.data);
      setbatchtypes(r.data);
    });

    axios.get(`${ipaddress}/SendCourses/`).then(r => {
      console.log('Courses successfully fetched', r.data);
      setavailable_courses(r.data);
    });

    axios.get(`${ipaddress2}/Classrooms/`).then(r => {
      console.log('Class rooms', r.data);
      setclassrooms(r.data);
    });

    axios.get(`${ipaddress2}/SubCoursesView/${user.email}/`).then(r => {
      console.log('Sub courses', r.data);
      setsubcourses(r.data);
    });

    axios.get(`${ipaddress2}/Trainers/`).then(r => {
      console.log('Trainers', r.data);
      settrainers(r.data);
    });
  };

  const [course, setcourse] = useState('');
  const [start_date, setstart_date] = useState('');
  const [status, setstatus] = useState('');
  const [end_date, setend_date] = useState('');

  const add_batch = () => {
    axios
      .post(`${ipaddress2}/upcomingBatchesView/`, {
        course_id: Number(course),
        expected_start_date: start_date,
        expected_end_date: end_date,
        active_status: status,
      })
      .then(r => {
        console.log('Successfully batch added', r.data);

        setcourse('');
        setstart_date('');
        setend_date('');
        setstatus('');

        toast.success(`Upcoming Batch added successfully`, {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="row m-0">
        <div className="col-md-12 px-0 px-md-5 pb-4">
          <h6 className="fs-5 text-dark m-0 p-2 p-md-4 bg-white mb-3">
            Add Upcoming Batch
          </h6>
          <div className="bg-white py-md-5 row m-0 p-4 px-5">
            <div className="mb-4 col-md-6 col-lg-4 pb-3">
              <label htmlFor="">Course Name</label>
              <select
                type=""
                value={course}
                onChange={e => {
                  setcourse(e.target.value);
                }}
                className="mt-2 form-select shadow-none border-secondary-subtle py-3 "
              >
                <option value="">Select Course</option>
                {available_courses.map(x => {
                  return (
                    <>
                      <option value={x.id}>{x.course_name}</option>
                    </>
                  );
                })}
              </select>
            </div>

            <div className="mb-4 col-lg-4 col-md-6 pb-3">
              <label htmlFor="">Scheduled Start Date</label>
              <input
                type="date"
                value={start_date}
                onChange={e => {
                  const today = new Date();
                  const selectedDate = new Date(e.target.value);

                  if (selectedDate < today.setHours(0, 0, 0, 0)) {
                    alert('You can only select today’s date or future dates.');
                    return false;
                  } else {
                    setstart_date(e.target.value);
                  }
                }}
                className="form-control shadow-none border-secondary-subtle py-3 add-option mt-2"
                placeholder="Enter the amount"
              />
            </div>

            <div className="mb-4 col-lg-4 col-md-6 pb-3">
              <label htmlFor="">Scheduled End Date</label>
              <input
                type="date"
                value={end_date}
                onChange={e => {
                  const today = new Date();
                  const selectedDate = new Date(e.target.value);

                  if (selectedDate < today.setHours(0, 0, 0, 0)) {
                    alert('You can only select today’s date or future dates.');
                    return false;
                  } else {
                    setend_date(e.target.value);
                  }
                }}
                className="form-control shadow-none border-secondary-subtle  py-3 add-option mt-2"
                placeholder="Enter the amount"
              />
            </div>
            <div className="mb-3 col-lg-4 col-md-6">
              <label htmlFor="">Status</label>
              <select
                name=""
                value={status}
                onChange={e => {
                  setstatus(e.target.value);
                }}
                className="form-select py-3  shadow-none border-secondary-subtle mt-2"
                id=""
              >
                <option value="">Select...</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>

            <div className="text-end mt-3">
              <button
                className="btns text-white px-4 py-2"
                onClick={add_batch}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add_upcoming_batch;
