import SectionBreak from './SectionBreak';
import SingleFieldCard from './SingleFieldCard';

function RangeDoubleWrap({
  startDate,
  endDate,
  anyNameOne,
  anyCountOne,
  navToOne,
  anyNameTwo,
  anyCountTwo,
  navToTwo,
  children,
}) {
  return (
    <>
      {startDate || endDate ? (
        <div className="tw-flex">
          <SingleFieldCard
            name={anyNameOne}
            count={anyCountOne}
            navTo={navToOne}
          />
          <SectionBreak />
          <SingleFieldCard
            name={anyNameTwo}
            count={anyCountTwo}
            navTo={navToTwo}
          />
        </div>
      ) : (
        children
      )}
    </>
  );
}

export default RangeDoubleWrap;
