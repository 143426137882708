import React, { useState, useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ipaddress } from '../../App';

const TableExample = () => {
  const [rowData, setRowData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const gridRef = useRef();

  // Fetch non-paginated data
  const fetchNonPaginatedData = useCallback(() => {
    const id = 'EMPA56AF6E';

    axios
      .get(`${ipaddress}/SendPendingAndCompletedRecords/${id}/total_leads/`)
      .then(res => {
        console.log('Data fetched');

        if (res.data?.customer_data) {
          const updatedData = res.data.customer_data;

          setRowData(prevRowData => [...prevRowData, ...updatedData]);
          setFilteredData(prevRowData => [...prevRowData, ...updatedData]);
        } else {
          console.error('Data format is incorrect');
        }
      })
      .catch(err => console.error('Error fetching data:', err));
  }, []);

  // onGridReady to ensure fetching data only after grid mounts
  const onGridReady = useCallback(params => {
    gridRef.current = params.api;
    console.log('Grid is ready. Initiating data fetch...');
    fetchNonPaginatedData();
  }, []);

  // Handle infinite scroll by listening to scroll events
  const onBodyScroll = useCallback(() => {
    const viewport = document.querySelector('.ag-body-viewport');
    const scrollTop = viewport?.scrollTop;
    const scrollHeight = viewport?.scrollHeight;
    const gridHeight = viewport?.clientHeight;

    if (scrollTop + gridHeight >= scrollHeight) {
      console.log('Reached end of scroll — fetching more rows...');
      fetchNonPaginatedData();
    }
  }, []);

  return (
    <div
      className="ag-theme-alpine"
      style={{ height: 600, width: '100%' }}
      onScroll={onBodyScroll}
    >
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={[
          {
            headerName: 'Mobile No',
            field: 'contact_number',
            cellStyle: { textAlign: 'center' }, // Centers the text
          },

          {
            headerName: 'Email',
            field: 'email',
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: 'Course Enquired',
            field: 'course_enquiredd',
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: 'Source',
            field: 'source',
            cellStyle: { textAlign: 'center' },
          },
        ]}
        rowSelection="multiple"
        domLayout="autoHeight"
        onGridReady={onGridReady}
      />
    </div>
  );
};

export default TableExample;
