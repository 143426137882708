import { useQuery } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryState } from 'nuqs';

import { fetchDashData } from '../../../API/FetchFns/Dashboard';

import FollowupSection from '../FollowUpSection';
import SingleFieldCard from '../SingleFieldCard';
import ThreeSectionCard from '../ThreeSectionCard';
import WalkinFieldCard from '../WalkinFieldCard';
import RangeWrap from '../RangeWrap';
import RangeDoubleWrap from '../RangeDoubleWrap';

function MainDashWrap() {
  const params = useParams();
  const location = useLocation();
  const USER = JSON.parse(sessionStorage.getItem('user'));
  // const isAdmin = USER.designation === 'admin';
  const isAdminDash = location.pathname === '/dashboard';

  const emp_id = isAdminDash ? null : params.id;

  const [startDate] = useQueryState('startDate', {
    withDefault: '',
  });
  const [endDate] = useQueryState('endDate', {
    withDefault: '',
  });

  const { data, isLoading, error } = useQuery({
    queryKey: ['DashDataCount', { startDate, endDate, emp_id }],
    queryFn: () => fetchDashData({ startDate, endDate, emp_id }),
  });

  console.log('DASH DATA', data?.data);
  console.log('ERROR', error);

  return (
    <div className="main-dashboard tw-h-max">
      <div className="tw-flex tw-gap-5 tw-flex-wrap">
        {/* /
        
        */}

        <RangeWrap
          anyCount={data?.data?.allB2CTotal}
          anyName={'Total Leads'}
          endDate={endDate}
          startDate={startDate}
          navTo={`/all_leads/?startDate=${startDate}&endDate=${endDate || ''}`}
        >
          <ThreeSectionCard
            name={'All B2C Leads'}
            subNameOne={'This Month'}
            countOne={data?.data?.allB2CTotal?.customer_lead_count || 0}
            navToOne={`/all_leads/?type=CurrentMonth`}
            subNameTwo={'Previous Month'}
            countTwo={
              data?.data?.allB2CTotal?.previous_monthcustomer_lead_count || 0
            }
            navToTwo={`/all_leads/?type=previousMonth`}
            subNameThree={'Total Leads'}
            countThree={data?.data?.allB2CTotal?.all_customer_lead_count || 0}
            navToThree={`/all_leads`}
          />
        </RangeWrap>

        <SingleFieldCard
          count={data?.data?.projectedWalkins?.currentMonth}
          name={'Total Projected Walkins'}
          navTo={'/all_leads/walkin/?stage=projected_walkins'}
        />

        <RangeDoubleWrap
          anyNameOne={'Employee Walkins'}
          anyCountOne={
            data?.data?.allWalkins?.total_filteed_employee_walkin_count
          }
          navToOne={'/all_leads/walkin/'}
          anyNameTwo={'Scanned Walkins'}
          anyCountTwo={
            data?.data?.allWalkins?.total_filtered_direct_walkin_count
          }
          navToTwo={'/all_leads/walkin/?isScanned=true'}
          endDate={endDate}
          startDate={startDate}
        >
          <WalkinFieldCard
            walkinCount={data?.data?.allWalkins}
            name={'Walk-Ins/Meetings'}
          />
        </RangeDoubleWrap>

        <RangeDoubleWrap
          anyNameOne={'Pending Followups'}
          anyCountOne={data?.data?.allWalkins?.filtered_pending_followups}
          navToOne={`/all_leads/followup`}
          anyNameTwo={'Upcoming Followups'}
          anyCountTwo={data?.data?.allWalkins?.filtered_upcomming_follows}
          navToTwo={`/all_leads/followup/?followUpType=upcomings`}
          endDate={endDate}
          startDate={startDate}
        >
          <FollowupSection followUpsCount={data?.data?.followups} />
        </RangeDoubleWrap>

        <RangeWrap
          anyCount={data?.data?.prospects}
          anyName={'Prospects'}
          endDate={endDate}
          startDate={startDate}
        >
          <ThreeSectionCard
            name={'Prospects'}
            subNameOne={'This Month'}
            navToOne={'/all_leads/prospect/?type=currentMonth'}
            countOne={data?.data?.prospects?.currentMonth}
            subNameTwo={'Previous Month'}
            countTwo={data?.data?.prospects?.previousMonth}
            navToTwo={'/all_leads/prospect/?type=previousMonth'}
            subNameThree={'Total Prospects'}
            countThree={data?.data?.prospects?.total}
            navToThree={'/all_leads/prospect'}
          />
        </RangeWrap>

        <RangeWrap
          anyCount={data?.data?.registrations}
          anyName={'Registrations'}
          endDate={endDate}
          startDate={startDate}
        >
          <ThreeSectionCard
            name={'Registrations'}
            subNameOne={'This Month'}
            countOne={data?.data?.registrations?.currentMonth}
            navToOne={'/all_leads/registered/?type=currentMonth'}
            subNameTwo={'Previous Month'}
            countTwo={data?.data?.registrations?.previousMonth}
            navToTwo={'/all_leads/registered/?type=previousMonth'}
            subNameThree={'Total Registrations'}
            countThree={data?.data?.registrations?.total}
            navToThree={'/all_leads/registered'}
          />
        </RangeWrap>

        <RangeWrap
          anyCount={data?.data?.closed}
          anyName={'Closed'}
          endDate={endDate}
          startDate={startDate}
        >
          <ThreeSectionCard
            name={'Closed Leads'}
            subNameOne={'This Month'}
            countOne={data?.data?.closed?.currentMonth}
            navToOne={'/all_leads/closed/?type=currentMonth'}
            subNameTwo={'Previous Month'}
            countTwo={data?.data?.closed?.previousMonth}
            navToTwo={'/all_leads/closed/?type=previousMonth'}
            subNameThree={'Total Closed'}
            countThree={data?.data?.closed?.total}
            navToThree={'/all_leads/closed'}
          />
        </RangeWrap>

        <RangeWrap
          anyCount={data?.data?.other_dpt_leads}
          anyName={'Other Dept. Leads'}
          endDate={endDate}
          startDate={startDate}
        >
          <ThreeSectionCard
            name={'Other Dept. Leads'}
            subNameOne={'This Month'}
            countOne={data?.data?.other_department?.currentMonth}
            navToOne={'/all_leads/otherdepartment/?type=currentMonth'}
            subNameTwo={'Previous Month'}
            countTwo={data?.data?.other_department?.previousMonth}
            navToTwo={'/all_leads/otherdepartment/?type=previousMonth'}
            subNameThree={'Total Other Dept. Leads'}
            countThree={data?.data?.other_department?.total}
            navToThree={'/all_leads/otherdepartment'}
          />
        </RangeWrap>
      </div>
    </div>
  );
}

export default MainDashWrap;
