import { useQueryState } from 'nuqs';
import { useNavLoc } from '../../Hooks/useNavLoc';
import SectionBreak from './SectionBreak';

function WalkinMonthCard({
  monthName,
  empWalkin,
  scanWalkin,
  navToEmp,
  navToScan,
}) {
  const isDataAvailableEmp = empWalkin !== undefined && empWalkin !== null;
  const isDataAvailablescanWalkin =
    scanWalkin !== undefined && scanWalkin !== null;

  const navWithLocation = useNavLoc();

  return (
    <div className="tw-flex tw-flex-col tw-flex-grow tw-justify-center  tw-bg-red-20 tw-p-2 tw-w-[200px]">
      <h2
        className="tw-text-[#A3AED0] tw-text-sm tw-pt-3
      "
      >
        {monthName}
        <span className="tw-text-2xl tw-ml-3">
          {empWalkin + scanWalkin || 0}
        </span>
      </h2>

      <div className="tw-flex tw-mt-1">
        <div
          className="tw-p-2 tw-cursor-pointer tw-pointer-events-auto hover:tw-bg-slate-200 "
          onClick={() => {
            navWithLocation(navToEmp);
          }}
        >
          <h2 className="tw-text-[#A3AED0] tw-text-xs">Employee Walkins</h2>
          <p className="tw-text-3xl tw-h-[2rem] tw-flex tw-items-center">
            {isDataAvailableEmp ? empWalkin : null}
          </p>
        </div>
        <SectionBreak />
        <div
          className="tw-p-2 tw-ml-2 tw-cursor-pointer tw-pointer-events-auto hover:tw-bg-slate-200"
          onClick={() => {
            navWithLocation(navToScan);
          }}
        >
          <h2 className="tw-text-[#A3AED0] tw-text-xs">Scanned Walkins</h2>
          <p className="tw-text-3xl tw-h-[2rem] tw-flex tw-items-center">
            {isDataAvailablescanWalkin ? scanWalkin : null}
          </p>
        </div>
      </div>
    </div>
  );
}

export default WalkinMonthCard;
