import React, { useState } from 'react';

function DateFilter({
  onFetch,
  startDate,
  endDate,
  singleDate,
  setStartDate,
  setSingleDate,
  setEndDate,
}) {
  const [filterType, setFilterType] = useState('current'); // 'single', 'range', 'current'

  const handleFetch = () => {
    console.log('REFTECH', startDate, endDate, singleDate);

    // Pass the correct values based on the filter type
    if (filterType === 'current') {
      onFetch('', '', ''); // Empty values for "current month"
    } else if (filterType === 'single') {
      onFetch('', '', singleDate);
    } else if (filterType === 'range') {
      onFetch(startDate, endDate, '');
    }
  };

  return (
    <div className="tw-flex tw-space-x-2 tw-rounded-lg tw-p-2 tw-w-full tw-justify-end">
      {/* Dropdown */}
      <select
        value={filterType}
        onChange={e => {
          const selectedType = e.target.value;
          setFilterType(selectedType);

          if (selectedType === 'current') {
            setSingleDate('');
            setStartDate('');
            setEndDate('');
          } else if (selectedType === 'single') {
            setStartDate('');
            setEndDate('');
          } else if (selectedType === 'range') {
            setSingleDate('');
          }
        }}
        className="tw-px-2 tw-py-1 tw-border tw-rounded-lg tw-text-gray-700 tw-bg-white tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-400"
      >
        <option value="current">Current Month</option>
        <option value="single">Single Date</option>
        <option value="range">Date Range</option>
      </select>

      {/* Single Date Input */}
      {filterType === 'single' && (
        <input
          type="date"
          value={singleDate}
          onChange={e => setSingleDate(e.target.value)}
          className="tw-px-2 tw-py-1 tw-border tw-rounded-lg tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-400"
        />
      )}

      {/* Date Range Inputs */}
      {filterType === 'range' && (
        <div className="tw-flex tw-space-x-2">
          <input
            type="date"
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
            className="tw-px-2 tw-py-1 tw-border tw-rounded-lg tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-400"
          />
          <input
            type="date"
            value={endDate}
            onChange={e => setEndDate(e.target.value)}
            className="tw-px-2 tw-py-1 tw-border tw-rounded-lg tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-400"
          />
        </div>
      )}

      {/* Action Button */}
      <button
        className="tw-bg-blue-500 tw-text-white tw-px-3 tw-py-1 tw-rounded-lg"
        onClick={handleFetch}
      >
        <i className="fa-solid fa-magnifying-glass"></i>
      </button>
    </div>
  );
}

export default DateFilter;
