import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ipaddress } from '../App';
import LeadStatusButton from './CustomAGComps/LeadStatusDropdown';
import NameCell from './CustomAGComps/NameCell';
import Lead_details from '../Components/Employee_leads/Lead_details';
import RemarksSections from './CustomAGComps/RemarksToolTip';
import ExtraButtons from './CustomAGComps/ExtraButtons';

function TableExampleOrig({
  handleCheckboxChange,
  selectedLeads,
  tableData = [],
  count,
}) {
  console.log('ISB2C TABLE', tableData);

  const gridRef = useRef(null);

  const [rowData, setRowData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [filterCourse, setFilterCourse] = useState('');
  const [filterSource, setFilterSource] = useState('');
  const [filterEmployee, setFilterEmployee] = useState('');

  const [leadId, setLeadId] = useState();
  const [show, setShow] = useState();
  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    if (refreshData) {
      console.log('REFETCH');
      resetGrid();
      setRefreshData(false);
    }
    onGridReady();
  }, [refreshData, tableData]);

  const defaultColDef = {
    sortable: true,
    floatingFilter: true,
    filter: true,
    flex: 1,
    resizable: true,
    cellClass: 'default-cell',
    headerClass: 'default-header',
  };

  const getRowHeight = params => {
    const defaultHeight = 50;
    return params.data?.isExpanded ? 200 : defaultHeight;
  };

  const dateFormatter = params => {
    const date = new Date(params.value);
    return isNaN(date) ? params.value : date.toLocaleDateString();
  };

  const columnDefs = [
    {
      headerName: 'Name',
      field: 'name',
      cellRenderer: NameCell,
      cellRendererParams: params => ({
        rowData: params.data,
        setLeadId,
        setShow,
      }),
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      cellStyle: { textAlign: 'center' }, // Centers the text
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterDate, cellValue) => {
          if (!cellValue) return -1; // Handle empty values gracefully

          // Convert cellValue to a Date object
          const cellDate = new Date(cellValue);
          const filterDateValue = new Date(filterDate);
          console.log(
            'DATES Parsed Cell VALUE:',
            cellValue,
            'DATES Parsed Cell Date:',
            cellDate.toLocaleDateString(),
            'DATES Filter Date:',
            filterDateValue.toLocaleDateString(),
          );

          // Ensure cellDate is valid
          if (isNaN(cellDate)) {
            console.warn(`Invalid date for filtering: ${cellValue}`);
            return -1; // Exclude invalid dates
          }

          // Compare dates
          if (
            cellDate.toLocaleDateString() < filterDateValue.toLocaleDateString()
          )
            return -1;
          if (
            cellDate.toLocaleDateString() > filterDateValue.toLocaleDateString()
          )
            return 1;
          return 0;
        },
        browserDatePicker: true, // Enable browser date picker
      },
    },
    {
      headerName: 'Mobile No',
      field: 'contact_number',
      cellStyle: { textAlign: 'center' }, // Centers the text
    },

    { headerName: 'Email', field: 'email', cellStyle: { textAlign: 'center' } },
    {
      headerName: 'Course Enquired',
      field: 'course_enquiredd',
      cellStyle: { textAlign: 'center', fontSize: 14 },
      valueFormatter: params =>
        params.value
          ? params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          : '',
    },
    {
      headerName: 'Source',
      field: 'source',
      cellStyle: { textAlign: 'center', fontSize: 14 },
      valueFormatter: params =>
        params.value
          ? params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          : '',
    },
    {
      headerName: 'Lead Level Name',
      field: 'level_lead_name',
      // cellStyle: { minWidth: 250 },

      cellRenderer: LeadStatusButton,
      cellRendererParams: params => ({
        rowData: params.data,
        isOpen: openDropdownId === params.data.customer_lead_id,
        setOpenDropdownId,
      }),
    },
    {
      headerName: 'Employee',
      field: 'emp_name',
      cellStyle: { textAlign: 'center' },
      valueFormatter: params =>
        params.value
          ? params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          : '',
    },
    {
      headerName: 'Assigned By',
      field: 'assigned_byy',
      cellStyle: { textAlign: 'center' },
      valueFormatter: params =>
        params.value
          ? params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          : '',
    },
    {
      headerName: 'Remarks',
      field: 'remarks',

      cellRenderer: RemarksSections,
      cellRendererParams: params => ({
        rowData: params.data,
      }),
    },
    {
      headerName: 'Priority',
      field: 'priority',
      cellStyle: { textAlign: 'center' },

      valueFormatter: params =>
        params.value
          ? params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          : '',
    },
    {
      headerName: 'Edit',
      // field: 'priority',
      cellRenderer: ExtraButtons,
      cellRendererParams: params => ({
        rowData: params.data,
        setRefreshData,
      }),
    },
  ];

  const onGridReady = useCallback(() => {
    setRowData(tableData);
    setFilteredData(tableData);
  }, [tableData]);

  const applyFilters = () => {
    let filtered = rowData;

    if (filterCourse) {
      filtered = filtered.filter(
        row =>
          row.course_enquiredd?.toLowerCase() === filterCourse.toLowerCase(),
      );
    }
    if (filterSource) {
      filtered = filtered.filter(
        row => row.source?.toLowerCase() === filterSource.toLowerCase(),
      );
    }
    if (filterEmployee) {
      filtered = filtered.filter(
        row => row.emp_name?.toLowerCase() === filterEmployee.toLowerCase(),
      );
    }

    setFilteredData(filtered);
  };

  const handleFilterChange = (e, setter) => {
    setter(e.target.value);
  };
  const resetGrid = () => {
    gridRef.current.api.setFilterModel(null);
    setFilteredData(rowData);
  };

  useEffect(() => {
    applyFilters();
  }, [filterCourse, filterSource, filterEmployee]);

  const onRowSelected = event => {
    const selectedRow = event.node;

    if (event.node.selected) {
      console.log('Row Selected:', selectedRow);
      console.log('Row Selected:', selectedRow.data);
      selectedLeads.includes(selectedRow.data.customer_lead_id);
    }
    console.log('ROW COUNT', selectedLeads.length);

    handleCheckboxChange(
      selectedRow.selected,
      selectedRow.data.customer_lead_id,
    );
  };

  const uniqueCourses = Array?.from(
    new Set(rowData?.map(item => item.course_enquiredd).filter(Boolean)),
  );
  const uniqueSources = Array?.from(
    new Set(rowData?.map(item => item.source).filter(Boolean)),
  );
  const uniqueEmployees = Array?.from(
    new Set(rowData?.map(item => item.emp_name).filter(Boolean)),
  );

  console.log('TABLE OPENDROPID', openDropdownId);

  const gridOptions = {
    autoSizeStrategy: {
      type: 'fitGridWidth',
      // defaultMinWidth: 100,
    },
  };
  return (
    <div
      id="dataTable"
      className="ag-theme-alpine"
      style={{ height: '78vh', width: '100%' }}
      onClick={e => e.stopPropagation()}
    >
      <div className="tw-flex tw-gap-x-2">
        {count && (
          <div className=" tw-bg-zinc-300 tw-p-2 tw-rounded-md tw-my-2 tw-w-max">
            Total Data:<span className="tw-ml-2 ">{count}</span>
          </div>
        )}
        {/* {filteredData && (
          <div className=" tw-bg-zinc-300 tw-p-2 tw-rounded-md tw-my-2 tw-w-max">
            Filtered Data:
            <span className="tw-ml-2 ">{filteredData?.length}</span>
          </div>
        )} */}
      </div>
      {/* <div className="tw-text-black tw-text-xs">
        {' '}
        Total Rows:
        <span className="tw-mx-2 tw-text-xs">({filteredData?.length})</span>
      </div>
      <div
        className=""
        style={{ padding: '10px', background: '#f0f4f8' }}
      >
        <label>
          Filter by Course:
          <select
            value={filterCourse}
            onChange={e => handleFilterChange(e, setFilterCourse)}
          >
            <option value="">All Courses</option>
            {uniqueCourses.map(course => (
              <option
                key={course}
                value={course}
              >
                {course}
              </option>
            ))}
          </select>
        </label>

        <label style={{ marginLeft: '20px' }}>
          Filter by Source:
          <select
            value={filterSource}
            onChange={e => handleFilterChange(e, setFilterSource)}
          >
            <option value="">All Sources</option>
            {uniqueSources.map(source => (
              <option
                key={source}
                value={source}
              >
                {source}
              </option>
            ))}
          </select>
        </label>

        <label style={{ marginLeft: '20px' }}>
          Filter by Employee:
          <select
            value={filterEmployee}
            onChange={e => handleFilterChange(e, setFilterEmployee)}
          >
            <option value="">All Employees</option>
            {uniqueEmployees.map(emp => (
              <option
                key={emp}
                value={emp}
              >
                {emp}
              </option>
            ))}
          </select>
        </label>

        {refreshData && <div className="tw-bg-red-200">LOADING</div>}
      </div> */}
      {console.log('TABLE', filteredData)}

      <AgGridReact
        ref={gridRef}
        gridOptions={{
          alwaysShowHorizontalScroll: true,
          autoSizeStrategy: { type: 'fitCellContents' },
        }}
        colResizeDefault="shift"
        columnDefs={columnDefs}
        enableBrowserTooltips
        rowData={filteredData}
        defaultColDef={defaultColDef}
        rowSelection={{
          mode: 'multiRow',
          checkboxes: true,
          enableSelectionWithoutKeys: true,
          selectAll: 'filtered',
        }}
        onGridReady={onGridReady}
        getRowHeight={getRowHeight}
        pagination
        columnMenu="new"
        paginationPageSize={20}
        onRowSelected={onRowSelected}
        suppressHorizontalScroll={false}
      />

      <Lead_details
        show={show}
        setshow={setShow}
        id={leadId}
      />
    </div>
  );
}

export default TableExampleOrig;
