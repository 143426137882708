import React, { useEffect, useState } from 'react';
import Sidebar from '../Components/Sidebar';
import Navbar from './Navbar';
import axios from 'axios';
import { ipaddress2 } from '../App';
import { toast } from 'react-toastify';
import { axiosInstance } from '../API/Axios/AxiosClient';
import { useNavLoc } from '../Hooks/useNavLoc';
import AllInvoices from './AllInvoices';

const Approvals = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));

  const [type, settype] = useState('certificate');

  const [certificate_approvals, setcertificate_approvals] = useState([]);

  const [count, setcount] = useState(0);

  useEffect(() => {
    axios.get(`${ipaddress2}/CertificateRequests/${user.email}/`).then(r => {
      console.log('Certificate approvals', r.data);
      setcertificate_approvals(r.data);
    });
  }, [count]);

  const [discounts, setdiscounts] = useState([]);

  const fetch_discounts = () => {
    axios.get(`${ipaddress2}/Dummy/`).then(r => {
      console.log('Discounts', r.data);
      setdiscounts(r.data);
    });
  };

  const approve_certificate = (id, status) => {
    axios
      .post(`${ipaddress2}/CertificateRequests/${user.email}/`, {
        request_id: id,
        status: status,
      })
      .then(r => {
        console.log('Responded', r.data);
        if (status === true) {
          toast.success('Certificate approved', {
            autoClose: 1000,
            theme: 'colored',
            position: 'top-center',
          });
          setcount(count + 1);
        } else {
          toast.warn('Certificate rejected', {
            autoClose: 1000,
            theme: 'colored',
            position: 'top-center',
          });
          setcount(count + 1);
        }
      })
      .catch(err => {
        console.log({
          request_id: id,
          status: status,
        });
      });
  };

  // Reject discount
  const reject_discount = id => {
    axios
      .put(`${ipaddress2}/Dummy/`, {
        id: id,
      })
      .then(r => {
        fetch_discounts();
        toast.success('Rejected successfully', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        setstate(false);
      });
  };

  // Accept discount

  const [count2, setcount2] = useState(0);
  // Reject discount
  const [load, setload] = useState();
  const [due_date, setdue_date] = useState('');

  const accept_discount = () => {
    setload(true);

    lead_data.emp_id = user.emp_id;

    if (lead_data.Course !== null) {
      console.log('ABC', lead_data);
      console.log('XYZ', lead_data.Course);
      // const courses = lead_data.Course.map(x => x.id);
      // const courses = lead_data.Course.id;
      // lead_data.Course = courses;
    }

    const updatedLeadData = {
      ...lead_data,
      initial_payment: null ? 0 : lead_data.initial_payment,
      installment_list: lead_data.installments,
      form_url: `https://crm.skilllearningacademy.com/registration_form`,
      performa_url: `https://crm.skilllearningacademy.com/performa_invoice`,
    };
    setlead_data(updatedLeadData);

    setcount2(count2 + 1);
  };

  const accept_discount2 = () => {
    setload(true);
    lead_data.emp_id = user.emp_id;

    const updatedLeadData = {
      ...lead_data,
      due_date: due_date,
      installment_list: lead_data.installments,
      form_url: `https://crm.skilllearningacademy.com/registration_form`,
      performa_url: `https://crm.skilllearningacademy.com/performa_invoice`,
    };

    setlead_data(updatedLeadData);

    setcount2(count2 + 1);
  };

  useEffect(() => {
    if (count2 > 0) {
      setload(true);
      send();
    }
  }, [count2]);

  const send = () => {
    console.log('FINAL LEAD DATA', lead_data);
    axios
      .post(`${ipaddress2}/MovetoRegistrationViewViaAdmin_mail/`, lead_data)
      .then(r => {
        fetch_discounts();
        toast.success('Accepted successfully', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        setload(false);
        setstate(false);
        setcount2(0);
        console.log('Accepted', lead_data);
      })
      .catch(err => {
        console.log('Err', lead_data);
        setload(false);
        setcount2(0);
      });
    console.log('Accept', lead_data);
  };

  const [lead_data, setlead_data] = useState({});
  const [state, setstate] = useState(false);

  const [installments, setInstallments] = useState([]);

  useEffect(() => {
    if (lead_data.installment_number) {
      setInstallments(
        Array.from({ length: lead_data.installment_number }, () => ''),
      );
    }
  }, [lead_data?.installment_number]);

  const handleInstallmentChange = (index, value) => {
    setInstallments(prev => {
      const newAmounts = [...prev];
      newAmounts[index] = value;
      return newAmounts;
    });
  };

  const display_lead = id => {
    const data = discounts.filter(x => x.id === id);
    console.log('lead', data[0]);
    setlead_data(data[0]);

    setTimeout(() => {
      show();
    }, 1000);
  };

  const show = () => {
    setstate(true);
  };

  const [batch, setbatch] = useState([]);

  const fetch_batch_requests = () => {
    axios.get(`${ipaddress2}/SendBatchCloseRequest/`).then(r => {
      console.log('Batches', r.data);
      setbatch(r.data);
    });
  };

  const accept_batch = (id, value) => {
    axios
      .put(`${ipaddress2}/BatchCloseView/${id}/${user.emp_id}/`, {
        status: value,
      })

      .then(r => {
        fetch_batch_requests();
      });
  };

  const [acceptedData, setAcceptedData] = useState();

  const fetchAcceptanceStatus = () => {
    axiosInstance
      .get(`/PerformaInvoiceAcceptStatus`)
      .then(res => {
        console.log('ACCEPTED DATA', res.data);
        setAcceptedData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const navWithLoc = useNavLoc();

  return (
    <div className="d-flex">
      <Sidebar activevalue={'approvals'} />
      <div className="w-100">
        <Navbar />

        <div
          className="bg-light p-3 main-container"
          style={{ minHeight: '100vh' }}
        >
          <h6 className="pt-3 fs-5">Pending Approvals</h6>
          <div className="d-flex mt-4 pb-3 gap-4">
            <p
              onClick={() => {
                settype('certificate');
              }}
              className="py-2 px-4 d-flex align-items-center pb-3"
              style={{
                color: type === 'certificate' ? '#57309C' : '',
                cursor: 'pointer',
                borderBottom:
                  type === 'certificate' ? '2px solid #57309C' : 'none',
              }}
            >
              Certificate Approvals
            </p>
            <p
              onClick={() => {
                settype('discount');
                fetch_discounts();
              }}
              className="py-2 px-4 d-flex align-items-center pb-3"
              style={{
                color: type === 'discount' ? '#57309C' : '',
                cursor: 'pointer',
                borderBottom:
                  type === 'discount' ? '2px solid #57309C' : 'none',
              }}
            >
              Discount Approvals
            </p>
            <p
              onClick={() => {
                settype('batch');
                fetch_batch_requests();
              }}
              className="py-2 px-4 d-flex align-items-center pb-3"
              style={{
                color: type === 'batch' ? '#57309C' : '',
                cursor: 'pointer',
                borderBottom: type === 'batch' ? '2px solid #57309C' : 'none',
              }}
            >
              Batch Completion Approvals
            </p>
            <p
              onClick={() => {
                settype('acceptance_status');
                fetchAcceptanceStatus();
              }}
              className="py-2 px-4 d-flex align-items-center pb-3"
              style={{
                color: type === 'acceptance_status' ? '#57309C' : '',
                cursor: 'pointer',
                borderBottom:
                  type === 'acceptance_status' ? '2px solid #57309C' : 'none',
              }}
            >
              Acceptance Status
            </p>
            <p
              onClick={() => {
                settype('invoices');
              }}
              className="py-2 px-4 d-flex align-items-center pb-3"
              style={{
                color: type === 'invoices' ? '#57309C' : '',
                cursor: 'pointer',
                borderBottom:
                  type === 'invoices' ? '2px solid #57309C' : 'none',
              }}
            >
              Invoices
            </p>
          </div>

          {/* Certificate Approvals */}
          <div className={`${type === 'certificate' ? '' : 'd-none'}`}>
            <h6
              className={
                certificate_approvals.length > 0
                  ? 'd-none'
                  : 'text-color text-center  py-3'
              }
            >
              No pending approvals...
            </h6>
            <div
              className={`table-responsive ${
                certificate_approvals.length > 0 ? '' : 'd-none'
              }`}
            >
              <table class="w-100">
                <thead>
                  <tr
                    style={{ backgroundColor: '#034592' }}
                    className="text-white"
                  >
                    <th scope="col">
                      <p className=" m-0 fw-medium">SI No</p>
                    </th>
                    <th
                      scope="col"
                      className="py-3"
                    >
                      <p className=" m-0 fw-medium">Applied By</p>
                    </th>
                    <th scope="col">
                      <p className=" m-0 fw-medium">Applied On</p>
                    </th>
                    <th scope="col">
                      <p className=" m-0 fw-medium">Course Name</p>
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {certificate_approvals.map((x, index) => {
                    return (
                      <tr className="table-row">
                        <td
                          scope="row"
                          className="py-3"
                        >
                          <p className=" m-0">{index + 1}</p>
                        </td>
                        <td className="py-3">
                          <p className=" m-0">{x.name}</p>
                        </td>
                        <td className="py-3">
                          <p className=" m-0">
                            {x.created_at && x.created_at.slice(0, 10)}
                          </p>
                        </td>
                        <td className="py-3">
                          <p className=" m-0">
                            {x.course_id &&
                              x.course_id.course_name &&
                              x.course_id.course_name}
                          </p>
                        </td>
                        <td className="py-3">
                          <div className="d-flex align-items-center justify-content-center">
                            <span
                              onClick={() => {
                                approve_certificate(x.id, true);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="green"
                                class="bi bi-check-square-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                              </svg>
                            </span>
                            <span
                              onClick={() => {
                                approve_certificate(x.id, false);
                              }}
                              style={{ cursor: 'pointer' }}
                              className="ms-3"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="red"
                                class="bi bi-x-square-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                              </svg>
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* Discount Approvals */}
          <div className={`${type === 'discount' ? '' : 'd-none'}`}>
            <h6
              className={
                discounts.length > 0 ? 'd-none' : 'text-color text-center  py-3'
              }
            >
              No pending approvals...
            </h6>
            <div
              className={`table-responsive ${
                discounts.length > 0 ? '' : 'd-none'
              }`}
            >
              <table class="w-100">
                <thead className="table-secondary">
                  <tr
                    style={{ backgroundColor: '#034592' }}
                    className="text-white"
                  >
                    <th
                      scope="col"
                      className="py-3"
                    >
                      <p className=" m-0 fw-medium">SI No</p>
                    </th>
                    <th scope="col">
                      <p className=" m-0 fw-medium">Customer ID</p>
                    </th>
                    <th scope="col">
                      <p className=" m-0 fw-medium">Initial Payment</p>
                    </th>
                    <th scope="col">
                      <p className=" m-0 fw-medium">Installment Count</p>
                    </th>
                    <th scope="col">
                      <p className=" m-0 fw-medium">Amount</p>
                    </th>
                    <th scope="col">
                      <p className=" m-0 fw-medium">GST Amount</p>
                    </th>
                    <th scope="col">
                      <p className=" m-0 fw-medium">Installment Days</p>
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {discounts.map((x, index) => {
                    return (
                      <tr className="table-row">
                        <td className="py-3">
                          <p className=" m-0">{index + 1}</p>
                        </td>
                        {x.customer_lead_id && (
                          <td className="py-3">
                            <p
                              className=" m-0 text-primary fw-medium"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                console.log(x.id);

                                display_lead(x.id);
                              }}
                            >
                              {x.customer_lead_id && x.customer_lead_id.name}
                            </p>
                          </td>
                        )}
                        {x.client_lead_id && (
                          <td className="py-3">
                            <p
                              className=" m-0 text-primary fw-medium"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                display_lead(x.id);
                              }}
                            >
                              {x.client_lead_id && x.client_lead_id.name}
                            </p>
                          </td>
                        )}
                        <td className="py-3">
                          <p className=" m-0">{x.initial_payment}</p>
                        </td>
                        <td className="py-3">
                          <p className=" m-0">{x.installment_number}</p>
                        </td>
                        <td className="py-3">
                          <p className=" m-0">{x.grand_total}</p>
                        </td>
                        <td className="py-3">
                          <p className=" m-0">{x.grand_gst_total}</p>
                        </td>
                        <td className="py-3">
                          <p className=" m-0">{x.installment_days}</p>
                        </td>

                        <td className="py-3">
                          <p
                            className={`text-danger fw-medium  m-0 ${
                              x.application_status === 'rejected'
                                ? ''
                                : 'd-none'
                            }`}
                          >
                            Rejected
                          </p>
                          <p
                            className={`text-success fw-medium  m-0 ${
                              x.application_status === 'approved'
                                ? ''
                                : 'd-none'
                            }`}
                          >
                            Approved
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* Batch Approvals */}
          <div className={`${type === 'batch' ? '' : 'd-none'}`}>
            <h6
              className={
                batch.length > 0 ? 'd-none' : 'text-color text-center  py-3'
              }
            >
              No pending approvals...
            </h6>
            <div
              className={`table-responsive ${batch.length > 0 ? '' : 'd-none'}`}
            >
              <table class="w-100">
                <thead>
                  <tr
                    style={{ backgroundColor: '#034592' }}
                    className="text-white"
                  >
                    <th
                      scope="col"
                      className="py-3"
                    >
                      <p className=" m-0 fw-medium">SI No</p>
                    </th>
                    <th scope="col">
                      <p className=" m-0 fw-medium">Batch Id</p>
                    </th>
                    <th scope="col">
                      <p className=" m-0 fw-medium">Course Name</p>
                    </th>
                    <th scope="col">
                      <p className=" m-0 fw-medium">Trainer Name</p>
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {batch.map((x, index) => {
                    return (
                      <tr className="table-row">
                        <td
                          scope="row"
                          className="py-3"
                        >
                          <p
                            className=" m-0"
                            style={{ width: '60px' }}
                          >
                            {index + 1}
                          </p>
                        </td>
                        <td className="py-3">
                          <p
                            className=" m-0"
                            style={{ width: '200px' }}
                          >
                            {x.batch_id}
                          </p>
                        </td>
                        <td className="py-3">
                          <p className=" m-0">
                            {x.name && x.name.sub_course_name}
                          </p>
                        </td>
                        <td className="py-3">
                          <p className=" m-0">
                            {x.trainer && x.trainer.first_name}
                          </p>
                        </td>
                        <td className="py-3">
                          <div className="d-flex align-items-center justify-content-center">
                            <span
                              onClick={() => {
                                accept_batch(x.id, true);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="green"
                                class="bi bi-check-square-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                              </svg>
                            </span>
                            <span
                              onClick={() => {
                                accept_batch(x.id, false);
                              }}
                              style={{ cursor: 'pointer' }}
                              className="ms-3"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="red"
                                class="bi bi-x-square-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                              </svg>
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {acceptedData && (
            <div className={`${type === 'acceptance_status' ? '' : 'd-none'}`}>
              <table className="tw-min-w-full tw-border-collapse tw-border tw-rounded-lg tw-shadow-md">
                <thead>
                  <tr className="tw-bg-gray-200">
                    <th className="tw-border tw-py-2 tw-px-4 tw-font-semibold tw-text-left">
                      Name
                    </th>
                    <th className="tw-border tw-py-2 tw-px-4 tw-font-semibold tw-text-left">
                      Course
                    </th>
                    <th className="tw-border tw-py-2 tw-px-4 tw-font-semibold tw-text-left">
                      Initial Payment
                    </th>
                    <th className="tw-border tw-py-2 tw-px-4 tw-font-semibold tw-text-left">
                      Remaining Total
                    </th>
                    <th className="tw-border tw-py-2 tw-px-4 tw-font-semibold tw-text-left">
                      Grand Total
                    </th>
                    <th className="tw-border tw-py-2 tw-px-4 tw-font-semibold tw-text-left">
                      Acceptance Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {console.log('DATA', acceptedData)}
                  {acceptedData?.map((item, index) => (
                    <tr
                      key={index}
                      className="tw-border-b"
                    >
                      <td
                        className="tw-border tw-py-2 tw-px-4 tw-text-blue-600  tw-cursor-pointer"
                        onClick={() => {
                          console.log('ITEM', item);

                          navWithLoc(
                            `/mark_as_register_form/${item?.customer_id?.registration_id?.customer_lead_id?.customer_lead_id}?edit=${item?.id}`,
                          );
                        }}
                      >
                        {console.log('ITEMM', item?.customer_id)}
                        {item?.customer_id?.customer_name}
                      </td>
                      <td className="tw-border tw-py-2 tw-px-4">
                        {item?.course[0]?.course_name}
                      </td>
                      <td className="tw-border tw-py-2 tw-px-4">
                        {item?.initial_payment[0]?.amount}
                      </td>
                      <td className="tw-border tw-py-2 tw-px-4">
                        {item.grand_total}
                      </td>
                      <td className="tw-border tw-py-2 tw-px-4">
                        {+item.grand_total + +item?.initial_payment[0]?.amount}
                      </td>
                      <td className="tw-border tw-py-2 tw-px-4">
                        {item.acceptance_status === null
                          ? 'Rejected'
                          : item.acceptance_status
                          ? 'Accepted'
                          : 'Pending'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <AllInvoices type={type} />
        </div>
      </div>

      {/* View lead */}
      <div
        className={`p-3 ${
          state ? 'animate__animated animate__fadeIn' : 'd-none'
        }`}
        style={{
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          minHeight: '100vh',
          zIndex: 6,
          backgroundColor: 'rgba(0,0,0,0.6)',
        }}
      >
        <div
          className="row m-0 bg-white p-4 pb-5"
          style={{ height: '100vh', overflowY: 'scroll' }}
        >
          <div className="d-flex align-items-center justify-content-between pb-4">
            <h6 className="m-0 text-primary fs-5">View Lead Details</h6>
            <svg
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setstate(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
            </svg>
          </div>

          {lead_data?.customer_lead_id && (
            <div className="col-12 px-0">
              <div className="row m-0">
                <div className="col-lg-4 mb-4">
                  <div>
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      disabled="true"
                      value={lead_data?.customer_lead_id?.name}
                      className="form-control shadow-none mt-2 py-3"
                      name=""
                      id=""
                    />
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div>
                    <label htmlFor="">Email</label>
                    <input
                      type="text"
                      disabled="true"
                      value={lead_data?.customer_lead_id?.email}
                      className="form-control shadow-none mt-2 py-3"
                      name=""
                      id=""
                    />
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div>
                    <label htmlFor="">Contact Number</label>
                    <input
                      type="tel"
                      disabled="true"
                      value={lead_data?.customer_lead_id?.contact_number}
                      className="form-control shadow-none mt-2 py-3"
                      name=""
                      id=""
                    />
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div>
                    <label htmlFor="">Course Enquired</label>
                    <input
                      type="text"
                      disabled="true"
                      value={lead_data?.Course?.course_name}
                      className={`form-control shadow-none mt-2 py-3 ${
                        lead_data.other_course == null ? '' : 'd-none'
                      }`}
                      name=""
                      id=""
                    />
                    {lead_data?.other_course && (
                      <input
                        type="text"
                        disabled="true"
                        value={lead_data?.customer_lead_id?.othercourseenquired}
                        className="form-control shadow-none mt-2 py-3"
                        name=""
                        id=""
                      />
                    )}
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div>
                    <label htmlFor="">Source</label>
                    <input
                      type="text"
                      disabled="true"
                      value={lead_data?.customer_lead_id?.source}
                      className="form-control shadow-none mt-2 py-3"
                      name=""
                      id=""
                    />
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div>
                    <label htmlFor="">Enquiry Location</label>
                    <input
                      type="text"
                      disabled="true"
                      value={lead_data?.customer_lead_id?.enquiry_location}
                      className="form-control shadow-none mt-2 py-3"
                      name=""
                      id=""
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {lead_data?.client_lead_id && (
            <div className="col-12 px-0">
              <div className="row m-0">
                <div className="col-lg-4 mb-4">
                  <div>
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      disabled="true"
                      value={lead_data?.client_lead_id?.name}
                      className="form-control shadow-none mt-2 py-3"
                      name=""
                      id=""
                    />
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div>
                    <label htmlFor="">Email</label>
                    <input
                      type="text"
                      disabled="true"
                      value={lead_data?.client_lead_id?.email}
                      className="form-control shadow-none mt-2 py-3"
                      name=""
                      id=""
                    />
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div>
                    <label htmlFor="">Contact Number</label>
                    <input
                      type="tel"
                      disabled="true"
                      value={lead_data?.client_lead_id?.contact_number}
                      className="form-control shadow-none mt-2 py-3"
                      name=""
                      id=""
                    />
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div>
                    <label htmlFor="">Purpose</label>
                    <input
                      type="text"
                      disabled="true"
                      value={lead_data?.client_lead_id?.purpose}
                      className={`form-control shadow-none mt-2 py-3`}
                      name=""
                      id=""
                    />
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div>
                    <label htmlFor="">Source</label>
                    <input
                      type="text"
                      disabled="true"
                      value={lead_data?.client_lead_id?.source}
                      className="form-control shadow-none mt-2 py-3"
                      name=""
                      id=""
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="col-lg-4 mb-4">
            <div>
              <label htmlFor="">Grand Total</label>
              <input
                type="text"
                value={+lead_data.grand_total + +lead_data.initial_payment}
                className="form-control shadow-none mt-2 py-3"
                name="grand_total"
                onChange={e => {
                  const { name, value } = e.target;
                  setlead_data(prev => ({
                    ...prev,
                    [name]: value,
                  }));
                }}
                id=""
              />
            </div>
          </div>

          <div className="col-lg-4 mb-4">
            <div>
              <label htmlFor="">Remaining Total</label>
              <input
                type="text"
                value={+lead_data.grand_total}
                className="form-control shadow-none mt-2 py-3"
                name="grand_total"
                onChange={e => {
                  const { name, value } = e.target;
                  setlead_data(prev => ({
                    ...prev,
                    [name]: value,
                  }));
                }}
                id=""
              />
            </div>
          </div>

          <div className="col-lg-4 mb-4">
            <div>
              <label htmlFor="">Registration Amount</label>
              <input
                type="text"
                value={lead_data.initial_payment}
                className="form-control shadow-none mt-2 py-3"
                name="initial_payment"
                onChange={e => {
                  const { name, value } = e.target;
                  setlead_data(prev => ({
                    ...prev,
                    [name]: value,
                  }));
                }}
                id=""
              />
            </div>
          </div>

          <div className="col-lg-4 mb-4 tw-flex tw-items-center">
            <div className="tw-flex tw-items-center tw-gap-3">
              <label
                htmlFor=""
                className="tw-text-lg  tw-font-medium tw-text-gray-700"
              >
                GST Inclusive
              </label>
              <input
                type="checkbox"
                checked={lead_data.inclusive}
                disabled
                name="inclusive"
                onChange={e => {
                  const { name, value } = e.target;
                  setlead_data(prev => ({
                    ...prev,
                    [name]: value,
                  }));
                }}
                className="tw-w-5 tw-h-5 tw-rounded tw-border tw-border-gray-300 tw-bg-gray-100 tw-cursor-not-allowed tw-checked:bg-green-500 tw-checked:border-green-500 tw-opacity-60"
                id=""
              />
            </div>
          </div>

          <div className="col-lg-4 mb-4">
            <div>
              <label htmlFor="">Grand GST Total</label>
              <input
                type="text"
                value={lead_data.grand_gst_total}
                className="form-control shadow-none mt-2 py-3"
                name="grand_gst_total"
                onChange={e => {
                  const { name, value } = e.target;
                  setlead_data(prev => ({
                    ...prev,
                    [name]: value,
                  }));
                }}
                id=""
              />
            </div>
          </div>

          {lead_data.client_lead_id && (
            <div className="col-lg-4 mb-4">
              <div>
                <label htmlFor="">Due Date</label>
                <input
                  type="date"
                  value={due_date}
                  className="form-control shadow-none mt-2 py-3"
                  name="due_date"
                  onChange={e => {
                    setdue_date(e.target.value);
                  }}
                  id=""
                />
              </div>
            </div>
          )}

          {/* <div className="col-lg-4 mb-4">
            <div>
              <label htmlFor="">Installment Days</label>
              <input
                type="text"
                value={lead_data.installment_days}
                className="form-control shadow-none mt-2 py-3"
                name="installment_days"
                onChange={e => {
                  const { name, value } = e.target;
                  setlead_data(prev => ({
                    ...prev,
                    [name]: value,
                  }));
                }}
                id=""
              />
            </div>
          </div> */}

          <div className="col-lg-4 mb-4">
            <div>
              <label htmlFor="">Number of Installments</label>
              <input
                type="text"
                value={lead_data.installment_number}
                className="form-control shadow-none mt-2 py-3"
                name="installment_number"
                onChange={e => {
                  const { name, value } = e.target;
                  setlead_data(prev => ({
                    ...prev,
                    [name]: value,
                  }));
                  setInstallments(
                    Array.from({ length: value }, (_, i) => ({
                      id: i,
                      amount: '',
                    })),
                  );
                }}
                id=""
              />
            </div>
          </div>

          <div className="tw-flex tw-gap-x-3">
            {lead_data?.installments?.map((field, index) => (
              <div
                key={index}
                className="col-lg-4 mb-4 tw-bg-zinc-50 tw-p-2 tw-rounded-md"
              >
                <div>
                  <label htmlFor="">Installment Amount {index + 1}</label>
                  <input
                    type="text"
                    value={field.fees}
                    className="form-control shadow-none mt-2 py-3"
                    name={`installment_amount_${index}`}
                    onChange={e =>
                      handleInstallmentChange(index, Number(e.target.value))
                    }
                    id=""
                  />
                </div>
                <div>
                  <label htmlFor="">Installment Due Date {index + 1}</label>
                  <input
                    type="date"
                    value={field.due_date}
                    className="form-control shadow-none mt-2 py-3"
                    name={`installment_amount_${index}`}
                    onChange={e =>
                      handleInstallmentChange(index, Number(e.target.value))
                    }
                    id=""
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-end mt-3">
            {lead_data.customer_lead_id && (
              <button
                className="btn btn-success px-4 py-1"
                onClick={() => {
                  accept_discount();
                }}
              >
                {load ? 'Accepting...' : 'Accept'}
              </button>
            )}
            {lead_data.client_lead_id && (
              <button
                className="btn btn-success px-4 py-1"
                onClick={() => {
                  accept_discount2();
                }}
              >
                {load ? 'Accepting...' : 'Accept'}
              </button>
            )}
            <button
              className="btn btn-danger px-4 py-1 ms-3"
              onClick={() => {
                reject_discount(lead_data.id);
              }}
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approvals;
