import { useState } from 'react';
import ProfileImg from '../../../images/Group 41.png';

function TwoFieldCards({
  primaryCount,
  primaryName,
  secondaryCount,
  secondaryName,
}) {
  const [state, setstate] = useState(true);

  return (
    <div className="col-md-4 col-lg-3 mb-4 mb-md-0 pe-lg-0">
      <div className="d-flex align-items-center bg-white p-2 dash py-3 justify-content-between">
        <div className="d-flex align-items-center">
          <img
            src={ProfileImg}
            width={44}
            alt=""
          />
          <div className={`ms-2 ${state ? '' : 'd-none'}`}>
            <p className="m-0 dash_data1">{primaryName}</p>
            <p className={`m-0 dash_data d-flex align-items-center`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-currency-rupee"
                viewBox="0 0 16 16"
              >
                <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
              </svg>
              {primaryCount}
            </p>
          </div>

          {/* Monthly refund */}
          <div className={`ms-2 ${state ? 'd-none' : ''}`}>
            <p className="m-0 dash_data1">{secondaryName}</p>
            <p className={`m-0 dash_data d-flex align-items-center`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-currency-rupee"
                viewBox="0 0 16 16"
              >
                <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
              </svg>
              {secondaryCount}
            </p>
          </div>
        </div>
        <div>
          <svg
            onClick={() => {
              setstate(true);
            }}
            style={{ cursor: 'pointer' }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="d-block bi bi-chevron-bar-up"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M3.646 11.854a.5.5 0 0 0 .708 0L8 8.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708M2.4 5.2c0 .22.18.4.4.4h10.4a.4.4 0 0 0 0-.8H2.8a.4.4 0 0 0-.4.4"
            />
          </svg>
          <svg
            onClick={() => {
              setstate(false);
            }}
            style={{ cursor: 'pointer' }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="mt-2 bi bi-chevron-bar-down"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M3.646 4.146a.5.5 0 0 1 .708 0L8 7.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708M1 11.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default TwoFieldCards;
