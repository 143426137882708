import ReactCardFlip from 'react-card-flip';
import { useState } from 'react';
import FinancialWrapper from '../FinancialCards.jsx/FinancialWrapper';
import MainDashWrap from '../MainDashWrapper/MainDashWrap';
import FlipButton from '../FlipButton';

function CardsWrapper() {
  const [flipped, setflipped] = useState(false);

  return (
    <>
      <FlipButton
        onClick={() => {
          setflipped(prev => !prev);
        }}
      />
      <ReactCardFlip
        isFlipped={flipped}
        flipDirection="vertical"
      >
        <MainDashWrap />
        <FinancialWrapper />
      </ReactCardFlip>
    </>
  );
}

export default CardsWrapper;
