import { useState } from 'react';
import SourcesSummary from '../../../SourceSummary/SourcesSummary';
import { useLocation } from 'react-router-dom';

function SourceWiseAll({ setLeads }) {
  const location = useLocation();

  const isAll =
    location.pathname === '/all_leads' ||
    location.pathname === '/all_leads/all' ||
    location.pathname === '/all_monthly_leads' ||
    location.pathname === '/all_leads/';

  const [months, setMonths] = useState({
    startDate: '',
    endDate: '',
    todayDate: '',
  });

  const [isRange, setIsRange] = useState(false);

  return (
    <div>
      {isAll && (
        <SourcesSummary
          fetchData={setLeads}
          endDate={months.endDate}
          startDate={months.startDate}
          singleDate={months.todayDate}
          setIsRange={setIsRange}
          isRange={isRange}
        />
      )}
    </div>
  );
}

export default SourceWiseAll;
