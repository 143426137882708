import { useNavLoc } from '../../Hooks/useNavLoc';
import cardImg from '../../images/Icon.png';

function SingleFieldCard({ count, name, imgCard = cardImg, navTo }) {
  const navWithLoc = useNavLoc();
  const isDataAvailable = count !== undefined && count !== null;

  return (
    <div className=" tw-bg-red-200 tw-w-72 tw-flex ">
      <div className="tw-flex tw-items-center  tw-bg-white tw tw-w-full">
        <div className="tw-w-[100%] tw-m-3 tw-items-center tw-flex-col tw-flex tw-justify-center">
          <h2 className="tw-h-[50%] tw-text-[#A3AED0] tw-font-semibold tw-m-2 tw-text-sm tw-text-center">
            {name}
          </h2>
          <img
            src={imgCard}
            width={44}
            alt=""
          />
        </div>
        <div
          className="tw-bg-green-30 tw-gap-3 tw-w-[40%] tw-p-4 tw-mr-4 tw-flex tw-flex-col tw-cursor-pointer hover:tw-bg-slate-200 tw-pointer-events-auto tw-z-10 "
          onClick={() => {
            navWithLoc(navTo);
          }}
        >
          <p className="tw-text-3xl tw-mx-auto tw-w-full">
            {isDataAvailable ? count : null}
          </p>
        </div>
      </div>
    </div>
  );
}

export default SingleFieldCard;
