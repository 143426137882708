import React, { useEffect } from 'react';
import MainLayout from '../MainLayout';
import CustomerTable from '../All/CustomerAllTable';
import { parseAsBoolean, parseAsString, useQueryState } from 'nuqs';
import { fetchAllDirectWalkinLeads } from '../../../../API/FetchFns/AllB2CLeads';
import ToggleScanDirect from './ToggleScanDirect';
import PaginatedButtons from '../All/PaginatedButtons';
import AllFilters from '../../../../Components/FilterForms/AllFilters';
import useLeadsQueryParams from '../../../../Hooks/useLeadsQueryParams';
import useLeadsQuery from '../../../../Hooks/useLeadsQuery';

const CustomerWalkinLeads = ({ settab }) => {
  useEffect(() => {
    settab('walkin');
  }, [settab]);

  const { queryParamsObj } = useLeadsQueryParams();

  const [stage] = useQueryState('stage', parseAsString.withDefault('walkins'));

  const [isScanned] = useQueryState(
    'isScanned',
    parseAsBoolean.withDefault(false),
  );

  const queryParams = {
    ...queryParamsObj,
    stage: stage,
    // stage: stage === 'projected_walkins' ? 'projected_walkins' : 'walkins',
    lt: isScanned,
  };

  const { data, error, isError, isFetching, isLoading } = useLeadsQuery({
    queryParams,
    fetchFn: fetchAllDirectWalkinLeads,
    fetchKey: 'WalkinB2CLeadsAdmin',
  });

  data && console.log('WALKK', data?.data);

  isError && console.log(error);

  const isPrevious = Boolean(
    data?.data?.previous || data?.data?.previous || data?.data?.previous,
  );
  const isNext = Boolean(
    data?.data?.next || data?.data?.next || data?.data?.next,
  );

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <ToggleScanDirect />
      <AllFilters />
      <MainLayout loading={isLoading}>
        <CustomerTable
          leads={data?.data?.results}
          count={data?.data?.count}
        />
        <PaginatedButtons
          isFetching={isFetching}
          isNext={isNext}
          isPrevious={isPrevious}
        />
      </MainLayout>
    </div>
  );
};

export default CustomerWalkinLeads;
