import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { ipaddress } from '../App';
import { useEffect, useState } from 'react';
import TermsAndConds from './Terms&Conds';

function QuotationProducts({
  lead_id,
  show,
  setshow,
  quotation_id,
  quoteData,
  setQuoteData,
  showTerms,
  setShowTerms,
}) {
  // console.log('LEADID---', lead_id);
  console.log('DORY DATA', quoteData?.service);
  const [selectedTerms, setSelectedTerms] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      lead_id: lead_id,
      termsNConds: selectedTerms,
      service: [
        {
          service_name: '',
          billing_amount: '',
          recurring: '',
          months: '',
          sub_services: [{ name: '' }],
        },
      ],
    },
  });

  useEffect(() => {
    // Update lead_id when it changes
    setValue('lead_id', lead_id);
    console.log('DATAA', quoteData);
    // Reset the form values when quoteData is available
    if (quoteData?.service) {
      console.log('DORY DATA RESET', quoteData?.service);
      reset({ service: quoteData.service });
    }
    if (quoteData?.terms_and_conditions) {
      setSelectedTerms(quoteData?.terms_and_conditions);
    }
  }, [lead_id, quoteData, reset, setValue]);

  const {
    fields: productFields,
    append: appendProduct,
    remove: removeProduct,
  } = useFieldArray({
    name: 'service',
    control,
  });

  // Helper to manage sub-services for each service_name
  const appendSubService = productIndex => {
    const currentSubServices = watch(`service[${productIndex}].sub_services`);
    setValue(
      `service[${productIndex}].sub_services`,
      [...currentSubServices, { name: '' }],
      { shouldValidate: true },
    );
  };

  const removeSubService = (productIndex, subServiceIndex) => {
    const currentSubServices = watch(`service[${productIndex}].sub_services`);
    const updatedSubServices = currentSubServices.filter(
      (_, idx) => idx !== subServiceIndex,
    );
    setValue(`service[${productIndex}].sub_services`, updatedSubServices, {
      shouldValidate: true,
    });
  };

  const generate_quotation = async data => {
    console.log('DORY DATA-----', {
      id: quotation_id,
      lead_id: lead_id,
      service: data.service,
      terms_and_conditions: selectedTerms,
    });

    if (quoteData) {
      try {
        const response = await axios.patch(`${ipaddress}/Quotation/`, {
          id: quotation_id,
          lead_id: lead_id,
          service: data.service,
          terms_and_conditions: selectedTerms,
        });
        console.log(response.data);
        alert('Quotation Updated successfully!');
        setshow(false);
        reset();
      } catch (error) {
        console.error(error);
        alert('Failed to update quotation!');
      }
    } else {
      try {
        const response = await axios.post(`${ipaddress}/Quotation/`, {
          id: quotation_id,
          lead_id: lead_id,
          service: data.service,
          terms_and_conditions: selectedTerms,
        });
        console.log(response.data);
        alert('Quotation generated successfully!');
        setshow(false);
        reset();
      } catch (error) {
        console.error(error);
        alert('Failed to generate quotation!');
      }
    }
  };

  console.log('MODAL INSIDE', show);

  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          reset({
            // lead_id: lead_id,
            service: [
              {
                service_name: '',
                billing_amount: '',
                recurring: '',
                months: '',
                sub_services: [{ name: '' }],
              },
            ],
          });
          // setQuoteData(null);
          setshow(false);
        }}
      >
        <Modal.Header
          closeButton
          className="tw-flex tw-justify-between tw-items-center "
        >
          <Modal.Title className="tw-flex-grow">Generate Quotation</Modal.Title>
          <button
            className="tw-bg-blue-400 tw-px-2 tw-py-1 tw-rounded-xl"
            onClick={() => {
              setShowTerms(true);
            }}
          >
            Terms and Conditions
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="row m-0 p-4">
            <div className="col-12 mb-4">
              <label className="fw-medium">Lead Id</label>
              <input
                type="text"
                disabled
                value={lead_id}
                className="form-control shadow-none border-secondary-subtle py-3 mt-2"
                {...register('lead_id')}
              />
            </div>

            {productFields.map((service_name, productIndex) => (
              <div
                key={service_name.id}
                className="col-12 mb-4"
              >
                <div className="row">
                  <div className="col-12">
                    <label className="fw-medium">Product Description</label>
                    <input
                      type="text"
                      className="form-control shadow-none border-secondary-subtle py-3 mt-2"
                      {...register(`service[${productIndex}].service_name`)}
                    />
                  </div>

                  <div className="col-12">
                    <label className="fw-medium">Billing Amount</label>
                    <input
                      type="number"
                      className="form-control shadow-none border-secondary-subtle py-3 mt-2"
                      {...register(`service[${productIndex}].billing_amount`)}
                    />
                  </div>

                  <div className="col-12">
                    <label className="fw-medium">Recurring</label>

                    <input
                      defaultChecked={false}
                      type="checkbox"
                      name={`service[${productIndex}].recurring`}
                      id={`service[${productIndex}].recurring`}
                      {...register(`service[${productIndex}].recurring`)}
                    />
                  </div>

                  {watch(`service[${productIndex}].recurring`) && (
                    <div className="col-12">
                      <label className="fw-medium">Months</label>
                      <input
                        type="number"
                        className="form-control shadow-none border-secondary-subtle py-3 mt-2"
                        {...register(`service[${productIndex}].months`)}
                      />
                    </div>
                  )}

                  <div className="col-12">
                    <label className="fw-medium">Sub Services</label>
                    {watch(`service[${productIndex}].sub_services`)?.map(
                      (subService, subIndex) => (
                        <div
                          key={subIndex}
                          className="d-flex align-items-center mb-2"
                        >
                          <input
                            type="text"
                            className="form-control me-2"
                            placeholder={`Sub Service ${subIndex + 1}`}
                            {...register(
                              `service[${productIndex}].sub_services[${subIndex}].name`,
                            )}
                          />
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() =>
                              removeSubService(productIndex, subIndex)
                            }
                          >
                            Remove
                          </button>
                        </div>
                      ),
                    )}
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => appendSubService(productIndex)}
                    >
                      Add Sub-Service
                    </button>
                  </div>

                  <div className="col-12 text-end">
                    <button
                      type="button"
                      className="btn btn-danger mt-2"
                      onClick={() => removeProduct(productIndex)}
                    >
                      Remove Product
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <div className="col-12 text-end">
              <button
                type="button"
                className="btn btn-success mt-2"
                onClick={() =>
                  appendProduct({
                    service_name: '',
                    billing_amount: '',
                    recurring: 'no',
                    months: '',
                    sub_services: [{ sub_services: '' }],
                  })
                }
              >
                Add Product
              </button>
            </div>

            <div className="mt-4 text-end">
              <button
                className="btn btn-primary px-4"
                onClick={handleSubmit(generate_quotation)}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <TermsAndConds
        setShowTerms={setShowTerms}
        showTerms={showTerms}
        selectedTerms={selectedTerms}
        setSelectedTerms={setSelectedTerms}
      />
    </div>
  );
}

export default QuotationProducts;
