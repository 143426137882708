import SingleFieldCard from './SingleFieldCard';

function RangeWrap({ startDate, endDate, anyCount, navTo, anyName, children }) {
  return (
    <>
      {startDate || endDate ? (
        <SingleFieldCard
          name={anyName}
          count={anyCount}
          navTo={navTo}
          // name={`${
          //   startDate && endDate
          //     ? `Range From ${startDate}-${endDate}`
          //     : `Leads At ${startDate}`
          // }`}
        />
      ) : (
        children
      )}
    </>
  );
}

export default RangeWrap;
