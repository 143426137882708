function FlipButton({ onClick }) {
  return (
    <button
      onClick={onClick}
      className=" tw-p-2 tw-rounded-full"
    >
      <i class="fa-solid fa-rotate"></i>
    </button>
  );
}

export default FlipButton;
