// import React, { useState, useEffect } from 'react';
// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import axios from 'axios';
// import { ipaddress } from '../App';
// import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';
// import LeadStatusButton from './LeadStatusButton';
// import LeadStatusAGGRID from './LeadStatusAGGRID';

// function Table() {
//   const [rows, setRows] = useState([]);
//   const [sourceOptions, setSourceOptions] = useState([]);
//   const [employeeOptions, setEmployeeOptions] = useState([]);
//   const [courseOptions, setCourseOptions] = useState([]);
//   const [sourceFilter, setSourceFilter] = useState('');
//   const [employeeFilter, setEmployeeFilter] = useState('');
//   const [courseFilter, setCourseFilter] = useState('');

//   const columns = [
//     { field: 'id', headerName: 'ID', width: 70 },
//     { field: 'name', headerName: 'Name', flex: 1 },
//     {
//       field: 'created_at',
//       headerName: 'Created At',
//       flex: 1,
//       type: 'date',
//       valueGetter: params => {
//         const date = new Date(params.value);
//         return isNaN(date.getTime()) ? null : date; // Ensure it's a valid date
//       },
//     },
//     { field: 'contact_number', headerName: 'Mobile No', flex: 1 },
//     { field: 'email', headerName: 'Email', flex: 1 },
//     {
//       field: 'course_enquiredd',
//       headerName: 'Course Enquired',
//       flex: 1,
//       renderCell: params =>
//         courseOptions.includes(params.value) ? params.value : 'N/A',
//     },
//     {
//       field: 'source',
//       headerName: 'Source',
//       flex: 1,
//       renderCell: params =>
//         sourceOptions.includes(params.value) ? params.value : 'N/A',
//     },
//     {
//       field: 'level_lead_name',
//       headerName: 'Lead Level Name',
//       flex: 1,

//       // renderCell: params => <LeadStatusAGGRID x={params} />,
//       renderCell: params => <LeadStatusAGGRID x={params} />,
//     },
//     { field: 'emp_name', headerName: 'Employee', flex: 1 },
//     { field: 'assigned_by', headerName: 'Assigned By', flex: 1 },
//     { field: 'remarks', headerName: 'Remarks', flex: 1 },
//     { field: 'priority', headerName: 'Priority', flex: 1 },
//   ];

//   // Fetch row data
//   const fetchRowData = async () => {
//     const id = 'EMPA56AF6E';
//     try {
//       const res = await axios.get(
//         `${ipaddress}/SendPendingAndCompletedRecords/${id}/total_leads/`,
//       );

//       const formattedData =
//         res.data?.customer_data?.map((item, index) => ({
//           id: index + 1,
//           ...item,
//         })) || [];

//       setRows(formattedData);

//       // Extract unique options from the API response
//       setSourceOptions([...new Set(formattedData.map(item => item.source))]);
//       setEmployeeOptions([
//         ...new Set(formattedData.map(item => item.emp_name)),
//       ]);
//       setCourseOptions([
//         ...new Set(formattedData.map(item => item.course_enquiredd)),
//       ]);
//     } catch (err) {
//       console.error('Data fetching error:', err.message);
//     }
//   };

//   useEffect(() => {
//     fetchRowData();
//   }, []);

//   const handleFilterChange = (e, filterName) => {
//     const value = e.target.value;
//     if (filterName === 'source') setSourceFilter(value);
//     else if (filterName === 'emp_name') setEmployeeFilter(value);
//     else if (filterName === 'course') setCourseFilter(value);
//   };

//   const filteredRows = rows.filter(
//     row =>
//       (!sourceFilter || row.source === sourceFilter) &&
//       (!employeeFilter || row.emp_name === employeeFilter) &&
//       (!courseFilter || row.course_enquiredd === courseFilter),
//   );

//   return (
//     <div style={{ height: 600, width: '100%' }}>
//       <div
//         style={{
//           display: 'flex',
//           justifyContent: 'space-between',
//           padding: 16,
//         }}
//       >
//         {/* Filter dropdowns */}
//         <FormControl fullWidth>
//           <InputLabel>Source</InputLabel>
//           <Select
//             value={sourceFilter}
//             label="Source"
//             onChange={e => handleFilterChange(e, 'source')}
//           >
//             {sourceOptions.map(option => (
//               <MenuItem
//                 key={option}
//                 value={option}
//               >
//                 {option}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>

//         <FormControl fullWidth>
//           <InputLabel>Employee</InputLabel>
//           <Select
//             value={employeeFilter}
//             label="Employee"
//             onChange={e => handleFilterChange(e, 'emp_name')}
//           >
//             {employeeOptions.map(option => (
//               <MenuItem
//                 key={option}
//                 value={option}
//               >
//                 {option}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>

//         <FormControl fullWidth>
//           <InputLabel>Course</InputLabel>
//           <Select
//             value={courseFilter}
//             label="Course"
//             onChange={e => handleFilterChange(e, 'course')}
//           >
//             {courseOptions.map(option => (
//               <MenuItem
//                 key={option}
//                 value={option}
//               >
//                 {option}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       </div>

//       {/* DataGrid displaying rows */}
//       <DataGrid
//         // loading={}
//         components={{ Toolbar: GridToolbar }}
//         rows={filteredRows}
//         columns={columns}
//         pageSize={10}
//         rowsPerPageOptions={[10, 20, 50]}
//         filterMode="server"
//       />
//     </div>
//   );
// }

// export default Table;

import React, { useState, useEffect, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ipaddress } from '../App';
import ClientForms from '../Components/ClientForms';

function LeadStatusButton({ rowData }) {
  const [lead, setLead] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [leadId, setLeadId] = useState('');
  const [stage, setStage] = useState('');
  const [courseName, setCourseName] = useState('');
  const [courseId, setCourseId] = useState('');

  const navigate = useNavigate();

  const handleDropdownChange = e => {
    const action = e.target.value;
    if (action === 'Walk-in') {
      console.log('Performing Walk-in action');
      setShow2(true);
      setLeadId(rowData?.customer_lead_id || rowData?.client_lead_id);
      setStage(rowData?.level_lead_name);
    }
    if (action === 'Follow-Up') {
      console.log('Performing Follow-Up action');
      setShow1(true);
      setLeadId(rowData?.customer_lead_id || rowData?.client_lead_id);
      setStage(rowData?.level_lead_name);
      setCourseName(rowData?.course_enquiredd);
      setCourseId(rowData?.course_enquired);
    }
    if (action === 'Register') {
      navigate(`/mark_as_register_form/${rowData?.customer_lead_id}`);
    }
    if (action === 'Prospects') {
      console.log('Handling prospects for:', rowData?.name);
    }
    if (action === 'Other Department Lead') {
      axios.post(`${ipaddress}/MoveToOtherDepartmentLead/`, {
        customer_lead_id: rowData?.customer_lead_id,
      });
      toast.success('Moved to Other Department Leads');
    }
  };

  return (
    <>
      <select onChange={handleDropdownChange}>
        <option value="">Actions</option>
        <option value="Walk-in">Walk-in</option>
        <option value="Follow-Up">Follow-Up</option>
        <option value="Register">Register</option>
        <option value="Prospects">Prospects</option>
        <option value="Other Department Lead">Other Department Lead</option>
      </select>

      <ClientForms
        show1={show1}
        setshow1={setShow1}
        show2={show2}
        setshow2={setShow2}
        lead_id={leadId}
        client_lead_id={rowData?.client_lead_id}
        stage1={stage}
        setstage={setStage}
        course_name={courseName}
        course_id={courseId}
      />
    </>
  );
}

function TableExample() {
  const [rowData, setRowData] = useState([]);

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  };

  const columnDefs = [
    { headerName: 'Name', field: 'name' },
    { headerName: 'Mobile', field: 'contact_number' },
    { headerName: 'Email', field: 'email' },
    {
      headerName: 'Lead Level Name',
      field: 'level_lead_name',
      cellRenderer: LeadStatusButton,
    },
  ];

  const fetchRowData = useCallback(() => {
    const id = 'EMPA56AF6E';
    axios
      .get(`${ipaddress}/SendPendingAndCompletedRecords/${id}/total_leads/`)
      .then(res => {
        if (res.data && res.data.customer_data) {
          setRowData(res.data.customer_data);
        } else {
          console.error('Data format is incorrect');
        }
      })
      .catch(err => console.error('API call failed:', err));
  }, []);

  const onGridReady = useCallback(params => {
    fetchRowData();
  }, []);

  return (
    <div
      className="ag-theme-alpine"
      style={{ height: '500px', width: '100%' }}
    >
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        pagination
        paginationPageSize={20}
      />
    </div>
  );
}

export default TableExample;

// import React, { useState, useCallback } from 'react';
// import { AgGridReact } from 'ag-grid-react';
// import axios from 'axios';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
// import { ipaddress } from '../App';
// import AgButton from './AGGRIDBUTTON';
// import LeadStatusButton from './LeadStatusDropdown';

// function TableExample() {
//   const [rowData, setRowData] = useState([]);
//   const [open, setOpen] = useState(false);

//   const defaultColDef = {
//     sortable: true,
//     floatingFilter: true,
//     filter: true,
//     flex: 1,
//     resizable: true,
//   };

//   // Custom row height function
//   const getRowHeight = params => {
//     // Default row height
//     const defaultHeight = 50;

//     // Adjust height if the row is expanded
//     if (params.data && params.data.isExpanded) {
//       return 200; // Expanded row height
//     }
//     return defaultHeight; // Default row height
//   };

//   const dateFormatter = params => {
//     const date = new Date(params.value);
//     return isNaN(date) ? params.value : date.toLocaleDateString();
//   };

//   const columnDefs = [
//     { headerName: 'Name', field: 'name' },
//     {
//       headerName: 'Created At',
//       field: 'created_at',
//       valueFormatter: dateFormatter,
//       filter: 'agDateColumnFilter',
//       filterParams: {
//         comparator: (filterDate, cellValue) => {
//           if (!cellValue) return -1;
//           const cellDate = new Date(cellValue);
//           if (cellDate < filterDate) return -1;
//           if (cellDate > filterDate) return 1;
//           return 0;
//         },
//         browserDatePicker: true,
//       },
//     },
//     { headerName: 'Mobile No', field: 'contact_number' },
//     { headerName: 'Email', field: 'email' },
//     { headerName: 'Course Enquired', field: 'course_enquiredd' },
//     { headerName: 'Source', field: 'source' },
//     {
//       headerName: 'Lead Level Name',
//       field: 'level_lead_name',
//       // cellRenderer: AgButton, // Custom cell renderer
//       cellRenderer: LeadStatusButton, // Custom cell renderer
//       cellRendererParams: params => ({
//         rowData: params.data,
//         setRowData, // Pass the setRowData function to modify row data
//         setOpen,
//         open,
//       }),
//     },
//     { headerName: 'Employee', field: 'emp_name' },
//     { headerName: 'Assigned By', field: 'assigned_by' },
//     { headerName: 'Remarks', field: 'remarks' },
//     { headerName: 'Priority', field: 'priority' },
//   ];

//   const onGridReady = useCallback(params => {
//     fetchRowData();
//   }, []);

//   const fetchRowData = useCallback(() => {
//     const id = 'EMPA56AF6E';
//     axios
//       .get(`${ipaddress}/SendPendingAndCompletedRecords/${id}/total_leads/`)
//       .then(res => {
//         if (res.data && res.data.customer_data) {
//           // Add isExpanded property to each row
//           const updatedData = res.data.customer_data.map(row => ({
//             ...row,
//             isExpanded: false,
//           }));
//           setRowData(updatedData);
//         } else {
//           console.error('Data format is incorrect');
//         }
//       })
//       .catch(err => console.error('Error fetching data:', err));
//   }, []);

//   return (
//     <div
//       className="ag-theme-alpine"
//       style={{ height: '600px', width: '100%' }}
//     >
//       <AgGridReact
//         columnDefs={columnDefs}
//         rowData={rowData}
//         defaultColDef={defaultColDef}
//         onGridReady={onGridReady}
//         getRowHeight={getRowHeight} // Set dynamic row height
//         pagination={true}
//         paginationPageSize={20}
//       />
//     </div>
//   );
// }

// export default TableExample;
