import dayjs from 'dayjs';

export const buildQueryString = params => {
  return Object.entries(params)
    .filter(
      ([_, value]) => value !== undefined && value !== null && value !== '',
    )
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&');
};

export const APIQueryString = params => {
  const queryString = Object.entries(params)
    .filter(
      ([_, value]) => value !== undefined && value !== null && value !== '',
    )
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&');

  console.log(queryString);

  return queryString ? `?${queryString}` : '';
};

export const formatDate = date =>
  date ? dayjs(date).format('YYYY-MM-DD') : '';

export const buildURLString = ({ startDate, endDate, singleDate }) => {
  const params = new URLSearchParams();

  if (startDate) params.append('startDate', startDate);
  if (endDate) params.append('endDate', endDate);
  if (singleDate) params.append('singleDate', singleDate);

  return params.toString();
};
