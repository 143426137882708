import { useNavLoc } from '../../Hooks/useNavLoc';

function InvoiceGenerated({ rowData }) {
  const navWithLocation = useNavLoc();

  const { invoice_status, admin_approval, customer_lead_id } = rowData;
  console.log('ENTIRE STATUS', rowData);

  return (
    <div
      className="tw-w-[40%] tw-m-0 tw-text- tw-flex"
      onClick={() => {
        console.log(
          'Entire',
          'BUTTONS',
          'INVOICE STATUS',
          invoice_status,
          'ADMIN APPROVAL',
          admin_approval,
          customer_lead_id,
        );
      }}
    >
      {!invoice_status && !admin_approval && (
        <p className="text-success tw-text-xs">Performa Invoice Generated</p>
      )}
      {admin_approval && !invoice_status && (
        <p className="text-warning tw-text-xs">Admin has to approve</p>
      )}
      {invoice_status && (
        <span
          style={{ cursor: 'pointer', backgroundColor: '#002147' }}
          onClick={() =>
            navWithLocation(`/installment_form/${customer_lead_id}`)
          }
          className="btn btn-sm tw-text-xs text-white rounded-0"
        >
          Invoice Generated
        </span>
      )}
    </div>
  );
}

export default InvoiceGenerated;
