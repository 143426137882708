import { useQueryState } from 'nuqs';
import { useState } from 'react';

function DateFilter() {
  const [startDate, setStartDate] = useQueryState('startDate', {
    withDefault: '',
  });
  const [endDate, setEndDate] = useQueryState('endDate', {
    withDefault: '',
  });

  const [isRange, setIsRange] = useState(false);

  const resetFilter = () => {
    setStartDate('');
    setEndDate('');
  };

  console.log('NUQS', startDate, endDate);

  return (
    <div className="tw-w-[600px] tw-bg-green-5 tw-flex tw-gap-x-3">
      <label className="tw-flex tw-items-center tw-gap-2">
        <input
          type="checkbox"
          checked={isRange}
          onChange={() => setIsRange(!isRange)}
        />
        Range
      </label>

      <div className="form-floating mb-3">
        <input
          type="date"
          className="form-control"
          id="startDateInput"
          value={startDate || ''}
          placeholder="yyyy-mm-dd"
          onChange={e => setStartDate(e.target.value)}
        />
        <label htmlFor="startDateInput">
          {isRange ? 'Start Date' : 'Date'}
        </label>
      </div>

      {isRange && (
        <div className="form-floating mb-3">
          <input
            type="date"
            className="form-control"
            id="endDateInput"
            value={endDate || ''}
            placeholder="yyyy-mm-dd"
            onChange={e => setEndDate(e.target.value)}
          />
          <label htmlFor="endDateInput">End Date</label>
        </div>
      )}

      <button
        className="tw-bg-red-300 tw-p-2 tw-rounded-md tw-h-max tw-mt-2"
        onClick={resetFilter}
      >
        Reset
      </button>
    </div>
  );
}

export default DateFilter;
