import CompanyLogo from '../images/Templates/merida logo white 1.png';
import GoalsSVg from '../Templates/ALL-TEMPS/SVGS/GoalsSVG.svg';
import DocsSVG from '../Templates/ALL-TEMPS/SVGS/DocsSVG.svg';
import AnalysisSVG from '../Templates/ALL-TEMPS/SVGS/AnalysisSVG.svg';
import HexSVG from '../Templates/ALL-TEMPS/SVGS/HexSVG.svg';
import PostSVG from '../Templates/ALL-TEMPS/SVGS/PostSVG.svg';
import RepeatSVG from '../Templates/ALL-TEMPS/SVGS/RepeatSVG.svg';
import WandSVG from '../Templates/ALL-TEMPS/SVGS/MagicWand.svg';

function Trial({ customerData }) {
  const ProfileFields = [
    {
      name: 'Information Setup',
      desc: 'Complete information setup on all the social media platforms',
      svg: DocsSVG,
    },
    {
      name: 'Concept',
      desc: 'Take Concept From Client or create a concept',
      svg: GoalsSVg,
    },
    {
      name: 'Design',
      desc: 'Graphic design',
      svg: WandSVG,
    },
    {
      name: 'Approval',
      desc: 'Take Approval from client',
      svg: HexSVG,
    },
    {
      name: 'Post',
      desc: 'Post on Social Media',
      svg: PostSVG,
    },
    {
      name: 'Analysis',
      desc: 'Analyzing the Reach, Engagement, Audience and Time',
      svg: AnalysisSVG,
    },
    {
      name: 'Repeat',
      desc: 'Plan According to Analysis',
      svg: RepeatSVG,
    },
  ];

  return (
    <div
      // className="tw-h-[2100px] tw-w-[]"
      style={{ backgroundColor: '', height: '2212px', width: '1500px' }}
    >
      <div
        // className="tw-container tw-mx-auto tw-w-[40%]"
        style={{
          boxSizing: 'border-box',
          // backgroundColor: 'bisque',
        }}
      >
        <div
          className="gradient-background"
          // className="tw-relative tw-flex tw-px-16 tw-justify-between tw-p-4 gradient-background"
          style={{
            boxSizing: 'border-box',
            height: '350px',

            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px 75px',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {/* Content */}
          <div
            // className="tw-relative tw-z-10 tw-mb-3  tw-items-center tw-flex tw-border"
            // id="uniq"
            style={{
              alignItems: 'center',
              boxSizing: 'border-box',
              // display: 'flex',
              margin: '0px 0px 10.5px',
              position: 'relative',
              zIndex: 10,
            }}
          >
            <img
              src={CompanyLogo}
              alt=""
              width={250}
              // className="tw-mb-3"
              style={{
                boxSizing: 'border-box',
                margin: '0px 0px 10.5px',
                maxWidth: '100%',
                verticalAlign: 'middle',
              }}
            />
          </div>
          <div
            // className="tw-relative tw-z-10 tw-text-white"
            style={{
              boxSizing: 'border-box',
              position: 'relative',
              zIndex: 10,
            }}
          >
            <h1
              className="tw-text-5xl"
              style={{
                boxSizing: 'border-box',
                color: '#212529',
                fontFamily: 'Lato, sans-serif',
                // fontSize: '40px',
                // lineHeight: '50px',
                fontWeight: 600,
              }}
            >
              {customerData?.customer_name}
            </h1>
            <p
              className="tw-text-3xl"
              style={{
                boxSizing: 'border-box',
                color: '#212529',
                fontFamily: 'Lato, sans-serif',
                // fontSize: '30px',
                // lineHeight: '36px',
              }}
            >
              {customerData?.email}
            </p>
            <p
              className="tw-text-3xl"
              style={{
                boxSizing: 'border-box',
                color: '#212529',
                fontFamily: 'Lato, sans-serif',
                // fontSize: '30px',
                // lineHeight: '36px',
              }}
            >
              Jayanagar, Bangalore
            </p>
            <p
              className="tw-text-3xl"
              style={{
                boxSizing: 'border-box',
                color: '#212529',
                fontFamily: 'Lato, sans-serif',
                // fontSize: '30px',
                // lineHeight: '36px',
                fontStyle: 'italic',
                margin: '7px 0px 0px',
              }}
            >
              +91 9764830503
            </p>
          </div>
        </div>

        <div
          // className="tw-mt-2 tw-px-16"
          style={{
            boxSizing: 'border-box',
            margin: '7px 0px 0px',
            padding: '0px 56px',
          }}
        >
          <h1
            // className="tw-text-2xl tw-font-semibold tw-translate-x-2"
            style={{
              boxSizing: 'border-box',
              color: '#212529',
              fontFamily: 'Lato, sans-serif',
              fontSize: '30px',
              fontWeight: '600',
              lineHeight: '36px',
              transform: 'matrix(1, 0, 0, 1, 7, 0)',
              marginTop: '20px',
              marginBottom: '75px',
            }}
          >
            Our Process of Profile Maintainance
          </h1>
          <div
            // className="tw-flex tw-mt-8"
            style={{
              boxSizing: 'border-box',
              display: 'flex',
              margin: '60px 0px 0px',
            }}
          >
            <div
              className="tw-rounded-b-full tw-rounded-t-full"
              style={{
                background:
                  'linear-gradient(153.45deg, #FF46ED 16.66%, #8B55FF 83.34%)',
                boxSizing: 'border-box',
                height: '1630px',
                width: '16px',
              }}
            />

            <div
              // className=" tw-flex-grow "
              style={{
                boxSizing: 'border-box',
                flexGrow: 1,
              }}
            >
              {ProfileFields.map((each, idx) => {
                return (
                  <div
                    // className=" tw-items-center tw-flex tw-relative py-1 px-5 tw-h-[75px] tw-border-dashed tw-border-slate-400 tw-border-x-0 tw-border-2 tw-border-t-0 "
                    style={{
                      alignItems: 'center',
                      borderBottom: '1.6px dashed #94a3b8',
                      boxSizing: 'border-box',
                      display: 'flex',
                      padding: '3.5px 42px',
                      position: 'relative',
                      height: '245px',
                    }}
                  >
                    <div
                      // className=" tw-text-center tw-text-gray-400 tw-text-2xl mt-2"
                      style={{
                        boxSizing: 'border-box',
                        margin: '7px 0px 0px 30px',
                      }}
                    >
                      <img
                        height={100}
                        src={each.svg}
                        alt=""
                      />
                    </div>
                    <div
                      className=" -tw-translate-x-[192px] -tw-translate-y-36"
                      // className="tw-bg-white -tw-top-7 tw-font-bold -tw-left-[26.45px] tw-border tw-shadow tw-py-2 tw-px-4 tw-absolute tw-rounded-full"
                      style={{
                        backgroundColor: '#ffffff',
                        fontWeight: '700',
                        border: '1px solid #e5e7eb',
                        boxShadow:
                          '#00000000 0px 0px 0px 0px, #00000000 0px 0px 0px 0px, #0000001a 0px 1px 3px 0px, #0000001a 0px 1px 2px -1px',
                        width: '80px',
                        height: '80px',
                        borderRadius: '9999px',
                      }}
                    >
                      <p
                        className="tw-text-4xl tw-translate-x-[35%] tw-translate-y-[30%]"
                        style={{
                          // backgroundColor: '',

                          // borderRadius: '9999px',
                          width: '80px',
                          height: '80px',
                        }}
                      >
                        {idx + 1}
                      </p>
                    </div>

                    <div
                      // className="tw-ml-10 tw-w-[75%] "
                      style={{
                        boxSizing: 'border-box',
                        cursor: 'default',
                        margin: '0px 0px 0px 35px',
                        width: '75%',
                      }}
                    >
                      <h2
                        // className="tw-text-5xl"
                        // className="tw-text-xl tw-font-bold tw-text-[#FF46ED]"
                        style={{
                          boxSizing: 'border-box',
                          color: '#ff46ed',
                          cursor: 'default',
                          fontFamily: 'Lato, sans-serif',
                          fontWeight: '700',
                          fontSize: '48px',
                          lineHeight: '1',
                          marginBottom: '10px',
                        }}
                      >
                        {each.name}
                      </h2>
                      <p
                        // className="tw-mt-1"
                        // className="tw-text-2xl"
                        style={{
                          boxSizing: 'border-box',
                          color: '#212529',
                          cursor: 'default',
                          fontFamily: 'Lato, sans-serif',
                          fontSize: '24px',
                          lineHeight: '32px',
                          margin: '3.5px 0px 0px',
                        }}
                      >
                        {each.desc}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trial;
