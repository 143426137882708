import { useQuery, useQueryClient } from '@tanstack/react-query';
import { parseAsInteger, useQueryState } from 'nuqs';
import { useEffect } from 'react';

function useLeadsQuery({ queryParams, fetchFn, fetchKey }) {
  const [page] = useQueryState('page', parseAsInteger.withDefault(1));

  const queryClient = useQueryClient();

  console.log(queryParams);

  const { data, isLoading, isError, error, isFetching } = useQuery({
    queryKey: [fetchKey, { ...queryParams }],
    queryFn: () => fetchFn(queryParams),
  });

  useEffect(() => {
    if (data?.data?.next) {
      const queryParamsPrefetch = {
        ...queryParams,
        page: page + 1,
      };

      queryClient.prefetchQuery({
        queryKey: [fetchKey, queryParamsPrefetch],
        queryFn: () => fetchFn(queryParamsPrefetch),
      });
    }
  }, [page, queryClient, fetchFn, fetchKey, queryParams, data]);

  return { data, isLoading, isError, error, isFetching };
}

export default useLeadsQuery;
