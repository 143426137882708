import React, { useEffect, useState } from 'react';
import Sidebar from '../Components/Sidebar';
import axios from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import { useNavigate } from 'react-router-dom';
import { buildQueryString, formatDate } from '../utils/QueryBuilder';
import dayjs from 'dayjs';
import MonthWiseCards from '../Admin_panel/MonthWiseCards';
import CollectionsDetails from '../Admin_panel/CollectionsDetails';
import Navbar from '../Admin_panel/Navbar';
import { GetRenderCount } from '../utils/GetRenderCount';
import CardsWrapper from './Cards/CardsWrapper/CardsWrapper';
import DateFilter from './DateFilters/DateFilter';
import ReactCardFlip from 'react-card-flip';
import AllFilters from '../Components/FilterForms/AllFilters';
import MonthWisePredictions from './MonthPredictions/MonthWisePredictions';

// const RenderComponent = ();

const AdminDashboard = () => {
  console.log('ADMINNN');

  const date = new Date();

  const todayDate = dayjs().format('YYYY-MM-DD');

  const [filter_type, setfilter_type] = useState('today');
  const [start_date, setstart_date] = useState(todayDate);
  const [end_date, setend_date] = useState('');

  const MonthName = dayjs(new Date(start_date)).format('MMMM');

  const user = JSON.parse(sessionStorage.getItem('user'));

  const [today_billing, settoday_billing] = useState({});
  const [monthwise_billing, setmonthwise_billing] = useState({});
  const [refunds, setrefunds] = useState({});
  const [monthly_collectables, setmonthly_collectables] = useState({});
  const [leads_count, setleads_count] = useState({});

  useEffect(() => {
    axios.get(`${ipaddress2}/TodaysBilling/`).then(r => {
      console.log(' ', r.data);
      settoday_billing(r.data);
    });

    axios.get(`${ipaddress2}/RefundedAmount/`).then(r => {
      console.log('Refunded Amount', r.data);
      setrefunds(r.data);
    });

    axios.get(`${ipaddress2}/MonthCollection/`).then(r => {
      console.log('Monthy collectables', r.data);
      setmonthly_collectables(r.data);
    });

    axios
      .get(
        `${ipaddress2}/MonthWiseCollection/${date.getFullYear()}/${
          date.getMonth() + 1
        }`,
      )
      .then(r => {
        console.log('Month wise collection', r.data);
        setmonthwise_billing(r.data);
      });

    fetch_statistics(year3, month2);
  }, []);

  const [statistics, setstatistics] = useState();
  const [statistics2, setstatistics2] = useState();

  const fetch_statistics = (year, month) => {
    axios
      .get(`${ipaddress2}/month-wise-collection?month=${year}-${month}`)
      .then(r => {
        console.log('Statistics graph', r.data);
        setstatistics(r.data.billing_amount);
        setstatistics2(r.data.amount_achieved);
      })
      .catch(err => {
        console.log('Statistics error', err);
      });
  };

  console.log('STATS', statistics);
  console.log('STATS', statistics2);

  const [year, setyear] = useState(() => new Date().getFullYear());
  const [year3, setyear3] = useState(() => new Date().getFullYear());
  const [month, setmonth] = useState(() =>
    String(new Date().getMonth() + 1).padStart(2, '0'),
  );
  const [month2, setmonth2] = useState(() =>
    String(new Date().getMonth() + 1).padStart(2, '0'),
  );

  useEffect(() => {
    const currentDate = new Date();
    setyear(currentDate.getFullYear());
    setyear3(currentDate.getFullYear());
    setmonth(String(currentDate.getMonth() + 1).padStart(2, '0'));
    setmonth2(String(currentDate.getMonth() + 1).padStart(2, '0'));
  }, []);

  useEffect(() => {
    fetch_month_prediction(month, year);
    fetchMonthWiseCollections();
  }, []);

  // const [year, setYear] = useState();
  // const [month, setMonth] = useState();

  console.log('MONTH', start_date);

  const [month_wise_prediction, setmonth_wise_prediction] = useState({});

  const fetch_month_prediction = () => {
    axios
      .get(
        `${ipaddress}/NewMonthWisePrediction/${start_date.slice(
          0,
          4,
        )}/?month=${start_date.slice(5, 7)}`,
      )
      .then(r => {
        console.log('Month Wise Prediction', r.data);
        setmonth_wise_prediction(r.data);
      })
      .catch(err => {
        console.log(`${ipaddress}/top-employees/?month=${year}-${month}`);
      });
  };
  // const fetch_month_prediction = () => {
  //   axios
  //     .get(
  //       `${ipaddress}/MonthWisePrediction/${start_date.slice(
  //         0,
  //         4,
  //       )}/${start_date.slice(5, 7)}/`,
  //     )
  //     .then(r => {
  //       console.log('Month Wise Prediction', r.data);
  //       setmonth_wise_prediction(r.data);
  //     })
  //     .catch(err => {
  //       console.log(`${ipaddress}/top-employees/?month=${year}-${month}`);
  //     });
  // };

  const [monthWiseRecords, setMonthWiseRecords] = useState();
  const [activeMonthRecords, setActiveMonthRecords] = useState();

  const fetchMonthWiseCollections = () => {
    axios
      .get(
        `${ipaddress}/NewMonthWisePrediction/${start_date.slice(
          0,
          4,
        )}/?month=${start_date.slice(5, 7)}&type=true`,
      )
      .then(r => {
        console.log('MonthWisePredictionRecords', r.data);
        setMonthWiseRecords(r.data);
      })
      .catch(err => {
        console.log('Employee billing data fetching error', err);
      });
  };

  const [state, setstate] = useState(true);
  const [state2, setstate2] = useState(true);

  const [flipped, setflipped] = useState(false);

  // Filter the Leads count

  const fetch_lead_count = () => {
    axios.get(`${ipaddress2}/AdminCountDisplay/`).then(r => {
      console.log('Leads Count', r.data);
      setleads_count(r.data);
    });
  };

  useEffect(() => {
    fetch_lead_count();
  }, []);

  const filter_lead_count = (start_date, end_date) => {
    axios
      .get(
        `${ipaddress2}/AdminCountDisplay/?start_date=${start_date}&end_date=${end_date}`,
      )
      .then(r => {
        console.log('Filtered Leads Count', r.data);
        setleads_count(r.data);
      });
  };

  const navigate = useNavigate();
  const [lead_type, setlead_type] = useState('customer');

  const [allLeads, setAllLeads] = useState();

  const fetchAllLeads = () => {
    axios
      .get(`${ipaddress}/AllB2Cleads/?all=true&count=true `)

      .then(r => {
        console.log('XYZAll New Customer Leads', r.data);
        console.log('All New Customer Leads', r.data.total_leads);
        setAllLeads(r.data.total_leads);
      })
      .catch(err => {
        console.log('all leads error MUIZ', err);
      });
  };

  const [coursePrCount, setCoursePrCount] = useState();

  const fetchCourseWiseProspectCount = () => {
    axios
      .get(`${ipaddress}/CourseWiseProspectCount/`)
      .then(res => {
        setCoursePrCount(res.data);
        console.log('CourseWiseProspectCount', res.data);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    fetchAllLeads();
    fetchCourseWiseProspectCount();
  }, []);

  const [achivedWalkins, setAchieveWalkins] = useState();

  const fetchAchivedWalkins = () => {
    const queryParams = {
      // emp_id: empID,
      start_date: formatDate(start_date),
      end_date: formatDate(end_date),
    };

    const IPADDRESS = `${ipaddress}/AcheivedWalkin/?${buildQueryString(
      queryParams,
    )}`;

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('ACHIVED', res.data);
        setAchieveWalkins(res.data.Acheived_walkin_count);
      })
      .catch(err => console.log(err));
  };

  const [todayWalkins, setTodayWalkins] = useState();

  const fetchTodayWalkins = () => {
    const queryParams = {
      // emp_id: empID,
      start_date: formatDate(start_date),
      end_date: formatDate(end_date),
    };

    const IPADDRESS = `${ipaddress}/TodayExpectedWalkinsRecords/?${buildQueryString(
      queryParams,
    )}`;

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('MUIZ', res.data);
        setTodayWalkins(res.data);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    fetchAchivedWalkins();
    fetchTodayWalkins();
    fetchRegistrationSummary();
  }, []);

  const [regisSum, setRegisSum] = useState();

  const fetchRegistrationSummary = () => {
    const queryParams = {
      // emp_id: empID,
      start_date: formatDate(start_date),
      end_date: formatDate(end_date),
    };

    const IPADDRESS = `${ipaddress}/RegistrationsSourceWiseAndCourseWise/?${buildQueryString(
      queryParams,
    )}`;

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('MUIZ', res.data);
        setRegisSum(res.data);
      })
      .catch(err => console.log(err));
  };

  const MonthWiseAllFields = [
    {
      name: 'Projected Revenue',
      data: 'projected_details',
      amount: month_wise_prediction?.projected_amount, // From "projected_amount"
    },
    {
      name: 'Billing Amount',
      data: 'billing_details',
      amount: month_wise_prediction?.billing_amount, // From "billing_amount"
    },
    {
      name: 'Registrations Amount',
      data: 'registrations_details',
      amount: month_wise_prediction?.registrations_amount, // From "registrations_amount"
    },
    {
      name: 'New Collectables',
      data: 'new_collectables_details',
      amount: month_wise_prediction?.new_collectables, // From "new_collectables"
    },
    {
      name: 'New Collections',
      data: 'new_collections_details',
      amount: month_wise_prediction?.new_collections, // From "new_collections"
    },
    {
      name: 'New Pending Collections',
      data: 'new_pending_collections_details',
      amount: month_wise_prediction?.new_pending_collections, // From "new_pending_collections"
    },
    {
      name: 'Old Collectables',
      data: 'old_collectables_details',
      amount: month_wise_prediction?.old_collectables, // From "old_collectables"
    },
    {
      name: 'Old Collections',
      data: 'old_collections_details',
      amount: month_wise_prediction?.old_collections, // From "old_collections"
    },
    {
      name: 'Old Pending Collections',
      data: 'old_pending_collections_details',
      amount: month_wise_prediction?.old_pending_collections, // From "old_pending_collections"
    },
    {
      name: 'Next Month Collectables',
      data: 'next_month_collectables_details',
      amount: month_wise_prediction?.next_month_collectables, // From "next_month_collectables"
    },
    {
      name: 'Total Collections',
      data: 'total_collections_details',
      amount: month_wise_prediction?.total_collections, // From "total_collections"
    },
    {
      name: 'Total Net Amount',
      data: 'total_net_amount_details',
      amount: month_wise_prediction?.total_net_amount, // From "total_net_amount"
    },
    {
      name: 'Total Collections GST',
      data: 'total_collections_gst_details',
      amount: month_wise_prediction?.total_collections_gst, // From "total_collections_gst"
    },
  ];

  // const MonthWiseAllFields = [
  //   {
  //     name: 'Projected Revenue',
  //     data: 'projected_details',
  //     amount: month_wise_prediction?.projected_amount,
  //   },
  //   {
  //     name: 'Projected Revenue (Excl. GST)',
  //     data: null,
  //     amount: month_wise_prediction?.projected_amount_without_gst,
  //   },
  //   {
  //     name: 'GST Amount',
  //     data: null,
  //     amount: month_wise_prediction?.gst_amount,
  //   },
  //   {
  //     name: 'Achieved Revenue',
  //     data: 'achieved_details',
  //     amount: month_wise_prediction?.acheived_amount,
  //   },
  //   {
  //     name: 'Total Pending Revenue',
  //     data: 'pending_details',
  //     amount: month_wise_prediction?.pending_collection,
  //   },
  //   {
  //     name: 'Pending Collections (Old)',
  //     data: null,
  //     amount: month_wise_prediction?.pending_old_collection,
  //   },
  //   {
  //     name: 'Pending Collections (New)',
  //     data: null,
  //     amount: month_wise_prediction?.pending_fresh_collection,
  //   },
  //   {
  //     name: 'Overdue Collections',
  //     data: 'overdue_details',
  //     amount: month_wise_prediction?.overdue_amount,
  //   },
  //   {
  //     name: "Today's Billing",
  //     data: null,
  //     amount: today_billing?.billing_amount,
  //   },
  //   {
  //     name: "Today's Collection",
  //     data: null,
  //     amount: today_billing?.amount_acheived,
  //   },
  // ];

  // /NewAdminB2CLeadsCountDisplay/

  return (
    <div className="d-flex tw-relative">
      <Sidebar activevalue={'dashboard'} />
      <div className="w-100">
        <Navbar
          lead_type={lead_type}
          allLeads={allLeads}
          leads_count={leads_count}
        />
        <div
          className="main-container  pt-4 px-2 px-lg-3 pb-4"
          style={{ minHeight: '100vh', backgroundColor: '#F0F0F0' }}
        >
          <div className=" ">
            <div className="row m-0">
              <div
                className="col-12 ps-0"
                style={{ position: 'relative' }}
              >
                {/* <GetRenderCount /> */}
                <DateFilter />
                <CardsWrapper />
              </div>
            </div>

            <MonthWisePredictions />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
