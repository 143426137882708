import axios from 'axios';
import { ipaddress } from '../../App';
import { useEffect, useState } from 'react';
import DataCount from '../All-leads/ClosedFilter/DataCount';
import { buildQueryString } from '../../utils/QueryBuilder';

function ProspectSummary({ fetchData, months }) {
  const [prospectData, setProspectData] = useState();
  const [active, setActive] = useState();

  console.log('PROS', months);

  const user = JSON.parse(sessionStorage.getItem('user'));

  const getCourseWiseProspectCount = () => {
    const queryParams = {
      // emp_id: user.emp_id,
      start_date: months?.startDate,
      end_date: months?.endDate,
      date: months?.todayDate,
    };

    const API = `${ipaddress}/CourseWiseProspectCount/?${buildQueryString(
      queryParams,
    )}`;
    console.log('PROS', API);

    axios
      .get(API)
      .then(res => {
        setProspectData(res.data);
        console.log('CourseWiseProspectCount', res.data);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getCourseWiseProspectCount();
  }, [months]);

  return (
    <div className="tw-p-4 tw-w-[30%] tw-bg-gray-50 tw-rounded-md tw-shadow-md tw-max-h-[400px] tw-overflow-y-auto">
      <h2 className="tw-text-xl tw-font-semibold tw-text-gray-700 tw-mb-4">
        Course Wise Prospects Summary{' '}
        {/* <span
          className="tw-ml-20 tw-bg-blue-500 tw-p-2 tw-cursor-pointer tw-rounded-lg"
          onClick={() => {
            setActive('');
          }}
        >
          Reset
        </span> */}
      </h2>
      {prospectData?.course_wise_count?.length > 0 ? (
        <ul className="tw-space-y-2">
          {prospectData?.course_wise_count?.map((item, idx) => (
            <li
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setActive(idx);
                if (fetchData) {
                  fetchData(item?.course_name);
                }
              }}
              key={idx}
              className={`tw-flex tw-justify-between tw-items-center tw-border tw-rounded-md tw-shadow-sm tw-p-3 tw-transition-transform tw-duration-300 ${
                active === idx
                  ? 'tw-bg-purple-500 tw-text-white'
                  : 'tw-bg-white '
              } `}
            >
              <span className="tw-text-lg tw-capitalize ">
                {item?.course_name || 'N/A'}
              </span>
              <span className="tw-text-xl tw-font-bold ">
                {item?.distinct_lead_count || 0}
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <p className="tw-text-gray-500">No closed data available.</p>
      )}
    </div>
  );
}

export default ProspectSummary;

/* <DataCount
  fetchData={fetchData}
  data={prospectData?.course_wise_count}
  fieldCount={'distinct_lead_count'}
  fieldName={'course_name'}
  title={'Course Wise Prospects Summary'}
/> */
