import { useState } from 'react';
import EmployeeAssign from '../../AssignAnyone.jsx/AssignEmployees';
import TableB2B from '../../../../Trials/TableB2B';

function AllB2BTable({ leads, count }) {
  const [selectedLeads, setSelectedLeads] = useState([]);

  const handleCheckboxChange = (status, id) => {
    if (status) {
      setSelectedLeads(prev => [...prev, id]);
    } else {
      setSelectedLeads(prev => prev.filter(leadId => leadId !== id));
    }
  };

  return (
    <div className="">
      <div className="tw-flex tw-justify-end tw-mt-6 tw-mb-0 ">
        <EmployeeAssign
          selectedLeads={selectedLeads}
          setSelectedLeads={setSelectedLeads}
        />
      </div>
      <TableB2B
        count={count}
        handleCheckboxChange={handleCheckboxChange}
        selectedLeads={selectedLeads}
        tableData={leads}
      />
    </div>
  );
}

export default AllB2BTable;
