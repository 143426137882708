import { useState } from 'react';
import Generate_quotation from '../../Components/Generate_quotation';
import axios from 'axios';
import { ipaddress } from '../../App';
import { useNavigate } from 'react-router-dom';

function GenerateQuotation({ data }) {
  const [quoteData, setQuoteData] = useState();
  const [quotation, setquotation] = useState(false);
  const [quotation_id, setquotation_id] = useState();
  const [show2, setshow2] = useState();
  const [service, setservice] = useState();
  const [service_amount, setservice_amount] = useState();
  const [show3, setshow3] = useState(false);
  const [client_lead_id, setclient_lead_id] = useState('');
  const [count2, setcount2] = useState(0);

  const getQuotation = id => {
    console.log('QUOTE', id);
    axios
      .get(`${ipaddress}/Quotation/?quotation_id=${id}`)
      .then(res => {
        console.log('QUOTE DATA-FETCH', res.data);
        setQuoteData(res.data);
      })
      .catch(err => console.log('QUOTE DATA', err));
  };

  const navigate = useNavigate();
  console.log('DDAATAA INSIDE', data);

  return (
    <>
      {console.log('QUOTE STATS', data.quotation_status)}
      <div className="tw-bg-pink-70 tw-w-full tw-flex">
        <td
          className={`table-row ${
            data.course_enquired == null &&
            (data.othercourseenquired === null ||
              data.othercourseenquired === '') &&
            data.quotation_id == null
              ? ''
              : 'd-none'
          }`}
        >
          <div className="py-2 ">
            <p
              className={` my-2 text-white rounded-0 btn btn-sm px-3 ${
                data.quotation_status ? 'd-none' : ''
              }`}
              style={{ backgroundColor: '#002147' }}
              onClick={() => {
                console.log(
                  'CLICKED GEN QUOTE',
                  data.client_lead_id,
                  data.quotation_id,
                  data,
                );
                setQuoteData(null);
                setclient_lead_id(data.client_lead_id);
                setshow2(true);
              }}
            >
              Generatee Quotation
            </p>
          </div>
        </td>

        <div
          className={`table-row ${
            data.level_lead_name === 'closed' ? 'd-none' : ''
          } ${
            data.course_enquired == null &&
            (data.othercourseenquired === null ||
              data.othercourseenquired === '') &&
            data.quotation_status &&
            data.quotation_id &&
            data.quotation_id.accepted_status !== 'accepted'
              ? ''
              : 'd-none'
          }`}
        >
          <div
            className={
              data.quotation_status
                ? 'd-flex align-items-center justify-content-around'
                : 'd-none'
            }
          >
            {/* Accept quotation btn */}
            <span
              className="m-0 py-2"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate(`/client_register_form/${data.client_lead_id}`);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="green"
                class={`bi bi-check-square-fill`}
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
              </svg>
            </span>
            <span className=" ">
              <svg
                style={{ cursor: 'pointer' }}
                data-bs-toggle="modal"
                data-bs-target="#closedform"
                onClick={() => {
                  setclient_lead_id(data.client_lead_id);
                  //   setstage(data.level_lead_name);
                  setquotation(true);
                  setquotation_id(Number(data.quotation_id.id));
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="red"
                class="bi bi-x-square-fill"
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
              </svg>
            </span>
            <span className="">
              <svg
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // console.log('MUIZ ID', x);

                  setservice(data.quotation_id && data.quotation_id.service);
                  setservice_amount(
                    data.quotation_id && data.quotation_id.quote_amount,
                  );
                  setquotation_id(Number(data.quotation_id.id));
                  setclient_lead_id(data.client_lead_id);
                  // setshow3(true);
                  setshow2(true);

                  getQuotation(+data.quotation_id.id);
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-pencil-square"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                />
              </svg>
            </span>
          </div>
        </div>

        <div
          className={`table-row ${
            !data.invoice_status &&
            data.level_lead_name === 'registered' &&
            data.admin_approval == false
              ? ''
              : 'd-none'
          }`}
        >
          <div className="py-2">
            <p className=" text-success m-0">Performa Invoice Generated</p>
          </div>
        </div>

        <div className={` ${data.admin_approval ? '' : 'd-none'}`}>
          <div className="py-2">
            <p className="tw-text-yellow-400">Admin has to approve</p>
          </div>
        </div>
      </div>
      {console.log('MODAL', show2)}
      <Generate_quotation
        setQuoteData={setQuoteData}
        quoteData={quoteData}
        quotation_id={quotation_id}
        show={show2}
        service={service}
        service_amount={service_amount}
        setshow1={setshow3}
        show1={show3}
        setshow={setshow2}
        lead_id={client_lead_id}
        setcount={setcount2}
      />
    </>
  );
}

export default GenerateQuotation;
