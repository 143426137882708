import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ipaddress } from '../App';
import Arrow_svg from '../Svgs/Arrow_svg';

const Edit_client_lead = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [availablecourses, setAvailablecourses] = useState([]);
  const [lead_levels, setlead_levels] = useState([]);

  const user = JSON.parse(sessionStorage.getItem('user'));

  useEffect(() => {
    axios(`${ipaddress}/AllcoursesnameView/`)
      .then(r => {
        console.log('Courses successfully fetched', r.data);
        setAvailablecourses(r.data.coursename);
      })
      .catch(err => {
        console.log('Courses fetching Error', err);
      });

    axios(`${ipaddress}/Allleadlevels/`)
      .then(r => {
        console.log('Lead levels successfully fetched', r.data);
        setlead_levels(r.data.leadlevels);
      })
      .catch(err => {
        console.log('Lead levels fetching Error', err);
      });

    axios(`${ipaddress}/LeadDetailsView/${id}/`)
      .then(r => {
        console.log('Lead details successfully fetched', r.data);
        setFormData(r.data);
      })
      .catch(err => {
        console.log('Lead details fetching Error', err);
      });
  }, [id]);

  const sourcedata = e => {
    if (e.target.value === 'walkin') {
      document.getElementById('contactedpersondiv').style.display = 'block';
    }
    if (e.target.value === 'other') {
      document.getElementById('othersource').style.display = 'block';
    }
  };

  const [formData, setFormData] = useState({
    emp_id: '',
    name: '',
    email: '',
    contact_number: '',
    company_name: '',
    company_website: '',
    company_field: '',
    company_contact_number: '',
    gst_number: '',
    door_number: '',
    street_name: '',
    area: '',
    state: '',
    city: '',
    pincode: '',
    purpose: '',
    updated_remarks: '',
    num_candidates_trained: '',
    place_of_training: '',
    preferred_duration_per_day: '',
    syllabus: '',
    course_enquired: '',
    enquiry_location: '',
    source: '',
    contacted_person_name: '',
    contacted_person_number: '',
    priority: '',
    level_lead: '',
    preferred_course: '',
    preferred_batch_type: '',
    preferred_batch_start_time: '',
    preferred_class_type: '',
    expected_registration_date: '',
    tentative_batch_start_date: '',
    assigned_to: '',
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = e => {
    const { name, checked, value } = e.target;
    setFormData(prevState => {
      const skills = prevState[name];
      if (checked) {
        skills.push(value);
      } else {
        const index = skills.indexOf(value);
        if (index > -1) {
          skills.splice(index, 1);
        }
      }
      return { ...prevState, [name]: skills };
    });
  };

  const senddetails = e => {
    e.preventDefault();
    setload(true);
    formData.emp_id = user.emp_id;

    axios
      .put(`${ipaddress}/ClientLeadsView/${id}/`, formData)
      .then(r => {
        setload(false);
        console.log('Client successfully updated', r.data);
        toast.success('Successfully updated', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });

        setTimeout(() => {
          navigate('/leads');
        }, 2000);
      })
      .catch(err => {
        setload(false);
        console.log('Client updating error', err);
        console.log(formData);
      });
  };

  const [load, setload] = useState(false);

  // Phone number verification
  const [load2, setload2] = useState();
  const [message2, setmessage2] = useState();

  const verify_phone = phone => {
    // if(phone.length==10){
    setload2(true);
    setTimeout(() => {
      verify2(phone);
    }, 1000);
    // }
  };

  const verify2 = phone => {
    axios
      .post(`${ipaddress}/MobileNumberValidation/`, {
        mobile_number: phone,
        customer: 'Customer',
      })
      .then(r => {
        console.log('Phone number verified', r.data);
        setload2(false);
        setmessage2(true);
        formData.contact_number = phone;
      })
      .catch(err => {
        setmessage2(false);
        setload2(false);
      });
  };

  return (
    <div
      className="animate__animated animate__fadeIn p-1 p-md-2"
      style={{ backgroundColor: '#E8E8E8' }}
    >
      <div className="container-fluid row m-0">
        <div className="my-3 d-flex align-items-center ps-md-0">
          <Link
            className="text-dark"
            to="/leads"
          >
            <Arrow_svg />
          </Link>
          <h5
            className="m-0 ms-2 ms-md-4"
            style={{
              color: '#002147',
              letterSpacing: '1px',
              fontWeight: 600,
              fontSize: '17px',
            }}
          >
            EDIT B2B LEAD
          </h5>
        </div>
        <div className="col-12 bg-white p-2 p-md-4 mb-4">
          <form
            className=""
            onSubmit={senddetails}
          >
            {/* ---------------------------------PERSONAL DETAILS--------------------------------------------------------- */}
            <div className="row m-0 border-bottom pb-2">
              <h6 className="text-color fw-bold mb-4">Personal Details</h6>
              <div className="col-md-6 col-lg-3 mb-4">
                <label
                  for="inputEmail4"
                  className="form-label"
                >
                  Name{' '}
                </label>
                <input
                  type="text"
                  className="form-control shadow-none  py-2"
                  id="inputEmail4"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-4">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="form-control shadow-none  py-2"
                  id="inputPassword4"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-4">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Contact Number
                </label>
                <div class="input-group form-inputs">
                  <input
                    type="tel"
                    maxLength={10}
                    className="form-control shadow-none  py-2 border-0 bg-transparent"
                    id="inputPassword4"
                    name="contact_number"
                    value={formData.contact_number}
                    onChange={e => {
                      verify_phone(e.target.value);
                    }}
                  />
                  <span
                    class="input-group-text border-0 bg-transparent"
                    id="basic-addon2"
                  >
                    <div
                      class={`spinner-border spinner-border-sm text-success ${
                        load2 ? '' : 'd-none'
                      }`}
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#198754"
                      class={`bi bi-check2-circle ${
                        !load2 && message2 == true ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                    </svg>
                  </span>
                </div>
                <p
                  className={`text-danger mt-1 ${
                    message2 == false ? '' : 'd-none'
                  }`}
                  style={{ fontSize: '13px' }}
                >
                  *Mobile Number already exist
                </p>
              </div>
            </div>

            {/* -----------------------------------COMPANY DETAILS------------------------------------------------------------- */}
            <div className="row m-0 border-bottom py-3">
              <h6 className="text-color fw-bold mb-4">Company Details</h6>
              <div className="col-md-6 col-lg-3 mb-4">
                <label
                  for="inputEmail4"
                  className="form-label"
                >
                  Company Name{' '}
                </label>
                <input
                  type="text"
                  className="form-control shadow-none  py-2"
                  id="inputEmail4"
                  value={formData.company_name}
                  name="company_name"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-4">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Company Website{' '}
                </label>
                <input
                  type="text"
                  className="form-control shadow-none  py-2"
                  id="inputPassword4"
                  value={formData.company_website}
                  name="company_website"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-4">
                <label
                  for="inputEmail4"
                  className="form-label"
                >
                  Company Field{' '}
                </label>
                <input
                  type="text"
                  className="form-control shadow-none  py-2"
                  id="inputEmail4"
                  value={formData.company_field}
                  name="company_field"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-4">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Company Contact Number{' '}
                </label>
                <input
                  type="tel"
                  className="form-control shadow-none  py-2"
                  maxLength={10}
                  id="inputPassword4"
                  value={formData.company_contact_number}
                  name="company_contact_number"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-4">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  GST Number
                </label>
                <input
                  type="text"
                  className="form-control shadow-none  py-2"
                  id="inputPassword4"
                  value={formData.gst_number}
                  name="gst_number"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            {/* --------------------------------------COMPANY ADDRESS----------------------------------------------------- */}
            <div className="row m-0 border-bottom py-3">
              <h6 className="text-color fw-bold mb-4">Company Address</h6>
              <div className="col-md-6 col-lg-3 mb-4">
                <label
                  for="inputEmail4"
                  className="form-label"
                >
                  Door Number
                </label>
                <input
                  type="text"
                  className="form-control shadow-none  py-2"
                  id="inputEmail4"
                  value={formData.door_number}
                  name="door_number"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-4">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Street Name
                </label>
                <input
                  type="text"
                  className="form-control shadow-none  py-2"
                  id="inputPassword4"
                  maxLength={10}
                  value={formData.street_name}
                  name="street_name"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-4">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Area
                </label>
                <input
                  type="text"
                  className="form-control shadow-none  py-2"
                  id="inputPassword4"
                  value={formData.area}
                  name="area"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-4">
                <label
                  for="inputState"
                  className="form-label shadow-none"
                >
                  State
                </label>
                <select
                  id="inputState"
                  className="form-select shadow-none  py-2"
                  value={formData.state}
                  name="state"
                  onChange={handleInputChange}
                >
                  <option selected>Choose...</option>
                  <option>Tamilnadu</option>
                </select>
              </div>
              <div className="col-md-6 col-lg-3 mb-4">
                <label
                  for="inputState"
                  className="form-label shadow-none"
                >
                  City
                </label>
                <select
                  id="inputState"
                  className="form-select shadow-none  py-2"
                  value={formData.city}
                  name="city"
                  onChange={handleInputChange}
                >
                  <option selected>Choose...</option>
                  <option>Madurai</option>
                </select>
              </div>
              <div className="col-md-6 col-lg-3 mb-4">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Pincode
                </label>
                <input
                  type="tel"
                  className="form-control shadow-none  py-2"
                  maxLength={6}
                  id="inputPassword4"
                  value={formData.pincode}
                  name="pincode"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            {/* ----------COURSE DETAILS----------------------- */}
            <div className="row m-0 border-bottom py-3">
              <h6 className="text-color fw-bold mb-4">Course Details</h6>
              <div className="col-md-6 col-lg-4 mb-4">
                <label
                  for="inputState"
                  className="form-label shadow-none"
                >
                  Course Enquired{' '}
                </label>
                <input
                  type="text"
                  className="form-control shadow-none  py-2"
                  id="inputPassword4"
                  value={formData.course_enquiredd}
                  disabled="true"
                  name="course_enquiredd"
                />
              </div>
              <div className="col-md-6 col-lg-4 mb-4">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Enquiry Location{' '}
                </label>
                <input
                  type="text"
                  className="form-control shadow-none  py-2"
                  id="inputPassword4"
                  value={formData.enquiry_location}
                  name="enquiry_location"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 col-lg-4 mb-4">
                <label
                  for="inputState"
                  className="form-label shadow-none"
                >
                  Priority{' '}
                </label>
                <select
                  id="inputState"
                  className="form-select shadow-none  py-2"
                  name="priority"
                  value={formData.priority}
                  onChange={handleInputChange}
                >
                  <option selected>Choose...</option>
                  <option>High</option>
                  <option>Medium</option>
                  <option>Low</option>
                </select>
              </div>
              <div></div>
            </div>

            <div className="row m-0 border-bottom py-3">
              <h6 className="text-color fw-bold mb-4">Purpose of enquiry</h6>

              <div className="col-md-6 col-lg-4 mb-4">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Enquiry Purpose{' '}
                </label>
                <input
                  type="text"
                  className="form-control shadow-none  py-2"
                  id="inputPassword4"
                  value={formData.purpose}
                  name="purpose"
                  onChange={handleInputChange}
                />
              </div>

              <div className="row m-0">
                <div className="col-12 mb-4 px-md-0">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Remarks
                  </label>
                  <textarea
                    type="text"
                    rows={3}
                    className="form-control shadow-none  py-2"
                    id="inputPassword4"
                    name="updated_remarks"
                    value={formData.updated_remarks}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 text-end mt-3">
              <button
                type="submit"
                disabled={load}
                className="btns text-white fw-medium px-2 px-lg-5"
              >
                {load ? 'Submitting' : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Edit_client_lead;
