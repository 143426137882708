import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ipaddress, ipaddress2 } from '../App';
import axios from 'axios';
import { toast } from 'react-toastify';

function StudentsGrades({ studentsData }) {
  const navigate = useNavigate();

  const [students, setStudents] = useState();

  useEffect(() => {
    if (studentsData) {
      setStudents(studentsData);
    }
  }, [studentsData]);

  console.log(studentsData, students);

  const sendMail = answerSheetId => {
    axios
      .post(`${ipaddress}/SendMarksToStudent/${answerSheetId}/`)
      .then(res => {
        console.log(res.data);
        toast.success(`Mail Sent Successfully`, {
          autoClose: 1000,
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className="table-responsive mt-2 bg-white p-3">
      <table className="w-100">
        <thead>
          <tr style={{ backgroundColor: '#034592' }}>
            <th
              scope="col"
              className="border-0 py-3"
            >
              <p className="text-center m-0 fw-medium text-white ps-2">SI.No</p>
            </th>
            <th
              scope="col"
              className="border-0"
            >
              <p className="text-center m-0 fw-medium text-white">
                Student Name
              </p>
            </th>
            <th
              scope="col"
              className="border-0"
            >
              <p className="text-center m-0 fw-medium text-white">
                View Answer Sheet
              </p>
            </th>
            <th
              scope="col"
              className="border-0"
            >
              <p className="text-center m-0 fw-medium text-white">
                Total Marks
              </p>
            </th>
            <th
              scope="col"
              className="border-0"
            >
              <p className="text-center m-0 fw-medium text-white">
                Marks Scored
              </p>
            </th>
          </tr>
        </thead>
        <tbody className="border">
          {students?.map((x, index) => (
            <tr
              key={index}
              className="table-row"
            >
              <td className="py-3 border-0 border-end border-start">
                <p className="text-center m-0">{index + 1}</p>
              </td>
              <td className="py-2 border-0 border-end">
                <p className="text-center m-0">{x.student.customer_name}</p>
              </td>
              <td className="py-2 border-0 border-end">
                {x?.marks?.answer_sheet?.pdf ? (
                  <div className="d-flex justify-content-center">
                    <a
                      href={x?.marks?.answer_sheet?.pdf}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Answer Sheet
                    </a>
                  </div>
                ) : (
                  <div className="text-center">N/A</div>
                )}
              </td>
              <td className="border-0 border-end justify-content-center d-flex py-4">
                <p className="text-center  m-0">{x?.marks?.out_of || 'N/A'}</p>
              </td>
              <td className="py-2 border-0 border-end">
                {x?.marks ? (
                  <div className="d-flex justify-content-center gap-4">
                    <div className=" tw-w-28 shadow-none text-center border-secondary-subtle">
                      {x?.marks?.marks_gained}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">N/A</div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default StudentsGrades;
