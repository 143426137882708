import React, { useState, useEffect } from 'react';
import MainLayout from '../MainLayout';
import CustomerAllTable from './CustomerAllTable';
import SourceWiseAll from './SourceWiseAll';
import { fetchAllLeads } from '../../../../API/FetchFns/AllB2CLeads';
import PaginatedButtons from './PaginatedButtons';
import AllFilters from '../../../../Components/FilterForms/AllFilters';
import useLeadsQueryParams from '../../../../Hooks/useLeadsQueryParams';
import useLeadsQuery from '../../../../Hooks/useLeadsQuery';

const CustomerAll = ({ settab }) => {
  useEffect(() => {
    settab('all');
  }, [settab]);

  const [leads, setLeads] = useState([]);

  const { queryParamsObj } = useLeadsQueryParams();

  const queryParams = {
    ...queryParamsObj,
    stage: 'all_leads',
  };

  const { data, error, isError, isLoading, isFetching } = useLeadsQuery({
    queryParams,
    fetchFn: fetchAllLeads,
    fetchKey: 'AllB2CLeads',
  });

  useEffect(() => {
    setLeads(data?.data?.results);
  }, [setLeads, data]);

  data && console.log('DATA', data?.data);
  isError && console.log(error);

  const isPrevious = Boolean(data?.data?.previous);
  const isNext = Boolean(data?.data?.next);

  return (
    <>
      <AllFilters />
      <SourceWiseAll setLeads={setLeads} />
      <MainLayout loading={isLoading}>
        <CustomerAllTable
          leads={leads}
          count={data?.data?.count}
        />
        <PaginatedButtons
          isFetching={isFetching}
          isNext={isNext}
          isPrevious={isPrevious}
        />
      </MainLayout>
    </>
  );
};

export default CustomerAll;
