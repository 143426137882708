import { parseAsBoolean, useQueryState } from 'nuqs';

function ToggleScanDirect() {
  const [isScanned, setIsScanned] = useQueryState(
    'isScanned',
    parseAsBoolean.withDefault(false),
  );
  return (
    <div className="tw-bg-green-300 tw-flex tw-m-2 ">
      <button
        className={`${
          isScanned ? 'tw-bg-white' : 'tw-bg-blue-700'
        } tw-p-2 tw-border`}
        onClick={() => {
          setIsScanned(false);
        }}
      >
        Employee Walkin Leads
      </button>
      <button
        className={`${
          !isScanned ? 'tw-bg-white' : 'tw-bg-blue-700'
        } tw-p-2 tw-border`}
        onClick={() => {
          setIsScanned(true);
        }}
      >
        Scanned Walkin Leads
      </button>
    </div>
  );
}

export default ToggleScanDirect;
