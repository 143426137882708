import { APIQueryString } from '../../utils/QueryBuilder';
import { axiosInstance } from '../Axios/AxiosClient';

export const fetchAllLeads = queryParams => {
  return axiosInstance.get(
    `/NewStageWiseB2CLeadsDisplay/${APIQueryString(queryParams)}`,
  );
};

export const fetchAllNewLeads = queryParams => {
  return axiosInstance.get(
    `/NewStageWiseB2CLeadsDisplay/${APIQueryString(queryParams)}`,
  );
};

export const fetchAllDirectWalkinLeads = queryParams => {
  return axiosInstance.get(
    `/NewStageWiseB2CLeadsDisplay/${APIQueryString(queryParams)}`,
  );
};

export const fetchAllPendingFollowupsLeads = queryParams => {
  return axiosInstance.get(
    `/NewStageWiseB2CLeadsDisplay/${APIQueryString(queryParams)}`,
  );
};

export const fetchAllRegistrationsLeads = queryParams => {
  return axiosInstance.get(
    `/NewStageWiseB2CLeadsDisplay/${APIQueryString(queryParams)}`,
  );
};

export const fetchAllClosedLeads = queryParams => {
  return axiosInstance.get(
    `/NewStageWiseB2CLeadsDisplay/${APIQueryString(queryParams)}`,
  );
};

export const fetchAllProspectsLeads = queryParams => {
  return axiosInstance.get(
    `/NewStageWiseB2CLeadsDisplay/${APIQueryString(queryParams)}`,
  );
};

export const fetchEmployeeLeads = queryParams => {
  return axiosInstance.get(
    `/NewStageWiseB2CLeadsDisplay/${APIQueryString(queryParams)}`,
  );
};
