import cardImg from '../../images/Icon.png';
import SectionBreak from './SectionBreak';
import SingleFieldCard from './SingleFieldCard';
import WalkinMonthCard from './WalkinMonthCard';

function WalkinFieldCard({ walkinCount, name, imgCard = cardImg, navTo }) {
  return (
    <div className="tw-bg-red-20 tw-cursor-pointer ">
      <div className="tw-flex tw-items-center tw-bg-white tw-h-full tw-w-full">
        <div className="tw-w-[30%] tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center">
          <h2 className="tw-text-[#A3AED0] tw-text-sm tw-mt-3 tw-px-3 tw-bg-red-20">
            Walkins
            <span className="tw-pl-1">/ Meetings</span>
          </h2>

          <img
            src={imgCard}
            width={44}
            alt=""
          />
        </div>

        <div className="tw-flex tw-gap-x-3 tw-p-2">
          <WalkinMonthCard
            monthName={'This Month'}
            empWalkin={walkinCount?.currentMonth?.employeeWalkins}
            scanWalkin={walkinCount?.currentMonth?.scannedWalkins}
            navToEmp={'/all_leads/walkin/?type=currentMonth'}
            navToScan={'/all_leads/walkin/?type=currentMonth&isScanned=true'}
          />
          <SectionBreak />

          <WalkinMonthCard
            monthName={'Previous Month'}
            empWalkin={walkinCount?.previousMonth?.employeeWalkins}
            scanWalkin={walkinCount?.previousMonth?.scannedWalkins}
            navToEmp={'/all_leads/walkin/?type=previousMonth'}
            navToScan={'/all_leads/walkin/?type=previousMonth&isScanned=true'}
          />
          <SectionBreak />

          <WalkinMonthCard
            monthName={'Today Walk-In Leads'}
            empWalkin={walkinCount?.today?.employeeWalkins}
            scanWalkin={walkinCount?.today?.scannedWalkins}
            navToEmp={'/all_leads/walkin?type=today'}
            navToScan={'/all_leads/walkin/?isScanned=true&type=today'}
          />

          <SectionBreak />

          <WalkinMonthCard
            monthName={'Total Walk-In Leads'}
            empWalkin={walkinCount?.total?.employeeWalkins}
            scanWalkin={walkinCount?.total?.scannedWalkins}
            navToEmp={'/all_leads/walkin'}
            navToScan={'/all_leads/walkin/?isScanned=true'}
          />
        </div>
      </div>
    </div>
  );
}

export default WalkinFieldCard;
