import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ipaddress, ipaddress2, role } from '../App';
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Slick_button_right from '../Slick_button_right';
import Slick_button_left from '../Slick_button_left';
import Preloader from '../Components/Preloader';
import Navbar from './Navbar';
import Sidebar from '../Components/Sidebar';
import ClientForms from '../Components/ClientForms';
import * as XLSX from 'xlsx';
import Client_all_leads from './All-client-leads/Client_all_leads';
import Client_new_leads from './All-client-leads/Client_new_leads';
import Client_walkin_leads from './All-client-leads/Client_walkin_leads';
import Client_followup_leads from './All-client-leads/Client_followup_leads';
import Client_prospect_leads from './All-client-leads/Client_prospect_leads';
import Client_registered_leads from './All-client-leads/Client_registered_leads';
import Client_closed_leads from './All-client-leads/Client_closed_leads';
import ClientAllLeads from './AllClientLeads/ClientAllLeads';
import ClientNewLeads from './AllClientLeads/ClientNewLeads';
import ClientWalkinsLeads from './AllClientLeads/ClientWalkinsLeads';
import ClientFollowUps from './AllClientLeads/ClientFollowUps';
import ClientProspects from './AllClientLeads/ClientProspects';
import ClientRegistered from './AllClientLeads/ClientRegistered';
import ClientClosedLeads from './AllClientLeads/ClientClosed';

const All_B2B_leads = ({ designation }) => {
  console.log(designation);
  const [tab, setTab] = useState('all');
  const navigate = useNavigate();

  const renderTooltip = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      Upload Excel
    </Tooltip>
  );

  const renderTooltip2 = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      Download Template
    </Tooltip>
  );

  const renderTooltip3 = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      View Invoice
    </Tooltip>
  );

  const [loading, setLoading] = useState(true);

  const [client_lead_id, setclient_lead_id] = useState('');
  const [lead_id, setlead_id] = useState('');
  const [stage, setstage] = useState('');

  const [leads, setleads] = useState([]);
  const [followupleads, setfollowupleads] = useState([]);
  const [prospectleads, setprospectleads] = useState([]);
  const [registeredleads, setregisteredleads] = useState([]);
  const [closedleads, setclosedleads] = useState([]);
  const [allleads, setallleads] = useState([]);

  const user = JSON.parse(sessionStorage.getItem('user'));
  const [count, setCount] = useState(0);

  const [employees, setemployees] = useState([]);

  // -------------------------------------------------Fetch all the assigned employees--------------------------------------------
  const fetchemployees = () => {
    axios
      .get(`${ipaddress}/SupervisorView/${user.emp_id}/`)
      .then(r => {
        console.log('Employees data', r.data);
        setemployees(r.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [original_newleads, setoriginal_newleads] = useState({});

  const fetchleads = () => {
    axios
      .get(`${ipaddress}/AllB2Bleads/?stage=newlead`)
      .then(r => {
        console.log('Client new Leads', r.data);
        setoriginal_newleads(r.data);
        setleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [newleadapi, setnewleadapi] = useState('');

  useEffect(() => {
    if (newleadapi !== '') {
      axios
        .get(`${newleadapi}`)
        .then(r => {
          console.log('Customer followup Leads', r.data);
          setoriginal_newleads(r.data);
          setleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [newleadapi]);

  // --------------------------------------Fetch Walkin Leads----------------------------------------------------
  const [walkinleads, setwalkinleads] = useState([]);
  const [original_walkindata, setoriginal_walkindata] = useState({});

  const fetchwalkinleads = () => {
    axios
      .get(`${ipaddress}/AllB2Bleads/?stage=walkin`)
      .then(r => {
        console.log('Client walkin Leads', r.data);
        setoriginal_walkindata(r.data);
        setwalkinleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [walkinapi, setwalkinapi] = useState('');

  useEffect(() => {
    if (walkinapi !== '') {
      axios
        .get(`${walkinapi}`)
        .then(r => {
          console.log('Customer followup Leads', r.data);
          setoriginal_walkindata(r.data);
          setwalkinleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [walkinapi]);

  // Fetch Followup Leads
  const [original_followupleads, setoriginal_followupleads] = useState({});

  const fetchfollowupleads = () => {
    axios
      .get(`${ipaddress}/AllB2Bleads/?stage=followup`)
      .then(r => {
        console.log('Client Followup Leads', r.data);
        setoriginal_followupleads(r.data);
        setfollowupleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [followupapi, setfollowupapi] = useState('');

  useEffect(() => {
    if (followupapi !== '') {
      axios
        .get(`${followupapi}`)
        .then(r => {
          console.log('Customer followup Leads', r.data);
          setoriginal_followupleads(r.data);
          setfollowupleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [followupapi]);

  // Fetch today followup leads
  const fetch_today_followupleads = () => {
    axios
      .post(`${ipaddress}/ClientFollowupleadsView/${user.emp_id}/`, {
        value: 'today',
      })
      .then(r => {
        console.log('Client today followup Leads', r.data);
        setoriginal_followupleads(r.data);
        setfollowupleads(r.data.results.today_followups);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Fetch pending followup leads
  const fetch_pending_followupleads = () => {
    axios
      .post(`${ipaddress}/ClientFollowupleadsView/${user.emp_id}/`, {
        value: 'pending',
      })
      .then(r => {
        console.log('Client pending followup Leads', r.data);
        setoriginal_followupleads(r.data);
        setfollowupleads(r.data.results.overdue_followups);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Fetch Prospects Leads
  const [original_prospectdata, setoriginal_prospectdata] = useState({});

  const fetchprospectsleads = () => {
    axios
      .get(`${ipaddress}/AllB2Bleads/?stage=prospect`)
      .then(r => {
        console.log('Client Prospect Leads', r.data);
        setoriginal_prospectdata(r.data);
        setprospectleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [prospectapi, setprospectapi] = useState('');

  useEffect(() => {
    if (prospectapi !== '') {
      axios
        .get(`${prospectapi}`)
        .then(r => {
          console.log('Customer followup Leads', r.data);
          setoriginal_prospectdata(r.data);
          setprospectleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [prospectapi]);

  // Fetch pending prospect leads
  const fetch_pending_prospectleads = () => {
    axios
      .post(`${ipaddress}/ClientProspectleadsView/${user.emp_id}/`)
      .then(r => {
        console.log('Client pending prospect Leads', r.data);
        setoriginal_prospectdata(r.data);
        setprospectleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Fetch pending prospect leads
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');

  const fetch_rangewise_leads = (start_date, end_date, stage) => {
    axios
      .get(
        `${ipaddress}/FilterClientLeadsView/?start_date=${start_date}&end_date=${end_date}&level_name=${stage}`,
      )
      .then(r => {
        console.log('Client range Leads', r.data);
        if (stage === 'prospect') {
          setoriginal_prospectdata(r.data);
          setprospectleads(r.data.results);
        }
        if (stage === 'registered') {
          setoriginal_registereddata(r.data);
          setregisteredleads(r.data.results);
        }
        if (stage === 'followup') {
          setoriginal_followupleads(r.data);
          setfollowupleads(r.data.results);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetch_today_prospectleads = stage => {
    const date = new Date();
    const todaydate = date.toISOString().slice(0, 10);
    axios
      .get(
        `${ipaddress}/FilterClientLeadsView/?start_date=${todaydate}&level_name=${stage}`,
      )
      .then(r => {
        console.log('Client today Leads', r.data);
        if (stage === 'prospect') {
          setoriginal_prospectdata(r.data);
          setprospectleads(r.data.results);
        }
        if (stage === 'registered') {
          setoriginal_registereddata(r.data);
          setregisteredleads(r.data.results);
        }
        if (stage === 'followup') {
          setoriginal_followupleads(r.data);
          setfollowupleads(r.data.results);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Fetch Registered Leads
  const [original_registereddata, setoriginal_registereddata] = useState({});

  const fetchregisteredleads = () => {
    axios
      .get(`${ipaddress}//AllB2Bleads/?stage=registered`)
      .then(r => {
        console.log('Client Regsitered Leads', r.data);
        setoriginal_registereddata(r.data);
        setregisteredleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [registeredapi, setregisteredapi] = useState('');

  useEffect(() => {
    if (registeredapi !== '') {
      axios
        .get(`${registeredapi}`)
        .then(r => {
          console.log('Customer followup Leads', r.data);
          setoriginal_registereddata(r.data);
          setregisteredleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [registeredapi]);

  // Fetch pending registered leads
  const fetch_pending_registeredleads = () => {
    axios
      .post(`${ipaddress}/TodayRegistrationCandidates/${user.emp_id}/`)
      .then(r => {
        console.log('Client pending registered Leads', r.data);
        setoriginal_registereddata(r.data);
        setregisteredleads(r.data.results.client_leads);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Fetch Closed Leads
  const [original_closeddata, setoriginal_closeddata] = useState({});

  const fetchclosedleads = () => {
    axios
      .get(`${ipaddress}/AllB2Bleads/?stage=closed`)
      .then(r => {
        console.log('Client Closed Leads', r.data);
        setoriginal_closeddata(r.data);
        setclosedleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [closedapi, setclosedapi] = useState('');

  useEffect(() => {
    if (closedapi !== '') {
      axios
        .get(`${closedapi}`)
        .then(r => {
          console.log('Customer followup Leads', r.data);
          setoriginal_closeddata(r.data);
          setclosedleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [closedapi]);

  const [all_employees, setall_employees] = useState([]);

  useEffect(() => {
    // fetchallleads()
    axios.get(`${ipaddress2}/AllprofileView/`).then(r => {
      console.log('All employeeess', r.data);
      setall_employees(r.data);
    });

    fetchleads();
    fetchfollowupleads();
    fetchprospectsleads();
    fetchregisteredleads();
    fetchclosedleads();
    fetchwalkinleads();
    fetchemployees();

    setLoading(false);
  }, [count]);

  // -------------------------------------Select the Leads----------------------------------------
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selecetdemp, setselectedemp] = useState('');

  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedLeads(prev => [...prev, id]);
    } else {
      setSelectedLeads(prev => prev.filter(leadId => leadId !== id));
      setSelectAll(false);
    }
  };

  const handleSelectAll = stage => {
    let allleads = [];

    if (!selectAll) {
      if (stage === 'newlead') {
        allleads = leads.map(lead => lead.client_lead_id);
      }
      if (stage === 'walkin') {
        allleads = walkinleads.map(lead => lead.client_lead_id);
      }
      if (stage === 'followup') {
        allleads = followupleads.map(lead => lead.client_lead_id);
      }
      if (stage === 'prospect') {
        allleads = prospectleads.map(lead => lead.client_lead_id);
      }
      if (stage === 'registered') {
        allleads = registeredleads.map(lead => lead.client_lead_id);
      }
      if (stage === 'closed') {
        allleads = closedleads.map(lead => lead.client_lead_id);
      }
      setSelectedLeads(allleads);
    } else {
      setSelectedLeads([]);
    }
    setSelectAll(!selectAll);
  };

  const assign_leads = value => {
    const emp_name = employees.find(x => x.emp_id === value);
    axios
      .post(`${ipaddress}/AssignClientToView/`, {
        loggedin_emp_id: user.emp_id,
        emp_id: value,
        client_lead_ids: selectedLeads,
      })

      .then(r => {
        console.log('Successfully client assigned', r.data);
        setselectedemp('');
        setSelectedLeads([]);
        toast.success(`Assigned to ${emp_name.firstname}`, {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
        setSelectAll(false);
      })
      .catch(err => {
        console.log('Assigning error', err);
        console.log({
          emp_id: value,
          client_lead_ids: selectedLeads,
        });
      });
  };

  // -----------------------------------------Search and take all leads-------------------------------------------------------
  // -------------------------------------------------Search leads from all leads table-----------------------------------

  const [searchvalue, setsearchvalue] = useState('');
  const [category_name, setcategory_name] = useState('select');
  const [category_name2, setcategory_name2] = useState('select');
  const [category_name3, setcategory_name3] = useState('select');
  const [category_name4, setcategory_name4] = useState('select');
  const [category_name5, setcategory_name5] = useState('select');
  const [category_name6, setcategory_name6] = useState('select');
  const [category_name7, setcategory_name7] = useState('select');
  const [category_name8, setcategory_name8] = useState('select');

  const [category_state, setcategory_state] = useState(false);

  const search_new_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseAllB2Cleads/${category_name2}/${value}/?level=newlead`,
        )
        .then(r => {
          console.log('searched new Leads', r.data);
          setleads(r.data);
          console.log(
            `${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`,
          );
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetchleads();
    }
  };

  const search_walkin_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseAllB2Cleads/${category_name3}/${value}/?level=walkin`,
        )
        .then(r => {
          console.log('searched new Leads', r.data);
          setwalkinleads(r.data);
          console.log(
            `${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`,
          );
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetchwalkinleads();
    }
  };

  const search_followup_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseAllB2Cleads/${category_name4}/${value}/?level=followup`,
        )
        .then(r => {
          console.log('searched new Leads', r.data);
          setfollowupleads(r.data);
          console.log(
            `${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`,
          );
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetchfollowupleads();
    }
  };

  const search_prospect_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseAllB2Cleads/${category_name5}/${value}/?level=prospect`,
        )
        .then(r => {
          console.log('searched new Leads', r.data);
          setprospectleads(r.data);
          console.log(
            `${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`,
          );
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetchprospectsleads();
    }
  };

  const search_registered_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseAllB2Cleads/${category_name6}/${value}/?level=registered`,
        )
        .then(r => {
          console.log('searched new Leads', r.data);
          setregisteredleads(r.data);
          console.log(
            `${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`,
          );
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetchregisteredleads();
    }
  };

  const search_closed_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseAllB2Cleads/${category_name7}/${value}/?level=closed`,
        )
        .then(r => {
          console.log('searched new Leads', r.data);
          setclosedleads(r.data);
          console.log(
            `${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`,
          );
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetchclosedleads();
    }
  };

  // Upload Excel
  const [excel, setexcel] = useState(null);

  const uploadexcel = file => {
    const formdata = new FormData();
    formdata.append('file', excel);

    axios
      .post(`${ipaddress}/ImportExcelClientView/`, formdata)
      .then(r => {
        console.log('Uploaded successfully', r.data);
        toast.success('File uploaded successfully', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
        setexcel(null);
      })
      .catch(err => {
        console.log('Uploading error', err);
      });
  };

  // ---------------------------------------------------Delete New laeds-----------------------------------------------------
  const delete_lead = id => {
    axios.put(`${ipaddress}/DeleteView/${id}/`).then(r => {
      console.log('Deleted successfully', r.data);
      setCount(count + 1);
      toast.success('B2B lead deleted successfully', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
    });
  };

  // --------------------------------------------------Download Excel Template----------------------------------------------------
  const download_template = () => {
    axios.get(`${ipaddress}/DownloadClientExcel/`).then(r => {
      console.log('Triggered', r.data);
      const pdfUrl = r.data.url;
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.href = pdfUrl;
      link.download = r.data.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  // To view the assigned permissions

  const [permissions, setpermissions] = useState({});

  useEffect(() => {
    axios.get(`${ipaddress}/PermissionsView/${user.emp_id}/`).then(r => {
      console.log('Permissions', r.data);
      setpermissions(r.data);
    });
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <Slick_button_right />,
    prevArrow: <Slick_button_left />,
    // initialSlide: 0,
    // autoplay: true,
    speed: 500,
    // autoplaySpeed: 3000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [course_id, setcourse_id] = useState();
  const [course_name, setcourse_name] = useState('');
  const [show1, setshow1] = useState(false);

  // Combination of search

  const fields = [
    'Name',
    'Email',
    'Contact_Number',
    'Course_Enquired',
    'Source',
    'Date',
    'Employee',
    'Lead_Stage',
  ];

  const [fields_array, setfields_array] = useState({
    Course_Enquired: false,
    Source: false,
    Lead_Stage: false,
    Employee: false,
    Email: false,
    Name: false,
    Date: false,
    Contact_Number: false,
  });

  const handleFieldChange = field => {
    setfields_array(prev => ({
      ...prev,
      [field]: !prev[field], // Toggle the state of the specific field dynamically
    }));
  };

  const [formdata, setformdata] = useState({
    course_enquired: '',
    source: '',
    level_lead: '',
    emp_id: '',
    email: '',
    lead_name: '',
    Date: '',
    contact_number: '',
  });

  const combine_fields = e => {
    const { name, value } = e.target;
    setformdata(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const [load, setload] = useState(false);
  const [show, setshow] = useState(false);

  const cancel_combined_search = () => {
    setshow(false);
    setformdata({
      course_enquired: '',
      source: '',
      level_lead: '',
      emp_id: '',
      email: '',
      lead_name: '',
      Date: '',
      contact_number: '',
    });
    setfields_array({
      Course_Enquired: false,
      Source: false,
      Lead_Stage: false,
      Employee: false,
      Email: false,
      Name: false,
      Date: false,
      Contact_Number: false,
    });
  };

  const combined_search = () => {
    formdata.emp_id = Number(user.id);

    axios
      .post(`${ipaddress}/CombinationSearchClientView/`, formdata)
      .then(r => {
        console.log('Combined filter data', r.data);
        if (tab === 'all') {
          setallleads(r.data);
        }
        if (tab === 'newleads') {
          setleads(r.data);
        }
        if (tab === 'walkin') {
          setwalkinleads(r.data);
        }
        if (tab === 'referred') {
          setallleads(r.data);
        }
        if (tab === 'followup') {
          setfollowupleads(r.data);
        }
        if (tab === 'prospects') {
          setprospectleads(r.data);
        }
        if (tab === 'registered') {
          setregisteredleads(r.data);
        }
        if (tab === 'closed') {
          setclosedleads(r.data);
        }
        cancel_combined_search();
      })
      .catch(err => {
        console.log('Combined search error', err);
      });
  };

  const downloadExcel = (value, name) => {
    const worksheet = XLSX.utils.json_to_sheet(value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${name}.xlsx`);
  };

  const leadtypes = [
    {
      name: 'All Leads',
      type: 'all',
    },
    {
      name: 'New Leads',
      type: 'newlead',
    },
    {
      name: 'Walkin/Meetings Leads',
      type: 'walkin',
    },
    {
      name: 'Referred Leads',
      type: 'referred',
    },
    {
      name: 'Followup Leads',
      type: 'followup',
    },
    {
      name: 'Prospect Leads',
      type: 'prospect',
    },
    {
      name: 'Registered Leads',
      type: 'registered',
    },
    {
      name: 'Closed Leads',
      type: 'closed',
    },
    // {
    //   name:"Other Department Leads",
    //   type:'otherdepartment'
    // },
  ];

  const navigation = value => {
    const data = leadtypes.find(x => x.type === value);
    navigate(`/all_client_leads/${data.type}`);
    setTab(value);
  };

  return (
    <div>
      {loading ? (
        <Preloader />
      ) : (
        <div className="d-flex">
          <div className="w-100">
            <div className="bg-light pt-2 h-100">
              <div className="px-1 px-lg-2">
                <div className="row m-0 mb-3">
                  <div className="col-lg-2 ps-md-4 col-md-6 ms-auto pe-md-0">
                    <select
                      name=""
                      onChange={e => {
                        navigation(e.target.value);
                      }}
                      value={tab}
                      className="form-control text-white  fw-bold shadow-none text-center"
                      id=""
                      style={{
                        backgroundColor: '#002147',
                        cursor: 'pointer',
                        border: '1px solid #002147',
                      }}
                    >
                      {leadtypes.map(x => {
                        return (
                          <>
                            <option
                              style={{ lineHeight: '2.5em', padding: '10px' }}
                              value={x.type}
                            >
                              {x.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div>
                  <Routes>
                    <Route
                      path="/*"
                      element={<ClientAllLeads settab={setTab} />}
                      // element={<Client_all_leads settab={setTab} />}
                    />
                    <Route
                      path="/newlead"
                      element={<ClientNewLeads settab={setTab} />}
                    />
                    <Route
                      path="/walkin"
                      element={<ClientWalkinsLeads settab={setTab} />}
                    />
                    <Route
                      path="/followup"
                      element={<ClientFollowUps settab={setTab} />}
                    />
                    <Route
                      path="/prospect"
                      element={<ClientProspects settab={setTab} />}
                    />
                    <Route
                      path="/registered"
                      element={<ClientRegistered settab={setTab} />}
                    />
                    <Route
                      path="/closed"
                      element={<ClientClosedLeads settab={setTab} />}
                    />
                    {/* <Route path='/otherdepartment' element={<Customer_other_leads/>}/> */}
                  </Routes>
                </div>
              </div>
            </div>
          </div>

          {/* --------------------------MODAL FORMS-------------------------- */}
          <ClientForms
            show1={show1}
            setshow1={setshow1}
            client_lead_id={client_lead_id}
            lead_id={lead_id}
            setCount={setCount}
            stage1={stage}
            setstage={setstage}
            course_id={course_id}
            course_name={course_name}
          />
        </div>
      )}
    </div>
  );
};

export default All_B2B_leads;
