export default function NameCell({ setLeadId, setShow, rowData }) {
  const FollowUpStyling = rowData?.followup_notification?.includes('today')
    ? 'tw-text-cyan-600'
    : rowData?.followup_notification?.includes('pending')
    ? 'tw-text-red-500'
    : 'tw-text-green-500';

  const PriorityStyling =
    rowData?.priority === 'low'
      ? 'tw-text-blue-400'
      : rowData?.priority === 'medium'
      ? 'tw-text-blue-500'
      : 'tw-text-blue-600';

  const viewDetails = e => {
    e.stopPropagation();
    setShow(true);

    console.log('THUSSS', rowData);
    if (rowData.customer_lead_id) {
      setLeadId(rowData.customer_lead_id);
    } else {
      alert('HI');
      setLeadId(rowData.client_lead_id);
    }
  };

  return (
    <div
      onClick={viewDetails}
      className=" tw-cursor-pointer"
    >
      {rowData.level_lead_name === 'followup' && (
        <span
          className={`${FollowUpStyling} tw-text-xs tw-capitalize tw-absolute tw-left-6 tw-top-0`}
        >
          {rowData.followup_notification}
        </span>
      )}

      <p className="tw-items-center tw-p-1 tw-text-center tw-capitalize">
        {rowData.name}
      </p>

      <span
        className={`${PriorityStyling} tw-text-xs tw-capitalize tw-absolute tw-right-6 tw-bottom-0`}
      >
        {rowData.priority === 'medium'
          ? rowData.priority.slice(0, 3)
          : rowData.priority}
      </span>
    </div>
  );
}
