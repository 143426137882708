import { useFormContext } from 'react-hook-form';
import { CheckBoxField } from './FilterForms/CategoriesControls/CheckBoxField';
import { TextField } from './FilterForms/CategoriesControls/TextField';

function GSTDetailsForm() {
  const { register, watch } = useFormContext();
  const hasGSTNumber = watch('customerGST'); // Watch for GST checkbox state

  return (
    <>
      <div>
        <p className="tw-text-xl tw-font-bold">GST Details</p>
        <div className="tw-h-[2px] tw-w-full tw-bg-black" />
        <div className="row tw-my-5">
          <div className="col tw-flex tw-items-end">
            <CheckBoxField
              label={'Do you have a GST number?'}
              {...register('customerGST')}
            />
          </div>
        </div>
        {hasGSTNumber && (
          <div className="row">
            <div className="col">
              <TextField
                isRequired={true}
                label={'GST Number'}
                type={'text'}
                placeholder="Enter your GST Number"
                {...register('gstNumber', { required: true })}
              />
            </div>
            <div className="col">
              <TextField
                isRequired={true}
                label={'Company Name'}
                type={'text'}
                placeholder="Enter your Company Name"
                {...register('companyName', { required: true })}
              />
            </div>
            <div className="col">
              <TextField
                isRequired={true}
                label={'Company Address'}
                type={'text'}
                placeholder="Enter your Company Address"
                {...register('companyAddress', { required: true })}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default GSTDetailsForm;
