import { forwardRef } from 'react';

export const RadioField = forwardRef(
  ({ className, label, error, options, ...other }, ref) => {
    return (
      <div className="">
        <div class="form-check">
          <input
            ref={ref}
            className="form-check-input"
            type="radio"
            {...other}
          />
          <label className="form-check-label">{label}</label>
        </div>
        {error && (
          <div className="tw-text-red-400 tw-text-sm">{error?.message}</div>
        )}
      </div>
    );
  },
);
