import { useEffect } from 'react';
import { TextField } from './FilterForms/CategoriesControls/TextField';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DateField } from './FilterForms/CategoriesControls/DateField';
import { parseAsString, useQueryState } from 'nuqs';

function InstallmentsForm() {
  const {
    register,
    watch,
    control,
    getValues,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: 'installments',
    control,
  });

  const [edit] = useQueryState('edit', parseAsString.withDefault(null));

  const noOfInstallments = watch('noOfInstallments');
  const remainingAmount = Number(watch('remainingTotal') || 0);
  const installments = watch('installments') || [];

  useEffect(() => {
    const currentFieldsCount = fields.length;
    const targetFieldsCount = noOfInstallments || 0;

    if (edit === null) {
      if (targetFieldsCount > currentFieldsCount) {
        for (let i = currentFieldsCount; i < targetFieldsCount; i++) {
          append({ amount: 0, dueDate: '' });
        }
      } else if (targetFieldsCount < currentFieldsCount) {
        for (let i = currentFieldsCount - 1; i >= targetFieldsCount; i--) {
          remove(i);
        }
      }
    }
  }, [noOfInstallments, append, remove, fields.length, edit]);

  const validateInstallments = () => {
    const totalInstallmentsAmount = installments.reduce(
      (sum, installment) => sum + Number(installment.amount || 0),
      0,
    );

    const operation =
      remainingAmount - totalInstallmentsAmount > 0 ? 'Add' : 'Substract';

    if (totalInstallmentsAmount !== remainingAmount) {
      setError('installments', {
        type: 'validate',
        message: `Installments total (${totalInstallmentsAmount}) does not match remaining amount (${remainingAmount}). Please ${operation} ${Math.abs(
          remainingAmount - totalInstallmentsAmount,
        )}`,
      });
    } else {
      clearErrors('installments');
    }
  };

  useEffect(() => {
    validateInstallments();
  }, [installments, remainingAmount]);

  return (
    <div className="tw-flex tw-gap-x-2">
      <div className="row tw-my-5">
        <div className="col">
          <TextField
            label="No of Installments"
            type="number"
            {...register('noOfInstallments', { valueAsNumber: true })}
          />
        </div>
      </div>
      <div className="row tw-my-5">
        {fields.map((field, index) => (
          <div
            className="tw-bg-slate-200 tw-mx-4 tw-flex tw-mt-1 tw-rounded-md"
            key={field.id}
          >
            <div className="col tw-w-24 tw-mx-2  ">
              <TextField
                label={`Installment ${index + 1}`}
                type="number"
                {...register(`installments.${index}.amount`, {
                  valueAsNumber: true,
                  onChange: () => validateInstallments(),
                })}
              />
            </div>
            <div className="col tw-mt-7">
              <DateField {...register(`installments.${index}.dueDate`)} />
            </div>
            <button
              type="button"
              className="tw-bg-slate-400 tw-text-white tw-font-bold tw-rounded-lg tw-px-3 tw-h-max tw-my-9 tw-mx-2"
              onClick={() => {
                const { noOfInstallments } = getValues();
                setValue('noOfInstallments', noOfInstallments - 1);
                remove(index);
              }}
            >
              -
            </button>
          </div>
        ))}
        <button
          type="button"
          className="tw-bg-blue-300 tw-rounded-lg tw-my-2 tw-w-max tw-px-3 tw-py-2 tw-mx-5"
          onClick={() => {
            const values = getValues();
            append({ amount: '', dueDate: '' });
            setValue('noOfInstallments', values.noOfInstallments + 1);
          }}
        >
          + Add
        </button>
      </div>

      {errors?.installments && (
        <p className="tw-text-red-500  tw-text-lg tw-font-bold tw-animate-pulse tw-duration-500 tw-transition-all">
          {errors.installments.message}
        </p>
      )}
    </div>
  );
}

export default InstallmentsForm;
