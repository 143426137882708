import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import { useNavigate, useParams } from 'react-router-dom';
import Mode_of_payment_modal from '../invoide_and_receipt/Mode_of_payment_modal';

const Student_performa_Invoice_page = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [printmode, setprintmode] = useState(false);

  const togglePrintMode = () => {
    setprintmode(prevMode => !prevMode);
  };

  const handleprint = () => {
    window.print();
  };

  const user = JSON.parse(sessionStorage.getItem('user'));

  const [indexvalue, setindexvalue] = useState(0);

  const [invoidedata, setinvoicedata] = useState([]);
  const [current_invoidedata, setcurrent_invoicedata] = useState({});
  const [current_invoidedata2, setcurrent_invoicedata2] = useState({});
  const [current_invoidedata3, setcurrent_invoicedata3] = useState({});
  const [installmentdata, setinstallmentdata] = useState([]);
  useEffect(() => {
    axios
      .get(`${ipaddress2}/invoiceGenerationForLeave/${user.email}/`)
      .then(r => {
        console.log('Performa invoice data', r.data);
        setinvoicedata(r.data);
        //   fetchobjectdata(r.data[0].invoice_number)
        setcurrent_invoicedata(r.data);
        setcurrent_invoicedata2(r.data.customer_id.registration_id);
      })
      .catch(err => {
        console.log('Invoice Error', err);
      });
  }, []);

  const [btnstate, setbtnstate] = useState(false);
  const [load, setload] = useState();

  const generate_invoice = () => {
    setload(true);
    setTimeout(() => {
      send_data();
    }, 1000);
  };

  const [payment_url, setpayment_url] = useState('');

  const send_data = () => {
    axios
      .put(`${ipaddress2}/invoiceGenerationForLeave/${user.email}/`, {
        id: current_invoidedata.id,
      })
      .then(r => {
        console.log('Invoice generated', r.data);
        setload(false);
        setbtnstate(true);
      })
      .catch(err => {
        console.log('Invoice Error', err);
      });

    axios
      .get(
        `${ipaddress2}/leave_pay/${current_invoidedata.fees}/${
          current_invoidedata.id
        }/?data=${'https://crmclient.skilllearningacademy.com/leave_request'}`,
      )
      .then(r => {
        console.log('Payment url', r.data);
        setpayment_url(r.data.redirect_url);
      })
      .catch(err => {
        console.log('Url Error', err);
      });
  };

  return (
    <div className="py-2 bg-light">
      <div className="row m-0 pb-4 mb-4">
        <div className="col-md-8 mx-auto bg-white pb-4">
          <div className="d-flex justify-content-between align-items-center py-3">
            <h5 className="m-0">Performa Invoice</h5>
            <button
              onClick={() => {
                generate_invoice();
              }}
              className={`btn btn-sm btn-primary px-3 ${
                btnstate ? 'd-none' : ''
              }`}
            >
              <span
                class={`spinner-border spinner-border-sm ${
                  load ? '' : 'd-none'
                }`}
                aria-hidden="true"
              ></span>
              <span
                class={`ms-2 ${load ? '' : 'd-none'}`}
                role="status"
              >
                Loading...
              </span>
              <span className={`${load ? 'd-none' : ''}`}>
                Accept Performa Invoice
              </span>
            </button>

            <a
              href={payment_url && payment_url}
              target="_self"
              className={`btn btn-sm btn-primary px-3 ${
                btnstate ? '' : 'd-none'
              }`}
            >
              Make Payment
            </a>
          </div>
          {/* <img src={require('../images/Group 27211.png')} alt="" style={{width:'100%'}} /> */}

          <div className="px-4">
            <div className="row mt-4 m-0 px-4">
              <div className="col-md-6 ps-0">
                <div className="invoice_div rounded py-3">
                  <h6
                    className="fw-medium pb-1"
                    style={{ color: '#250F3C', fontSize: '16px' }}
                  >
                    Billed by
                  </h6>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">Company</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata.company_details &&
                        current_invoidedata.company_details.company_name}
                    </p>
                  </p>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">Address</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata.company_details &&
                        current_invoidedata.company_details.Area}
                    </p>
                  </p>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">City</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata.company_details &&
                        current_invoidedata.company_details.city}
                    </p>
                  </p>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">State</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata.company_details &&
                        current_invoidedata.company_details.state}
                      ,{' '}
                      {current_invoidedata.company_details &&
                        current_invoidedata.company_details.pincode}
                    </p>
                  </p>
                </div>
              </div>

              {/* -----------------------------------------Client / Customer----------------------------------------------- */}
              <div className="col-md-6 ps-0">
                <div className="invoice_div rounded py-3">
                  <h6
                    className="fw-medium pb-1"
                    style={{ color: '#250F3C', fontSize: '16px' }}
                  >
                    Billed to
                  </h6>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">Name</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata2.customer_lead_id &&
                        current_invoidedata2.customer_lead_id.name &&
                        current_invoidedata2.customer_lead_id.name}
                    </p>
                  </p>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">Mobile No</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata2.customer_lead_id &&
                        current_invoidedata2.customer_lead_id.contact_number &&
                        current_invoidedata2.customer_lead_id.contact_number}
                    </p>
                  </p>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">City</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata3.City && current_invoidedata3.City}
                    </p>
                  </p>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">State</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata3.State && current_invoidedata3.State}
                      ,{' '}
                      {current_invoidedata3.Pincode &&
                        current_invoidedata3.Pincode}
                    </p>
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-1 pb-1">
              <div className="table-responsive">
                <table className="table-borderless w-100">
                  <thead className="border-bottom invoice-table">
                    <tr className="">
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">SI No</p>
                      </th>
                      {/* <th scope="col" className='py-3'><p className="text-center m-0">Course Description</p></th> */}
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">Amount</p>
                      </th>
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">SGST</p>
                      </th>
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">CGST</p>
                      </th>
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">Total Amount</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    <tr className="">
                      <th
                        scope="row"
                        className="invoice-table-cell"
                      >
                        1
                      </th>
                      {/* <td className='invoice-table-cell fw-medium' style={{fontSize:'16px'}}>{a.course_name}</td> */}
                      <td className="invoice-table-cell fw-medium">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-currency-rupee"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                        </svg>
                        {current_invoidedata.fees}
                      </td>
                      <td className="invoice-table-cell fw-medium">-</td>
                      <td className="invoice-table-cell fw-medium">-</td>
                      <td className="invoice-table-cell fw-medium">
                        {current_invoidedata.fees}
                      </td>
                    </tr>
                    <tr className="">
                      <td
                        colspan="3"
                        className=""
                        style={{ color: '#40BA5A', fontSize: '17px' }}
                      ></td>
                      <td
                        className="text-center fw-medium invoice-table py-2"
                        style={{ fontSize: '15px' }}
                      >
                        <span>Total Amount</span>
                      </td>
                      <td className="invoice-table text-center py-2">
                        <p className="m-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-currency-rupee"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                          </svg>{' '}
                          <span className="">
                            {current_invoidedata.fees}
                            <span>/-</span>
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr className="">
                      <td
                        colspan="3"
                        className=""
                        style={{ color: '#40BA5A', fontSize: '17px' }}
                      ></td>
                      <td
                        className="text-center fw-medium invoice-table py-2"
                        style={{ fontSize: '15px' }}
                      >
                        Tax
                      </td>
                      <td className="invoice-table text-center py-2">
                        <p className="m-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-currency-rupee"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                          </svg>{' '}
                          <span className="">
                            {current_invoidedata.grand_gst_total}
                            <span>/-</span>
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr className="">
                      <td
                        colspan="3"
                        className=""
                        style={{ color: '#40BA5A', fontSize: '17px' }}
                      ></td>
                      <td
                        className="text-center fw-medium text-white py-3"
                        style={{
                          fontSize: '15px',
                          backgroundColor: '#250F3C',
                          borderTop: '4px solid #fff',
                        }}
                      >
                        Grand Total
                      </td>
                      <td
                        className="text-center py-3 fw-medium"
                        style={{
                          fontSize: '15px',
                          backgroundColor: '#D3D2D7',
                          borderTop: '4px solid #fff',
                        }}
                      >
                        <p className="m-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-currency-rupee"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                          </svg>{' '}
                          <span className="">
                            {current_invoidedata.fees}
                            <span>/-</span>
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Installment table */}
            {/* <div className={`mt-5 pb-1 ${current_invoidedata.course && current_invoidedata.course.length>0 ? '':'d-none'}`}>
  <h6>Installment Details</h6>
            <div className="table-responsive">
            <table className="table-borderless w-100">
  <thead className='border-bottom invoice-table'>
    <tr className=''>
      <th scope="col" className='py-3'><p className='text-center m-0'>Installment</p></th>
      <th scope="col" className='py-3'><p className="text-center m-0">Amount</p></th>
      <th scope="col" className='py-3'><p className="text-center m-0">Due Date</p></th>
      <th scope="col" className='py-3'><p className="text-center m-0">Status</p></th>

    </tr>
  </thead>
  <tbody className=''>
    {current_invoidedata.course && current_invoidedata.due_dates.map((a,index)=>{
      return(
        <>
        <tr className=''>
      <th scope="row" className='invoice-table-cell'>Installment {index+1}</th>
      <td className='invoice-table-cell fw-medium'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
</svg>{a.fees}</td>
      <td className='invoice-table-cell fw-medium'>{a.due_date}</td>
      <td className='invoice-table-cell fw-medium'>{a.status}</td>
    </tr>
        </>
      )
    })}
  </tbody>
</table>
</div>
            </div> */}

            {/* ---------------------------------------------------Terms and conditions--------------------------------------------- */}
            <div className="row m-0 mt-5 align-items-center">
              <div className="col-6">
                <p
                  style={{ color: '#000', fontWeight: 500 }}
                  className="ps-0 m-0 mb-2"
                >
                  <span style={{ color: 'red' }}>*</span>Terms & Conditions
                </p>
                <span style={{ fontSize: '13px' }}>
                  1. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Unde facilis praesentium consequatur dolor quia tenetur beatae
                  quaerat ad numquam neque.
                </span>
                <div className="text-center">
                  {/* <img src={require('../images/png-01 2 1.png')} alt="" /> */}
                </div>
              </div>
              <div className="col-6 text-center">Signature</div>
            </div>

            <div
              style={{ position: 'relative' }}
              className="mt-2"
            >
              <div
                className="d-flex justify-content-evenly px-3 py-1 align-items-center"
                style={{ backgroundColor: '#250F3C', height: '30px' }}
              >
                <p className="m-0 text-white">
                  <span className="bg-white rounded px-1 d-felx align-items-center justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      fill="#000"
                      class="bi bi-envelope"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                    </svg>
                  </span>{' '}
                  <span style={{ fontSize: '12px' }}>merida@gmail.com</span>
                </p>
                <p className="m-0 text-white">
                  <span className="bg-white rounded px-1 d-felx align-items-center justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      fill="#000"
                      class="bi bi-geo-alt"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                    </svg>
                  </span>{' '}
                  <span style={{ fontSize: '12px' }}>
                    Jayanagar, Bangalore.
                  </span>
                </p>
              </div>
              <div
                className="d-flex gap-4"
                style={{ backgroundColor: '#57309C', height: '10px' }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------Ask for help------------------------------------------------------ */}
      <div
        class="modal fade"
        id="need_help"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div className="d-flex justify-content-between px-3 mb-4 align-items-center">
                <h5 className="text-primary">Need a help</h5>
                <button
                  data-bs-dismiss="modal"
                  className="bg-transparent border-0 ms-auto"
                >
                  <i class="fa-solid fa-circle-xmark fs-5"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Mode_of_payment_modal />
    </div>
  );
};

export default Student_performa_Invoice_page;
