import { useLocation, useNavigate } from 'react-router-dom';

export const useNavLoc = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navWithLocation = to => {
    navigate(to, {
      state: {
        from: location.pathname,
      },
    });
  };

  return navWithLocation;
};
