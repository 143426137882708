import React, { useState } from 'react';
import Sidebar from './Sidebar1';
import Navbar from './Navbar1';
import axios from 'axios';
import { ipaddress2 } from '../App';
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Test = () => {
  const renderTooltip3 = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      Add Question
    </Tooltip>
  );

  const [questions, setQuestions] = useState([
    { text: '', options: ['', '', '', ''] },
  ]);

  const handleAddQuestion = () => {
    setQuestions([...questions, { text: '', options: ['', '', '', ''] }]);
  };

  const handleQuestionChange = (index, event) => {
    const newQuestions = questions.map((q, qIndex) =>
      qIndex === index ? { ...q, text: event.target.value } : q,
    );
    setQuestions(newQuestions);
  };

  const handleOptionChange = (qIndex, optIndex, event) => {
    const newQuestions = questions.map((q, index) => {
      if (index === qIndex) {
        const newOptions = q.options.map((opt, oIndex) =>
          oIndex === optIndex ? event.target.value : opt,
        );
        return { ...q, options: newOptions };
      }
      return q;
    });
    setQuestions(newQuestions);
  };

  const user = JSON.parse(sessionStorage.getItem('user'));
  const handleSubmit = event => {
    event.preventDefault();
    axios
      .post(`${ipaddress2}/questions/${user.emp_id}/${state}/`, questions)
      .then(r => {
        console.log('MCQ Questions sent', r.data);
        console.log(questions);
        setQuestions([{ text: '', options: ['', '', '', ''] }]);

        toast.success('Question Paper created', {
          autoClose: 2000,
        });
      })
      .catch(err => {
        console.log('MCQ Error', err);
        console.log(questions);
      });
  };

  // Coding Questions
  const [codingquestions, setcodingQuestions] = useState([
    {
      text: '',
    },
  ]);
  const [assignmentQuestions, setAssignmentQuestions] = useState([
    {
      text: '',
    },
  ]);

  const handleAddcodingQuestion = () => {
    setcodingQuestions([
      ...codingquestions,
      {
        text: '',
      },
    ]);
  };

  const handleAddAssQuestion = () => {
    setAssignmentQuestions([
      ...assignmentQuestions,
      {
        text: '',
      },
    ]);
  };

  const handlecodingQuestionChange = (index, event) => {
    const newQuestions = codingquestions.map((q, qIndex) =>
      qIndex === index ? { ...q, text: event.target.value } : q,
    );
    setcodingQuestions(newQuestions);
  };

  const handleAssignmentQuestionChange = (index, event) => {
    const newAssQuestions = assignmentQuestions.map((q, qIndex) =>
      qIndex === index ? { ...q, text: event.target.value } : q,
    );
    setAssignmentQuestions(newAssQuestions);
  };

  const [title, setTitle] = useState();
  const [titleAss, setTitleAss] = useState();

  const handleSubmit2 = event => {
    event.preventDefault();
    axios
      .post(`${ipaddress2}/questions/${user.emp_id}/${state}/`, {
        title,
        questions: codingquestions,
      })
      .then(r => {
        console.log('Coding Questions sent', r.data);
        console.log(codingquestions);
        setcodingQuestions([
          {
            text: '',
          },
        ]);

        toast.success('Question Paper created', {
          autoClose: 2000,
        });
      })
      .catch(err => {
        console.log('Coding Question Error', err);
        console.log(codingquestions);
      });
  };
  const handleSubmit3 = event => {
    event.preventDefault();
    axios
      .post(`${ipaddress2}/questions/${user.emp_id}/${state}/`, {
        title: titleAss,
        questions: assignmentQuestions,
      })
      .then(r => {
        console.log('Assignment Questions sent', r.data);
        console.log(assignmentQuestions);
        setAssignmentQuestions([
          {
            text: '',
          },
        ]);

        toast.success('Question Paper created', {
          autoClose: 2000,
        });
      })
      .catch(err => {
        console.log('Ass Question Error', err);
        console.log(assignmentQuestions);
      });
  };

  const [state, setstate] = useState('mcq');

  return (
    <div className="d-flex">
      <Sidebar activevalue={'assigntests'} />
      <div className="w-100">
        <Navbar />
        <div
          className="main-container p-3"
          style={{ backgroundColor: '#E8E8E8' }}
        >
          <div
            className="row m-0 d-flex bg-white p-3"
            style={{ minHeight: '100vh' }}
          >
            <div
              className="scrollable col-lg-9 pb-4 pt-2"
              style={{ minHeight: '100vh' }}
            >
              <h6 className="fs-5 pt-2 pb-3 fw-medium text-dark">
                Add New Questions
              </h6>
              <div className="d-flex pb-2">
                <h5
                  onClick={() => {
                    setstate('mcq');
                  }}
                  className="py-2 px-4 d-flex align-items-center"
                  style={{
                    color: state === 'mcq' ? '#ffff' : '#002147',
                    cursor: 'pointer',
                    border: '1px solid #002147',
                    backgroundColor: state === 'mcq' ? '#002147' : '',
                  }}
                >
                  <span className="">MCQ Question</span>
                </h5>
                <h5
                  onClick={() => {
                    setstate('coding');
                  }}
                  className="py-2 px-4 d-flex align-items-center"
                  style={{
                    color: state === 'coding' ? '#ffff' : '#002147',
                    cursor: 'pointer',
                    border: '1px solid #002147',
                    backgroundColor: state === 'coding' ? '#002147' : '',
                  }}
                >
                  <span className="">Coding Question</span>
                </h5>
                <h5
                  onClick={() => {
                    setstate('task');
                  }}
                  className="py-2 px-4 d-flex align-items-center"
                  style={{
                    color: state === 'task' ? '#ffff' : '#002147',
                    cursor: 'pointer',
                    border: '1px solid #002147',
                    backgroundColor: state === 'task' ? '#002147' : '',
                  }}
                >
                  <span className="">Assignments</span>
                </h5>
              </div>

              {/* MCQ QUESTIONS */}
              <form
                onSubmit={handleSubmit}
                className={`${state === 'mcq' ? 'mt-3' : 'd-none'}`}
              >
                {questions.map((q, qIndex) => (
                  <div
                    className="row m-0 mb-5 bg-white py-3 px-3"
                    key={qIndex}
                    style={{
                      marginBottom: '20px',
                      border: '1px solid #002147',
                    }}
                  >
                    <div className="col-12 p-0">
                      <textarea
                        className="add-option form-control bg-secondary-subtle shadow-none border-0 mt-2"
                        type="text"
                        placeholder="Type your question here..."
                        value={q.text}
                        onChange={e => handleQuestionChange(qIndex, e)}
                        required
                      />
                    </div>
                    {q.options.map((opt, optIndex) => (
                      <div
                        className="col-12 mt-2 form-input p-0"
                        key={optIndex}
                      >
                        <input
                          placeholder={`Option ${optIndex + 1}`}
                          className="add-option py-3 bg-light form-control shadow-none border-0 mt-2"
                          type="text"
                          value={opt}
                          onChange={e =>
                            handleOptionChange(qIndex, optIndex, e)
                          }
                          required
                        />
                      </div>
                    ))}
                  </div>
                ))}
                <div className="d-flex justify-content-end">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltip3}
                  >
                    <button
                      type="button"
                      className="btns text-white d-flex align-items-center"
                      onClick={handleAddQuestion}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </button>
                  </OverlayTrigger>
                  <button
                    type="submit"
                    className="btns py-2 text-white ms-3 px-3"
                  >
                    Submit
                  </button>
                </div>
              </form>

              {/* CODING QUESTIONS */}
              <form
                onSubmit={handleSubmit2}
                className={`${state === 'coding' ? 'mt-3' : 'd-none'}`}
              >
                <div className="tw-mb-4">
                  <label
                    htmlFor="title"
                    className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    className="tw-mt-1 tw-block tw-w-[30%] tw-rounded-md tw-border tw-border-gray-300 
               tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-text-gray-900 tw-shadow-sm 
               focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500 
               focus:tw-border-blue-500"
                  />
                </div>

                {codingquestions.map((q, qIndex) => (
                  <div
                    className="row m-0 mb-3 bg-white p-0 py-2"
                    key={qIndex}
                    style={{ marginBottom: '20px', borderRadius: '10px' }}
                  >
                    <div className="col-12 px-md-0">
                      <textarea
                        value={q.text}
                        className="add-option form-control shadow-none bg-secondary-subtle my-2"
                        type="text"
                        placeholder="Type your question here..."
                        onChange={e => handlecodingQuestionChange(qIndex, e)}
                        required
                      />
                    </div>
                  </div>
                ))}
                <div className="d-flex justify-content-end mt-4">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltip3}
                  >
                    <button
                      type="button"
                      className="btns text-white d-flex align-items-center"
                      onClick={handleAddcodingQuestion}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </button>
                  </OverlayTrigger>
                  <button
                    type="submit"
                    className="btns py-2 text-white ms-3 px-3"
                  >
                    Submit
                  </button>
                </div>
              </form>

              <form
                onSubmit={handleSubmit3}
                className={`${state === 'task' ? 'mt-3' : 'd-none'}`}
              >
                <div className="tw-mb-4">
                  <label
                    htmlFor="title"
                    className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    id="title"
                    value={titleAss}
                    onChange={e => setTitleAss(e.target.value)}
                    className="tw-mt-1 tw-block tw-w-[30%] tw-rounded-md tw-border tw-border-gray-300 
               tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-text-gray-900 tw-shadow-sm 
               focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500 
               focus:tw-border-blue-500"
                  />
                </div>

                {assignmentQuestions.map((q, qIndex) => (
                  <div
                    className="row m-0 mb-3 bg-white p-0 py-2"
                    key={qIndex}
                    style={{ marginBottom: '20px', borderRadius: '10px' }}
                  >
                    <div className="col-12 px-md-0">
                      <textarea
                        value={q.text}
                        className="add-option form-control shadow-none bg-secondary-subtle my-2"
                        type="text"
                        placeholder="Type your question here..."
                        onChange={e =>
                          handleAssignmentQuestionChange(qIndex, e)
                        }
                        required
                      />
                    </div>
                  </div>
                ))}
                <div className="d-flex justify-content-end mt-4">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltip3}
                  >
                    <button
                      type="button"
                      className="btns text-white d-flex align-items-center"
                      onClick={handleAddAssQuestion}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </button>
                  </OverlayTrigger>
                  <button
                    type="submit"
                    className="btns py-2 text-white ms-3 px-3"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test;
