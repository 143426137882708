import { useFormContext } from 'react-hook-form';
import { SelectField } from './FilterForms/CategoriesControls/SelectField';
import { TextField } from './FilterForms/CategoriesControls/TextField';
import { DateField } from './FilterForms/CategoriesControls/DateField';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '../API/Axios/AxiosClient';

const fetchBatchTypes = async () => {
  const res = await axiosInstance.get('/Allbatchtypes/');
  const { batchtypes } = res.data;

  const formattedBatchTypes = batchtypes.map(each => ({
    name: each,
    value: each,
  }));

  return formattedBatchTypes;
};

const fetchClassTypes = async () => {
  const res = await axiosInstance.get('/Allclasstypes/');
  const { classtypes } = res.data;

  const formattedClassTypes = classtypes.map(each => ({
    name: each,
    value: each,
  }));

  return formattedClassTypes;
};

function BatchDetailsForm() {
  const { register } = useFormContext();

  const { data: batchTypes } = useQuery({
    queryKey: ['fetchBatchTypes'],
    queryFn: fetchBatchTypes,
  });

  const { data: classTypes } = useQuery({
    queryKey: ['fetchClassTypes'],
    queryFn: fetchClassTypes,
  });

  return (
    <>
      <div className="">
        <p className="tw-text-xl tw-font-bold">Batch Details</p>
        <div className="tw-h-[2px] tw-w-full tw-bg-black" />
      </div>
      <div className="row tw-my-5">
        <div className="col">
          <TextField
            label={'Course Name'}
            {...register('course_enquiredd')}
          />
        </div>
        <div className="col">
          <TextField
            label={'Course Duration'}
            {...register('course_duration')}
          />
        </div>
        <div className="col">
          <SelectField
            label={'Batch Type'}
            {...register('batch_type')}
            options={batchTypes}
          />
        </div>
      </div>
      <div className="row tw-my-5">
        <div className="col ">
          <SelectField
            label={'Class Type'}
            {...register('class_type')}
            options={classTypes}
          />
        </div>
        <div className="col">
          <DateField
            label={'Batch Start Time'}
            type={'time'}
            {...register('batch_start_time')}
          />
        </div>
        <div className="col">
          <DateField
            label={'Batch End Time'}
            type={'time'}
            {...register('batch_end_time')}
          />
        </div>
      </div>
      <div className="row tw-my-5 tw-w-[34%]">
        <div className="col">
          <DateField
            label={'Tentative Batch Start Date '}
            {...register('tentative_start_date')}
          />
        </div>
      </div>
    </>
  );
}

export default BatchDetailsForm;
