import React, { useState } from 'react';
import Sidebar from '../Components/Sidebar';
import Navbar from './Navbar';
import Allleads from './All_leads';
import All_client_leads from './All_client_leads';
import All_customer_leads from './AllCustomerLeads';
import { Route, Routes, useNavigate } from 'react-router-dom';
import All_B2B_leads from './All_B2B_leads';

const View_all_client_leads = () => {
  const [value, setValue] = useState('client');
  const navigate = useNavigate();

  return (
    <div className="d-flex">
      <Sidebar activevalue={'allb2bleads'} />
      <div className="w-100">
        <Navbar />
        <div
          className="bg-light p-3 main-container"
          style={{ minHeight: '100vh' }}
        >
          <div>
            <All_B2B_leads />
          </div>
        </div>
      </div>
    </div>
  );
};

export default View_all_client_leads;
