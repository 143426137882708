import { useState, useEffect } from 'react';
import ClosedFilter from '../../Admin_panel/All-leads/ClosedFilter/ClosedFilter';
import ClosedData from '../../Admin_panel/All-leads/ClosedFilter/ClosedData';
import MainLayout from '../../Admin_panel/All-leads/CustomerAllLeads/MainLayout';
import AllFilters from '../FilterForms/AllFilters';
import CustomerTable from '../../Admin_panel/All-leads/CustomerAllLeads/All/CustomerAllTable';
import { fetchAllClosedLeads } from '../../API/FetchFns/AllB2CLeads';
import useLeadsQuery from '../../Hooks/useLeadsQuery';
import useLeadsQueryParams from '../../Hooks/useLeadsQueryParams';
import PaginatedButtons from '../../Admin_panel/All-leads/CustomerAllLeads/All/PaginatedButtons';
import { getUser } from '../../utils/GetUser';

const B2CClosedLeads = ({ settab }) => {
  useEffect(() => {
    settab('closed');
  }, [settab]);

  const [leads, setLeads] = useState([]);

  const { queryParamsObj } = useLeadsQueryParams();

  const queryParams = {
    ...queryParamsObj,
    stage: 'closed',
    employee_id: getUser(),
  };

  const { data, error, isError, isFetching, isLoading } = useLeadsQuery({
    queryParams,
    fetchFn: fetchAllClosedLeads,
    fetchKey: 'ClosedB2CLeadsAdmin',
  });

  data && console.log('CLOSEDDD', data?.data?.results);
  isError && console.log(error);

  useEffect(() => {
    setLeads(data?.data?.results);
  }, [setLeads, data]);

  const isPrevious = Boolean(data?.data?.previous);
  const isNext = Boolean(data?.data?.next);

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <AllFilters />
      <div className=" tw-h-max tw-w-full ">
        <ClosedData setCloseCourseData={setLeads} />
      </div>
      <ClosedFilter setclosedleads={setLeads} />
      <MainLayout loading={isLoading}>
        <CustomerTable
          leads={leads}
          count={data?.data?.count}
        />
        <PaginatedButtons
          isFetching={isFetching}
          isNext={isNext}
          isPrevious={isPrevious}
        />
      </MainLayout>
    </div>
  );
};

export default B2CClosedLeads;
