import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar1';
import Navbar from './Navbar1';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ipaddress2 } from '../App';

const Assign_students_marks = () => {
  const { id } = useParams();

  const [students, setstudents] = useState([]);

  const navigate = useNavigate();

  const [count, setCount] = useState(0);

  useEffect(() => {
    axios
      .get(`${ipaddress2}/StudentsFromParticularBatch/${id}/`)
      .then(r => {
        console.log('Students details', r.data);
        setstudents(r.data);
      })
      .catch(err => {
        console.log('Students details error', err);
      });
  }, [count]);

  const update_marks = (student_id, marks) => {
    console.log(student_id, marks);
    axios
      .put(`${ipaddress2}/update-marks/${id}/${student_id}/`, {
        marks: marks,
      })
      .then(r => {
        console.log('Marks updated', r.data);
        setCount(count + 1);
      });
  };

  return (
    <div className="d-flex ">
      <Sidebar activevalue={'assignmarks'} />
      <div className="w-100 main-div">
        <Navbar />

        <div
          style={{ minHeight: '80vh' }}
          className="bg-light p-3 main-container"
        >
          <div>
            <h6
              className="pb-4 d-flex align-items-center"
              style={{ color: '#474747' }}
            >
              <svg
                onClick={() => {
                  navigate(`/assign_marks`);
                }}
                style={{ cursor: 'pointer' }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                />
              </svg>{' '}
              <span
                className="ms-2"
                style={{ fontSize: '15px' }}
              >
                Update Marks for the Students
              </span>
            </h6>
            <div class="table-responsive mt-2 bg-white p-3">
              <table class="w-100">
                <thead>
                  <tr style={{ backgroundColor: '#034592' }}>
                    <th
                      scope="col"
                      className="border-0 py-3"
                    >
                      <p className="text-center m-0 fw-medium text-white ps-2">
                        SI.No
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="border-0 "
                    >
                      <p className="text-center m-0 fw-medium text-white">
                        Student Name
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="border-0 "
                    >
                      <p className="text-center m-0 fw-medium text-white">
                        View Answer Sheet
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="border-0 "
                    >
                      <p className="text-center m-0 fw-medium text-white">
                        Total Marks
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="border-0 "
                    >
                      <p className="text-center m-0 fw-medium text-white">
                        Marks Scored
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody className="border">
                  {console.log('STU', students)}
                  {students.map((x, index) => {
                    return (
                      <tr className="table-row">
                        <td className="py-3 border-0 border-end border-start">
                          <p className="text-center m-0">{index + 1}</p>
                        </td>
                        <td className="py-2 border-0 border-end">
                          <p className="text-center m-0">
                            {x.user.customer_name}
                          </p>
                        </td>
                        <td className="py-2 border-0 border-end">
                          <div className="text-center">
                            <button
                              onClick={() => {
                                navigate(
                                  `/view_answer_sheet/${x.user.id}/${x.batch.id}`,
                                );
                              }}
                              className="btn btn-sm "
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                class="text-primary bi bi-filetype-pdf"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z"
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                        <td className="border-0 border-end">
                          <p className="text-center m-0">100</p>
                        </td>
                        {console.log('MARKS', x)}
                        <td className="py-2 border-0 border-end">
                          <div className="d-flex justify-content-center">
                            <input
                              type="text"
                              value={x.marks}
                              onChange={e => {
                                update_marks(x.user.id, Number(e.target.value));
                              }}
                              className="form-control w-25 shadow-none border-secondary-subtle"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="certificateModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1
                class="modal-title fs-5"
                id="exampleModalLabel"
              >
                Modal title
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">...</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assign_students_marks;
