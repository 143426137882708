import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ipaddress } from '../App';
import { useFieldArray, useForm } from 'react-hook-form';

const TermsAndConds = ({
  showTerms,
  setShowTerms,
  selectedTerms,
  setSelectedTerms,
}) => {
  const [terms, setterms] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      terms_and_conditions: [
        {
          terms_and_conditions: '',
          client: true,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'terms_and_conditions',
    control,
  });

  useEffect(() => {
    axios
      .get(`${ipaddress}/TermsAndConditionsView/?data=client`)
      .then(r => {
        console.log('Terms & conditions', r.data);
        setterms(r.data);
      })
      .catch(err => {
        console.log('Terms error');
      });
  }, []);

  const onSubmit = data => {
    console.log('TERMSDATA:', data);
    axios
      .post(`${ipaddress}/CreateTermsConditions/`, data)
      .then(response => {
        console.log('Success:', response.data);
        reset();
      })
      .catch(err => {
        console.error('Error:', err);
      });
  };

  const handleCheckboxChange = (id, isChecked) => {
    setSelectedTerms(prev => {
      if (isChecked) {
        // Add term ID if not already selected
        return [...prev, id];
      } else {
        // Remove term ID if unchecked
        return prev.filter(termId => termId !== id);
      }
    });
  };

  return (
    <div>
      <Modal
        show={showTerms}
        onHide={() => {
          setShowTerms(false);
        }}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <Modal.Title className="text-success fs-5">
            Terms and Conditions
          </Modal.Title>
          <svg
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setShowTerms(false);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-x-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
          </svg>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            {terms?.map((x, index) => {
              const isChecked = selectedTerms.includes(x.id);
              console.log(isChecked);
              return (
                <div
                  key={x.id}
                  className="tw-bg-red-30 tw-border tw-p-2 tw-mb-1 tw-gap-x-2 tw-flex tw-items-center tw-justify-center"
                >
                  <input
                    className="tw-bg-red-20 tw-border "
                    type="checkbox"
                    checked={isChecked}
                    onChange={e => handleCheckboxChange(x.id, e.target.checked)}
                  />
                  <p className="tw-flex-grow">
                    <span className="fw-bold">{index + 1}) </span>
                    {x.terms_and_conditions}
                  </p>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Body>
          <div className="p-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              {fields.map((field, index) => (
                <div
                  key={field.id}
                  className="tw-mb-3 tw-flex tw-gap-2 tw-items-center"
                >
                  <input
                    {...register(
                      `terms_and_conditions.${index}.terms_and_conditions`,
                      {
                        required: 'This field is required',
                      },
                    )}
                    placeholder="Enter terms and conditions"
                    className="form-control"
                  />
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => remove(index)}
                  >
                    Remove
                  </button>
                  <input
                    type="hidden"
                    {...register(`terms_and_conditions.${index}.client`)}
                    value={true}
                  />
                </div>
              ))}
              <button
                type="button"
                className="btn btn-primary tw-ml-3"
                onClick={() =>
                  append({ terms_and_conditions: '', client: true })
                }
              >
                Add New
              </button>
              <button
                type="submit"
                className="btn btn-success"
              >
                Submit
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TermsAndConds;
