import React from 'react';
import Sidebar from '../Components/Sidebar';
import Navbar from './Navbar';
import AllCustomerLeads from './AllCustomerLeads';

const View_all_leads = () => {
  return (
    <div className="d-flex">
      <Sidebar activevalue={'allb2cleads'} />
      <div className="w-100">
        <Navbar />
        <div
          className="bg-light p-3 main-container"
          style={{ minHeight: '100vh' }}
        >
          <div>
            <AllCustomerLeads />
          </div>
        </div>
      </div>
    </div>
  );
};

export default View_all_leads;
