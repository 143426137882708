import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ipaddress2 } from '../App';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

const Course_completion = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));

  const { id } = useParams();
  const [details, setDetails] = useState({});

  useEffect(() => {
    axios
      .get(`${ipaddress2}/CertificateGeneration/${user.email}/${id}/`)
      .then(r => {
        console.log('Cerificate Details', r.data);
        setDetails(r.data);
      });
  }, []);

  console.log('CERTI', details);

  const downloadCertificate = () => {
    const input = document.getElementById('certificate');
    html2canvas(input, { scale: 3, useCORS: true })
      .then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('landscape', 'pt', [canvas.width, canvas.height]); // Set PDF dimensions based on the canvas
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = 0; // Align to the left
        const imgY = 0; // Align to the top

        pdf.addImage(
          imgData,
          'PNG',
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio,
        );
        pdf.save('certificate.pdf');
      })
      .catch(error => {
        console.error('Error generating PDF:', error);
      });
  };

  // To get current date
  const getCurrentDate = () => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  };

  const date = getCurrentDate();

  function getOrdinalSuffix(day) {
    const j = day % 10;
    const k = day % 100;
    if (j === 1 && k !== 11) {
      return day + 'st';
    }
    if (j === 2 && k !== 12) {
      return day + 'nd';
    }
    if (j === 3 && k !== 13) {
      return day + 'rd';
    }
    return day + 'th';
  }

  // Function to format the date as DDth Full Month Year
  function formatDate(inputDate) {
    const date = dayjs(inputDate); // Parse the input date
    const day = date.date(); // Get the day of the month

    // Format the date in 'DDth Full Month Year' format
    return `${getOrdinalSuffix(day)} ${date.format('MMMM YYYY')}`;
  }

  console.log('CERTI', formatDate(details.start_date));

  return (
    <div className="bg-light py-4">
      <div className="text-center mb-3">
        <button
          onClick={downloadCertificate}
          className="btn btn-primary"
        >
          Download
        </button>
      </div>

      <div
        className="m-0 p-0 container mx-auto bg-white pe-5 certificate2"
        id="certificate"
        style={{ minHeight: '100vh' }}
      >
        <div className="row m-0">
          <div
            className="col-3 certificate1"
            style={{ position: 'relative' }}
          >
            <img
              src={require('../images/certificate/Group 8.png')}
              width={220}
              alt=""
              style={{ position: 'absolute', right: '-70px', top: '36%' }}
            />
          </div>
          <div
            className="col-9 my-4 border-start-0 py-4"
            style={{ border: '3px solid #C9A448' }}
          >
            <div className="text-center pb-4">
              <img
                src={require('../images/student/Logo_Blue 1.png')}
                width={180}
                alt=""
              />
            </div>
            <div>
              <p className="certificate1-head text-center">CERTIFICATE</p>
              <p className="text-center">
                <img
                  src={require('../images/certificate/Group 10.png')}
                  width={130}
                  height={2}
                  alt=""
                />
                <span className="mx-4 certificate1-text">
                  OF COURSE COMPLETION
                </span>
                <img
                  src={require('../images/certificate/Group 10.png')}
                  width={130}
                  height={2}
                  alt=""
                />
              </p>
            </div>

            <div className="mt-5">
              <p
                className="text-center"
                style={{ fontSize: '19px' }}
              >
                This is to certify that
              </p>

              <div
                className="px-5"
                style={{ position: 'relative' }}
              >
                <p className="certificate1-head2 text-center m-0">
                  {details.name}
                </p>
                <p
                  className="text-center"
                  style={{
                    position: 'absolute',
                    top: '45px',
                    marginLeft: '70px',
                  }}
                >
                  <img
                    src={require('../images/certificate/Group 11.png')}
                    style={{ width: '80%' }}
                    alt=""
                  />
                </p>

                <p
                  className=" text-center mt-2 px-3 m-0"
                  style={{ fontSize: '18px' }}
                >
                  has successfully completed the{' '}
                  <span
                    className="tw-mr-1"
                    style={{ fontSize: '18px', fontWeight: 600 }}
                  >
                    {details?.course}
                  </span>
                  from{' '}
                  <span style={{ fontSize: '18px' }}>
                    {formatDate(details?.start_date)}
                  </span>{' '}
                  to{' '}
                  <span style={{ fontSize: '18px' }}>
                    {formatDate(details?.end_date)}
                  </span>{' '}
                  in our Jayanagar 4th Block,
                </p>
                <p
                  className=" text-center px-3 m-0"
                  style={{ fontSize: '18px' }}
                >
                  Bangalore.
                </p>

                <p
                  className=" text-center mt-2"
                  style={{ fontSize: '18px' }}
                >
                  We wish <span style={{ fontSize: '18px' }}>him/her</span> good
                  luck and success in future endeavors.
                </p>
              </div>
            </div>

            <div className="row m-0 justify-content-between mt-5 pt-5">
              <div className="col-4 pt-5 pe-4">
                <div>
                  <p className="m-0 text-center">{date}</p>
                  <div className="text-center">
                    <img
                      src={require('../images/certificate/Group 10.png')}
                      width={130}
                      height={2}
                      alt=""
                    />
                  </div>
                  <p
                    className="text-center"
                    style={{ fontSize: '18px', letterSpacing: '1px' }}
                  >
                    DATE
                  </p>
                </div>
              </div>
              <div className="col-4 pt-4 px-4">
                <div>
                  <p className="m-0 text-center">
                    Jayanagar 4th Block Branch, Bangalore
                  </p>
                  <img
                    src={require('../images/certificate/Group 10.png')}
                    width={280}
                    height={2}
                    alt=""
                  />
                  <p
                    className="text-center"
                    style={{ fontSize: '18px', letterSpacing: '1px' }}
                  >
                    BRANCH
                  </p>
                </div>
              </div>
              <div className="col-4 ps-4">
                <div>
                  <div className="text-center">
                    <img
                      src={require('../images/certificate/Group 9.png')}
                      width={100}
                      height={48}
                      alt=""
                    />
                    <p
                      className="m-0"
                      style={{ fontSize: '10px' }}
                    >
                      MERIDA TECH MINDS (OPC) PVT.LTD
                    </p>
                  </div>

                  <p className="m-0 text-center">
                    <img
                      src={require('../images/certificate/Group 10.png')}
                      style={{ width: '90%' }}
                      height={2}
                      alt=""
                    />
                  </p>
                  <p
                    className="text-center"
                    style={{ fontSize: '18px', letterSpacing: '1px' }}
                  >
                    SIGNATURE
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course_completion;
