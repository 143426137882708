import { useParams } from 'react-router-dom';
import cardImg from '../../images/Icon.png';
import SectionBreak from './SectionBreak';
import WalkinMonthCard from './WalkinMonthCard';

function WalkinEmployeeCard({ walkinCount, name, imgCard = cardImg, navTo }) {
  const { id } = useParams();

  return (
    <div className="tw-bg-red-20 tw-cursor-pointer ">
      <div className="tw-flex tw-items-center tw-bg-white tw-h-full tw-w-full">
        <div className="tw-w-[30%] tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center">
          <h2 className="tw-text-[#A3AED0] tw-text-sm tw-mt-3 tw-px-3 tw-bg-red-20">
            Walkins
            <span className="tw-pl-1">/ Meetings</span>
          </h2>

          <img
            src={imgCard}
            width={44}
            alt=""
          />
        </div>

        <div className="tw-flex tw-gap-x-3 tw-p-2">
          <WalkinMonthCard
            monthName={'This Month'}
            empWalkin={walkinCount?.currentMonth?.employeeWalkins}
            scanWalkin={walkinCount?.currentMonth?.scannedWalkins}
            navToEmp={`/view_employee_leads/${id}/walkins/?type=currentMonth`}
            navToScan={`/view_employee_leads/${id}/walkins/?type=currentMonth&isScanned=true`}
          />
          <SectionBreak />

          <WalkinMonthCard
            monthName={'Previous Month'}
            empWalkin={walkinCount?.previousMonth?.employeeWalkins}
            scanWalkin={walkinCount?.previousMonth?.scannedWalkins}
            navToEmp={`/view_employee_leads/${id}/walkins/?type=previousMonth`}
            navToScan={`/view_employee_leads/${id}/walkins/?type=previousMonth&isScanned=true`}
          />
          <SectionBreak />
          <WalkinMonthCard
            monthName={'Total Walk-In Leads'}
            empWalkin={walkinCount?.total?.employeeWalkins}
            scanWalkin={walkinCount?.total?.scannedWalkins}
            navToEmp={`/view_employee_leads/${id}/walkins`}
            navToScan={`/view_employee_leads/${id}/walkins/?isScanned=true`}
          />
        </div>
      </div>
    </div>
  );
}

export default WalkinEmployeeCard;
