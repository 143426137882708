import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import TodayWalkin from '../Components/TodayWalkinPage/TodayWalkin';
import AchievedWalkins from '../Components/AchivedWalkins/AchievedWalkins';
import RegisSummary from './RegisSummary/RegisSummary';
import CustomerAll from './All-leads/CustomerAllLeads/All/CustomerAll';
import CustomerAllNewLeads from './All-leads/CustomerAllLeads/CustomerAllNewLeads';
import CustomerWalkinLeads from './All-leads/CustomerAllLeads/Walkins/CustomerWalkinLeads';
import CustomerFollowUps from './All-leads/CustomerAllLeads/CustomerFollowUp';
import CustomerProspects from './All-leads/CustomerAllLeads/Prospects/CustomerProspects';
import CustomerRegistered from './All-leads/CustomerAllLeads/CustomerRegistered';
import CustomerClosedLeads from './All-leads/CustomerAllLeads/Closed/CustomerClosedLeads';
import CustomerOtherDept from './All-leads/CustomerAllLeads/CustomerOtherDept';
import CustomerTodayWalkiins from './All-leads/CustomerAllLeads/CustomerTodayWalkiins';
import CustomerAchievedWalkins from './All-leads/CustomerAllLeads/CustomerAchievedWalkins';
import CustomerRegistrationSum from './All-leads/CustomerAllLeads/CustomerRegistrationSum';
import TabSlider from './MobileSlider';
import LeadsDropdown from './LeadsDropdown';
import Customer_all_leads from './All-leads/Customer_all_leads';
import Customer_registered_leads from './All-leads/Customer_registered_leads';

const AllCustomerLeads = () => {
  const [tab, setTab] = useState('all');

  return (
    <div>
      <div className="d-flex">
        <div className="w-100 main-div">
          <div className="bg-light pt-1 h-100">
            <div className="px-1">
              <TabSlider
                setTab={setTab}
                tab={tab}
              />
              <LeadsDropdown
                setTab={setTab}
                tab={tab}
              />
              <div className="tw-bg-red-0">
                <Routes>
                  <Route
                    path="/old"
                    element={<Customer_registered_leads settab={setTab} />}
                  />
                  <Route
                    path="/*"
                    element={<CustomerAll settab={setTab} />}
                  />
                  <Route
                    path="/newlead"
                    element={<CustomerAllNewLeads settab={setTab} />}
                  />
                  <Route
                    path="/walkin"
                    element={<CustomerWalkinLeads settab={setTab} />}
                  />
                  <Route
                    path="/followup"
                    element={<CustomerFollowUps settab={setTab} />}
                  />
                  <Route
                    path="/prospect"
                    element={<CustomerProspects settab={setTab} />}
                  />
                  <Route
                    path="/registered"
                    element={<CustomerRegistered settab={setTab} />}
                  />
                  <Route
                    path="/closed"
                    element={<CustomerClosedLeads settab={setTab} />}
                  />
                  <Route
                    path="/otherdepartment"
                    element={<CustomerOtherDept settab={setTab} />}
                  />
                  <Route
                    path="/today-walkins"
                    element={<CustomerTodayWalkiins settab={setTab} />}
                  />
                  <Route
                    path="/today-walkins1"
                    element={<TodayWalkin settab={setTab} />}
                  />
                  <Route
                    path="/achieved-walkins"
                    element={<CustomerAchievedWalkins settab={setTab} />}
                  />
                  <Route
                    path="/achieved-walkins1"
                    element={<AchievedWalkins settab={setTab} />}
                  />
                  <Route
                    path="/registration-summary1"
                    element={<RegisSummary settab={setTab} />}
                  />
                  <Route
                    path="/registration-summary"
                    element={<CustomerRegistrationSum settab={setTab} />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCustomerLeads;
