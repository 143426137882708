import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function RemarksSections({ rowData }) {
  return (
    <OverlayTrigger
      placement="left" // Tooltip appears to the right
      overlay={
        <Tooltip id={`tooltip-${rowData.id}`}>
          <span
            style={{
              whiteSpace: 'normal', // Allow text wrapping
              minWidth: '500px', // Set max width for the tooltip
              wordWrap: 'break-word', // Break long words if needed
            }}
          >
            {rowData.remarks || '-'}
          </span>
        </Tooltip>
      }
    >
      <p
        className=" tw-text-blue-600 py-2 tw-mb-2"
        style={{
          cursor: 'pointer',
        }}
      >
        {rowData.remarks ? `${rowData.remarks}...` : '-'}
      </p>
    </OverlayTrigger>
  );
}

export default RemarksSections;
