import axios from 'axios';
import { ipaddress } from '../../../App';
import { useEffect, useState, useCallback } from 'react';
import DataCount from './DataCount';
import { useLocation } from 'react-router-dom';
import DataSubCounts from '../ClosedReasons/DataSubCounts';
import { buildQueryString } from '../../../utils/QueryBuilder';

function ClosedData({ setCloseCourseData, startDate, endDate, singleDate }) {
  const [closedData, setClosedData] = useState([]);
  const [reasonsData, setReasonsData] = useState([]);
  const [courseBasedReasons, setCourseBasedReasons] = useState([]);
  const [reasonsBasedCourses, setReasonsBasedCourses] = useState([]);

  const [selectedReason, setSelectedReason] = useState();
  const [selectedCourse, setSelectedCourse] = useState();

  const location = useLocation();

  let empID;

  if (location.pathname.startsWith('/view_employee_leads')) {
    empID = location.pathname.slice(21, 31);
  }
  if (location.pathname.startsWith('/today_leads')) {
    empID = location.pathname.slice(13, 23);
  }
  console.log('HII', empID);

  const getReasonsData = useCallback(async () => {
    const queryParams = {
      emp_id: empID,
      start_date: startDate,
      end_date: endDate,
      date: singleDate,
    };

    const IPPADDRESS = `${ipaddress}/SearchClosedLeadsForReasons/?${buildQueryString(
      queryParams,
    )}`;

    console.log('REASONSAPI', queryParams, IPPADDRESS);

    try {
      const response = await axios.get(IPPADDRESS);
      console.log('REASONS ALL', response.data);
      setReasonsData(response.data.reason_counts);
    } catch (error) {
      console.error('Error fetching reasons data:', error);
    }
  }, [empID]);

  const getCoursesBasedOnReasonsData = useCallback(
    async reason => {
      setSelectedReason(reason);
      console.log(reason);

      const queryParams = {
        emp_id: empID,
        start_date: startDate,
        end_date: endDate,
        date: singleDate,
        reason: reason,
      };

      const IPPADDRESS = `${ipaddress}/SearchLeadsForReason/?${buildQueryString(
        queryParams,
      )}`;

      console.log('REASONSAPI', IPPADDRESS);

      try {
        const response = await axios.get(IPPADDRESS);
        console.log('COURSE BASED REASONS', response.data);
        setCourseBasedReasons(response.data.course_counts);
      } catch (error) {
        console.error('Error fetching reasons data:', error);
      }
    },
    [empID],
  );

  const getReasonsBasedOnCoursesData = useCallback(
    async course_name => {
      setSelectedCourse(course_name);
      console.log(course_name);

      const queryParams = {
        emp_id: empID,
        start_date: startDate,
        end_date: endDate,
        date: singleDate,
        course_name: course_name,
      };

      const IPPADDRESS = `${ipaddress}/PeopleByReasonForClosure/?${buildQueryString(
        queryParams,
      )}`;

      console.log('REASONSAPI', IPPADDRESS);

      try {
        const response = await axios.get(IPPADDRESS);
        console.log(' REASONS BASED COURSES', response.data);
        setReasonsBasedCourses(response.data);
      } catch (error) {
        console.error('Error fetching reasons data:', error);
      }
    },
    [empID],
  );

  const getClosedData = useCallback(async () => {
    const queryParams = {
      emp_id: empID,
      start_date: startDate,
      end_date: endDate,
      date: singleDate,
    };

    const IPPADDRESS = `${ipaddress}/CourseWiseClosedLeads/?${buildQueryString(
      queryParams,
    )}`;
    console.log('IPAD', IPPADDRESS);

    try {
      const response = await axios.get(IPPADDRESS);
      console.log('CLOSED ALL', response.data);
      setClosedData(response.data);
    } catch (error) {
      console.error('Error fetching closed data:', error);
    }
  }, [empID]);

  useEffect(() => {
    getReasonsData();
    getClosedData();
  }, [getClosedData, getReasonsData, singleDate]);

  const fetchCourses = async courseName => {
    const queryParams = {
      course_name: courseName,
      reason_for_closure: selectedReason,
      start_date: startDate,
      end_date: endDate,
      date: singleDate,
      emp_id: empID,
    };

    const apiUrl = `${ipaddress}/SearchClosedLeads/?${buildQueryString(
      queryParams,
    )}`;

    try {
      const response = await axios.get(apiUrl);
      console.log('MUIZ:', courseName, apiUrl, response.data);

      const section = document.getElementById('dataTable');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      console.log('MUIZ', response.data.customer_leads_data);

      setCloseCourseData(response.data.customer_leads_data);
    } catch (error) {
      console.error('Error fetching filtered closed leads:', error);
    }
  };

  const fetchReasons = async reason => {
    const queryParams = {
      course_name: selectedCourse,
      reason_for_closure: reason,
      start_date: startDate,
      end_date: endDate,
      date: singleDate,
      emp_id: empID,
    };

    const apiUrl = `${ipaddress}/SearchClosedLeads/?${buildQueryString(
      queryParams,
    )}`;

    console.log('MUIZ', apiUrl);

    axios
      .get(apiUrl)
      .then(response => {
        console.log('MUIZDATA', response.data);
        setCloseCourseData(response.data.customer_leads_data);

        const section = document.getElementById('dataTable');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      })
      .catch(error => {
        console.error('Error fetching filtered closed leads:', error);
      });
  };

  return (
    <div className="tw-p-4">
      <div className="tw-grid tw-grid-cols-4 tw-grid-rows-1 tw-gap-x-2 ">
        <DataCount
          fetchData={getReasonsBasedOnCoursesData}
          data={closedData}
          fieldCount="count"
          fieldName="customer_lead_id__course_enquired__course_name"
          title="Courses Summary"
        />
        {selectedCourse && (
          <DataSubCounts
            fetchData={fetchReasons}
            data={reasonsBasedCourses}
            fieldCount="count"
            fieldName="reason_for_closure__reason"
            title="Reasons Based On Courses Summary"
          />
        )}
        <DataCount
          fetchData={getCoursesBasedOnReasonsData}
          data={reasonsData}
          fieldCount="count"
          fieldName="reason"
          title="Reasons Summary"
        />
        {selectedReason && (
          <DataSubCounts
            fetchData={fetchCourses}
            data={courseBasedReasons}
            fieldCount="count"
            fieldName="course_name"
            title="Courses Based On Reasons Summary"
          />
        )}
      </div>
    </div>
  );
}

export default ClosedData;
