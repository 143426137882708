import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Slider from 'react-slick';
import { Chart as ChartJS } from 'chart.js/auto';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ipaddress, ipaddress2, role } from '../App';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import Add_activity_targets from './Add_activity_targets';
import Employee_leads_count from './Employee_leads_count';
import Arrow_svg from '../Svgs/Arrow_svg';
import EmployeeLeadsCount from './EmployeeLeadsCount';

const Employee_profile = () => {
  const params = useParams();
  const location = useLocation();
  const [refresh, setRefresh] = useState(false);

  console.log('THIS IS ', params);
  const isEmployee = location.pathname === '/dashboard' ? true : false;

  const user = JSON.parse(sessionStorage.getItem('user'));

  const id = isEmployee ? user?.emp_id : params?.id;

  console.log('MUIZ', user.emp_id);
  const options = {
    plugins: {
      legend: {
        position: 'bottom', // Set legend position to bottom
        labels: {
          boxWidth: 20,
          maxWidth: 350, // Adjust this value as needed
        },
      },
    },
    // Other chart options
  };

  const navigate = useNavigate();

  const [title, settitle] = useState([]);
  const [targets, settargets] = useState([]);
  const [achieved, setachieved] = useState([]);

  useEffect(() => {
    axios
      .get(`${ipaddress2}/employee/${id || user.emp_id}/activities/`)
      .then(r => {
        console.log('Activities graph', r.data.datasets);
        const data = r.data.datasets;

        const titles = data.map(item => item.activity_name);
        const totalMarks = data.map(item => item.Target);
        const marksAchieved = data.map(item => item.achieved);
        settitle(titles);
        settargets(totalMarks);
        setachieved(marksAchieved);
      });
  }, []);

  const employeecomparision = {
    labels: title,
    datasets: [
      {
        label: 'Target',
        data: targets,
        fill: false,
        backgroundColor: '#1814F3',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Achieved',
        data: achieved,
        fill: false,
        backgroundColor: '#74C228',
        tension: 0.1,
        barThickness: 8,
      },
    ],
  };

  const [emp_data, setemp_data] = useState({});
  const [modal_name, setmodal_name] = useState();

  const [designation, setdesignation] = useState('');
  const [supervisor, setsupervisor] = useState('');

  const fetchemp_data = () => {
    axios
      .get(`${ipaddress}/MyprofileView/${id || user.emp_id}/`)
      .then(r => {
        console.log('Employee data found', r.data);
        setemp_data(r.data);
        setdesignation(r.data.designation);
        setsupervisor(r.data.supervisor);
      })
      .catch(err => {
        console.log('Employee data fetching error', err);
      });
  };

  const [modals, setmodals] = useState([]);

  useEffect(() => {
    fetchemp_data();

    axios.get(`${ipaddress}/AllModules/`).then(r => {
      console.log('Module Names', r.data);
      setmodals(r.data);
    });
  }, [id]);

  const block_trainer = status => {
    axios
      .post(`${ipaddress2}/BlockTrainer/${emp_data.email}/`, {
        email: emp_data.email,
        status: status,
      })

      .then(r => {
        fetchemp_data();
        if (status == true) {
          toast.warn(`${emp_data.firstname} has been inactivated`, {
            autoClose: 2000,
            theme: 'colored',
            position: 'top-center',
          });
        } else {
          toast.success(`${emp_data.firstname} has been activated`, {
            autoClose: 2000,
            theme: 'colored',
            position: 'top-center',
          });
        }
      });
  };

  // To assign the Target count

  const assigntarget = value => {
    axios
      .post(`${ipaddress}/ActivityView/${user.emp_id}/`, {
        module_name: modal_name,
        EmployeeId: [id],
        login_user: user.emp_id,
        Activity_data: fields,
      })
      .then(r => {
        console.log('Activity Assigned', r.data);
        toast.success('Activity Assigned Successfully', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setFields([{ Activity_Name: '', targets: 0 }]);
        setmodal_name('');
      })

      .catch(err => {
        console.log('Assigning activity error', err);
        console.log({
          emp_id: [id],
          login_user: user.emp_id,
          Activity_data: fields,
          module_name: modal_name,
        });
      });
  };

  const [delete_customer_lead, setdelete_customer_lead] = useState(false);
  const [delete_client_lead, setdelete_client_lead] = useState(false);
  const [assign_activity, setassign_activity] = useState(false);
  const [assign_students, setassign_students] = useState(false);
  const [view_all_employees, setview_all_employees] = useState(false);
  const [view_newleads, setview_newleads] = useState(false);
  const [assign_leads, setassign_leads] = useState(false);
  const [create_projects, setcreate_projects] = useState(false);
  const [add_events, setadd_events] = useState(false);
  const [add_activity_module, setadd_activity_module] = useState(false);
  const [batch_permission, setbatch_permission] = useState(false);

  const [selecetdemp, setselectedemp] = useState('');

  const assign_permissions = () => {
    axios
      .post(`${ipaddress2}/PermissionsView/${user.emp_id}/`, {
        employee: id,
        delete_customer_lead: delete_customer_lead,
        delete_client_lead: delete_client_lead,
        assign_activity: assign_activity,
        view_all_employees: view_all_employees,
        assign_leads: assign_leads,
        add_events: add_events,
        create_projects: create_projects,
        assign_students: assign_students,
        view_newleads: view_newleads,
        add_activity_module: add_activity_module,
        batch_permission: batch_permission,
      })
      .then(r => {
        console.log('Permissions assigned', r.data);
        toast.success(`Permissions assigned successfully`, {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });

        console.log({
          delete_customer_lead: delete_customer_lead,
          delete_client_lead: delete_client_lead,
          assign_activity: assign_activity,
          view_all_employees: view_all_employees,
          add_events: add_events,
          create_projects: create_projects,
        });
        setcount(count + 1);
      })
      .catch(err => {
        console.log({
          delete_customer_lead: delete_customer_lead,
          delete_client_lead: delete_client_lead,
          assign_activity: assign_activity,
          view_all_employees: view_all_employees,
        });
        console.log(err);
      });
  };

  // To view the assigned permissions

  const [count, setcount] = useState(0);
  const [permissions, setpermissions] = useState({});

  useEffect(() => {
    if (user.designation === 'admin') {
      axios.get(`${ipaddress}/PermissionsView/${id}/`).then(r => {
        console.log('Permissions', r.data);
        setpermissions(r.data);
        setdelete_customer_lead(r.data.delete_customer_lead);
        setdelete_client_lead(r.data.delete_client_lead);
        setassign_activity(r.data.assign_activity);
        setview_all_employees(r.data.view_all_employees);
        setassign_leads(r.data.assign_leads);
        setcreate_projects(r.data.create_projects);
        setadd_events(r.data.add_events);
        setassign_students(r.data.assign_students);
        setview_newleads(r.data.view_newleads);
        setadd_activity_module(r.data.add_activity_module);
        setbatch_permission(r.data.batch_permission);

        console.log('ADMINNNN');
      });
    } else {
      axios.get(`${ipaddress}/PermissionsView/${user.emp_id}/`).then(r => {
        console.log('Permissions', r.data);
        setpermissions(r.data);
        setdelete_customer_lead(r.data.delete_customer_lead);
        setdelete_client_lead(r.data.delete_client_lead);
        setassign_activity(r.data.assign_activity);
        setview_all_employees(r.data.view_all_employees);
        setassign_leads(r.data.assign_leads);
        setcreate_projects(r.data.create_projects);
        setadd_events(r.data.add_events);
        setassign_students(r.data.assign_students);
        setview_newleads(r.data.view_newleads);
        setadd_activity_module(r.data.add_activity_module);
        setbatch_permission(r.data.batch_permission);

        console.log('EMPLOYEEEE');
      });
    }
  }, [count, id, user.designation, user.emp_id]);

  const [fields, setFields] = useState([{ Activity_Name: '', targets: 0 }]);

  const handleAddMore = () => {
    setFields([...fields, { Activity_Name: '', targets: 0 }]);
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newFields = [...fields];
    newFields[index][name] = value;
    setFields(newFields);
  };

  // Filter for chart

  const [filter_type, setfilter_type] = useState('');
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');

  const filter_chart = (value, end_time) => {
    console.log('THIS', user.emp_id);
    axios

      .get(`${ipaddress2}/employee/${id || user.emp_id}/activities/`)
      .then(r => {
        console.log('Filter Activities graph', r.data.datasets);
        const data = r.data.datasets;

        const titles = data.map(item => item.activity_name);
        const totalMarks = data.map(item => item.Target);
        const marksAchieved = data.map(item => item.achieved);
        settitle(titles);
        settargets(totalMarks);
        setachieved(marksAchieved);
      });
  };

  const [sourcesData, setSourcesData] = useState();

  const [sourcedata, setsourcedata] = useState([]);

  const [coursedata, setcoursedata] = useState([]);

  useEffect(() => {
    axios
      .get(`${ipaddress}/Leads_chart_by_course/${id || user.emp_id}/`)
      .then(r => {
        console.log('Course based leads', r.data.datasets);
        setcoursedata(r.data.datasets);
      });

    axios
      .get(`${ipaddress}/Leads_chart_by_source_bargraph/${user.emp_id}/`)
      .then(r => {
        console.log('Sourse based leads', r.data);
        setsourcedata(r.data.datasets);
      });

    getSourceWiseData();
  }, []);

  const getSourceWiseData = () => {
    const IPADDRESS = `${ipaddress}/CountsForSourceLeads/`;

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('COUNTSOURCESDATA', res.data.customer_leads);
        setSourcesData(res.data.customer_leads);
        // setSourcesData(res.data);
      })
      .catch(err => console.log(err));
  };

  const leadsbycourse = {
    labels: coursedata.map(x => x.label),
    datasets: [
      {
        label: 'New Leads',
        data: coursedata.map(item => item.data[0]),
        fill: false,
        backgroundColor: 'rgb(27,98,169)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Followups',
        data: coursedata.map(item => item.data[1]),
        fill: false,
        backgroundColor: 'rgb(255,173,51)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Prospects',
        data: coursedata.map(item => item.data[2]),
        fill: false,
        backgroundColor: 'rgb(255,76,76)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Regsitered',
        data: coursedata.map(item => item.data[3]),
        fill: true,
        backgroundColor: 'rgb(72,193,255)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Closed',
        data: coursedata.map(item => item.data[4]),
        fill: false,
        backgroundColor: 'rgb(173,173,133)',
        tension: 0.1,
        barThickness: 8,
      },
    ],
  };

  const leadsbyPIEcourse = {
    labels: coursedata.filter(item => item.data[2] > 0).map(x => x.label),
    datasets: [
      {
        label: 'Prospects',
        data: coursedata
          .filter(item => item.data[2] > 0)
          .map(item => item.data[2]),
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#E7E9ED',
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#E7E9ED',
        ],
        tension: 0.1,
      },
    ],
  };

  const options4 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 10,
          padding: 15,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.raw}`;
          },
        },
      },
    },
  };

  console.log('SOURCEDATA', sourcedata);

  const leadsbyPIEsourse = {
    labels: sourcesData?.map(item => item.source),
    datasets: [
      {
        // label: 'Prospects',
        data: sourcesData?.map(item => item.count),
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#E7E9ED',
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#E7E9ED',
        ],
        tension: 0.1,
      },
    ],
  };

  const options3 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 10,
          padding: 15,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.raw}`;
          },
        },
      },
    },
  };

  const leadsbysource = {
    labels: sourcedata.map(x => x.label),
    datasets: [
      {
        label: 'New Leads',
        data: sourcedata.map(item => item.data[0]),
        fill: false,
        backgroundColor: 'rgb(27,98,169)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Followups',
        data: sourcedata.map(item => item.data[1]),
        fill: false,
        backgroundColor: 'rgb(255,173,51)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Prospects',
        data: sourcedata.map(item => item.data[2]),
        fill: false,
        backgroundColor: 'rgb(255,76,76)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Regsitered',
        data: sourcedata.map(item => item.data[3]),
        fill: true,
        backgroundColor: 'rgb(72,193,255)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Closed',
        data: sourcedata.map(item => item.data[4]),
        fill: false,
        backgroundColor: 'rgb(173,173,133)',
        tension: 0.1,
        barThickness: 8,
      },
    ],
  };

  const [show, setshow] = useState(false);
  const [show2, setshow2] = useState(false);

  const [designations_data, setdesignation_data] = useState([]);
  const [supervisor_data, setsupervisor_data] = useState([]);

  useEffect(() => {
    axios.get(`${ipaddress}/UsersDropdown/`).then(r => {
      console.log('Supervisors', r.data);
      setsupervisor_data(r.data);
    });

    axios.get(`${ipaddress}/DesignationDropdown/`).then(r => {
      console.log('Designations', r.data);
      setdesignation_data(r.data);
    });
  }, []);

  useEffect(() => {
    fetchemp_data();
  }, []);

  const [edit_designation, setedit_designation] = useState(false);
  const [edit_supervisor, setedit_supervisor] = useState(false);

  const change_designation = value => {
    const val = designations_data.find(x => x.designation === value);
    setdesignation(val.designation);

    axios
      .patch(`${ipaddress}/AddEmployeeView/`, {
        empid: id,
        designation: Number(val.id),
      })
      .then(r => {
        toast.success('Designation Updated Successfully', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        fetchemp_data();
        setedit_designation(false);
      });
  };

  const change_supervisor = value => {
    const val = supervisor_data.find(x => x.first_name === value);
    setsupervisor(val.first_name);

    axios
      .patch(`${ipaddress}/AddEmployeeView/`, {
        empid: id,
        supervisorr: Number(val.id),
      })
      .then(r => {
        toast.success('Supervisor Changed Successfully', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        fetchemp_data();
        setedit_supervisor(false);
      });
  };

  const [showProfile, setShowProfile] = useState(false);

  return (
    <div className="d-flex">
      <Sidebar activevalue={'employees'} />
      <div className="w-100 main-div">
        <Navbar
          pagename={'Employees'}
          followUp={emp_data}
          id={id}
        />
        <div
          className="p-2 p-md-3 main-container"
          style={{ minHeight: '100vh', backgroundColor: '#E8E8E8' }}
        >
          <div className="bg-white py-2 py-md-4">
            <div className="row m-0 d-flex justify-content-between pb-2">
              <div className="col-md-8">
                <h5 className="d-flex align-items-center text-color">
                  <span
                    className="me-2"
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate('/view_employees')}
                  >
                    <Arrow_svg />
                  </span>
                  {emp_data.firstname && emp_data.firstname.toUpperCase()}'S
                  PROFILE
                </h5>
              </div>
              <div
                className="col-md-4 d-flex align-items-center justify-content-end"
                style={{}}
              >
                <button
                  onClick={() => {
                    setShowProfile(prev => !prev);
                  }}
                  className={`btns py-2 text-white px-3 me-3`}
                >
                  View Profile
                </button>

                <button
                  onClick={() => {
                    navigate(`/view_employee_activity/${id}`);
                  }}
                  className={`btns py-2 text-white px-3 me-3`}
                >
                  View Performance
                </button>

                <button
                  className={`btns py-2 text-white px-3 ${
                    permissions.assign_activity ||
                    user.designation === 'admin' ||
                    user.designation === role
                      ? ''
                      : 'd-none'
                  }`}
                  onClick={() => {
                    setshow(true);
                  }}
                >
                  Assign Target
                </button>
              </div>
            </div>

            {showProfile && (
              <div className="row p-2 justify-content-between tw-mt-10">
                <div className="col-lg-5 col-md-6 px-1 px-md-3 mb-4">
                  <div className="border-bottom border-0 d-flex pb-1 mb-4 emp_profile_layout">
                    <p className="m-0 text-secondary">Employee ID</p>
                    <p className="m-0 fw-bold">{emp_data.emp_id}</p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6 px-1 px-md-3 mb-4">
                  <div className="border-bottom border-0 d-flex pb-1 mb-4 emp_profile_layout">
                    <p className="m-0 text-secondary">Employee Name</p>
                    <p className="m-0 fw-bold">{emp_data.firstname}</p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6 px-1 px-md-3 mb-4">
                  <div className="border-bottom border-0 d-flex pb-1 mb-4 emp_profile_layout">
                    <p className="m-0 text-secondary">Designation</p>
                    <select
                      style={{ cursor: 'pointer' }}
                      disabled={!edit_designation}
                      onChange={e => {
                        change_designation(e.target.value);
                      }}
                      className={`m-0 bg-transparent ps-2 fw-bold border-0 p-0 shadow-none ${
                        edit_designation ? 'form-select' : 'form-control'
                      }`}
                      value={designation}
                    >
                      {designations_data.map(x => {
                        return (
                          <>
                            <option value={x.designation}>
                              {x.designation}
                            </option>
                          </>
                        );
                      })}
                    </select>
                    {!isEmployee && (
                      <svg
                        onClick={() => setedit_designation(!edit_designation)}
                        style={{ cursor: 'pointer' }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="currentColor"
                        class={`bi bi-pencil-fill ${
                          edit_designation ? 'text-dark' : 'text-secondary'
                        }`}
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                      </svg>
                    )}
                  </div>
                </div>
                <div className="col-lg-5 col-md-6 px-1 px-md-3 mb-4">
                  <div className="border-bottom border-0 d-flex pb-1 mb-4 emp_profile_layout">
                    <p className="m-0 text-secondary">Supervisor</p>
                    <select
                      style={{ cursor: 'pointer' }}
                      disabled={!edit_supervisor}
                      onChange={e => {
                        change_supervisor(e.target.value);
                      }}
                      className={`m-0 bg-transparent ps-2 fw-bold border-0 p-0 shadow-none ${
                        edit_supervisor ? 'form-select' : 'form-control'
                      }`}
                      value={supervisor}
                    >
                      <option value="">Not Assigned</option>
                      {supervisor_data.map(x => {
                        return (
                          <>
                            <option value={x.first_name}>{x.first_name}</option>
                          </>
                        );
                      })}
                    </select>
                    {!isEmployee && (
                      <svg
                        onClick={() => setedit_supervisor(!edit_supervisor)}
                        style={{ cursor: 'pointer' }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="currentColor"
                        class="bi bi-pencil-fill text-secondary"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                      </svg>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 px-1 px-md-3 mb-4">
                  <div className="border-bottom border-0 d-flex pb-1 mb-4 emp_profile_layout">
                    <p className="m-0 text-secondary">Email ID</p>
                    <p className="m-0 fw-medium word-break fw-bold">
                      {emp_data.email}
                    </p>
                  </div>
                </div>

                <div>
                  <button
                    className={`btns text-white px-md-4 py-2 ${
                      user.designation === 'admin' ? '' : 'd-none'
                    }`}
                    data-bs-toggle="modal"
                    data-bs-target="#assignpermissions"
                  >
                    Assign Permissions
                  </button>

                  {!isEmployee && (
                    <button
                      onClick={() => {
                        block_trainer(false);
                      }}
                      className={`bg-danger-subtle ms-3 ms-lg-4 text-danger px-3 py-2 border-0 ${
                        emp_data.status ? '' : 'd-none'
                      }`}
                    >
                      Inactive
                    </button>
                  )}
                  <button
                    onClick={() => {
                      block_trainer(true);
                    }}
                    className={`bg-success-subtle ms-3 ms-lg-4 text-success px-3 py-2 border-0 ${
                      emp_data.status ? 'd-none' : ''
                    }`}
                  >
                    Active
                  </button>
                </div>
              </div>
            )}

            {!showProfile && (
              <div className="row m-0 d-flex justify-content-between mt-3 ">
                <div className=" ">
                  {console.log('THIS IS ', id, user.emp_id)}
                  <EmployeeLeadsCount
                    start_date={start_date}
                    end_date={end_date}
                    setstart_date={setstart_date}
                    setend_date={setend_date}
                    id={id}
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
                </div>
              </div>
            )}

            {!showProfile && (
              <div className="row m-0 mt-lg-3 pt-3 px-3 gap-0 gap-lg-4 justify-content-center">
                <div className={`col-md-9 mb-5 mb-lg-0 bg-white p-3 py-3`}>
                  <div className="d-flex justify-content-between align-items-center mb-3 mb-lg-4">
                    <h5 className="text-dark m-0">Activities Chart</h5>
                    <div className="d-flex">
                      <select
                        name=""
                        value={filter_type}
                        onChange={e => {
                          if (e.target.value === 'custom') {
                            setfilter_type(e.target.value);
                          } else {
                            setfilter_type(e.target.value);
                            setstart_date('');
                            setend_date('');
                            filter_chart(e.target.value, '');
                          }
                        }}
                        className="border-secondary-subtle rounded-pill px-2"
                        style={{ cursor: 'pointer' }}
                        id=""
                      >
                        <option value="">Filter</option>
                        <option value="daily">Today</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                        <option value="custom">Custom</option>
                      </select>

                      <div
                        className={`${
                          filter_type === 'custom' ? 'd-flex' : 'd-none'
                        }`}
                      >
                        <div className="d-flex ms-4 align-items-center">
                          <label
                            htmlFor=""
                            className="fw-medium"
                          >
                            From :{' '}
                          </label>
                          <input
                            type="date"
                            value={start_date}
                            onChange={e => {
                              setstart_date(e.target.value);
                            }}
                            className="border-secondary-subtle rounded ms-2 px-2"
                          />
                        </div>
                        <div className="d-flex ms-4 align-items-center">
                          <label
                            htmlFor=""
                            className="fw-medium"
                          >
                            To :{' '}
                          </label>
                          <input
                            value={end_date}
                            onChange={e => {
                              setend_date(e.target.value);
                              filter_chart('custom', e.target.value);
                            }}
                            type="date"
                            className="border-secondary-subtle rounded ms-2 px-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Bar
                    data={employeecomparision}
                    options={options}
                  />
                </div>
              </div>
            )}

            {!showProfile && (
              <div className=" row m-0 mt-lg-5 pt-3 px-3 mb-4 gap-0 gap-lg-4 justify-content-center">
                <div className="col-md-9 mb-5 mb-lg-0 bg-white p-4 shadow-sm rounded">
                  <h5 className="text-dark mb-4">
                    Leads Categorized by Course
                  </h5>
                  {leadsbyPIEcourse.labels.length > 0 ? (
                    <Pie
                      data={leadsbyPIEcourse}
                      options={options4}
                    />
                  ) : (
                    <p className="text-center text-muted">
                      No data available for this chart
                    </p>
                  )}
                </div>
                <div className="col-md-9 mb-5 mb-lg-0 bg-white p-4 shadow-sm rounded">
                  <h5 className="text-dark mb-4">
                    Leads Categorized by Source
                  </h5>
                  {sourcesData?.length > 0 ? (
                    <Pie
                      data={leadsbyPIEsourse}
                      options={options3}
                    />
                  ) : (
                    <p className="text-center text-muted">
                      No data available for this chart
                    </p>
                  )}
                </div>
              </div>
            )}

            {!showProfile && (
              <div className="row m-0 mt-lg-5 pt-3 px-3 mb-4 gap-0 gap-lg-4 justify-content-center">
                <div className={`col-md-9 mb-5 mb-lg-0 bg-white p-3 py-3`}>
                  <h5 className="text-dark mb-3 mb-lg-4">
                    Leads Categorized by Course
                  </h5>
                  <Bar
                    data={leadsbycourse}
                    options={options}
                  />
                </div>

                <div className={`col-md-9 mb-5 mb-lg-0 bg-white p-3 mt-2 py-3`}>
                  <h5 className="text-dark mb-3 mb-lg-4">
                    Leads Categorized by Source
                  </h5>
                  <Bar
                    data={leadsbysource}
                    options={options}
                  />
                </div>
              </div>
            )}

            {/*/
            
            
            
            /*/}
          </div>
        </div>
      </div>

      {/* Activity target assigning modal */}
      <Modal
        show={show2}
        size="lg"
        onHide={() => {
          setshow2(false);
        }}
      >
        <Modal.Body>
          <div class="d-flex justify-content-between align-items-center">
            <h6
              class="modal-title fs-5 text-primary"
              id="exampleModalLabel"
            >
              Add Activity for {emp_data.firstname}!
            </h6>
            <svg
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setshow2(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
            </svg>
          </div>

          <div className="row m-0">
            <div className="col-6">
              {/* <img src={require('../images/Banner4 3.png')} style={{width:'100%'}} alt="" /> */}
            </div>
            <div
              className="col-6 d-flex align-items-center py-5"
              style={{ height: '373px' }}
            >
              <div
                className="w-100 row m-0"
                style={{
                  height: '100%',
                  overflowY: fields.length > 1 ? 'scroll' : 'none',
                }}
              >
                <div
                  className={`col-12 p-0 ${fields.length > 1 ? 'mb-4' : ''}`}
                >
                  <select
                    name=""
                    onChange={e => {
                      setmodal_name(e.target.value);
                      console.log(e.target.value);
                    }}
                    className="form-select shadow-none border-secondary-subtle border-bottom border-0 rounded-0"
                    id=""
                  >
                    <option value="">Select the Module</option>
                    {modals.map(x => {
                      return (
                        <>
                          <option value={x.id}>{x.module_name}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                {fields.map((field, index) => (
                  <div
                    key={index}
                    className="p-0"
                  >
                    <div className="col-12 p-0 bg-info">
                      {/* <label htmlFor="">Activity Name</label> */}
                      <input
                        className="activity form-control shadow-none border-0 border-bottom rounded-0 border-secondary-subtle"
                        type="text"
                        name="Activity_Name"
                        value={field.Activity_Name}
                        placeholder="Activity Name"
                        onChange={e => handleChange(index, e)}
                      />
                    </div>
                    <div className="col-12 border-0 mt-4 pt-2 mb-4">
                      {/* <label htmlFor="">Target</label> */}
                      <input
                        className="activity form-control shadow-none border-0 border-bottom border-dark-subtle rounded-0"
                        type="number"
                        name="targets"
                        placeholder="Target"
                        // value={field.targets}
                        onChange={e => {
                          const { name, value } = e.target;
                          const newFields = [...fields];
                          newFields[index][name] = Number(value);
                          setFields(newFields);
                        }}
                      />
                    </div>
                  </div>
                ))}

                <div
                  className={`text-end ${fields.length > 1 ? 'pt-2 mt-4' : ''}`}
                >
                  <button
                    type="button"
                    className="btn btn-sm text-white px-4 sidebar-color mt-1 rounded-pill"
                    onClick={handleAddMore}
                  >
                    Add More
                  </button>
                  <button
                    className="btn btn-sm text-white px-4 sidebar-color mt-1 rounded-pill ms-3"
                    data-bs-dismiss="modal"
                    onClick={assigntarget}
                  >
                    Assign
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Assign Permissions */}
      <div
        className="modal fade"
        id="assignpermissions"
        tabindex="-1"
        aria-labelledby="assignpermissions"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between align-items-center">
              <h6
                className="modal-title"
                id="exampleModalLabel"
              >
                Assign Permissions
              </h6>
              <svg
                style={{ cursor: 'pointer' }}
                data-bs-dismiss="modal"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-x-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
              </svg>
            </div>
            <div className="modal-body">
              <div className="px-3">
                <div
                  className="d-flex align-items-center shadow-sm px-3 bg-white py-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setdelete_customer_lead(!delete_customer_lead);
                  }}
                >
                  {/* <input type="checkbox" name='delete_customer_lead' onChange={(e)=>{
              handleCheckboxChange(e,'delete_customer_lead')
            }}/> */}
                  <span style={{ cursor: 'pointer' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#467DF7"
                      class={`bi bi-check-circle-fill ${
                        delete_customer_lead ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class={`bi bi-check-circle ${
                        delete_customer_lead ? 'd-none' : ''
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                  </span>

                  <label
                    style={{ cursor: 'pointer' }}
                    htmlFor=""
                    className="ms-2"
                  >
                    Delete Customer Leads
                  </label>
                </div>
                <div
                  className="d-flex align-items-center mt-3 bg-white shadow-sm p-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setdelete_client_lead(!delete_client_lead);
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#467DF7"
                      class={`bi bi-check-circle-fill ${
                        delete_client_lead ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class={`bi bi-check-circle ${
                        delete_client_lead ? 'd-none' : ''
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                  </span>
                  <label
                    style={{ cursor: 'pointer' }}
                    htmlFor=""
                    className="ms-2"
                  >
                    Delete Client Leads
                  </label>
                </div>
                <div
                  className="d-flex align-items-center mt-3 bg-white shadow-sm p-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setassign_activity(!assign_activity);
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#467DF7"
                      class={`bi bi-check-circle-fill ${
                        assign_activity ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class={`bi bi-check-circle ${
                        assign_activity ? 'd-none' : ''
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                  </span>
                  <label
                    style={{ cursor: 'pointer' }}
                    htmlFor=""
                    className="ms-2"
                  >
                    Assign Activity
                  </label>
                </div>
                <div
                  className="d-flex align-items-center mt-3 bg-white shadow-sm p-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setview_all_employees(!view_all_employees);
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#467DF7"
                      class={`bi bi-check-circle-fill ${
                        view_all_employees ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class={`bi bi-check-circle ${
                        view_all_employees ? 'd-none' : ''
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                  </span>
                  <label
                    style={{ cursor: 'pointer' }}
                    htmlFor=""
                    className="ms-2"
                  >
                    View all Employees
                  </label>
                </div>

                <div
                  className="d-flex align-items-center mt-3 bg-white shadow-sm p-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setassign_leads(!assign_leads);
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#467DF7"
                      class={`bi bi-check-circle-fill ${
                        assign_leads ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class={`bi bi-check-circle ${
                        assign_leads ? 'd-none' : ''
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                  </span>
                  <label
                    style={{ cursor: 'pointer' }}
                    htmlFor=""
                    className="ms-2"
                  >
                    Assign Leads
                  </label>
                </div>

                <div
                  className="d-flex align-items-center mt-3 bg-white shadow-sm p-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setbatch_permission(!batch_permission);
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#467DF7"
                      class={`bi bi-check-circle-fill ${
                        batch_permission ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class={`bi bi-check-circle ${
                        batch_permission ? 'd-none' : ''
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                  </span>
                  <label
                    style={{ cursor: 'pointer' }}
                    htmlFor=""
                    className="ms-2"
                  >
                    Add Batch
                  </label>
                </div>

                <div
                  className="d-flex align-items-center mt-3 bg-white shadow-sm p-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setadd_events(!add_events);
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#467DF7"
                      class={`bi bi-check-circle-fill ${
                        add_events ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class={`bi bi-check-circle ${add_events ? 'd-none' : ''}`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                  </span>
                  <label
                    style={{ cursor: 'pointer' }}
                    htmlFor=""
                    className="ms-2"
                  >
                    Add Events
                  </label>
                </div>

                <div
                  className="d-flex align-items-center mt-3 bg-white shadow-sm p-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setcreate_projects(!create_projects);
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#467DF7"
                      class={`bi bi-check-circle-fill ${
                        create_projects ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class={`bi bi-check-circle ${
                        create_projects ? 'd-none' : ''
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                  </span>
                  <label
                    style={{ cursor: 'pointer' }}
                    htmlFor=""
                    className="ms-2"
                  >
                    Create Projects
                  </label>
                </div>

                <div
                  className="d-flex align-items-center mt-3 bg-white shadow-sm p-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setassign_students(!assign_students);
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#467DF7"
                      class={`bi bi-check-circle-fill ${
                        assign_students ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class={`bi bi-check-circle ${
                        assign_students ? 'd-none' : ''
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                  </span>
                  <label
                    style={{ cursor: 'pointer' }}
                    htmlFor=""
                    className="ms-2"
                  >
                    Assign Students
                  </label>
                </div>

                <div
                  className="d-flex align-items-center mt-3 bg-white shadow-sm p-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setview_newleads(!view_newleads);
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#467DF7"
                      class={`bi bi-check-circle-fill ${
                        view_newleads ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class={`bi bi-check-circle ${
                        view_newleads ? 'd-none' : ''
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                  </span>
                  <label
                    style={{ cursor: 'pointer' }}
                    htmlFor=""
                    className="ms-2"
                  >
                    View Walkin Leads
                  </label>
                </div>

                <div
                  className="d-flex align-items-center mt-3 bg-white shadow-sm p-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setadd_activity_module(!add_activity_module);
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#467DF7"
                      class={`bi bi-check-circle-fill ${
                        add_activity_module ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class={`bi bi-check-circle ${
                        add_activity_module ? 'd-none' : ''
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                  </span>
                  <label
                    style={{ cursor: 'pointer' }}
                    htmlFor=""
                    className="ms-2"
                  >
                    Add Activity Module
                  </label>
                </div>

                <div className="text-end mt-4 mb-2">
                  <button
                    className="btn text-white btn-sm px-4 py-1"
                    style={{ backgroundColor: '#57309C' }}
                    onClick={() => {
                      assign_permissions();
                    }}
                    data-bs-dismiss="modal"
                  >
                    Assign
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Add_activity_targets
          setshow={setshow}
          show={show}
          setshow2={setshow2}
          id={id}
          name={emp_data.firstname}
        />
      </div>
    </div>
  );
};

export default Employee_profile;
