import cardImg from '../../images/Icon.png';
import SectionBreak from './SectionBreak';
import SubSection from './SubSection';

function ThreeSectionCard({
  countOne,
  subNameOne,
  navToOne,
  countTwo,
  subNameTwo,
  navToTwo,
  countThree,
  subNameThree,
  navToThree,
  name,
  imgCard = cardImg,
}) {
  return (
    <div className="tw-bg-red-200 tw-cursor-pointer tw-w-[40%] tw-min-h-[150px]  ">
      <div className="tw-flex tw-items-center tw-bg-white tw-h-full tw-w-full">
        <div className="tw-w-[30%] tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center ">
          <h2 className="tw-text-[#A3AED0] tw-text-sm tw-mt-3">{name}</h2>

          <img
            src={imgCard}
            width={44}
            alt=""
          />
        </div>

        <div className="tw-flex tw-gap-3 tw-p-2 ">
          <div className=" tw-flex tw-bg-purple-40  tw-gap-3">
            <SubSection
              count={countOne}
              name={subNameOne}
              navTo={navToOne}
            />
            <SectionBreak />
            <SubSection
              count={countTwo}
              name={subNameTwo}
              navTo={navToTwo}
            />
            <SectionBreak />
            <SubSection
              count={countThree}
              name={subNameThree}
              navTo={navToThree}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThreeSectionCard;
