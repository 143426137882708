import axios from 'axios';
import { ipaddress, ipaddress2 } from '../../../App';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

function EmployeeAssign({ setSelectedLeads, selectedLeads }) {
  const [allEmployees, setAllEmployees] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [selecetdemp, setselectedemp] = useState('');
  const [permissions, setPermissions] = useState({});

  const user = JSON.parse(sessionStorage.getItem('user'));

  const isB2C = selectedLeads[0]?.startsWith('CUS');

  const fetchPermissions = useCallback(() => {
    axios.get(`${ipaddress2}/PermissionsView/${user.emp_id}/`).then(r => {
      // console.log('Permissions', r.data);
      setPermissions(r.data);
    });
  }, [user.emp_id]);

  const fetchSupervisors = () => {
    axios
      .get(`${ipaddress}/SupervisorView/${user.emp_id}/`)
      .then(r => {
        // console.log('ZORO SUPERVISORS', r.data);
        setSupervisors(r.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchAllEmployees = () => {
    axios.get(`${ipaddress2}/AllprofileView/`).then(r => {
      // console.log('ZORO All employeeess', r.data);
      const data = r.data.filter(x => x.user.blockstatus === false);
      // console.log('ZORO active employees', data);
      setAllEmployees(data);
    });
  };

  const AssignLeadsB2B = value => {
    const emp_name = allEmployees.find(x => x.user.emp_id === value);
    axios
      .post(`${ipaddress}/AssignClientToView/`, {
        loggedin_emp_id: user.emp_id,
        emp_id: value,
        client_lead_ids: selectedLeads,
      })

      .then(r => {
        console.log('Successfully client assigned', r.data);
        setselectedemp('');
        setSelectedLeads([]);
        toast.success(`Assigned to ${emp_name.user.first_name}`, {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
      })
      .catch(err => {
        console.log('Assigning error', err);
        console.log({
          emp_id: value,
          client_lead_ids: selectedLeads,
        });
      });
  };

  const AssignLeadsB2C = value => {
    const emp_name = allEmployees?.find(x => x.user.emp_id === value);
    // const emp_name = supervisors?.find(x => x.emp_id === value);

    postAssign(value);

    toast.success(`Lead assigned to ${emp_name?.user?.first_name}`, {
      autoClose: 1000,
      theme: 'colored',
      position: 'top-center',
    });
  };

  const postAssign = value => {
    axios
      .post(`${ipaddress}/AssignToView/`, {
        loggedin_emp_id: user.emp_id,
        emp_id: value,
        customer_lead_ids: selectedLeads,
      })

      .then(r => {
        console.log('Successfully assigned', r.data);
        setselectedemp('');
        setSelectedLeads([]);
      })
      .catch(err => {
        console.log('Assigning error', err);
      });
  };

  useEffect(() => {
    fetchSupervisors();
    fetchAllEmployees();
    fetchPermissions();
  }, []);

  return (
    <div className="">
      <div className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-px-7">
        <p className="tw-w-full tw-text-sm tw-font-semibold tw-text-black">
          Assign To:
        </p>
        {/* {console.log('PERMISSIONS 123', permissions)} */}
        <select
          value={selecetdemp}
          disabled={selectedLeads.length === 0}
          onChange={e => {
            if (isB2C) {
              AssignLeadsB2C(e.target.value);
            } else {
              AssignLeadsB2B(e.target.value);
            }
          }}
          className={`tw-w-max tw-px-4 tw-py-2 tw-bg-white tw-border tw-border-gray-300 tw-rounded-md tw-text-gray-700 tw-text-sm tw-shadow-sm tw-outline-none tw-focus:ring-2 tw-focus:ring-red-300 tw-focus:border-red-500 ${
            permissions.assign_leads || user.designation === 'admin'
              ? ''
              : 'tw-hidden'
          }`}
          style={{
            cursor: selectedLeads.length > 0 ? 'pointer' : 'not-allowed',
          }}
        >
          <option
            value=""
            disabled
          >
            Select Employee...
          </option>
          {allEmployees.map(x => (
            <option
              key={x.user.emp_id}
              value={x.user.emp_id}
            >
              {x.user.first_name}
            </option>
          ))}
        </select>
      </div>
      {selectedLeads.length > 0 && (
        <div className=" tw-py-2 tw-px-1 tw-mt-1 tw-flex tw-justify-end  tw-mx-7">
          <p className="tw-bg-slate-200 tw-rounded-lg  tw-py-2 tw-w-max">
            Selected Leads:
            <span className="tw-mx-1">{selectedLeads.length}</span>
          </p>
        </div>
      )}
    </div>
  );
}

export default EmployeeAssign;
