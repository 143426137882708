import React from 'react';
import { ColorRing } from 'react-loader-spinner';

const SmallPreloader = () => {
  return (
    <div className="py-5 mt-md-2 text-center">
      <ColorRing
        visible={true}
        height="60"
        width="60"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#03045B', '#0073B0', '#00AED1', '#8BD9E8', '#C4E8F0']}
      />
    </div>
  );
};

export default SmallPreloader;
