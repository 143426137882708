import axios from 'axios';
import { useEffect, useState } from 'react';
import { ipaddress } from '../../App';
import { buildQueryString } from '../../utils/QueryBuilder';
import { useLocation } from 'react-router-dom';
import DataCount from '../All-leads/ClosedFilter/DataCount';
import DataSubCounts from '../All-leads/ClosedReasons/DataSubCounts';
import Preloader from '../../Components/Preloader';

function SourcesSummary({
  fetchData,
  startDate,
  endDate,
  singleDate,
  isRange,
  setIsRange,
}) {
  const [loading, setLoading] = useState(false); // State for loading
  const location = useLocation();

  const [sourcesData, setSourcesData] = useState();
  const [selectedSource, setSelectedSource] = useState();
  const [courseBySourceData, setCourseBySourceData] = useState();

  const [coursesData, setCoursesData] = useState();
  const [selectedCourse, setSelectedCourse] = useState();
  const [sourceByCourseData, setSourceByCourseData] = useState();

  let empID;
  if (location.pathname.startsWith('/view_employee_leads')) {
    empID = location.pathname.slice(21, 31);
  }

  useEffect(() => {
    console.log('REFETCHED SOURCE AND COURSE WISE');
    getSourceWiseData();
    getCourseWiseData();
  }, [selectedSource, singleDate, endDate]);

  const getSourceWiseData = () => {
    const queryParams = {
      emp_id: empID,
      start_date: !isRange ? '' : startDate,
      end_date: !isRange ? '' : endDate,
      date: isRange ? '' : singleDate,
    };

    const IPADDRESS = `${ipaddress}/CountsForSourceLeads/?${buildQueryString(
      queryParams,
    )}`;

    console.log('REFETCHED SOURCEDATA', IPADDRESS);

    setLoading(true); // Set loading to true
    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('SOURCES', res.data);
        setSourcesData(res.data.customer_leads);
        // setSourcesData(res.data);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false)); // Set loading to false
  };

  const getCourseWiseData = () => {
    const queryParams = {
      emp_id: empID,
      start_date: !isRange ? '' : startDate,
      end_date: !isRange ? '' : endDate,
      date: isRange ? '' : singleDate,
    };

    const IPADDRESS = `${ipaddress}/LeadsByCourse/?${buildQueryString(
      queryParams,
    )}`;

    console.log('REFETCHED', IPADDRESS);

    setLoading(true);
    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('COURSES WISEE', res.data);
        // setCoursesData(res.data.customer_leads);
        setCoursesData(res.data);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  const fetchSourceDetails = selectedValue => {
    setLoading(true); // Set loading to true

    const queryParams = {
      emp_id: empID,
      course_enquired: selectedValue,
      start_date: !isRange ? '' : startDate,
      end_date: !isRange ? '' : endDate,
      date: isRange ? '' : singleDate,
    };

    const IPADDRESS = `${ipaddress}/SearchStageWiseAllB2Cleads/source/${encodeURIComponent(
      selectedSource?.split(' ')[0],
    )}/?${buildQueryString(queryParams)}`;

    console.log('SOURCESS', IPADDRESS);

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('SOURCESS', res.data);
        fetchData(res.data);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  const fetchCoursesDetails = selectedValue => {
    setLoading(true);

    const queryParams = {
      emp_id: empID,
      course_enquired: selectedCourse,
      start_date: !isRange ? '' : startDate,
      end_date: !isRange ? '' : endDate,
      date: isRange ? '' : singleDate,
    };

    const IPADDRESS = `${ipaddress}/SearchStageWiseAllB2Cleads/source/${encodeURIComponent(
      selectedValue.split(' ')[0],
    )}/?${buildQueryString(queryParams)}`;

    console.log('DETSOURCESS', IPADDRESS);

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('DETSOURCESS', res.data);
        fetchData(res.data);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false)); // Set loading to false
  };

  const getCourseBySources = selectedValue => {
    setSelectedSource(selectedValue);
    setLoading(true); // Set loading to true

    const queryParams = {
      emp_id: empID,
      source: selectedValue?.split('(')[0],
      start_date: !isRange ? '' : startDate,
      end_date: !isRange ? '' : endDate,
      date: isRange ? '' : singleDate,
    };

    const IPADDRESS = `${ipaddress}/LeadsBySource/?${buildQueryString(
      queryParams,
    )}`;

    console.log('SOURCESS COURSES WISE active', IPADDRESS);

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('COURSES WISE', res.data);
        setCourseBySourceData(res.data);
        // fetchData(res.data);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false)); // Set loading to false
  };

  const getSourceByCourses = selectedValue => {
    setSelectedCourse(selectedValue);
    setLoading(true); // Set loading to true

    const queryParams = {
      emp_id: empID,
      course: selectedValue,
      start_date: !isRange ? '' : startDate,
      end_date: !isRange ? '' : endDate,
      date: isRange ? '' : singleDate,
    };

    const IPADDRESS = `${ipaddress}/LeadsByCourseSource/?${buildQueryString(
      queryParams,
    )}`;

    console.log('SOURCE WISEEEE', IPADDRESS);

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('SOURCE WISEEEE', res.data);
        setSourceByCourseData(res.data);
        // setCourseBySourceData(res.data);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div
        className={`tw-p-4 tw-w-full tw-bg-gray-50 tw-rounded-md tw-shadow-md ${
          loading ? 'tw-h-max' : 'tw-max-h-[400px]'
        } tw-overflow-y-auto`}
      >
        {loading ? (
          <div className="tw-grid tw-place-items-center">
            <Preloader />
          </div>
        ) : (
          <div className="tw-grid tw-grid-cols-4 tw-grid-rows-1 tw-gap-x-2 ">
            <DataCount
              fetchData={getCourseBySources}
              data={sourcesData}
              fieldCount="count"
              fieldName="source"
              title="Sources Summary"
            />
            {selectedSource && (
              <DataSubCounts
                fetchData={fetchSourceDetails}
                data={courseBySourceData}
                fieldCount="count"
                fieldName="course_name"
                title="Courses Based on Sources"
              />
            )}
            <DataCount
              fetchData={getSourceByCourses}
              data={coursesData}
              fieldCount="count"
              fieldName="course_name"
              title="Courses Summary"
            />
            {selectedCourse && (
              <DataSubCounts
                fetchData={fetchCoursesDetails}
                data={sourceByCourseData}
                fieldCount="count"
                fieldName="source"
                title="Sources Based On Courses"
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default SourcesSummary;
