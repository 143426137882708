import { useEffect } from 'react';
import { getUser } from '../../utils/GetUser';
import { fetchAllRegistrationsLeads } from '../../API/FetchFns/AllB2CLeads';
import MainLayout from '../../Admin_panel/All-leads/CustomerAllLeads/MainLayout';
import useLeadsQuery from '../../Hooks/useLeadsQuery';
import AllFilters from '../FilterForms/AllFilters';
import CustomerTable from '../../Admin_panel/All-leads/CustomerAllLeads/All/CustomerAllTable';
import useLeadsQueryParams from '../../Hooks/useLeadsQueryParams';
import PaginatedButtons from '../../Admin_panel/All-leads/CustomerAllLeads/All/PaginatedButtons';

const B2COtherDept = ({ settab }) => {
  useEffect(() => {
    settab('otherdepartment');
  }, [settab]);

  const { queryParamsObj } = useLeadsQueryParams();

  const queryParams = {
    ...queryParamsObj,
    stage: 'other_department',
    employee_id: getUser(),
  };

  const { data, error, isError, isFetching, isLoading } = useLeadsQuery({
    queryParams,
    fetchFn: fetchAllRegistrationsLeads,
    fetchKey: 'OtherDeptB2CLeadsAdmin',
  });

  data && console.log(data?.data?.results);
  isError && console.log(error);

  const isPrevious = Boolean(data?.data?.previous);
  const isNext = Boolean(data?.data?.next);

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <AllFilters />
      <MainLayout loading={isLoading}>
        <CustomerTable
          leads={data?.data?.results}
          count={data?.data?.count}
        />
        <PaginatedButtons
          isFetching={isFetching}
          isNext={isNext}
          isPrevious={isPrevious}
        />
      </MainLayout>
    </div>
  );
};

export default B2COtherDept;
