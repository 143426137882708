import React, { useState, useEffect } from 'react';
import MainLayout from '../MainLayout';
import ClosedData from '../../ClosedFilter/ClosedData';
import ClosedFilter from '../../ClosedFilter/ClosedFilter';
import CustomerTable from '../All/CustomerAllTable';
import useLeadsQueryParams from '../../../../Hooks/useLeadsQueryParams';
import useLeadsQuery from '../../../../Hooks/useLeadsQuery';
import { fetchAllClosedLeads } from '../../../../API/FetchFns/AllB2CLeads';
import PaginatedButtons from '../All/PaginatedButtons';
import AllFilters from '../../../../Components/FilterForms/AllFilters';

const CustomerClosedLeads = ({ settab }) => {
  useEffect(() => {
    settab('closed');
  }, [settab]);

  const [leads, setLeads] = useState([]);

  const { queryParamsObj } = useLeadsQueryParams();

  const queryParams = {
    ...queryParamsObj,
    stage: 'closed',
  };

  const { data, error, isError, isFetching, isLoading } = useLeadsQuery({
    queryParams,
    fetchFn: fetchAllClosedLeads,
    fetchKey: 'ClosedB2CLeadsAdmin',
  });

  data && console.log('CLOSEDDD', data?.data?.results);
  isError && console.log(error);

  useEffect(() => {
    setLeads(data?.data?.results);
  }, [setLeads, data]);

  const isPrevious = Boolean(data?.data?.previous);
  const isNext = Boolean(data?.data?.next);

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <AllFilters />
      <div className=" tw-h-max tw-w-full ">
        <ClosedData
          setCloseCourseData={setLeads}
          // months={months}
        />
      </div>
      <ClosedFilter setclosedleads={setLeads} />
      <MainLayout loading={isLoading}>
        <CustomerTable
          leads={leads}
          count={data?.data?.count}
        />
        <PaginatedButtons
          isFetching={isFetching}
          isNext={isNext}
          isPrevious={isPrevious}
        />
      </MainLayout>
    </div>
  );
};

export default CustomerClosedLeads;
