import SmallPreloader from '../../../SmallPreloader';

function MainLayout({ loading, children }) {
  return (
    <div>
      {loading ? (
        <SmallPreloader />
      ) : (
        <div className="w-100 main-div">{children}</div>
      )}
    </div>
  );
}

export default MainLayout;
