import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ipaddress } from '../App';
import Closed_form from './Lead_aading_forms/Closed_form';
import Prospect_form from './Lead_aading_forms/Prospect_form';
import Follow_up_form from './Lead_aading_forms/Followup_form';
import Walkinform from './Lead_aading_forms/Walkinform';
import Arrow_svg from '../Svgs/Arrow_svg';

const Customerregistrationform = ({ client_data, setclient_data }) => {
  const navigate = useNavigate();

  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);
  const [bachelor_degrees, setbachelor_degrees] = useState([]);
  const [bachelor_specifications, setbachelor_specifications] = useState([]);

  const [availablecourses, setAvailablecourses] = useState([]);
  const [lead_levels, setlead_levels] = useState([]);

  const user = JSON.parse(sessionStorage.getItem('user'));

  useEffect(() => {
    if (client_data) {
      setFormData(client_data);
      setemail(client_data.email);
      setphone(client_data.contact_number);
    }

    axios(`${ipaddress}/States/`)
      .then(r => {
        console.log('States successfully fetched', r.data);
        setstates(r.data);
      })
      .catch(err => {
        console.log('States fetching Error', err);
      });

    axios(`${ipaddress}/AllcoursesnameView/`)
      .then(r => {
        console.log('Courses successfully fetched', r.data);
        setAvailablecourses(r.data.coursename);
      })
      .catch(err => {
        console.log('Courses fetching Error', err);
      });

    axios(`${ipaddress}/Allleadlevels/`)
      .then(r => {
        console.log('Lead levels successfully fetched', r.data);
        setlead_levels(r.data.leadlevels);
      })
      .catch(err => {
        console.log('Lead levels fetching Error', err);
      });
  }, []);

  const sourcedata = e => {
    if (e.target.value === 'walkin') {
      document.getElementById('contactedpersondiv').style.display = 'block';
    }
    if (e.target.value === 'other') {
      document.getElementById('othersource').style.display = 'block';
    }
  };

  const fetchcities = state => {
    axios(`${ipaddress}/Cities/${state}/`)
      .then(r => {
        console.log('Cities successfully fetched', r.data);
        setcities(r.data);
      })
      .catch(err => {
        console.log('Cities fetching Error', err);
      });
  };

  const [reason_for_contact, setreason_for_contact] = useState('');

  const [formData, setFormData] = useState({
    emp_id: '',
    name: '',
    email: '',
    contact_number: '',
    company_name: '',
    company_website: '',
    company_field: '',
    company_contact_number: '',
    gst_number: '',
    door_number: '',
    street_name: '',
    area: '',
    state: '',
    city: '',
    pincode: '',
    purpose: '',
    num_candidates_trained: 0,
    place_of_training: '',
    preferred_duration_per_day: 0,
    syllabus: '',
    course_enquired: '',
    enquiry_location: '',
    source: '',
    contacted_person_name: '',
    contacted_person_number: '',
    priority: '',
    level_lead: '',
    preferred_course: '',
    preferred_batch_type: '',
    preferred_batch_start_time: '',
    preferred_class_type: '',
    expected_registration_date: '',
    tentative_batch_start_date: '',
    assigned_to: '',
    mode_of_training: '',
    cost: '',
    reason_for_contact: '',
    othercourseenquired: '',
    technology: '',
    experience: '',
    service: [],
    description: '',
    remarks: '',
    noofdays: 0,
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggle__to_client = () => {
    const client_data = {
      name: formData.name,
      email: formData.email,
      contact_number: formData.contact_number,
      enquiry_location: formData.enquiry_location,
      source: formData.source,
      contacted_person_name: formData.contacted_person_name,
      contacted_person_number: formData.contacted_person_number,
      priority: formData.priority,
    };
    console.log('CLIENT TO B2C DATA', client_data);
    setclient_data(client_data);

    navigate('/customerregistrationform');
  };

  const handleCheckboxChange = e => {
    const { name, checked, value } = e.target;
    setFormData(prevState => {
      const skills = prevState[name];
      if (checked) {
        skills.push(value);
      } else {
        const index = skills.indexOf(value);
        if (index > -1) {
          skills.splice(index, 1);
        }
      }
      return { ...prevState, [name]: skills };
    });
  };

  const [course, setcourse] = useState('');

  const [loading, setloading] = useState(false);

  const senddetails = e => {
    e.preventDefault();
    // formData.level_lead === 'newlead' && setloading(true);
    formData.emp_id = user.emp_id;
    // formData.level_lead = 'newlead';
    formData.reason_for_contact = value;

    if (value === 'client') {
      formData.mode_of_training = null;
      formData.service = service_id;
    }

    console.log('MUIZTRIG', formData.level_lead);
    if (formData.level_lead === 'newlead') {
      console.log('MUIZTRIG');

      axios
        .post(`${ipaddress}/ClientLeadsView/`, formData)
        .then(r => {
          console.log('Client successfully added', r.data);
          toast.success('Lead successfully added', {
            autoClose: 1000,
            theme: 'colored',
            position: 'top-center',
          });
          setloading(false);
          setTimeout(() => {
            navigate('/leads');
          }, 2000);

          setclient_data({
            name: '',
            email: '',
            enquiry_location: '',
            source: '',
            contact_number: '',
            contacted_person_name: '',
            contacted_person_number: '',
            priority: '',
          });
        })
        .catch(err => {
          console.log('Client adding error', err);
          console.log('FORMDATA', formData);
          setloading(false);
        });
    }
  };

  const [formstate, setformstate] = useState(false);
  const [otpstate, setotpstate] = useState(false);
  const [backendotp, setbackendotp] = useState('');
  const [otp, setotp] = useState('');

  const sendotp = () => {
    // e.preventDefault()
    axios
      .post(`${ipaddress}/send-otp/`, {
        email: formData.email,
      })
      .then(r => {
        console.log('Otp sent suceessfully', r.data);
        setbackendotp(r.data.otp);
      })
      .catch(err => {
        console.log('Otp sending error', err);
      });
  };

  const verifyotp = () => {
    console.log(otp, backendotp);
    if (otp === backendotp) {
      toast.success('OTP verified successfully', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
      setotpstate(false);
      setformstate(true);
      setotp('');
    } else {
      toast.error('Invalid OTP', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
    }
  };

  // Phone number verification
  const [load2, setload2] = useState();
  const [message2, setmessage2] = useState();
  const [phone, setphone] = useState();

  const verify_phone = phone => {
    setphone(phone);
    if (phone.length == 10) {
      setload2(true);
      setTimeout(() => {
        verify2(phone);
      }, 1000);
    }
  };

  const verify2 = phone => {
    axios
      .post(`${ipaddress}/MobileNumberValidation/`, {
        mobile_number: phone,
        customer: 'Client',
      })
      .then(r => {
        console.log('Phone number verified', r.data);
        setload2(false);
        setmessage2(true);
        formData.contact_number = phone;

        if (!r.data === 'unique mobile number') {
          setFormData(prev => ({
            ...prev,
            ...r.data,
          }));
        }
      })
      .catch(err => {
        setmessage2(false);
        setload2(false);
      });
  };

  // Email verification
  const [load, setload] = useState();
  const [message, setmessage] = useState();

  const [mail, setmail] = useState('');
  const [email, setemail] = useState('');

  const handle_email = email => {
    setemail(email);
    const atIndex = email.indexOf('@');

    if (atIndex !== -1 && email.length > atIndex + 1) {
      verify_email(email);
    } else {
      setload(false);
    }
  };

  const verify_email = email => {
    // console.log("Mail",email);

    if (email.length > 0) {
      setload(true);
      setTimeout(() => {
        verify(email);
      }, 1000);
    }
  };

  const verify = email => {
    axios.get(`${ipaddress}/EmailCheck/${email}/`).then(r => {
      console.log('Email verified', r.data);
      setload(false);

      if (r.data.message === 'mail_notexist_customerlead') {
        setmessage(true);
        formData.email = email;
      } else {
        setmessage(false);
      }
    });
  };

  const [classtypes, setclasstypes] = useState([]);
  const [batchtypes, setbatchtypes] = useState([]);
  const [services, setservices] = useState([]);

  useEffect(() => {
    axios.get(`${ipaddress}/Allclasstypes/`).then(r => {
      console.log('Class types', r.data);
      setclasstypes(r.data.classtypes);
    });

    axios.get(`${ipaddress}/Allbatchtypes/`).then(r => {
      console.log('Batch types', r.data);
      setbatchtypes(r.data.batchtypes);
    });

    axios.get(`${ipaddress}/ServicesAvailable/`).then(r => {
      console.log('Services', r.data);
      setservices(r.data);
    });
  }, []);

  const [value, setValue] = useState('customer');

  const [service_id, setservice_id] = useState([]);

  const service_data = value => {
    // const data=services.filter(x=>x.service_name===value)
    // setservice_id([...service_id,Number(data[0].id)])
  };

  const [show, setshow] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);
  const [show4, setshow4] = useState(false);
  const [other_service, setother_service] = useState(false);

  return (
    <div
      className="p-2 p-md-3"
      style={{ minHeight: '100vh', backgroundColor: '#E8E8E8' }}
    >
      <div className="container-fluid row m-0 pb-4 pt-3 animate__animated animate__fadeIn">
        <div className="mb-4 d-flex align-items-center ps-md-0">
          <Link
            className="text-dark"
            to="/leads"
          >
            <Arrow_svg />
          </Link>
          <h5
            className="m-0 ms-2 ms-md-4"
            style={{
              color: '#002147',
              letterSpacing: '1px',
              fontWeight: 600,
              fontSize: '17px',
            }}
          >
            CLIENT REGISTRATION FORM
          </h5>
        </div>

        <div className="mb-3 ps-md-0">
          <button
            onClick={() => {
              toggle__to_client();
            }}
            className="btn px-4 btn-sm rounded-0 py-2"
            style={{ border: '1px solid #002147', color: '#002147' }}
          >
            B2C Form
          </button>
          <button
            className="btn px-4 btn-sm rounded-0 text-white py-2"
            style={{ backgroundColor: '#002147' }}
          >
            B2B Form
          </button>
        </div>
        <div className="col-12 bg-white p-2 p-md-4">
          <form
            className=""
            onSubmit={senddetails}
          >
            {/* ---------------------------------PERSONAL DETAILS--------------------------------------------------------- */}
            <div className="row m-0 border-bottom pb-2">
              <h6 className="form-heading">Personal Details</h6>
              <div className="col-md-6 col-lg-4 mb-3">
                <label
                  for="inputEmail4"
                  className="form-label"
                >
                  Name <span className="required-field">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control shadow-none py-2"
                  id="inputEmail4"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-md-6 col-lg-4 mb-3">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Contact Number <span className="required-field">*</span>
                </label>
                <div class="input-group form-inputs border">
                  <input
                    type="tel"
                    value={phone}
                    maxLength={10}
                    className="form-control shadow-none py-2 border-0 bg-transparent"
                    id="inputPassword4"
                    name="contact_number"
                    onChange={e => {
                      verify_phone(e.target.value);
                    }}
                  />
                  <span
                    class="input-group-text border-0 bg-transparent"
                    id="basic-addon2"
                  >
                    <div
                      class={`spinner-border spinner-border-sm text-success ${
                        load2 ? '' : 'd-none'
                      }`}
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#198754"
                      class={`bi bi-check2-circle ${
                        !load2 && message2 == true ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                    </svg>
                  </span>
                </div>
                <p
                  className={`text-danger mt-1 ${
                    message2 == false ? '' : 'd-none'
                  }`}
                  style={{ fontSize: '13px' }}
                >
                  *Mobile Number already exist
                </p>
              </div>

              <div className="col-md-6 col-lg-4 mb-3">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Email
                </label>
                <div class="input-group form-inputs border">
                  <input
                    required
                    type="email"
                    value={email}
                    className="form-control shadow-none py-2 border-0 bg-transparent"
                    id="inputPassword4"
                    name="email"
                    onChange={e => {
                      handle_email(e.target.value);
                    }}
                  />
                  <span
                    class="input-group-text border-0 bg-transparent"
                    id="basic-addon2"
                  >
                    <div
                      class={`spinner-border spinner-border-sm text-success ${
                        load ? '' : 'd-none'
                      }`}
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#198754"
                      class={`bi bi-check2-circle ${
                        !load && message == true ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                    </svg>
                  </span>
                </div>
                <p
                  className={`text-danger mt-1 ${
                    message == false ? '' : 'd-none'
                  }`}
                  style={{ fontSize: '13px' }}
                >
                  *Email already exist
                </p>
              </div>
            </div>

            <div>
              {/* -----------------------------------COMPANY DETAILS------------------------------------------------------------- */}
              <div className="row m-0 border-bottom py-3">
                <h6 className="form-heading">Company Details</h6>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputEmail4"
                    className="form-label"
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none py-2 py-2"
                    id="inputEmail4"
                    value={formData.company_name}
                    name="company_name"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Company Website
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none py-2 py-2"
                    id="inputPassword4"
                    value={formData.company_website}
                    name="company_website"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputEmail4"
                    className="form-label"
                  >
                    Comapny Expertise Sector
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none py-2 py-2"
                    id="inputEmail4"
                    value={formData.company_field}
                    name="company_field"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Company Official Contact Number
                  </label>
                  <input
                    type="tel"
                    className="form-control shadow-none py-2 py-2"
                    maxLength={10}
                    id="inputPassword4"
                    value={formData.company_contact_number}
                    name="company_contact_number"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    GST Number
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none py-2 py-2"
                    id="inputPassword4"
                    value={formData.gst_number}
                    name="gst_number"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* --------------------------------------COMPANY ADDRESS----------------------------------------------------- */}
              <div className="row m-0 border-bottom py-3">
                <h6 className="form-heading">Company Address</h6>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputEmail4"
                    className="form-label"
                  >
                    Door Number
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none py-2"
                    id="inputEmail4"
                    value={formData.door_number}
                    name="door_number"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Street Name
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none py-2"
                    id="inputPassword4"
                    maxLength={10}
                    value={formData.street_name}
                    name="street_name"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Area
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none py-2"
                    id="inputPassword4"
                    value={formData.area}
                    name="area"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    State
                  </label>
                  <select
                    id="inputState"
                    className="form-select shadow-none py-2"
                    value={formData.state}
                    name="state"
                    onChange={e => {
                      fetchcities(e.target.value);
                      const { name, value } = e.target;
                      setFormData(prev => ({
                        ...prev,
                        [name]: value,
                      }));
                    }}
                  >
                    <option selected>Choose...</option>
                    {states.map(x => {
                      return (
                        <>
                          <option value={x}>{x}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    City
                  </label>
                  <select
                    id="inputState"
                    className="form-select shadow-none py-2"
                    value={formData.city}
                    name="city"
                    onChange={handleInputChange}
                  >
                    <option selected>Choose...</option>
                    {cities.map(x => {
                      return (
                        <>
                          <option value={x}>{x}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Pincode
                  </label>
                  <input
                    type="tel"
                    className="form-control shadow-none py-2"
                    maxLength={6}
                    id="inputPassword4"
                    value={formData.pincode}
                    name="pincode"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="border-bottom py-3 ps-md-2">
                {/* <h6 className="form-heading">Services Looking For</h6> */}

                <div className="pt-1">
                  <div className="col-md-6 col-lg-4 mb-3 ps-md-0">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Services Looking For{' '}
                      <span className="required-field">*</span>
                    </label>
                    <select
                      type="text"
                      className="form-select shadow-none py-2"
                      id="inputPassword4"
                      value={value}
                      name="purpose"
                      onChange={e => {
                        setValue(e.target.value);
                      }}
                    >
                      <option value="">Select the Purpose</option>
                      <option value="Training">Corporate Training</option>
                      <option value="Software development">
                        Software Development
                      </option>
                      <option value="Digital Marketing Services">
                        Digital Marketing Services
                      </option>
                      <option value="hr consultancy">HR Counsultancy</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>

                {/* Training */}
                <div
                  className={`row m-0 ${value === 'Training' ? '' : 'd-none'}`}
                >
                  <div className={`col-md-6 col-lg-4 mb-3 ps-md-0`}>
                    <label
                      for="inputState"
                      className="form-label shadow-none"
                    >
                      Course Enquired <span className="required-field">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none py-2"
                      id="inputPassword4"
                      value={formData.othercourseenquired}
                      name="othercourseenquired"
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Purpose <span className='required-field'>*</span></label>
    <input type="text" className="form-control shadow-none py-2" id="inputPassword4" value={formData.purpose} name='purpose' onChange={handleInputChange}/>
  </div> */}
                  <div className="col-md-6 col-lg-4 mb-3">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Number of candidates{' '}
                      <span className="required-field">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control shadow-none py-2"
                      id="inputPassword4"
                      value={formData.num_candidates_trained}
                      name="num_candidates_trained"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 mb-3">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Place of training{' '}
                      <span className="required-field">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none py-2"
                      id="inputPassword4"
                      value={formData.place_of_training}
                      name="place_of_training"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 mb-3 ps-md-0">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Number of Days <span className="required-field">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control shadow-none py-2"
                      id="inputPassword4"
                      value={formData.noofdays}
                      name="noofdays"
                      onChange={handleInputChange}
                    />
                  </div>
                  x
                  <div className="col-md-6 col-lg-4 mb-3">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Preffered duration per day (In Hours){' '}
                      <span className="required-field">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control shadow-none py-2"
                      id="inputPassword4"
                      value={formData.preferred_duration_per_day}
                      name="preferred_duration_per_day"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 mb-3">
                    <label
                      for="inputState"
                      className="form-label shadow-none"
                    >
                      Syllabus <span className="required-field">*</span>
                    </label>
                    <select
                      id="inputState"
                      className="form-select shadow-none py-2"
                      name="syllabus"
                      value={formData.syllabus}
                      onChange={handleInputChange}
                    >
                      <option selected>Choose...</option>
                      <option>Our syllabus</option>
                      <option>Client Syllabus</option>
                    </select>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-3 mt-md-1 ps-md-0">
                    <label htmlFor="">
                      Preferred Batch Type{' '}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      id=""
                      className="form-select py-2 shadow-none mt-2"
                      value={formData.preferred_batch_type}
                      onChange={handleInputChange}
                      name="preferred_batch_type"
                    >
                      <option value="">Choose...</option>
                      {batchtypes.map(x => {
                        return (
                          <>
                            <option value={x}>{x}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-3 mt-md-1">
                    <label htmlFor="">
                      Mode of Training <span className="text-danger">*</span>
                    </label>
                    <select
                      id=""
                      className="form-select py-2 shadow-none mt-2"
                      onChange={handleInputChange}
                      value={formData.mode_of_training}
                      name="mode_of_training"
                    >
                      <option value="">Choose...</option>
                      {classtypes.map(c => {
                        return (
                          <>
                            <option value={c}>{c}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-3 mt-md-1">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Tentative Batch Start Date
                      <span className="required-field">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control shadow-none py-2"
                      id="inputPassword4"
                      value={formData.tentative_batch_start_date}
                      name="tentative_batch_start_date"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                {/* Software development */}
                <div
                  className={`row m-0 ${
                    value === 'Software development' ||
                    value === 'Digital Marketing Services' ||
                    value === 'other'
                      ? ''
                      : 'd-none'
                  }`}
                >
                  <div className="col-md-6 col-lg-4 mb-3 ps-md-0">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Services<span className="required-field">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none py-2"
                      id="inputPassword4"
                      value={formData.purpose}
                      name="purpose"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Description
                    </label>
                    <textarea
                      type="text"
                      className="form-control shadow-none py-2"
                      id="inputPassword4"
                      rows={1}
                      value={formData.description}
                      name="description"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                {/* HR Consultancy */}
                <div
                  className={`row m-0 ${
                    value === 'hr consultancy' ? '' : 'd-none'
                  }`}
                >
                  <div className="col-md-6 col-lg-4 mb-3 ps-md-0">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Designation
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none py-2"
                      id="inputPassword4"
                      value={formData.technology}
                      name="technology"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 col-lg-4 mb-3">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Experience Level
                    </label>
                    <select
                      className="form-select shadow-none py-2"
                      id="inputPassword4"
                      value={formData.experience}
                      name="experience"
                      onChange={handleInputChange}
                    >
                      <option value="">Select the Experience Level</option>
                      <option value="fresher">Fresher</option>
                      <option value="experienced">Experienced</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* ///----------COURSE DETAILS----------------------- */}
              <div className={`row m-0 border-bottom py-3`}>
                {/* <h6 className='form-heading'>Course Details</h6> */}
                <div className={`col-md-6 col-lg-4 mb-3`}>
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Enquiry Location
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none py-2"
                    id="inputPassword4"
                    value={formData.enquiry_location}
                    name="enquiry_location"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="source"
                    className="form-label shadow-none"
                  >
                    Source <span className="required-field">*</span>
                  </label>
                  <select
                    id="source"
                    className="form-select shadow-none py-2"
                    value={formData.source}
                    name="source"
                    onChange={e => {
                      if (e.target.value === 'walkin') {
                        document.getElementById(
                          'contactedpersondiv',
                        ).style.display = 'block';
                        document.getElementById('other-source').style.display =
                          'none';
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                      } else if (e.target.value === 'other') {
                        document.getElementById('other-source').style.display =
                          'block';
                        document.getElementById(
                          'contactedpersondiv',
                        ).style.display = 'none';
                      } else {
                        document.getElementById(
                          'contactedpersondiv',
                        ).style.display = 'none';
                        document.getElementById('other-source').style.display =
                          'none';
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                      }
                    }}
                  >
                    <option selected>Choose...</option>
                    <option value="other">Other</option>
                    <option value="walkin">Walkin</option>
                    <option value="naukri">Naukri</option>
                    <option value="indeed">Indeed</option>
                    <option value="linkedin">Linkedin</option>
                    <option value="just dial">Just Dial</option>
                    <option value="whatsapp">Whatsapp</option>
                    <option value="facebook">Facebook</option>
                    <option value="instagram">Instagram</option>
                  </select>
                </div>
                <div
                  className={
                    formData.source === 'other' || formData.source === 'walkin'
                      ? ''
                      : 'd-none'
                  }
                >
                  <div
                    className=""
                    style={{ display: 'none' }}
                    id="contactedpersondiv"
                  >
                    <div className="col-md-6 col-lg-4 mb-3">
                      <label
                        for="inputPassword4"
                        className="form-label"
                      >
                        Contacted Person's Name
                      </label>
                      <input
                        type="text"
                        className="form-control shadow-none py-2"
                        id="inputPassword4"
                        onChange={handleInputChange}
                        value={formData.contacted_person_name}
                        name="contacted_person_name"
                      />
                    </div>

                    <div className="col-md-6 col-lg-4 mb-3">
                      <label
                        for="inputPassword4"
                        className="form-label"
                      >
                        Contacted Person's Number
                      </label>
                      <input
                        type="text"
                        className="form-control shadow-none py-2"
                        id="inputPassword4"
                        onChange={handleInputChange}
                        value={formData.contacted_person_number}
                        name="contacted_person_number"
                      />
                    </div>
                  </div>

                  {/* Other source */}
                  <div
                    className="col-md-6 col-lg-4 mb-3"
                    id="other-source"
                    style={{ display: 'none' }}
                  >
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Enter the source
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none py-2"
                      id="inputPassword4"
                      value={formData.source}
                      name="source"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Priority
                    <span className="required-field">*</span>
                  </label>
                  <select
                    required
                    id="inputState"
                    className="form-select shadow-none py-2"
                    name="priority"
                    value={formData.priority}
                    onChange={handleInputChange}
                  >
                    <option value="">Choose...</option>
                    <option value="high">High</option>
                    <option value="medium">Medium</option>
                    <option value="low">Low</option>
                  </select>
                </div>

                <div className="col-12 mb-4">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Remarks <span className="required-field">*</span>
                  </label>
                  <textarea
                    required
                    type="text"
                    rows={3}
                    className="form-control shadow-none  py-2"
                    id="inputPassword4"
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleInputChange}
                  />
                </div>

                {/* //!TO DO  */}

                {formData.source ? (
                  <div className="col-md-6 col-lg-4 mb-3">
                    <label
                      for="inputState"
                      className="form-label shadow-none"
                    >
                      Level of Lead
                      <span className="required-field">*</span>
                    </label>
                    <select
                      required
                      id="inputState"
                      className="form-select shadow-none py-2"
                      name="level_lead"
                      value={formData.level_lead}
                      onChange={e => {
                        if (formData.remarks) {
                          const { name, value } = e.target;

                          setFormData(prev => ({
                            ...prev,
                            [name]: value,
                          }));

                          if (e.target.value === 'followup') {
                            setshow(true);
                          } else if (e.target.value === 'closed') {
                            setshow2(true);
                          } else if (e.target.value === 'prospect') {
                            setshow3(true);
                          } else if (e.target.value === 'walkin') {
                            setshow4(true);
                          }
                        } else {
                          toast.warn('Fill the Remarks Field', {
                            autoClose: 2000,
                            theme: 'colored',
                            position: 'top-center',
                          });

                          formData.level_lead = 'newlead';
                        }
                      }}
                    >
                      <option selected>Choose...</option>
                      <option value="newlead">New Lead</option>
                      <option value="walkin">Walkin</option>
                      <option value="followup">Followup</option>
                      <option value="prospect">Prospect</option>
                      <option value="closed">Closed</option>
                    </select>
                  </div>
                ) : (
                  <div className="tw-text-red-500 tw-text-lg tw-bg-slate-200 tw-w-max tw-p-2 tw-mx-3">
                    Please Enter source
                  </div>
                )}
              </div>

              <div className="col-12 text-end mt-3">
                <button
                  onClick={() => {
                    formData.level_lead === 'followup' && setshow(true);
                    formData.level_lead === 'closed' && setshow2(true);
                    formData.level_lead === 'prospect' && setshow3(true);
                    formData.level_lead === 'walkin' && setshow4(true);
                  }}
                  disabled={loading}
                  type="submit"
                  style={{ backgroundColor: '#002147' }}
                  className="btn py-2 rounded-0 text-white fw-medium px-2 px-lg-5"
                >
                  <span
                    class={`spinner-border spinner-border-sm ${
                      loading ? '' : 'd-none'
                    }`}
                    aria-hidden="true"
                  ></span>
                  <span
                    className={`${loading ? 'ms-2' : 'd-none'}`}
                    role="status"
                  >
                    Submitting...
                  </span>
                  <span className={`${loading ? 'd-none' : ''}`}>Submit</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Follow_up_form
        show={show}
        type={'client'}
        setShow={setshow}
        form_value={formData}
      />
      <Closed_form
        show1={show2}
        type={'client'}
        setshow1={setshow2}
        form_value={formData}
      />
      <Prospect_form
        show1={show3}
        type={'client'}
        setShow1={setshow3}
        form_value={formData}
      />
      <Walkinform
        show={show4}
        type={'client'}
        setShow={setshow4}
        form_value={formData}
      />
    </div>
  );
};

export default Customerregistrationform;
