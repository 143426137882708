import { useParams } from 'react-router-dom';
import useLeadsQueryParams from '../../../Hooks/useLeadsQueryParams';
import useLeadsQuery from '../../../Hooks/useLeadsQuery';
import { fetchEmployeeLeads } from '../../../API/FetchFns/AllB2CLeads';
import AllFilters from '../../FilterForms/AllFilters';
import MainLayout from '../../../Admin_panel/All-leads/CustomerAllLeads/MainLayout';
import CustomerTable from '../../../Admin_panel/All-leads/CustomerAllLeads/All/CustomerAllTable';
import PaginatedButtons from '../../../Admin_panel/All-leads/CustomerAllLeads/All/PaginatedButtons';

const EmployeeLeadsData = ({ refresh }) => {
  const { id } = useParams();
  const { lead_stage } = useParams();

  const user = JSON.parse(sessionStorage.getItem('emp_idA'));
  console.log('USERR', user);

  console.log('EMP PARAMS', id, lead_stage, refresh);

  const { queryParamsObj } = useLeadsQueryParams();
  // const [type] = useQueryState('type');

  // const [isScanned] = useQueryState(
  //   'isScanned',
  //   parseAsBoolean.withDefault(false),
  // );

  const queryParams = {
    ...queryParamsObj,
    stage: lead_stage === 'registered' ? 'registration' : lead_stage,
    type: refresh.type,
    employee_id: id,
    lt:
      refresh?.isScanned || !refresh?.followUpType || refresh.type === 'today',
  };

  console.log('QUERYPARAMS', queryParams);

  const { data, error, isError, isFetching, isLoading } = useLeadsQuery({
    queryParams,
    fetchFn: fetchEmployeeLeads,
    fetchKey: 'EmployeeB2CLeads',
  });

  isError && console.log(error);

  const isPrevious = Boolean(data?.data?.previous);
  const isNext = Boolean(data?.data?.next);

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-2">
        <AllFilters />
        <MainLayout loading={isLoading}>
          <CustomerTable
            // leads={[]}
            leads={data?.data?.results}
            count={data?.data?.count}
          />
          <PaginatedButtons
            isFetching={isFetching}
            isNext={isNext}
            isPrevious={isPrevious}
          />
        </MainLayout>
      </div>
    </>
  );
};

export default EmployeeLeadsData;
