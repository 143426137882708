import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar1';
import Navbar from './Navbar1';
import axios from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import { toast } from 'react-toastify';

const Leave_approval = () => {
  const [leave_requests, setleave_requests] = useState([]);

  const user = JSON.parse(sessionStorage.getItem('user'));

  const [count, setCount] = useState(0);

  const [loading, setloading] = useState();
  const [loading2, setloading2] = useState();

  useEffect(() => {
    axios.get(`${ipaddress2}/DisplayLeaveRequests/${user.email}/`).then(r => {
      console.log('Leave Requests', r.data);
      setleave_requests(r.data.reverse());
    });
  }, [count]);

  const leave_processing = (id, status) => {
    status === 'True' ? setloading(true) : setloading2(true);

    axios
      .patch(`${ipaddress2}/DisplayLeaveRequests/${user.email}/`, {
        status: status,
        leave_request_id: id,
      })
      .then(r => {
        setCount(count + 1);
        console.log('LEAVE PROCESSING', r.data);
        if (status === 'True') {
          toast.success('Leave Request Approved', {
            autoClose: 2000,
          });
          setloading(false);
        } else {
          toast.warn('Leave Request Rejected', {
            autoClose: 2000,
          });
          setloading2(false);
        }
      });
  };

  return (
    <div className="d-flex">
      <Sidebar activevalue={'leave_approval'} />
      <div className="w-100">
        <Navbar />

        <div
          className="main-container p-3"
          style={{ backgroundColor: '#E8E8E8' }}
        >
          <div
            className="bg-white p-2 p-lg-3"
            style={{ minHeight: '100vh' }}
          >
            <h5>Leave Approvals</h5>
            <div className="row m-0">
              <h6
                className={`text-center py-4 text-secondary ${
                  leave_requests.length > 0 ? 'd-none' : ''
                }`}
              >
                No data available...🧐
              </h6>
              <div className="col-md-8 ps-0">
                {leave_requests.map((x, index) => {
                  return (
                    <div
                      className="p-3 py-4 mb-4"
                      style={{ border: '1px solid #002147' }}
                    >
                      <div className="row m-0">
                        <div className="col-md-6">
                          <p className="text-color fw-bold">
                            Student Name :{' '}
                            <span className="text-dark ms-2">
                              {x.customer_id &&
                                x.customer_id.customer_name &&
                                x.customer_id.customer_name}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p className="text-color fw-bold">
                            Batch Name :{' '}
                            <span className="text-dark ms-2">
                              {x.batch_id.name &&
                                x.batch_id.name.sub_course_name &&
                                x.batch_id.name.sub_course_name}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p className="text-color fw-bold">
                            Student Email :{' '}
                            <span className="text-dark ms-2">
                              {x.customer_id &&
                                x.customer_id.email &&
                                x.customer_id.email}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p className="text-color fw-bold">
                            Requested On :{' '}
                            <span className="text-dark ms-2">
                              {x.created_at && x.created_at.slice(0, 10)}
                            </span>
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="text-color fw-bold">
                            Reason for the Leave
                          </h6>
                          <p>{x.leave}</p>
                        </div>
                        <div className="col-12 text-end">
                          <p
                            className={`${
                              x.responded && x.status
                                ? 'text-success fw-bold'
                                : 'd-none'
                            }`}
                          >
                            Approved
                          </p>
                          <p
                            className={`${
                              x.responded && x.status == false
                                ? 'text-danger fw-bold'
                                : 'd-none'
                            }`}
                          >
                            Rejected
                          </p>
                          <button
                            className={`btn btn-sm rounded-0 btn-success ${
                              x.responded ? 'd-none' : ''
                            }`}
                            onClick={() => {
                              leave_processing(x.id, 'True');
                            }}
                          >
                            {loading ? 'Processing...' : 'Approve'}
                          </button>
                          <button
                            className={`btn btn-sm rounded-0 btn-danger ms-3 ${
                              x.responded ? 'd-none' : ''
                            }`}
                            onClick={() => {
                              leave_processing(x.id, 'False');
                            }}
                          >
                            {loading2 ? 'Processing...' : 'Reject'}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leave_approval;
