import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { TextField } from '../FilterForms/CategoriesControls/TextField';
import { SelectField } from '../FilterForms/CategoriesControls/SelectField';
import { TextAreaField } from '../FilterForms/CategoriesControls/TextAreaField';
import { getUser } from '../../utils/GetUser';
import { RadioField } from '../FilterForms/CategoriesControls/RadioField';
import { axiosInstance } from '../../API/Axios/AxiosClient';
import WalkinHistoryCard from './WalkinHistoryCard';
import { DateField } from '../FilterForms/CategoriesControls/DateField';

function WalkinsUpdateForm({
  show,
  data,
  walkinHistory,
  show2,
  setshow1,
  setshow2,
  leadID,
}) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      leadID: leadID,
      expected_walkin_date: '',
      priority: '',
      Remarks: '',
      meeting: 'false',
      conductedRemarks: '',
      notConductedRemarks: '',
    },
  });

  useEffect(() => {
    reset({
      leadID: leadID,
      expected_walkin_date: data?.expected_walkin_date?.slice(0, 16),
      priority: data?.priority,
      Remarks: data?.Remarks,
      meeting: 'false',
    });
  }, [leadID, data, reset]);

  const priorityOptions = [
    { name: 'High', value: 'high' },
    { name: 'Medium', value: 'medium' },
    { name: 'Low', value: 'low' },
  ];

  const submitData = formData => {
    if (data) {
      updateMeeting(formData);
    } else {
      addWalkins(formData);
    }
  };

  const addWalkins = formData => {
    const addData = {
      emp_id: getUser(),

      customer_lead_id: leadID.startsWith('CUS') ? formData.leadID : null,

      client_lead_id: leadID.startsWith('CLI') ? formData.leadID : null,

      expected_walkin_date: formData.expected_walkin_date,

      Remarks: formData.Remarks,

      priority: formData.priority,

      meeting: null,
    };

    console.log('ADD DATA', addData);

    axiosInstance
      .post(`/MoveToWalkin/`, addData)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const updateMeeting = formData => {
    const patchData = {
      meeting: formData.meeting,
      meeting_remarks:
        formData.conductedRemarks || formData.notConductedRemarks,
    };
    console.log('UPDATE DATA', patchData);

    axiosInstance
      .patch(`/MoveToWalkin/?id=${data?.id}`, patchData)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const meetingConducted = watch('meeting');

  // const thisss = [];

  const meetingHeld = walkinHistory?.at(-1)?.meeting ?? null;

  console.log('HELLDDD', meetingHeld);
  return (
    <Modal
      size="md"
      aria-labelledby="example-modal-sizes-title-md"
      show={show2}
      onHide={() => setshow2(false)}
    >
      <Modal.Body>
        {walkinHistory?.length > 0 && (
          <WalkinHistoryCard walkinHistory={walkinHistory} />
        )}

        {!meetingHeld && (
          <form onSubmit={handleSubmit(submitData)}>
            <div className=" tw-flex tw-flex-col tw-gap-y-3">
              <div className="col-12">
                <TextField
                  label={'Lead ID'}
                  placeholder={''}
                  error={errors.leadID}
                  disabled
                  {...register('leadID')}
                />
              </div>

              <div className="col-12">
                <DateField
                  label={'Expected Walking Date'}
                  type={'datetime-local'}
                  disabled={meetingHeld}
                  error={errors.expected_walkin_date}
                  {...register('expected_walkin_date', {
                    required: 'Please enter Expected Follow-up Date',
                    // validate: {
                    //   futureDate: value => {
                    //     const selectedDate = new Date(value);
                    //     const now = new Date();
                    //     if (selectedDate <= now) {
                    //       return 'Please select a future date';
                    //     }
                    //     return true;
                    //   },
                    // },
                  })}
                />
              </div>

              <div className="col-12 mb-3">
                <SelectField
                  label={'Priority'}
                  disabled={meetingHeld}
                  options={priorityOptions}
                  error={errors.priority}
                  {...register('priority', {
                    required: 'Choose Priority',
                  })}
                />
              </div>

              <div className="col-12">
                <TextAreaField
                  label={'Remarks'}
                  disabled={meetingHeld}
                  error={errors.Remarks}
                  {...register('Remarks', {
                    required: 'Please Enter Remarks',
                  })}
                />
              </div>

              {data && (
                <div className="row m-0">
                  <div className="col-12">
                    <h2>Meeting Status</h2>
                    <div className=" tw-flex tw-gap-x-5">
                      <RadioField
                        label="Conducted"
                        value={true}
                        disabled={meetingHeld}
                        error={errors?.meeting}
                        {...register('meeting', {
                          setValueAs: v => v === 'true',
                        })}
                      />
                      <RadioField
                        label="Not Conducted"
                        value={false}
                        disabled={meetingHeld}
                        defaultChecked={!data?.meeting}
                        error={errors?.meeting}
                        {...register('meeting', {
                          setValueAs: v => v === 'true',
                        })}
                      />
                    </div>
                  </div>
                </div>
              )}

              {console.log('DATAFROM OUT', data)}
              {data &&
                (meetingConducted === 'true' ? (
                  <div className="col-12">
                    <TextAreaField
                      label={'Conducted Remarks'}
                      disabled={meetingHeld}
                      error={errors.conductedRemarks}
                      {...register('conductedRemarks', {
                        required: 'Please Enter Remarks',
                      })}
                    />
                  </div>
                ) : (
                  <div className="col-12">
                    <TextAreaField
                      disabled={meetingHeld}
                      label={'Not Conducted Remarks'}
                      error={errors.notConductedRemarks}
                      {...register('notConductedRemarks', {
                        required: 'Please Enter Remarks',
                      })}
                    />
                  </div>
                ))}

              {!meetingHeld && (
                <div className="col-12 text-end mt-4">
                  <button
                    type="submit"
                    className="btns px-5 py-2 text-white fw-medium"
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default WalkinsUpdateForm;
