import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ipaddress } from '../App';
import { useNavigate } from 'react-router-dom';
import { buildQueryString, buildURLString } from '../utils/QueryBuilder';
import { scrollToTable } from '../utils/ScrolltoTable';
import EmployeeCardsMain from './EmployeeCardsMain';
import EmployeeLeadCard from './EmployeeLeadCard';
import ReactCardFlip from 'react-card-flip';
import { getUser } from '../utils/GetUser';

const EmployeeLeadsCount = ({ id }) => {
  const [flipped, setflipped] = useState(false);

  const [emp_data, setemp_data] = useState({});

  const [emp_billing_data, setemp_billing_data] = useState({});

  const navigate = useNavigate();

  const [todayWalkins, setTodayWalkins] = useState();

  const [achievedWalkins, setAchieveWalkins] = useState();

  const [regisSum, setRegisSum] = useState();

  const [singleDate, setSingleDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fetchAllEmpCounts = () => {
    const queryParams = {
      // emp_id: id,
      start_date: startDate || singleDate,
      end_date: endDate,
      date: singleDate,
    };

    const PROFILEVIEWIPADDRESS = `${ipaddress}/MyprofileView/${id}/?${buildQueryString(
      queryParams,
    )}`;

    console.log('REFETCH MYPROFILE', PROFILEVIEWIPADDRESS);

    const BILLINGVIEWADDRESS = `${ipaddress}/BillingHistoryOfEmployee/${id}/?${buildQueryString(
      queryParams,
    )}`;

    console.log('REFETCH', BILLINGVIEWADDRESS);
    axios
      .get(PROFILEVIEWIPADDRESS)
      .then(r => {
        console.log('Employee data found', r.data);
        console.log('CARDS DATA', r.data);
        setemp_data(r.data);
      })
      .catch(err => {
        console.log('Employee data fetching error', err);
      });

    axios
      .get(BILLINGVIEWADDRESS)
      .then(r => {
        console.log('Employee billing data found', r.data);
        setemp_billing_data(r.data);
      })
      .catch(err => {
        console.log('Employee billing data fetching error', err);
      });
  };

  const fetchTodayWalkins = () => {
    const queryParams = {
      emp_id: id,
      start_date: startDate,
      end_date: endDate,
      date: singleDate,
    };

    const IPADDRESS = `${ipaddress}/TodayExpectedWalkinsRecords/?${buildQueryString(
      queryParams,
    )}`;

    console.log('REFETCH TodayExpectedWalkinsRecords', IPADDRESS);

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('MUIZ DATE', res.data);
        setTodayWalkins(res.data);
      })
      .catch(err => console.log(err));
  };

  const fetchAchivedWalkins = () => {
    const queryParams = {
      emp_id: id,
      start_date: startDate,
      end_date: endDate,
      date: singleDate,
    };

    const IPADDRESS = `${ipaddress}/AcheivedWalkin/?${buildQueryString(
      queryParams,
    )}`;

    console.log('REFETCH AcheivedWalkin', IPADDRESS);

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('ACHIVED', res.data);
        setAchieveWalkins(res.data.Acheived_walkin_count);
      })
      .catch(err => console.log(err));
  };

  const fetchRegistrationSummary = () => {
    const queryParams = {
      emp_id: id,
      start_date: startDate,
      end_date: endDate,
      date: singleDate,
    };

    const IPADDRESS = `${ipaddress}/RegistrationsSourceWiseAndCourseWise/?${buildQueryString(
      queryParams,
    )}`;

    console.log('REFETCH RegistrationsSourceWiseAndCourseWise', IPADDRESS);

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('MUIZ', res.data);
        setRegisSum(res.data);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    fetchAchivedWalkins();
    fetchTodayWalkins();
    fetchRegistrationSummary();
  }, []);

  useEffect(() => {
    fetchAllEmpCounts();
  }, [id]);

  const datesFilterQuery = buildURLString({
    singleDate: singleDate,
    startDate: startDate,
    endDate: endDate,
  });

  const allCards = {
    LeadsData: [
      {
        name: 'Total Leads',
        count: emp_data?.all_leads_count,
        handleClick: () => {
          scrollToTable('dataTable');
          navigate(`/view_employee_leads/${id}/allleads/?${datesFilterQuery}`);
        },
      },
      {
        name: 'New Leads',
        count: emp_data?.new_leads_count,
        handleClick: () => {
          scrollToTable('dataTable');

          navigate(`/view_employee_leads/${id}/newlead/?${datesFilterQuery}`);
        },
      },
      {
        name: 'Followups',
        count: emp_data?.followup_leads_count,
        handleClick: () => {
          scrollToTable('dataTable');

          navigate(`/view_employee_leads/${id}/followup/?${datesFilterQuery}`);
        },
      },
      {
        name: 'Prospects',
        count: emp_data?.prospect_leads_count,
        handleClick: () => {
          scrollToTable('dataTable');

          navigate(`/view_employee_leads/${id}/prospect/?${datesFilterQuery}`);
        },
      },
      {
        name: 'Registered Leads',
        count: emp_data?.registered_leads_count,
        handleClick: () => {
          scrollToTable('dataTable');

          navigate(
            `/view_employee_leads/${id}/registered/?${datesFilterQuery}`,
          );
        },
      },
      {
        name: 'Closed Leads',
        count: emp_data?.closed_leads_count,
        handleClick: () => {
          scrollToTable('dataTable');

          navigate(`/view_employee_leads/${id}/closed/?${datesFilterQuery}`);
        },
      },
      {
        name: 'Pending Followups',
        count: emp_data?.overdue_followup_count,
        handleClick: () => {
          scrollToTable('dataTable');

          navigate(`/view_employee_leads/${id}/overdue/?${datesFilterQuery}`);
        },
      },
      {
        name: 'On-Time Completion',
        count: emp_data?.on_time_followup_count,
        handleClick: () => {
          scrollToTable('dataTable');

          navigate(
            `/view_employee_leads/${id}/on_time_followup/?${datesFilterQuery}`,
          );
        },
      },
      {
        name: 'Late Completion',
        count: emp_data?.late_followup_count,
        handleClick: () => {
          scrollToTable('dataTable');

          navigate(
            `/view_employee_leads/${id}/late_followup/?${datesFilterQuery}`,
          );
        },
      },

      {
        name: 'Today Followups',
        count: emp_data?.today_followup_count,
        handleClick: () => {
          scrollToTable('dataTable');

          navigate(
            `/view_employee_leads/${id}/today_followup/?${datesFilterQuery}`,
          );
        },
      },
      {
        name: 'Upcoming Followups',
        count: emp_data?.upcoming_followups,
        handleClick: () => {
          scrollToTable('dataTable');

          navigate(
            `/view_employee_leads/${id}/upcoming_followups/?${datesFilterQuery}`,
          );
        },
      },
      {
        name: 'Today Walkins',
        count: todayWalkins?.customer_leads?.length,
        handleClick: () => {
          scrollToTable('dataTable');

          navigate(
            `/view_employee_leads/${id}/today_walkins/?${datesFilterQuery}`,
          );
        },
      },
      {
        name: 'Achieved Walkins',
        count: achievedWalkins,
        handleClick: () => {
          scrollToTable('dataTable');

          navigate(
            `/view_employee_leads/${id}/achived-walkins/?${datesFilterQuery}`,
          );
        },
      },
      {
        name: 'Registrations Summary',
        count: regisSum?.length,
        handleClick: () => {
          scrollToTable('dataTable');
          navigate(
            `/view_employee_leads/${id}/registration-summary/?${datesFilterQuery}`,
          );
        },
      },
    ],
    BillingData: [
      {
        name: 'Total Billing',
        count: emp_billing_data?.total_billing,
        handleClick: () => {
          console.log('Total Billing');
        },
      },
      {
        name: 'Monthly Billing',
        count: emp_billing_data?.month_billing,
        handleClick: () => {
          console.log('Current Month Billing');
        },
      },
      {
        name: 'Today Collection',
        count: emp_billing_data?.today_collection,
        handleClick: () => {
          console.log('Today Collection');
        },
      },
      {
        name: 'Pending Collections',
        count: emp_billing_data?.old_collectables,
        handleClick: () => {
          console.log('Old Collectables');
        },
      },
      {
        name: 'Total Collection',
        count: emp_billing_data?.total_collection,
        handleClick: () => {
          console.log('Total Collection');
        },
      },
    ],
  };

  return (
    <div>
      <div className="mb-5 ">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex ms-auto">
            <button
              className="btns text-white py-2 me-3"
              onClick={() => navigate(`/today_leads/${id}`)}
            >
              Today Leads
            </button>
          </div>
        </div>

        {/* <ReactCardFlip
          isFlipped={flipped}
          flipDirection="vertical"
        >
          <div className="row m-0 justify-content-center">
            {allCards.LeadsData.map((type, idx) => {
              return (
                <EmployeeLeadCard
                  key={idx}
                  count={type.count}
                  handleClick={type.handleClick}
                  name={type.name}
                />
              );
            })}
          </div>

          <div className="row m-0 justify-content-evenly">
            {allCards.BillingData.map((type, idx) => {
              return (
                <EmployeeLeadCard
                  key={idx}
                  count={type.count}
                  handleClick={type.handleClick}
                  name={type.name}
                />
              );
            })}
          </div>
        </ReactCardFlip> */}

        <EmployeeCardsMain employeeID={id} />
      </div>
    </div>
  );
};

export default EmployeeLeadsCount;
