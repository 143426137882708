/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-no-undef */
import { useNavLoc } from '../Hooks/useNavLoc';
import Arrow_svg from '../Svgs/Arrow_svg';

function FormHeader({ children }) {
  const navWithLoc = useNavLoc();

  return (
    <div
      className="animate__animated animate__fadeIn p-lg-3"
      style={{ backgroundColor: '#E8E8E8' }}
    >
      <div className="container-fluid row m-0 pb-4">
        <div className="my-3">
          <h6
            className="text-color tw-text-lg d-flex align-items-center fw-bold"
            style={{ letterSpacing: '1px' }}
          >
            <span
              className="me-2 me-md-4"
              style={{ cursor: 'pointer' }}
              onClick={() => navWithLoc('/customerleads')}
            >
              <Arrow_svg />
            </span>
            REGISTRATION FORM
          </h6>
        </div>
        {children}
      </div>
    </div>
  );
}

export default FormHeader;
