import { createRoot } from 'react-dom/client';
import App from './App';
import Context_provider from './Components/Context/Context_provider';
import { NuqsAdapter } from 'nuqs/adapters/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient();

createRoot(document.getElementById('root')).render(
  <NuqsAdapter>
    <QueryClientProvider client={queryClient}>
      <Context_provider>
        <App />
      </Context_provider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </NuqsAdapter>,
);
