import cardImg from '../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png';

function EmployeeLeadCard({ handleClick, name, count }) {
  return (
    <div className="p-2 px-3 col-md-4 col-lg-2">
      <div
        className="py-3 py-md-4 px-2 px-lg-3 shadow-sm emp-cards"
        onClick={handleClick}
      >
        <img
          src={cardImg}
          className="d-block mx-auto"
          style={{ width: '46%' }}
          alt=""
        />
        <p
          className="m-0 text-center mt-2 fw-bold"
          style={{ color: '#202224', fontSize: '13px' }}
        >
          {name}
        </p>
        <p
          className="fw-medium text-center m-0"
          style={{ fontSize: '14px', color: '#202224' }}
        >
          {count}
        </p>
      </div>
    </div>
  );
}

export default EmployeeLeadCard;
