import { useState } from 'react';
import TableExampleOrig from '../../../../Trials/TableExample';
import EmployeeAssign from '../../AssignAnyone.jsx/AssignEmployees';

function CustomerTable({ leads, count }) {
  const [selectedLeads, setSelectedLeads] = useState([]);

  const handleCheckboxChange = (status, id) => {
    if (status) {
      setSelectedLeads(prev => [...prev, id]);
    } else {
      setSelectedLeads(prev => prev.filter(leadId => leadId !== id));
    }
  };

  return (
    <div className="">
      <div className="tw-flex tw-justify-end tw-mt-6 tw-mb-0 ">
        <EmployeeAssign
          selectedLeads={selectedLeads}
          setSelectedLeads={setSelectedLeads}
        />
      </div>
      <TableExampleOrig
        count={count}
        tableData={leads}
        handleCheckboxChange={handleCheckboxChange}
        selectedLeads={selectedLeads}
      />
    </div>
  );
}

export default CustomerTable;
