import CompanyLogo from '../images/Templates/merida logo white 1.png';

function TermsAndConditionsTemplate() {
  const Terms = [
    'We reserve the right to amend course prices or run promotions at any time, without prior notice.',

    'All courses booked as part of the Package deals are non-refundable and non-transferable under any circumstances. Any reschedules of dates will be at the discretion of Merida Skill Learning Academy and subject to the rescheduling fee of 5% of the Total course fee per training date, where deemed possible',

    'Installments should be Paid on or Before the due date failing which will Result in Late payment Charges.',

    'On any instance of Cheque Bounces you will be charged Rs 1000 as an admin fee',

    'Dates must be selected at time of booking. Training dates are subject to availability at preferred training center & places are not confirmed until your booking has been processed by our team. All training booked as part of a package must be taken within 3 months of date of booking the package.',

    'In the event of a genuine reason for cancellation (proof of which may be required); of a confirmed training date notified prior to the booked training date, a request for a transfer will be considered at the discretion of The Merida Skill Learning Academy (subject to availability). If successful, an administration fee of 5% on Total course fee per course date will be charged.',

    'We need 1 weeks notice of changing your course date, anything under this will result in an admin fee.',

    'Non-attendance where no prior notice is given will be handled at the discretion of The Merida Skill Learning Academy and may result in loss of course fees, including the booking fee. Should you be unable to attend a confirmed training date for medical reasons, a valid medical certificate covering the named student and day of the booked training will be required to waiver the fee to reschedule. Under medical or compassionate circumstances (proof of which may be required) and where immediate reschedule might not be possible; a credit may be placed on a file as a gesture of goodwill. Credits will be valid towards training course only and run for a period of up to 3 months from the date of issue at which time the course fees would be forfeited. No refund of course fees can be issued. Write to info@skilllearningacademy.com regarding your reschedule request (or nonattendance). Booked training is non-transferable to an alternative student. Please ensure the booking is made giving the correct name of the person attending the course at the time of making payment. Amendments to this will be chargeable at RS 500 per course date/person, where possible for a change to be accommodated and a minimum of 48 hours notice prior to the course date is required, in writing',

    'In the event that The Merida Skill Learning Academy should have to cancel your course for reasons outside of our control; The Skill Learning Academy will endeavor to give students notice of any cancellation by telephone and/or email. The Academy is not responsible for any accommodation/travelling costs students may incur due to the cancellation of courses. ',
  ];

  return (
    <div
      className="tw-flex tw-flex-col"
      style={{
        height: '2200px',
        width: '1500px',
        // backgroundColor: 'aquamarine',
        // border: '1px solid #000',
      }}
    >
      <div
        className="gradient-background"
        // className="tw-relative tw-flex tw-px-16 tw-justify-between tw-p-4 gradient-background"
        style={{
          boxSizing: 'border-box',
          height: '350px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0px 75px',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div
          // className="tw-relative tw-z-10 tw-mb-3  tw-items-center tw-flex tw-border"
          // id="uniq"
          style={{
            alignItems: 'center',
            boxSizing: 'border-box',
            // display: 'flex',
            margin: '0px auto',
            position: 'relative',
            zIndex: 10,
          }}
        >
          <img
            src={CompanyLogo}
            alt=""
            width={300}
            // className="tw-mb-3"
            style={{
              boxSizing: 'border-box',
              margin: '0px 0px 10.5px',
              maxWidth: '100%',
              verticalAlign: 'middle',
            }}
          />
        </div>
      </div>
      <div className=" tw-flex tw-flex-col tw-gap-y-2 tw-mt-6 tw-flex-grow">
        <h1
          style={{
            boxSizing: 'border-box',
            color: '#212529',
            fontFamily: 'Lato, sans-serif',
            fontSize: '31.5px',
            lineHeight: '35px',
            margin: '0px 0px 0px 28px',
          }}
        >
          Terms and Conditions
        </h1>
        <div className="tw-mt-6 tw-flex tw-flex-col tw-gap-y-3 ">
          {Terms.map((term, idx) => {
            return (
              <div className=" tw-flex tw-gap-x-3 tw-mx-5">
                <div className="tw-mt-[1px] tw-px-2 tw-text-xl">
                  {idx + 1}.)
                </div>
                <p
                  style={{
                    boxSizing: 'border-box',
                    color: '#212529',
                    fontFamily: 'Lato, sans-serif',
                    fontSize: '21px',
                    lineHeight: '28px',
                    textAlign: 'justify',
                    wordSpacing: '10px',
                  }}
                >
                  {term}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditionsTemplate;
