import React, { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { ipaddress, ipaddress2 } from '../App';
import Sidebar from './Sidebar1';
import Navbar from './Navbar1';
import { useNavigate } from 'react-router-dom';
import StudentsBasedOnGroup from './StudentsBasedOnGroup';

const Trainer_Groups = ({ designation }) => {
  const [groups, setgroups] = useState([]);
  const [students, setStudents] = useState([]);

  const navigate = useNavigate();

  const user = JSON.parse(sessionStorage.getItem('user'));

  useEffect(() => {
    axios
      .get(`${ipaddress2}/GetGroupView/${user.emp_id}/`)
      .then(r => {
        console.log('Groups', r.data);
        setgroups(r.data);
      })
      .catch(err => {
        console.log();
      });
  }, []);

  const fetchStudents = id => {
    axios
      .get(`${ipaddress}/StudentsBasedOnGroup/${id}`)
      .then(res => {
        console.log('StudentsBasedOnGroup', res.data);
        setStudents(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [active, setActive] = useState();

  return (
    <div className="d-flex">
      <Sidebar activevalue={'your_groups'} />
      <div className="w-100 main-div">
        <Navbar />
        <div
          className="bg-light py-3 pt-4 main-container"
          style={{ minHeight: '100vh' }}
        >
          <div className="container row m-0 d-flex justify-content-between ">
            <div className="col-md-8">
              <h5 className="">Groups</h5>
            </div>
            <div
              className="col-md-4 d-flex align-items-center justify-content-end"
              style={{}}
            >
              <span className="border-end border-secondary-subtle pe-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </span>
              <span className="ps-4"></span>
            </div>
          </div>
          <h6
            className={`text-secondary text-center py-4 ${
              groups.length > 0 ? 'd-none' : ''
            }`}
          >
            No groups available...🧐
          </h6>

          <div
            className={`container d-flex gap-4 tw-pt-4 row m-0 mx-auto tw-bg-red-400 ${
              groups?.length > 0 ? '' : 'd-none'
            }`}
          >
            {groups?.map(x => {
              console.log('STUDGRP', x);
              return (
                <div
                  className={`${
                    active === x.id ? 'tw-bg-blue-200' : 'tw-bg-white'
                  } col-md-4 col-lg-5 py-4 `}
                  style={{
                    cursor: 'pointer',
                    width: '250px',
                    border: '1px solid #002147',
                  }}
                  onClick={() => {
                    setActive(x.id);
                    fetchStudents(x.id);
                  }}
                >
                  <div className="">
                    <img
                      src={require('../images/group 1.png')}
                      className="d-block mx-auto"
                      width={100}
                      height={100}
                      alt=""
                    />
                    <p
                      className="m-0 fw-bold mt-3 mb-2"
                      style={{ color: '#002147' }}
                    >
                      Group : {x.name}
                    </p>
                    <span
                      className="text-color fw-bold mt-2"
                      style={{ fontSize: '14px' }}
                    >
                      Batch : {x.batch_name}
                    </span>
                    <p
                      className="btns m-0 text-center text-white px-4 mt-3 d-flex align-items-center justify-content-center"
                      style={{ fontSize: '12px' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-people"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                      </svg>{' '}
                      <span
                        className="ms-2"
                        style={{ fontSize: '12px' }}
                      >
                        {x.memberships.length} Members
                      </span>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          {
            <div className=" container d-flex gap-4 tw-mt-4 row mx-auto">
              <StudentsBasedOnGroup studentsData={students.students} />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Trainer_Groups;
