import axios from 'axios';
import { useEffect, useState } from 'react';
import { ipaddress } from '../../../App';
import { useLocation } from 'react-router-dom';

function ClosedFilter({ setclosedleads }) {
  const [availableCourses, setAvailableCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const location = useLocation();

  let empID;

  console.log('LOCSLICE', location.pathname.slice(21, 31));

  if (location.pathname.startsWith('/view_employee_leads')) {
    empID = location.pathname.slice(21, 31);
  }

  const [reasons, setReasons] = useState();

  const [selectedReasons, setSelectedReasons] = useState([]);

  const curUser = JSON.parse(sessionStorage.getItem('user'));
  console.log('XYZ', curUser.emp_id);

  const handleClosedReasonChange = event => {
    const { value, checked } = event.target; // Get the checkbox value and checked status

    setSelectedReasons(prev => {
      if (checked) {
        if (prev.length > 0) {
          // Add the reason value to the array when checked
          return [...prev, value];
        } else {
          return [value];
        }
      } else {
        // Remove the reason value from the array when unchecked
        return prev.filter(reason => reason !== value);
      }
    });
  };

  const getCourses = () => {
    axios
      .get(`${ipaddress}/AllcoursesnameView/`)
      .then(r => {
        console.log('SEARCH ALL COURSES', r.data);
        setAvailableCourses(r.data.coursename);
      })
      .catch(err => {
        console.log('Courses fetching Error', err);
      });
  };

  const fetchReasonforClosure = () => {
    axios
      .get(`${ipaddress}/ReasonsForCloser/`)
      .then(res => {
        console.log('REASON COURSES', res.data);
        setReasons(res.data);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getCourses();
    fetchReasonforClosure();
  }, []);

  const getFilteredClosedLeads = () => {
    const courseName = searchTerm || '';

    const reasonQuery = selectedReasons?.length
      ? selectedReasons
          .map(reason => `reason_for_closure=${encodeURIComponent(reason)}`)
          .join('&')
      : '';

    const apiUrl = `${ipaddress}/SearchClosedLeads/?${
      empID ? `emp_id=${empID}&` : ''
    }${
      courseName ? `course_name=${encodeURIComponent(courseName)}&` : ''
    }${reasonQuery}`;

    console.log('APIURL', apiUrl);

    axios
      .get(apiUrl)
      .then(res => {
        console.log('REASON API', res.data.customer_leads_data);

        setclosedleads(res.data.customer_leads_data);
        console.log('STATE CHANGE');
      })
      .catch(err => console.log('Error fetching filtered closed leads', err));
  };

  const handleSearchChange = value => {
    setSearchTerm(value);

    console.log('SEARCH VALUE', value, availableCourses);

    const matches = availableCourses.filter(course =>
      course.course_name.toLowerCase().startsWith(value.toLowerCase()),
    );
    console.log('SEARCH MATCH', matches);
    setFilteredCourses(matches);
  };

  console.log('MUIZ', selectedReasons, filteredCourses, searchTerm);
  return (
    <div className="tw-p-4 tw-border tw-rounded-lg tw-shadow-lg tw-text-white">
      <div className="tw-mb-4 tw-relative">
        <input
          type="text"
          value={searchTerm}
          placeholder="Search courses..."
          onChange={e => handleSearchChange(e.target.value)}
          className="tw-w-full tw-px-4 tw-py-2  tw-text-black tw-border-none tw-rounded-md focus:tw-ring-2 focus:tw-ring-emerald-400 tw-shadow-sm"
        />
        <ul
          className={`tw-absolute tw-bg-white tw-text-black tw-rounded-md tw-mt-2 tw-w-full tw-shadow-lg ${
            filteredCourses?.length > 0 ? 'tw-block' : 'tw-hidden'
          }`}
        >
          {filteredCourses.length > 0 ? (
            filteredCourses.map((course, index) => (
              <button
                key={index}
                className="tw-w-full tw-text-left tw-px-4 tw-py-2 hover:tw-bg-emerald-100 tw-transition tw-rounded-md"
                onClick={() => {
                  setSearchTerm(course.course_name);
                  setFilteredCourses([]);
                }}
              >
                {course.course_name}
              </button>
            ))
          ) : (
            <li className="tw-px-4 tw-py-2 tw-text-center tw-text-gray-500">
              No matches found
            </li>
          )}
        </ul>
      </div>

      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
        {reasons?.map(({ id, reason }) => (
          <div
            key={id}
            className="tw-flex tw-items-center tw-space-x-3"
          >
            <input
              type="checkbox"
              className="tw-form-checkbox tw-h-5 tw-w-5 "
              id={`reason-${id}`}
              name="reason_for_closure"
              value={reason} // Store the reason text, not the ID
              checked={selectedReasons?.includes(reason)} // Check if the reason is selected
              onChange={handleClosedReasonChange} // Handle checkbox change
            />
            <label
              className="tw-text-md tw-text-black tw-cursor-pointer"
              htmlFor={`reason-${id}`}
            >
              {reason}
            </label>
          </div>
        ))}
      </div>

      {/* Search Button */}
      <button
        className="tw-mt-4 tw-bg-red-500 tw-text-white tw-px-6 tw-py-2 tw-rounded-md tw-font-semibold hover:tw-bg-red-600 tw-transition"
        onClick={() => getFilteredClosedLeads()} // Properly call the function
      >
        Search
      </button>
    </div>
  );
}

export default ClosedFilter;
