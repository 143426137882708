import React, { useState, useEffect } from 'react';
import { ipaddress } from '../../../../App';
import axios from 'axios';
import MainLayout from '../MainLayout';
import ProspectSummary from '../../../ProspectCourse/ProspectSummary';
import { buildQueryString } from '../../../../utils/QueryBuilder';
import CustomerTable from '../All/CustomerAllTable';
import PaginatedButtons from '../All/PaginatedButtons';
import AllFilters from '../../../../Components/FilterForms/AllFilters';
import useLeadsQuery from '../../../../Hooks/useLeadsQuery';
import { fetchAllProspectsLeads } from '../../../../API/FetchFns/AllB2CLeads';
import useLeadsQueryParams from '../../../../Hooks/useLeadsQueryParams';

const CustomerProspects = ({ settab }) => {
  const user = JSON.parse(sessionStorage.getItem('user'));

  const [leads, setLeads] = useState([]);

  useEffect(() => {
    settab('prospect');
  }, [settab]);

  const { queryParamsObj } = useLeadsQueryParams();

  const queryParams = {
    ...queryParamsObj,
    stage: 'prospect',
  };

  const { data, error, isError, isFetching, isLoading } = useLeadsQuery({
    queryParams,
    fetchFn: fetchAllProspectsLeads,
    fetchKey: 'ProspectsB2CLeadsAdmin',
  });

  useEffect(() => {
    setLeads(data?.data?.results);
  }, [setLeads, data]);

  data && console.log('PROS', data?.data);
  isError && console.log(error);

  const isPrevious = Boolean(data?.data?.previous);
  const isNext = Boolean(data?.data?.next);

  const fetchCoursePrName = prFieldName => {
    const queryParams = {
      emp_id: user.emp_id,
      start_date: '',
      end_date: '',
      date: '',
      course_name: prFieldName,
    };

    const API = `${ipaddress}/CourseWiseProspectRecords/?${buildQueryString(
      queryParams,
    )}`;

    console.log('CLICKED', API);

    axios
      .get(API)
      .then(res => {
        console.log('CLICKED THIS', res.data.customer_leads);
        setLeads(res.data.customer_leads);
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <AllFilters />
      <ProspectSummary fetchData={fetchCoursePrName} />
      <MainLayout loading={isLoading}>
        <CustomerTable
          leads={leads}
          count={data?.data?.count}
        />
        <PaginatedButtons
          isFetching={isFetching}
          isNext={isNext}
          isPrevious={isPrevious}
        />
      </MainLayout>
    </div>
  );
};

export default CustomerProspects;
