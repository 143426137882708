import CompanyLogo from '../images/Templates/merida logo white 1.png';

function QuoteBillTemplate() {
  const Quote = [
    {
      name: 'Web App Development',
      cost: 10,
      desc: [
        'Booking Tickets',
        'Payment gateway integration',
        'QR code integration',
        'Discount Code',
        'Affiliate Marketing Login',
      ],
    },
    {
      name: 'Running Ad Campaigns',
      cost: 10,
      desc: ['Google Ads and Meta Platform'],
    },
    {
      name: 'Bulk Messaging Campaign',
      cost: 10,
      desc: ['Whatsapp, Email Campaign for Database'],
    },
    {
      name: 'Graphic Work',
      cost: 10,
      desc: ['Posters, Pamphlets, Hoardings, Newspaper etc.'],
    },
    {
      name: 'Video Creation and Editing',
      cost: 10,
      desc: [
        'Creating Reels and Shorts ',
        'Editing the Video',
        'Creating Content for the Video',
        'Publishing the Video',
      ],
      note: 'Note : Celebrity, Influencers Charges and Other Video Editing Charges Are Not Included',
    },
    {
      name: 'Direct Marketing',
      cost: 10,
      desc: ['Coordinating for Newspaper Ads, Hoardings etc.'],
    },
  ];

  return (
    <div
      className="tw-flex tw-flex-col tw-relative"
      style={{
        height: '2212px',
        width: '1500px',
        // backgroundColor: 'aquamarine',
        // border: '1px solid #000',
      }}
    >
      <div
        className="gradient-background"
        // className="tw-relative tw-flex tw-px-16 tw-justify-between tw-p-4 gradient-background"
        style={{
          boxSizing: 'border-box',
          height: '35%',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0px 75px',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div
          // className="tw-relative tw-z-10 tw-mb-3  tw-items-center tw-flex tw-border"
          // id="uniq"
          style={{
            alignItems: 'center',
            boxSizing: 'border-box',
            // display: 'flex',
            margin: '0px auto',
            position: 'relative',
            zIndex: 10,
            height: '35%',
          }}
        >
          <img
            src={CompanyLogo}
            alt=""
            width={400}
            // className="tw-mb-3"
            style={{
              boxSizing: 'border-box',
              margin: '0px 0px 10.5px',
              maxWidth: '100%',
              verticalAlign: 'middle',
            }}
          />
        </div>
      </div>
      <div className=" tw-h-[85%] tw-w-full -tw-translate-y-[10%]">
        <div className="tw-mx-auto tw-w-[90%] tw-bg-white tw-rounded-[50px] tw-h-full tw-flex tw-flex-col tw-gap-y-3 tw-pt-10 ">
          <div
            // className="tw-flex tw-bg-slate-200 tw-rounded-2xl tw-py-8 tw-mx-4  "
            style={{
              backgroundColor: '#e2e8f0',
              borderRadius: '14px',
              boxSizing: 'border-box',
              display: 'flex',
              margin: '0px 14px',
              padding: '28px 0px',
            }}
          >
            <p className="tw-w-[15%] tw-text-5xl tw-text-center ">No.</p>
            <p
              className="tw-w-[75%]"
              style={{
                boxSizing: 'border-box',
                color: '#212529',
                fontFamily: 'Lato, sans-serif',
                fontSize: '42px',
                lineHeight: '42px',
                padding: '0px 0px 0px 10.5px',
              }}
            >
              Item Description
            </p>
            <p className="tw-text-center tw-text-5xl tw-w-[10%]">Cost</p>
          </div>
          {Quote.map((each, idx) => {
            return (
              <div
                style={{
                  //   backgroundColor: '#ffffff',
                  borderRadius: '14px',
                  boxSizing: 'border-box',
                  display: 'flex',
                  margin: '0px 14px',
                  padding: '28px 0px',
                }}
                className={`${
                  idx % 2 !== 0 ? 'tw-bg-slate-200' : 'tw-bg-white'
                }`}
              >
                <p className="tw-w-[15%] tw-text-4xl tw-text-center ">
                  {idx + 1}.)
                </p>
                <div className="tw-w-[75%] tw-pl-3 ">
                  <div>
                    <h2
                      //   className="tw-text-4xl"
                      style={{
                        boxSizing: 'border-box',
                        color: '#212529',
                        fontFamily: 'Lato, sans-serif',
                        fontSize: '31.5px',
                        lineHeight: '35px',
                      }}
                    >
                      {each?.name}
                    </h2>
                    <ul className="tw-ml-6">
                      {each?.desc.map((item, idx) => {
                        return (
                          <li
                            style={{
                              boxSizing: 'border-box',
                              color: '#212529',
                              display: 'list-item',
                              fontFamily: 'Lato, sans-serif',
                              fontSize: '31.5px',
                              lineHeight: '35px',
                              textAlign: 'left',
                            }}
                            className="tw-list-disc"
                            key={idx}
                          >
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <p
                    // className="tw-text-2xl tw-font-semibold tw-mt-5"
                    style={{
                      boxSizing: 'border-box',
                      color: '#ff0000',
                      fontFamily: 'Lato, sans-serif',
                      fontSize: '21px',
                      fontWeight: '600',
                      lineHeight: '28px',
                      margin: '17.5px 0px 0px',
                    }}
                  >
                    {each?.note}
                  </p>
                </div>
                <p className="tw-text-4xl tw-text-center tw-w-[10%]">
                  {each?.cost}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default QuoteBillTemplate;
