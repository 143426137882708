import { useRef } from 'react';

export function GetRenderCount() {
  const renderCount = useRef(0);

  // Increment the render count on each render
  renderCount.current++;

  return (
    <div className=" tw-bg-red-300 tw-text-center tw-h-max tw-my-auto tw-p-2">
      Render count: {renderCount.current}
    </div>
  );
}
