import React, { useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import axios from 'axios';
import { useEffect } from 'react';
import { ipaddress } from '../App';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import StudentsBasedOnGroup from '../Trainer_pages/StudentsBasedOnGroup';
import StudentsGrades from '../Trainer_pages/StudentsGrades';
// import { useNavigate } from 'react-router-dom'

const Groups = ({ designation }) => {
  const [groups, setgroups] = useState([]);

  const navigate = useNavigate();

  const user = JSON.parse(sessionStorage.getItem('user'));

  //   const navigate=useNavigate()
  const { id } = useParams();

  const [count, setCount] = useState(0);

  useEffect(() => {
    axios.get(`${ipaddress}/GetBatchGroupView/${id}/${user.id}/`).then(r => {
      console.log('Groups available', r.data);
      setgroups(r.data);
    });
  }, [count]);

  const join_group = group_id => {
    axios
      .post(`${ipaddress}/JoinGroupView/`, {
        group_id: group_id,
        student_id: user.id,
      })
      .then(r => {
        toast.success('Successfully joined', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
        setCount(count + 1);
      })

      .catch(err => {
        console.log(err);
      });
  };

  const [students, setStudents] = useState();

  const fetchStudents = id => {
    axios
      .get(`${ipaddress}/StudentsBasedOnGroup/${id}`)
      .then(res => {
        console.log('StudentsBasedOnGroup', res.data);
        setStudents(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className="d-flex animate__animated animate__fadeIn">
      <Sidebar activevalue={'batches'} />
      <div className="w-100 main-div">
        <Navbar />
        <div
          className="bg-light py-3 pt-4 main-container"
          style={{ minHeight: '100vh' }}
        >
          <div className="container row m-0 d-flex justify-content-between">
            <div className="col-md-8">
              <h5 className="d-flex align-items-center">
                {' '}
                <svg
                  onClick={() => {
                    navigate('/student_batches');
                  }}
                  style={{ cursor: 'pointer' }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                  />
                </svg>{' '}
                <span className="ms-2">Groups</span>
              </h5>
            </div>
            <div
              className="col-md-4 d-flex align-items-center justify-content-end"
              style={{}}
            >
              <span className="border-end border-secondary-subtle pe-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </span>
              <span className="ps-4">
                {/* <img src={require('../images/filter.png')} alt="" /> */}
              </span>
            </div>
          </div>

          <h6
            className={`text-secondary text-center py-5 ${
              groups.length > 0 ? 'd-none' : ''
            }`}
          >
            No groups available...🧐
          </h6>
          <div
            className={`container d-flex gap-4 pt-4 row m-0 mx-auto ${
              groups.length > 0 ? '' : 'd-none'
            }`}
          >
            {groups.map(x => {
              console.log('STUDGRP', x);
              return (
                <div
                  className={`bg-white d-flex flex-column col-md-4 col-lg-5 align-items-center py-4 shadow-sm`}
                  style={{ width: '250px', borderRadius: '14px' }}
                >
                  <div className="pb-3">
                    {/* <img src={require('../images/skill_learning_logo-04-removebg-preview.png')} width={100} alt="" /> */}
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <img
                      className="rounded-circle"
                      src=""
                      width={100}
                      height={100}
                      alt=""
                    />
                    <p
                      className={`m-0 fw-medium mt-3 ${
                        x.joined ? 'd-none' : ''
                      }`}
                      style={{ color: '#000' }}
                    >
                      {x.name}
                    </p>

                    <p
                      className={`m-0 fw-medium mt-3 ${
                        x.joined ? '' : 'd-none'
                      }`}
                      style={{ color: '#000', cursor: 'pointer' }}
                      onClick={() => {
                        navigate(`/view_group/${x.id}/${id}`);
                      }}
                    >
                      {x.name}
                    </p>
                    <span
                      className="text-primary mt-1"
                      style={{ fontSize: '14px' }}
                    >
                      {x.batch_name}
                    </span>
                    <span
                      className="text-secondary mt-2 d-flex align-items-center"
                      style={{ fontSize: '12px' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-people"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                      </svg>{' '}
                      <span
                        className="ms-2"
                        style={{ fontSize: '12px' }}
                      >
                        {x.memberships.length} Members
                      </span>
                    </span>

                    <span
                      className={`text-primary text-decoration-underline mt-3 ${
                        x.joined ? 'd-none' : ''
                      }`}
                      onClick={() => {
                        join_group(x.id);
                      }}
                      style={{ cursor: 'pointer', fontSize: '13px' }}
                    >
                      Join the Group
                    </span>

                    <span
                      className={`text-primary mt-3 fw-medium ${
                        x.joined ? '' : 'd-none'
                      }`}
                      style={{ fontSize: '13px' }}
                    >
                      Joined
                    </span>
                  </div>
                  <button
                    className="tw-bg-blue-300 tw-px-2 tw-py-1 tw-mt-2 tw-rounded-md"
                    onClick={() => {
                      fetchStudents(x.id);
                    }}
                  >
                    Show Marks
                  </button>
                </div>
              );
            })}
          </div>

          <div className=" container d-flex gap-4 pt-4 tw-mt-4 row  mx-auto">
            <StudentsGrades studentsData={students?.students} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Groups;
