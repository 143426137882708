import { useEffect } from 'react';
import MainLayout from '../../Admin_panel/All-leads/CustomerAllLeads/MainLayout';
import { fetchAllB2BLeads } from '../../API/FetchFns/AllB2BLeads';
import useLeadsQueryParams from '../../Hooks/useLeadsQueryParams';
import useLeadsQuery from '../../Hooks/useLeadsQuery';
import AllFilters from '../../Components/FilterForms/AllFilters';
import AllB2BTable from '../All-leads/CustomerAllLeads/All/AllB2BTable';
import PaginatedButtons from '../All-leads/CustomerAllLeads/All/PaginatedButtons';

const ClientNewLeads = ({ settab }) => {
  useEffect(() => {
    settab('newlead');
  }, [settab]);

  const { queryParamsObj } = useLeadsQueryParams();

  const queryParams = {
    ...queryParamsObj,
    stage: 'new_leads',
  };

  const { data, error, isError, isLoading, isFetching } = useLeadsQuery({
    queryParams,
    fetchFn: fetchAllB2BLeads,
    fetchKey: 'NewB2BLeadsAdmin',
  });

  data && console.log('DATA', data?.data);
  isError && console.log(error);

  const isPrevious = Boolean(data?.data?.previous);
  const isNext = Boolean(data?.data?.next);
  return (
    <>
      <AllFilters />
      <MainLayout loading={isLoading}>
        <AllB2BTable
          leads={data?.data?.results}
          count={data?.data?.count}
        />
        <PaginatedButtons
          isFetching={isFetching}
          isNext={isNext}
          isPrevious={isPrevious}
        />
      </MainLayout>
    </>
  );
};

export default ClientNewLeads;
