import axios from 'axios';
import { ipaddress } from '../../App';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import CollectionsDetails from '../../Admin_panel/CollectionsDetails';
import MonthWiseCards from '../../Admin_panel/MonthWiseCards';

function MonthWisePredictions() {
  const todayDate = dayjs().format('YYYY-MM-DD');

  const [start_date, setstart_date] = useState(todayDate);
  const [month_wise_prediction, setmonth_wise_prediction] = useState({});
  const [year, setyear] = useState(() => new Date().getFullYear());
  const [month, setmonth] = useState(() =>
    String(new Date().getMonth() + 1).padStart(2, '0'),
  );

  const fetch_month_prediction = () => {
    console.log(
      `${ipaddress}/NewMonthWisePrediction/${start_date.slice(
        0,
        4,
      )}/?month=${start_date.slice(5, 7)}`,
    );

    axios
      .get(
        `${ipaddress}/NewMonthWisePrediction/${start_date.slice(
          0,
          4,
        )}/?month=${start_date.slice(5, 7)}`,
      )
      .then(r => {
        console.log('Month Wise Prediction', r.data);
        setmonth_wise_prediction(r.data);
      })
      .catch(err => {
        console.log(`${ipaddress}/top-employees/?month=${year}-${month}`);
      });
  };

  const [monthWiseRecords, setMonthWiseRecords] = useState();
  const [activeMonthRecords, setActiveMonthRecords] = useState();

  const fetchMonthWiseCollections = () => {
    console.log(
      `${ipaddress}/NewMonthWisePrediction/${start_date.slice(
        0,
        4,
      )}/?month=${start_date.slice(5, 7)}&type`,
    );
    axios
      .get(
        `${ipaddress}/NewMonthWisePrediction/${start_date.slice(
          0,
          4,
        )}/?month=${start_date.slice(5, 7)}&type=true`,
      )
      .then(r => {
        console.log('MonthWisePredictionRecords', r.data);
        setMonthWiseRecords(r.data);
      })
      .catch(err => {
        console.log('Employee billing data fetching error', err);
      });
  };

  useEffect(() => {
    fetch_month_prediction();
    fetchMonthWiseCollections();
  }, []);

  const MonthWiseAllFields = [
    {
      name: 'Projected Revenue',
      data: 'projected_details',
      amount: month_wise_prediction?.projected_amount, // From "projected_amount"
    },
    {
      name: 'Billing Amount',
      data: 'billing_amount',
      amount: month_wise_prediction?.billing_amount, // From "billing_amount"
    },
    {
      name: 'Registrations Amount',
      data: 'registrations_amount',
      amount: month_wise_prediction?.registrations_amount, // From "registrations_amount"
    },
    {
      name: 'New Collectables',
      data: 'new_collectables',
      amount: month_wise_prediction?.new_collectables, // From "new_collectables"
    },
    {
      name: 'New Collections',
      data: 'new_collections',
      amount: month_wise_prediction?.new_collections, // From "new_collections"
    },
    {
      name: 'New Pending Collections',
      data: 'new_pending_collections',
      amount: month_wise_prediction?.new_pending_collections, // From "new_pending_collections"
    },
    {
      name: 'Old Collectables',
      data: 'old_collectables',
      amount: month_wise_prediction?.old_collectables, // From "old_collectables"
    },
    {
      name: 'Old Collections',
      data: 'old_collections',
      amount: month_wise_prediction?.old_collections, // From "old_collections"
    },
    {
      name: 'Old Pending Collections',
      data: 'old_pending_collections',
      amount: month_wise_prediction?.old_pending_collections, // From "old_pending_collections"
    },
    {
      name: 'Outstanding Collectables',
      data: 'all_outstanding_collectables',
      amount: month_wise_prediction?.all_outstanding_collectables, // From "old_pending_collections"
    },
    {
      name: 'Next Month Collectables',
      data: 'next_month_collectables',
      amount: month_wise_prediction?.next_month_collectables, // From "next_month_collectables"
    },
    {
      name: 'Total Collections',
      data: 'total_collections',
      amount: month_wise_prediction?.total_collections, // From "total_collections"
    },
    {
      name: 'Total Net Amount',
      data: 'total_net_amount',
      amount: month_wise_prediction?.total_net_amount, // From "total_net_amount"
    },
    {
      name: 'Total Collections GST',
      data: 'total_collections_gst',
      amount: month_wise_prediction?.total_collections_gst, // From "total_collections_gst"
    },
  ];
  return (
    <div>
      <div className="row m-0 mt-4">
        <h5 className="mb-3">Monthly Financial Projections</h5>
        {MonthWiseAllFields.map((field, idx) => {
          return (
            <MonthWiseCards
              key={idx}
              activeMonthRecords={activeMonthRecords}
              recordAmount={field.amount}
              recordName={field.name}
              recordsData={field.data}
              setActiveMonthRecords={setActiveMonthRecords}
            />
          );
        })}
      </div>

      {console.log('DATACOL active', activeMonthRecords)}

      {console.log('DATACOL all', monthWiseRecords)}

      {console.log('DATACOL data', monthWiseRecords?.[activeMonthRecords])}

      {monthWiseRecords?.[activeMonthRecords] &&
        monthWiseRecords?.[activeMonthRecords].length > 0 && (
          <CollectionsDetails data={monthWiseRecords?.[activeMonthRecords]} />
        )}
    </div>
  );
}

export default MonthWisePredictions;
