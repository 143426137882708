import React, { useState, useEffect, useCallback } from 'react';
import { ipaddress } from '../../../App';
import axios from 'axios';
import TableExampleOrig from '../../../Trials/TableExample';
import EmployeeAssign from '../AssignAnyone.jsx/AssignEmployees';
import DateSelector from '../DateSelector/DateSelector';
import MainLayout from './MainLayout';
import { buildQueryString, formatDate } from '../../../utils/QueryBuilder';

const CustomerAchievedWalkins = ({ designation, settab }) => {
  settab('registered');

  const [loading, setLoading] = useState(true);

  const [leads, setLeads] = useState([]);

  const now = new Date();
  console.log('NOW', now.toISOString());

  const [selectedLeads, setSelectedLeads] = useState([]);
  const [months, setMonths] = useState({
    startDate: '',
    endDate: '',
    todayDate: formatDate(now),
  });

  const fetchAcheivedWalkins = useCallback(() => {
    setLoading(true);

    const queryParams = {
      start_date: months.todayDate ? '' : months.startDate,
      end_date: months.todayDate ? '' : months.endDate,
      date: months.startDate || months.endDate ? '' : months.todayDate,
    };

    const IPADDRESS = `${ipaddress}/AcheivedWalkinRecords/?${buildQueryString(
      queryParams,
    )}`;

    console.log('ACHIEVED WALKINS', IPADDRESS);
    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('ACHIEVED WALKINS', res.data);

        setLeads(res.data.customer_leads);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, [months]);

  useEffect(() => {
    fetchAcheivedWalkins();
  }, [fetchAcheivedWalkins, months]);

  const handleCheckboxChange = (status, id) => {
    if (status) {
      setSelectedLeads(prev => [...prev, id]);
    } else {
      setSelectedLeads(prev => prev.filter(leadId => leadId !== id));
    }
  };

  return (
    <MainLayout loading={loading}>
      <div className=" tw-flex tw-justify-between">
        <DateSelector
          months={months}
          setMonths={setMonths}
        />
        <EmployeeAssign
          selectedLeads={selectedLeads}
          setSelectedLeads={setSelectedLeads}
        />
      </div>
      <TableExampleOrig
        tableData={leads}
        handleCheckboxChange={handleCheckboxChange}
        selectedLeads={selectedLeads}
      />
    </MainLayout>
  );
};

export default CustomerAchievedWalkins;
