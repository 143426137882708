import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ipaddress } from '../App';
import { toast } from 'react-toastify';
import ForgotPassword from './LoginPage/ForgotPassword';

const LoginPage = () => {
  const [passwordType, setPasswordType] = useState(true);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${ipaddress}/Login/`, { username: userId, password })
      .then(res => {
        if (res.data && res.data.user_details) {
          sessionStorage.setItem('user', JSON.stringify(res.data.user_details));
          navigate('/dashboard');
        } else {
          toast.error('Invalid Email and Password', {
            autoClose: 2000,
            theme: 'colored',
            position: 'top-center',
          });
        }
      })
      .catch(err => {
        console.error(err);
        toast.error('Login Failed. Please try again.', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div
      className="login-page"
      style={{ height: '100vh' }}
    >
      <div className="row m-0 h-100">
        <div className="col-lg-6 login-div d-flex align-items-center justify-content-center">
          <img
            src={require('../images/SLA_logo_White 2.png')}
            style={{ width: '30%' }}
            alt="Logo"
          />
        </div>

        <div className="col-lg-6 d-flex justify-content-center align-items-center">
          <div className="login-form px-3 py-5">
            <h3
              className="text-center mb-4 fw-bold"
              style={{ color: '#002147' }}
            >
              Sign In
            </h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="fw-bold">Username</label>
                <input
                  type="text"
                  className="form-control shadow-none"
                  value={userId}
                  onChange={e => setUserId(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="fw-bold">Password</label>
                <input
                  type={passwordType ? 'password' : 'text'}
                  className="form-control shadow-none"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                />
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => setPasswordType(!passwordType)}
                >
                  {passwordType ? 'Show' : 'Hide'}
                </span>
              </div>
              <div className="text-end mb-4">
                <p
                  className="fw-bold"
                  style={{ color: '#FF845D', cursor: 'pointer' }}
                  onClick={() => setShowModal(true)}
                >
                  Forgot Password?
                </p>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Sign In'}
              </button>
            </form>
          </div>
        </div>
      </div>

      <ForgotPassword
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default LoginPage;
