import { useFormContext, useFormState } from 'react-hook-form';
import { TextField } from './FilterForms/CategoriesControls/TextField';
import { useDebounce } from '../Hooks/useDebounce';
import axios from 'axios';
import { ipaddress } from '../App';
import { useEffect } from 'react';
import { parseAsString, useQueryState } from 'nuqs';

function LeadDetailsForm({ data }) {
  const { register, watch, setError } = useFormContext();

  const { errors } = useFormState();

  const [edit] = useQueryState(
    'edit',
    parseAsString.withDefault(null), // Parses to a number or null by default
  );

  const validateMail = async emailAdd => {
    try {
      const response = await axios.get(`${ipaddress}/EmailCheck/${emailAdd}/`);

      if (response.data.message === 'mail_notexist_customerlead') {
        return true;
      } else {
        return 'This email is invalid or already taken.';
      }
    } catch (error) {
      return 'Error while validating the email.';
    }
  };

  const debouncedEmail = useDebounce(watch('email_address'), 500);

  useEffect(() => {
    if (edit === null) {
      console.log('VALIDATION', edit);
      if (!data?.email) {
        if (debouncedEmail) {
          validateMail(debouncedEmail).then(result => {
            setError('email_address', {
              type: 'validate',
              message: result,
            });
          });
        }
      }
    }
  }, [debouncedEmail, setError, edit, data]);

  return (
    <div>
      <div className="row tw-my-5">
        <div className="col">
          <TextField
            label={'Lead ID'}
            disabled
            {...register('lead_id')}
          />
        </div>
        <div className="col">
          <TextField
            isRequired={true}
            disabled={edit !== null}
            error={errors.email_address}
            label={'Email Address'}
            {...register('email_address', {
              required: 'Please Enter the Mail',
            })}
          />
        </div>
        <div className="col tw-flex tw-items-center tw-gap-x-2">
          <button
            type="button"
            className="tw-bg-purple-500 tw-rounded-lg tw-p-2"
          >
            Update Email
          </button>

          <button
            type="button"
            className="tw-bg-purple-600 tw-rounded-lg tw-p-2"
          >
            Verify OTP
          </button>
        </div>
      </div>
    </div>
  );
}

export default LeadDetailsForm;
