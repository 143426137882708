import React, { useEffect, useState } from 'react';
import Mode_of_payment_modal from './Mode_of_payment_modal';
import axios from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
import { axiosInstance } from '../API/Axios/AxiosClient';
import { calculateGstAmount } from '../utils/GetGSTAmount';

const Receipt_page = () => {
  const { token } = useParams();

  const [printmode, setprintmode] = useState(false);

  const togglePrintMode = () => {
    setprintmode(prevMode => !prevMode);
  };

  const handleprint = () => {
    window.print();
  };

  const [receiptdata, setreceiptdata] = useState({});
  const [installmentdata, setinstallmentdata] = useState([]);
  const cust_number = 'CUSF73A89E';

  const [terms, setterms] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`/ReceiptView/${token}/`)
      .then(r => {
        console.log('Receipt data', r.data);
        setreceiptdata(r.data);
      })
      .catch(err => {
        console.log('Receipt Error', err);
      });

    axiosInstance
      .get(`/TermsAndConditionsView/`)
      .then(r => {
        console.log('Terms & conditions', r.data);
        setterms(r.data);
      })
      .catch(err => {
        console.log('Terms error');
      });
  }, [token]);
  const [loading, setLoading] = useState(false); // Loading state for send_receipt

  const send_receipt = () => {
    const url = `crm.skilllearningacademy.com/receipt/${token}/`;
    setLoading(true); // Start loading
    axiosInstance
      .post(`/ReceiptView/${token}/`, { url })
      .then(r => {
        console.log('Url Shared', r.data);
        toast.success('Url shared successfully', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
      })
      .catch(err => {
        console.log('Sharing error', err);
        toast.error('Failed to share URL. Please try again.', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
      })
      .finally(() => setLoading(false)); // Stop loading
  };

  const downloadCertificate = () => {
    const input = document.getElementById('receipt');
    html2canvas(input, { scale: 2 })
      .then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('landscape', 'pt', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 10;
        const imgY = (pdfHeight - imgHeight * ratio) / 10;

        pdf.addImage(
          imgData,
          'PNG',
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio,
        );
        pdf.save('receipt.pdf');
      })
      .catch(error => {
        console.error('Error generating PDF:', error);
      });
  };

  return (
    <div className="py-2">
      {loading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}

      <div className="row bg-light m-0 pb-4 mb-4 justify-content-center">
        <div className="d-flex justify-content-center py-3">
          <button
            onClick={downloadCertificate}
            className="btn btn-primary d-flex align-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
            </svg>{' '}
            <span className="ms-2">Download</span>
          </button>
          <button
            className="tw-bg-blue-400 tw-border  tw-p-2 tw-rounded-md tw-mx-2 "
            onClick={() => {
              send_receipt();
            }}
          >
            Send
          </button>
        </div>
        <div
          className="col-md-8 bg-white pb-4"
          id="receipt"
        >
          <img
            src={require('../images/Group 27211.png')}
            alt=""
            style={{ width: '100%' }}
          />

          <div className="px-4">
            <div className="d-flex justify-content-between align-items-center mt-4 px-4">
              <div>
                <p className="m-0 font1">
                  <span className="text-secondary me-3">To</span>
                </p>
                <p className="m-0 mt-1 font1">
                  <span
                    className="text-secondary  me-3 fw-medium"
                    style={{ fontSize: '16px' }}
                  >
                    {receiptdata.customer && receiptdata.customer.customer_name}
                  </span>
                </p>
                <p className="m-0 mt-1 font1">
                  <span
                    className="text-secondary me-3"
                    style={{ fontSize: '13px' }}
                  >
                    {receiptdata.customer && receiptdata.customer.email}
                  </span>
                </p>
              </div>

              <div>
                <p className="m-0 font1 d-flex justify-content-between">
                  <span className="text-secondary">GST No </span>
                  <span className="text-dark fw-medium">
                    {receiptdata?.customer?.registration_id?.gst_number}
                  </span>
                </p>
                <p className="m-0 font1 d-flex justify-content-between">
                  <span className="text-secondary">Invoice No </span>
                  <span className="text-dark fw-medium">
                    {receiptdata.invoice_id &&
                      receiptdata.invoice_id.invoice_number}
                  </span>
                </p>
                <p className="m-0 mt-1 font1 d-flex justify-content-between">
                  <span className="text-secondary">Invoice Date </span>{' '}
                  <span className="text-dark fw-medium">
                    {receiptdata.created_at &&
                      receiptdata.created_at.slice(0, 10)}
                  </span>
                </p>
                <p className="m-0 mt-1 font1 d-flex justify-content-between">
                  <span className="text-secondary">Due Date </span>{' '}
                  <span className="text-dark fw-medium">
                    {receiptdata.invoice_id &&
                      receiptdata.invoice_id.created_at &&
                      receiptdata.invoice_id.created_at.slice(0, 10)}
                  </span>
                </p>
              </div>
            </div>

            <div className="row mt-4 m-0 px-4">
              <div className="col-md-6 ps-0">
                <div className="invoice_div rounded py-3">
                  <h6
                    className="fw-medium pb-1"
                    style={{ color: '#250F3C', fontSize: '16px' }}
                  >
                    Billed by
                  </h6>
                  <div className="tw-space-y-1">
                    <div className="tw-flex">
                      <p className="tw-w-28 tw-font-bold tw-m-0">Company :</p>
                      <p className="tw-m-0 tw-font-medium tw-text-black">
                        {receiptdata?.company_details?.company_name}
                      </p>
                    </div>
                    <div className="tw-flex">
                      <p className="tw-w-28 tw-font-bold tw-m-0">Address :</p>
                      <p className="tw-m-0 tw-font-medium tw-text-black">
                        {receiptdata?.company_details?.Area}
                      </p>
                    </div>
                    <div className="tw-flex">
                      <p className="tw-w-28 tw-font-bold tw-m-0">City :</p>
                      <p className="tw-m-0 tw-font-medium tw-text-black">
                        {receiptdata?.company_details?.city}
                      </p>
                    </div>
                    <div className="tw-flex">
                      <p className="tw-w-28 tw-font-bold tw-m-0">State :</p>
                      <p className="tw-m-0 tw-font-medium tw-text-black">
                        {receiptdata?.company_details?.state}
                      </p>
                    </div>
                    <div className="tw-flex">
                      <p className="tw-w-28 tw-font-bold tw-m-0">GST No :</p>
                      <p className="tw-m-0 tw-font-medium tw-text-black">
                        {receiptdata?.company_details?.gst_number}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* -----------------------------------------Client / Customer----------------------------------------------- */}
              <div className="col-md-6 ps-0">
                <div className="invoice_div rounded py-3">
                  <h6
                    className="fw-medium pb-1"
                    style={{ color: '#250F3C', fontSize: '16px' }}
                  >
                    Billed to
                  </h6>
                  <div className="tw-space-y-1">
                    <div className="tw-flex">
                      <p className="tw-w-24 tw-font-bold tw-m-0">Name :</p>
                      <p className="tw-m-0 tw-font-medium tw-text-black">
                        {receiptdata?.customer?.customer_name || 'N/A'}
                      </p>
                    </div>
                    <div className="tw-flex">
                      <p className="tw-w-24 tw-font-bold tw-m-0">Mobile No :</p>
                      <p className="tw-m-0 tw-font-medium tw-text-black">
                        {receiptdata?.customer?.email || 'N/A'}
                      </p>
                    </div>
                    <div className="tw-flex">
                      <p className="tw-w-24 tw-font-bold tw-m-0">City :</p>
                      <p className="tw-m-0 tw-font-medium tw-text-black">
                        {receiptdata?.customer?.registration_id?.City || 'N/A'}
                      </p>
                    </div>
                    <div className="tw-flex">
                      <p className="tw-w-24 tw-font-bold tw-m-0">State :</p>
                      <p className="tw-m-0 tw-font-medium tw-text-black">
                        {receiptdata?.customer?.registration_id?.State || 'N/A'}
                        ,{' '}
                        {receiptdata?.customer?.registration_id?.Pincode ||
                          'N/A'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 pb-1">
              <div className="table-responsive">
                <table className="table-borderless w-100">
                  <thead className="border-bottom invoice-table">
                    <tr className="">
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0"></p>
                      </th>
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">Amount</p>
                      </th>
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">Due Date</p>
                      </th>
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">Mode of Payment</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    <tr className="">
                      <th
                        scope="row"
                        className="invoice-table-cell"
                      >
                        <p className="text-center m-0">Course Installment</p>
                      </th>
                      <td
                        className="invoice-table-cell fw-medium"
                        style={{ fontSize: '16px' }}
                      >
                        <p className="text-center m-0">
                          {Math.ceil(
                            receiptdata?.fees -
                              calculateGstAmount(
                                !receiptdata?.inclusive,
                                receiptdata?.fees,
                              ),
                          )}
                        </p>
                      </td>
                      <td className="invoice-table-cell fw-medium">
                        <p className="text-center m-0">
                          {receiptdata?.invoice_id?.created_at?.slice(0, 10)}
                        </p>
                      </td>
                      <td className="invoice-table-cell fw-medium ">
                        <p className="text-center m-0 tw-uppercase ">
                          {receiptdata?.payment_mode}
                        </p>
                      </td>
                    </tr>
                    <tr className="">
                      <th
                        scope="row"
                        className="invoice-table-cell"
                      >
                        <p className="text-center m-0">GST</p>
                      </th>
                      <td
                        className="invoice-table-cell fw-medium"
                        style={{ fontSize: '16px' }}
                      >
                        <p className="text-center m-0">
                          {console.log('INCCCC', receiptdata?.inclusive)}
                          {Math.round(
                            calculateGstAmount(
                              !receiptdata?.inclusive,
                              receiptdata?.fees,
                            ),
                            1,
                          )}
                        </p>
                      </td>
                      <td className="invoice-table-cell fw-medium">
                        <p className="text-center m-0">
                          {receiptdata?.invoice_id?.created_at?.slice(0, 10)}
                        </p>
                      </td>
                      <td className="invoice-table-cell fw-medium ">
                        <p className="text-center m-0 tw-uppercase ">
                          {receiptdata?.payment_mode}
                        </p>
                      </td>
                    </tr>
                    <tr className="">
                      <th
                        scope="row"
                        className="invoice-table-cell"
                      >
                        <p className="text-center m-0">Total</p>
                      </th>
                      <td
                        className="invoice-table-cell fw-medium"
                        style={{ fontSize: '16px' }}
                      >
                        <p className="text-center m-0">{receiptdata?.fees}</p>
                      </td>
                      <td className="invoice-table-cell fw-medium">
                        <p className="text-center m-0">
                          {receiptdata?.invoice_id?.created_at?.slice(0, 10)}
                        </p>
                      </td>
                      <td className="invoice-table-cell fw-medium ">
                        <p className="text-center m-0 tw-uppercase ">
                          {receiptdata?.payment_mode}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* ---------------------------------------------------Terms and conditions--------------------------------------------- */}
            <div className=" tw-text-wrap">
              <p
                style={{ color: '#000', fontWeight: 500 }}
                className="ps-0 m-0 mb-2"
              >
                <span style={{ color: 'red' }}>*</span>Terms & Conditions
              </p>
              {terms.map((x, index) => {
                return (
                  <>
                    <p className="tw-text-justify">
                      {index + 1}){' '}
                      <span style={{ fontSize: '13px', textAlign: 'justify' }}>
                        {' '}
                        {x.terms_and_conditions}
                      </span>
                    </p>
                  </>
                );
              })}
            </div>
            <div className=" tw-text-end tw-mt-4">Signature</div>

            <div
              style={{ position: 'relative' }}
              className="mt-5"
            >
              <div
                className="d-flex justify-content-evenly px-3 py-1 align-items-center"
                style={{ backgroundColor: '#250F3C', height: '40px' }}
              >
                <p className="m-0 text-white">
                  <span className="bg-white rounded px-1 d-felx align-items-center justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      fill="#000"
                      class="bi bi-envelope"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                    </svg>
                  </span>{' '}
                  <span style={{ fontSize: '12px' }}>-</span>
                </p>
                <p className="m-0 text-white">
                  <span className="bg-white rounded px-1 d-felx align-items-center justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      fill="#000"
                      class="bi bi-geo-alt"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                    </svg>
                  </span>{' '}
                  <span style={{ fontSize: '12px' }}>-</span>
                </p>
              </div>
              <div
                className="d-flex gap-4"
                style={{ backgroundColor: '#57309C', height: '14px' }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      {/* )
        })}  */}

      {/* ------------------------------------------------Ask for help------------------------------------------------------ */}
      <div
        class="modal fade"
        id="need_help"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div className="d-flex justify-content-between px-3 mb-4 align-items-center">
                <h5 className="text-primary">Need a help</h5>
                <button
                  data-bs-dismiss="modal"
                  className="bg-transparent border-0 ms-auto"
                >
                  <i class="fa-solid fa-circle-xmark fs-5"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Mode_of_payment_modal />
    </div>
  );
};

export default Receipt_page;
