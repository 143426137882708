import CompanyLogoBlue from '../images/Templates/Logo_blue.png';
function ThankYouCard() {
  const Contacts = [
    {
      name: '+91 99452 94539',
      icon: <i class="fa-solid fa-phone fa-2xl"></i>,
    },
    {
      name: 'info@meridatechminds.com',
      icon: <i class="fa-solid fa-envelope fa-2xl"></i>,
    },
    {
      name: 'www.meridatechminds.com',
      icon: <i class="fa-solid fa-globe fa-2xl"></i>,
    },
  ];

  return (
    <div
      className="tw-py-36"
      style={{
        height: '2180px',
        width: '1500px',
        // backgroundColor: 'red',
      }}
    >
      <div className="tw-bg-red-20 tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full tw-justify-between">
        <div className=" tw-bg-green-30 tw-my-20 ">
          <img
            src={CompanyLogoBlue}
            alt=""
          />
        </div>
        <div
          className="gradient-background tw-w-full"
          style={{
            boxSizing: 'border-box',
            height: '500px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {/* Content */}
          <div
            style={{
              boxSizing: 'border-box',
              zIndex: 10,
            }}
          >
            <h1
              className="tw-mb-4"
              style={{
                boxSizing: 'border-box',
                color: '#ffffff',
                fontFamily: 'Lato, sans-serif',
                fontSize: '200px',
                lineHeight: '200px',
              }}
            >
              THANK YOU!
            </h1>
          </div>
        </div>

        <div
          className="gradient-background tw-w-[75%] tw-h-[25%]"
          style={{
            boxSizing: 'border-box',
            height: '500px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <div className="tw-h-full tw-w-full tw-bg-blue-40 tw-p-5">
            <div className="tw-flex tw-h-1/2 tw-bg-green-30 tw-justify-between tw-w-[95%] tw-mx-auto">
              <div className="tw-w-3 tw-h-full tw-bg-white tw-rounded-t-full tw-rounded-b-full" />
              <div className="tw-bg-purple-30 tw-flex ">
                <div className="tw-mt-[15%]">
                  <h1
                    style={{
                      boxSizing: 'border-box',
                      color: '#ffffff',
                      fontFamily: 'Lato, sans-serif',
                      fontSize: '42px',
                      fontWeight: 600,
                      lineHeight: '42px',
                      margin: '0px 0px 7px',
                      textAlign: 'end',
                    }}
                    className="tw-text-5xl tw-text-white tw-text-end tw-font-semibold tw-mb-2"
                  >
                    Athirupan
                  </h1>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      color: '#ffffff',
                      fontFamily: 'Lato, sans-serif',
                      fontSize: '26.25px',
                      lineHeight: '31.5px',
                      textAlign: 'end',
                    }}
                    className="tw-text-3xl tw-text-end tw-text-white"
                  >
                    Chief Executive Officer
                  </p>
                </div>
              </div>
            </div>
            <div className="tw-bg-orange-30 tw-h-1/2 tw-w-[95%] tw-mx-auto tw-flex tw-justify-between tw-mt-5">
              <div
                style={{
                  boxSizing: 'border-box',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '35px',
                  margin: '21px 0px',
                }}
                className="tw-bg-yellow-40 tw-flex tw-flex-col tw-gap-y-10 tw-justify-endd tw-my-6"
              >
                {Contacts.map((each, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{
                        alignItems: 'center',
                        boxSizing: 'border-box',
                        display: 'flex',
                        gap: '21px',
                      }}
                      className="tw-flex tw-gap-x-6 tw-text-white tw-items-center"
                    >
                      <p
                        style={{
                          boxSizing: 'border-box',
                          color: '#ffffff',
                          fontFamily: 'Lato, sans-serif',
                          fontSize: '26.25px',
                          lineHeight: '31.5px',
                        }}
                        // className="tw-text-3xl"
                      >
                        {each.icon}
                      </p>
                      <p
                        style={{
                          boxSizing: 'border-box',
                          color: '#ffffff',
                          fontFamily: 'Lato, sans-serif',
                          fontSize: '26.25px',
                          lineHeight: '31.5px',
                        }}
                        // className="tw-text-3xl"
                      >
                        {each.name}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="tw-bg-red-30 tw-text-end tw-w-[30%] tw-flex tw-flex-col tw-justify-endd tw-my-6 ">
                <div
                  //   className="tw-text-3xl tw-text-white "
                  style={{
                    boxSizing: 'border-box',
                    color: '#ffffff',
                    fontFamily: 'Lato, sans-serif',
                    fontSize: '26.25px',
                    lineHeight: '31.5px',
                    textAlign: 'end',
                  }}
                >
                  20-2 ,Keshava Krupa Building 2nd Floor, 30th cross, 10th Main
                  Rd, 4th Block, Jayanagar, Bengaluru, Karnataka 560011
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYouCard;
