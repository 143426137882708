import { useNavLoc } from '../../Hooks/useNavLoc';
import cardImg from '../../images/Icon.png';
import SectionBreak from './SectionBreak';
import SubSection from './SubSection';

function FollowupSection({ followUpsCount, name, imgCard = cardImg, navTo }) {
  const navWithLoc = useNavLoc();
  // const navWithLoc=useNavLoc()

  return (
    <div className="tw-bg-gray-100 tw-max-w-[600px] tw-rounded-lg tw-shadow-lg tw-p-4">
      <div className="tw-flex tw-items-center tw-px-4">
        <div className="tw-w-[10%] tw-flex tw-flex-col tw-items-center tw-py-4 ">
          <h2 className="tw-text-gray-500 tw-text-base tw-text-center tw-font-semibold">
            Pending Followups
          </h2>
          <img
            src={imgCard}
            width={44}
            alt="Followups Icon"
            className="tw-mt-2"
          />
        </div>

        <div className="tw-w-[100%] tw-flex tw-gap-4 tw-px-4 tw-py-2">
          <div className="tw-flex tw-justify-between tw-gap-4 tw-bg-purple-0 tw-p-3 tw-rounded-md">
            <SectionBreak />
            <SubSection
              name={'This Month'}
              count={followUpsCount?.pending?.currentMonth}
              navTo={'/all_leads/followup/?type=CurrentMonth'}
            />
            <SubSection
              name={'Previous Month'}
              count={followUpsCount?.pending?.previousMonth}
              navTo={'/all_leads/followup/?type=previousMonth'}
            />
            <SectionBreak />
            <SubSection
              name={'Total FollowUps'}
              count={
                followUpsCount?.pending?.previousMonth +
                  followUpsCount?.pending?.currentMonth || ''
              }
              navTo={'/all_leads/followup'}
            />
          </div>

          <div
            className="tw-bg-green-0 tw-p-3 tw-rounded-md tw-w-full tw-flex tw-items-center tw-justify-center tw-border-l-4 tw-border-green-40 hover:tw-bg-slate-200 tw-pointer-events-auto tw-cursor-pointer"
            onClick={() => {
              navWithLoc('/all_leads/followup/?followUpType=upcomings');
            }}
          >
            <div className="tw-bg-green-30 tw-gap-3 tw-w-[50%] tw-p-4 tw-flex tw-flex-col tw-items-center tw-justify-center">
              <h2 className="tw-text-gray-500 tw-text-base tw-text-center tw-font-semibold">
                Upcoming Followups
              </h2>
              <p className="tw-text-gray-500 tw-text-3xl tw-text-center tw-font-semibold">
                {followUpsCount?.upcomming_follows}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FollowupSection;
