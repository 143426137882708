import CompanyLogo from '../images/Templates/merida logo white 1.png';
import CurveLine from '../images/Templates/SVGS/Vector 1.svg';
import RevCurveLine from '../images/Templates/SVGS/Vector 2.svg';
import FB from '../images/Templates/Social Icons/facebook.png';
import GB from '../images/Templates/Social Icons/googlebusiness.png';
import Insta from '../images/Templates/Social Icons/instagram.png';
import YT from '../images/Templates/Social Icons/youtube.png';

import Reacty from '../images/Templates/Tech Used/react.png';
import Python from '../images/Templates/Tech Used/python.png';
import NodeJS from '../images/Templates/Tech Used/nodejs.png';
import MYSQL from '../images/Templates/Tech Used/mysql.png';
import Figma from '../images/Templates/Tech Used/figma.png';

function Template2() {
  return (
    <div
      className=""
      style={{
        height: '2212px',
        width: '1500px',
        // backgroundColor: 'aquamarine',
        // border: '1px solid #000',
      }}
    >
      <div
        className="gradient-background"
        // className="tw-relative tw-flex tw-px-16 tw-justify-between tw-p-4 gradient-background"
        style={{
          boxSizing: 'border-box',
          height: '350px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0px 75px',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div
          // className="tw-relative tw-z-10 tw-mb-3  tw-items-center tw-flex tw-border"
          // id="uniq"
          style={{
            alignItems: 'center',
            boxSizing: 'border-box',
            // display: 'flex',
            margin: '0px auto',
            position: 'relative',
            zIndex: 10,
          }}
        >
          <img
            src={CompanyLogo}
            alt=""
            width={300}
            // className="tw-mb-3"
            style={{
              boxSizing: 'border-box',
              margin: '0px 0px 10.5px',
              maxWidth: '100%',
              verticalAlign: 'middle',
            }}
          />
        </div>
      </div>

      <div className="tw-my-[35%] tw-flex tw-flex-col  tw-h-[40%] tw-justify-between tw-mx-10">
        <div className=" ">
          <h1 className="tw-mb-44 tw-text-4xl tw-w-full tw-ml-20">
            Social Media Presence
          </h1>
          <div className=" tw-bg-red-30 tw-relative">
            <img
              src={CurveLine}
              alt=""
            />
            <div className="tw-flex tw-absolute -tw-top-[100%] tw-px-28 tw-bg-blue-30 tw-justify-between tw-gap-x-10 tw-w-full">
              <div className="tw-bg-red-10 tw-py-20">
                <img
                  className="tw-bg-green tw-h-[150px] -tw-translate-y-[25%]"
                  src={FB}
                  alt=""
                />
              </div>

              <div className="tw-bg-red-30 tw-py-20 ">
                <img
                  className="tw-bg-green- tw-h-[150px] -tw-translate-y-[45%]"
                  src={Insta}
                  alt=""
                />
              </div>

              <div className="tw-bg-red-30 tw-py-20">
                <img
                  className="tw-bg-green- tw-h-[120px] -tw-translate-y-[0%]"
                  src={YT}
                  alt=""
                />
              </div>

              <div className="tw-bg-red-30 tw-py-20 ">
                <img
                  className="tw-bg-green- tw-h-[130px] -tw-translate-y-[15%]"
                  src={GB}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <h1 className="tw-mb-44 tw-text-4xl tw-w-full tw-ml-20">
            Technologies We Use
          </h1>
          <div className=" tw-bg-red-30 tw-relative">
            <img
              src={RevCurveLine}
              alt=""
            />
            <div className="tw-flex tw-absolute -tw-top-[100%] tw-px-28 tw-bg-blue-30 tw-justify-between tw-gap-x-10 tw-w-full">
              <div className="tw-bg-red-10 tw-py-20  ">
                <img
                  className="tw-bg-green- tw-h-[150px] -tw-translate-y-[5%] "
                  src={Figma}
                  alt=""
                />
              </div>
              <div className="tw-bg-red-10 tw-py-20  ">
                <img
                  className="tw-bg-green- tw-h-[150px] tw-translate-y-[5%] "
                  src={Reacty}
                  alt=""
                />
              </div>

              <div className="tw-bg-red-30 tw-py-20 ">
                <img
                  className="tw-bg-green- tw-h-[150px] -tw-translate-y-[55%]"
                  src={MYSQL}
                  alt=""
                />
              </div>

              <div className="tw-bg-red-30 tw-py-20">
                <img
                  className="tw-bg-green- tw-h-[150px] -tw-translate-y-[35%]"
                  src={NodeJS}
                  alt=""
                />
              </div>

              <div className="tw-bg-red-30 tw-py-20 ">
                <img
                  className="tw-bg-green- tw-h-[150px] -tw-translate-y-[15%]"
                  src={Python}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Template2;
