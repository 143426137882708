import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar1';
import Navbar from './Navbar1';
import axios from 'axios';
import { ipaddress2 } from '../App';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Arrow_svg from '../Svgs/Arrow_svg';

const View_coding_question = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [questions, setQuestions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [modalOpen, setModalOpen] = useState(false); // Modal visibility state
  const [selectedGroup, setSelectedGroup] = useState(null); // Selected group ID
  const [outOff, setOutOff] = useState(''); // Out of marks

  const user = JSON.parse(sessionStorage.getItem('user'));

  useEffect(() => {
    axios.get(`${ipaddress2}/QuestionsView/${id}/`).then(r => {
      console.log('Question Paper', r.data);
      setQuestions(r.data);
    });

    axios.get(`${ipaddress2}/GetGroupView/${user.emp_id}/`).then(r => {
      console.log('Groups', r.data);
      setGroups(r.data);
    });
  }, []);

  const assign_question = () => {
    axios
      .post(`${ipaddress2}/AssignTestPaperView/`, {
        group_id: Number(selectedGroup),
        testpaper_id: Number(id),
        out_off: Number(outOff),
      })
      .then(r => {
        console.log('Assigned', r.data);
        toast.success(`Question assigned successfully`, {
          autoClose: 1000,
        });
        setModalOpen(false); // Close the modal
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(err => {
        console.error(err);
        toast.error('Failed to assign question');
      });
  };

  const openModal = group_id => {
    setSelectedGroup(group_id);
    setModalOpen(true);
  };

  return (
    <div className="tw-flex">
      <Sidebar activevalue={'questions'} />
      <div className="tw-w-full">
        <Navbar />

        <div className="tw-bg-gray-100 main-container tw-p-3 tw-min-h-screen">
          <div className="tw-flex tw-justify-between tw-pb-4">
            <h6 className="tw-flex tw-items-center">
              <span
                onClick={() => {
                  navigate('/question_papers');
                }}
                className="tw-cursor-pointer"
              >
                <Arrow_svg />
              </span>
              <span className="tw-ml-2">Coding Question</span>
            </h6>

            <select
              name=""
              onChange={e => {
                openModal(e.target.value); // Open modal instead of directly assigning
              }}
              className="tw-form-select tw-w-1/4 tw-shadow-none tw-border-gray-300 tw-cursor-pointer"
            >
              <option value="">Assign Question Paper</option>
              {groups.map(x => (
                <option
                  key={x.id}
                  value={x.id}
                >
                  {x.name}
                </option>
              ))}
            </select>
          </div>

          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4">
            <div className="tw-col-span-2 tw-bg-white">
              {questions.map((x, index) => (
                <div
                  key={index}
                  className="tw-py-4 tw-border-b"
                >
                  <p className="tw-text-blue-500">Question {index + 1}</p>
                  <p className="tw-font-medium tw-break-words">{x.text}</p>
                </div>
              ))}
            </div>
            <div className="tw-col-span-1 tw-bg-white tw-flex tw-items-center tw-justify-center tw-h-80">
              {/* Add any image or content here */}
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {modalOpen && (
        <div className="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-50 tw-z-50">
          <div className="tw-bg-white tw-rounded-lg tw-p-6 tw-w-96 tw-shadow-lg">
            <h5 className="tw-mb-4 tw-text-lg tw-font-bold tw-text-gray-700">
              Enter Out of Marks
            </h5>
            <input
              type="number"
              className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded tw-mb-4 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-400"
              placeholder="Out of Marks"
              value={outOff}
              onChange={e => setOutOff(e.target.value)}
            />
            <div className="tw-flex tw-justify-end tw-space-x-2">
              <button
                className="tw-bg-gray-300 tw-text-gray-700 tw-px-4 tw-py-2 tw-rounded hover:tw-bg-gray-400"
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-blue-600"
                onClick={assign_question}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default View_coding_question;
