import { useState } from 'react';

const DateSelector = ({ months, setMonths, isRange, setIsRange }) => {
  const handleDateChange = (field, value) => {
    setMonths(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const toggleRange = () => {
    setIsRange(prev => !prev);
    // Reset fields when toggling to avoid stale data
    // setMonths({
    //   startDate: '',
    //   endDate: '',
    //   todayDate: '',
    // });
  };

  return (
    <div className="tw-bg-gray-100 tw-p-6 tw-mb-3 tw-max-w-md ">
      <div className="tw-mb-6">
        <label className="tw-inline-flex tw-text-sm tw-items-center tw-text-gray-700 tw-font-medium">
          <input
            type="checkbox"
            className="tw-mr-2 tw-w-4 tw-h-4  tw-cursor-pointer tw-accent-red-500"
            checked={isRange}
            onChange={toggleRange} // No preventDefault needed unless within a form
          />
          Select Date Range
        </label>
      </div>

      {isRange ? (
        <div className="tw-grid tw-grid-cols-2 tw-gap-4">
          <div>
            <label
              htmlFor="startMonth"
              className="tw-block tw-text-sm tw-font-semibold tw-text-gray-700"
            >
              Start Month
            </label>
            <input
              onChange={e => handleDateChange('startDate', e.target.value)}
              className="tw-mt-1 tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-text-sm tw-focus:ring-red-300 tw-focus:border-red-500"
              type="date"
              name="startMonth"
              id="startMonth"
              value={months.startDate}
            />
          </div>
          <div>
            <label
              htmlFor="endMonth"
              className="tw-block tw-text-sm tw-font-semibold tw-text-gray-700"
            >
              End Month
            </label>
            <input
              onChange={e => handleDateChange('endDate', e.target.value)}
              className="tw-mt-1 tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-text-sm tw-focus:ring-red-300 tw-focus:border-red-500"
              type="date"
              name="endMonth"
              id="endMonth"
              value={months.endDate}
            />
          </div>
        </div>
      ) : (
        <div>
          <label
            htmlFor="todayDate"
            className="tw-block tw-text-sm tw-font-semibold tw-text-gray-700"
          >
            Single Date
          </label>
          <input
            onChange={e => handleDateChange('todayDate', e.target.value)}
            className="tw-mt-1 tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-text-sm tw-focus:ring-red-300 tw-focus:border-red-500"
            type="date"
            name="todayDate"
            id="todayDate"
            value={months.todayDate}
          />
        </div>
      )}
    </div>
  );
};

export default DateSelector;
