import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import axios from 'axios';
import { ipaddress } from '../App';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Arrow_svg from '../Svgs/Arrow_svg';
import ClosedData from '../Admin_panel/All-leads/ClosedFilter/ClosedData';
import ClosedFilter from '../Admin_panel/All-leads/ClosedFilter/ClosedFilter';
import { scrollToTable } from '../utils/ScrolltoTable';
import EmployeeTodayCard from './EmployeeTodayCard';
import EmployeeAssign from '../Admin_panel/All-leads/AssignAnyone.jsx/AssignEmployees';
import TableExampleOrig from '../Trials/TableExample';
import DateFilter from './DatesFilterButton';

const EmployeeTodayLeads = () => {
  const { id } = useParams();

  const [today_leads, settoday_leads] = useState({});
  const [emp_data, setemp_data] = useState({});
  const [today_all_leads, settoday_all_leads] = useState([]);
  const [lead_type, setlead_type] = useState('today_added_leads');

  const today = new Date();
  const todaysDate = today.getDate();
  const todaysMonth = today.getMonth();
  const todaysYear = today.getFullYear();

  const todayDate = `${todaysYear}-${todaysMonth + 1}-${todaysDate}`;

  const [dateWise, setDateWise] = useState();

  useEffect(() => {
    const API = `${ipaddress}/TodayEntireRecordsOfEmployee/${id}/`;
    console.log('TODAY LEADS', API);
    axios.get(API).then(r => {
      console.log('Today leads count', r.data);
      settoday_leads(r.data);
    });

    axios.get(`${ipaddress}/MyprofileView/${id}/`).then(r => {
      console.log('Employee data', r.data);
      setemp_data(r.data);
    });
  }, [id]);

  useEffect(() => {
    fetch_leads();
  }, [lead_type, dateWise]);

  const fetch_leads = () => {
    console.log('ISTODAY', todayDate);

    const IPPADDRESS =
      todayDate === dateWise
        ? `${ipaddress}/DetailsOfRecords/${id}/?today_added_leads=${lead_type}`
        : `${ipaddress}/DetailsOfRecords/${id}/?today_added_leads=${lead_type}&date=${
            dateWise ? dateWise : todayDate
          }`;

    console.log('TODAYLEADS', IPPADDRESS);
    axios.get(IPPADDRESS).then(r => {
      // setLoading(false)
      console.log('DetailsOfRecords', IPPADDRESS);
      console.log('All today leads DetailsOfRecords', r.data);
      const data =
        r.data?.customer_data?.concat(r.data?.client_data) ||
        r.data?.customer_leads?.concat(r.data?.client_leads);
      settoday_all_leads(data);
      //   setleads(r.data.customers_data)
      //   setclient_newleads(r.data.clients_data)
    });
  };

  const navigate = useNavigate();

  const location = useLocation();

  const getDateWise = () => {
    const IPADDRESS =
      todayDate === dateWise
        ? `${ipaddress}/TodayEntireRecordsOfEmployee/${id}/`
        : `${ipaddress}/TodayEntireRecordsOfEmployee/${id}/?date=${dateWise}`;

    if (dateWise) {
      axios
        .get(IPADDRESS)
        .then(r => {
          console.log('DATEWISE DATA FROM BACKEND', r.data);
          settoday_leads(r.data);
        })
        .catch(error => {
          console.error('Error fetching today’s leads:', error);
        });

      axios
        .get(`${ipaddress}/MyprofileView/${id}/`)
        .then(r => {
          console.log('Employee data', r.data);
          setemp_data(r.data);
        })
        .catch(error => {
          console.error('Error fetching employee data:', error);
        });
    }
  };

  useEffect(() => {
    getDateWise();
  }, [dateWise]); // Trigger API call when dateWise changes

  useEffect(() => {
    if (location?.state?.dateWise) {
      console.log('LOCA', location.state.dateWise);
      setDateWise(location.state.dateWise);
    }
  }, [location?.state]);

  const [selectedLeads, setSelectedLeads] = useState([]);

  const handleCheckboxChange = (status, id) => {
    if (status) {
      setSelectedLeads(prev => [...prev, id]);
    } else {
      setSelectedLeads(prev => prev.filter(leadId => leadId !== id));
    }
  };

  const user = JSON.parse(sessionStorage.getItem('user'));

  const LeadsData = [
    {
      name: 'Added Leads',
      count: today_leads?.today_added_leads,
      lead_type_name: 'today_added_leads',
    },
    {
      name: 'Followup Leads',
      count: today_leads?.today_added_followups,
      lead_type_name: 'today_added_followups',
    },
    {
      name: 'Prospect Leads',
      count: today_leads?.today_added_prospects,
      lead_type_name: 'today_added_prospects',
    },
    {
      name: 'Walkin/Meeting Leads',
      count: today_leads?.today_walkin_leads,
      lead_type_name: 'today_walkin_leads',
    },
    {
      name: 'Closed Leads',
      count: today_leads?.today_closed_leads,
      lead_type_name: 'today_closed_leads',
    },
    {
      name: 'Old Closed Leads',
      count: today_leads?.today_old_closed_leads,
      lead_type_name: 'today_added_old_closed_leads',
    },
    {
      name: 'Registration Leads',
      count: today_leads?.registration_leads,
      lead_type_name: 'registration_leads',
    },
    {
      name: 'Today Followups',
      count: today_leads?.today_pending_followups,
      lead_type_name: 'today_pending_followups',
    },
    {
      name: 'Billing Amount',
      count: today_leads?.billing_amount,
      lead_type_name: 'billing_amount',
    },
    {
      name: 'Collection Today',
      count: today_leads?.total_collection_today,
      lead_type_name: 'total_collection_today',
    },
  ];

  return (
    <div className="d-flex">
      <Sidebar activevalue={'employees'} />
      <div className="w-100 main-div">
        <Navbar pagename={'Employees'} />
        <div
          className="p-2 p-md-3 main-container"
          style={{ backgroundColor: '#E8E8E8' }}
        >
          <h5
            className="d-flex align-items-center text-color fw-bold mb-3 fs-6"
            style={{ letterSpacing: '1px' }}
          >
            <span
              className="me-2"
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(`/employee_profile/${id}`)}
            >
              <Arrow_svg />
            </span>
            {emp_data.firstname && emp_data.firstname.toUpperCase()}'S TODAY
            PROGRESS
          </h5>

          <div className="tw-mb-2  tw-text-end tw-mr-6">
            <input
              className="tw-text-black tw-p-2 tw-mr-3 tw-border tw-rounded-lg"
              type="date"
              name="dateWise"
              id="dateWise"
              value={dateWise}
              onChange={e => {
                // console.log('DATEWISE', todayDate === e.target.value);
                console.log('LOC STATE', e.target.value, todayDate);
                setDateWise(e.target.value);
              }}
            />
          </div>
          <div
            className="bg-white p-3"
            style={{ minHeight: '100vh' }}
          >
            <div className="row m-0 ">
              {LeadsData.map((each, idx) => {
                return (
                  <EmployeeTodayCard
                    count={each.count}
                    lead_type={lead_type}
                    lead_type_name={each.lead_type_name}
                    name={each.name}
                    setlead_type={setlead_type}
                  />
                );
              })}
            </div>

            <div>
              <h6
                className={`text-color text-center ${
                  today_all_leads?.length > 0 ? 'd-none' : 'py-3'
                }`}
              >
                No Leads available...
              </h6>

              <div className=" tw-h-max tw-w-full">
                <ClosedData
                  setCloseCourseData={settoday_leads}
                  singleDate={dateWise}
                />
              </div>

              <ClosedFilter setclosedleads={settoday_leads} />

              {lead_type === 'today_closed_leads' && (
                <div className="tw-my-2  tw-border tw-w-max tw-p-2 tw-bg-zinc-200 tw-rounded-xl tw-flex">
                  <p className="tw-text-lg">Total Closed Leads:</p>
                  <span className="tw-text-lg">{today_all_leads?.length}</span>
                </div>
              )}

              <div className="">
                <div className="tw-flex tw-justify-end tw-mt-6 tw-mb-0 ">
                  <EmployeeAssign
                    selectedLeads={selectedLeads}
                    setSelectedLeads={setSelectedLeads}
                  />
                </div>
                <TableExampleOrig
                  tableData={today_all_leads}
                  handleCheckboxChange={handleCheckboxChange}
                  selectedLeads={selectedLeads}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeTodayLeads;
