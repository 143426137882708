import MainLayout from './MainLayout';
import CustomerTable from './All/CustomerAllTable';
import PaginatedButtons from './All/PaginatedButtons';
import useLeadsQuery from '../../../Hooks/useLeadsQuery';
import { useQueryState } from 'nuqs';
import { fetchAllPendingFollowupsLeads } from '../../../API/FetchFns/AllB2CLeads';
import AllFilters from '../../../Components/FilterForms/AllFilters';
import useLeadsQueryParams from '../../../Hooks/useLeadsQueryParams';
import { useEffect } from 'react';

const CustomerFollowUps = ({ settab }) => {
  useEffect(() => {
    settab('followup');
  }, [settab]);

  const { queryParamsObj } = useLeadsQueryParams();

  const [followUpType] = useQueryState('followUpType', {
    defaultValue: 'pendings',
  });

  const queryParams = {
    ...queryParamsObj,
    stage: 'followup',
    lt: followUpType === 'pendings',
  };

  const { data, error, isError, isFetching, isLoading } = useLeadsQuery({
    queryParams,
    fetchFn: fetchAllPendingFollowupsLeads,
    fetchKey: 'PendingFollowUpB2CLeadsAdmin',
  });

  data && console.log('DATAAA', data?.data);
  isError && console.log(error);

  const isPrevious = Boolean(data?.data?.previous);
  const isNext = Boolean(data?.data?.next);

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <AllFilters />
      <MainLayout loading={isLoading}>
        <CustomerTable
          leads={data?.data?.results}
          count={data?.data?.count}
        />
        <PaginatedButtons
          isFetching={isFetching}
          isNext={isNext}
          isPrevious={isPrevious}
        />
      </MainLayout>
    </div>
  );
};

export default CustomerFollowUps;
