import React, { useEffect, useState } from 'react';
import Project_navbar from './Project_navbar';
import axios from 'axios';
import { ipaddress2 } from '../App';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Preloader from '../Components/Preloader';
import { getUser } from '../utils/GetUser';
import { axiosInstance } from '../API/Axios/AxiosClient';

const Project_dashboard = () => {
  const navigate = useNavigate();

  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [loading, setloading] = useState();

  const [categories, setCategories] = useState([]);

  const user = JSON.parse(sessionStorage.getItem('user'));

  useEffect(() => {
    setloading(true);
    axiosInstance
      .get(`${ipaddress2}/CategoryChoicesView/?category=${user.id}`)
      .then(r => {
        console.log(r.data);
        console.log(getUser());
        setCategories(r.data);
        setloading(false);
      });
  }, []);

  return (
    <div>
      {loading ? (
        <Preloader />
      ) : (
        <div
          className=""
          style={{ minHeight: '100vh' }}
        >
          <div
            className=""
            style={{ height: '100vh' }}
          >
            <Project_navbar />

            <div
              className="project-main-div "
              style={{ height: '90%' }}
            ></div>
          </div>
          <div
            className="container pt-5 mt-3"
            style={{ paddingBottom: '100px' }}
          >
            {/* <div className="slider-container container">
     <Slider {...settings}>
       {categories.map((x)=>{
           return(
               <div>
                <div className="d-flex justify-content-center">
           <div class="dash-card card border border-white px-3 pt-3" style={{backgroundColor:'rgba(0,0,0,0.6)',borderRadius:'20px'}}>
 <img src={x.image} class="card-img-top bg-info" alt="..." style={{height:'170px',borderRadius:'15px'}}/>
 <div class="card-body d-flex flex-column justify-content-center">
   <h5 class="card-title text-white mt-3 text-center fw-bold">{x.category_name}</h5>
   <p class="card-text text-white text-center mt-3 pb-2" style={{height:"50px"}}>{x.description.slice(0,50)}</p>
   <div className='text-center border-top pt-3 mt-2'>
   <a onClick={()=>{
       navigate(`/view_category/${x.id}`)
   }} class="btn text-white btn-secondary rounded-circle"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
 <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></a>
   </div>
 </div>
</div>
           </div>
               </div>
           )
       })}
     </Slider>
   </div> */}

            <div
              className="row mt-5"
              style={{ minHeight: '400px', paddingTop: '30px' }}
            >
              <div className="col-1">
                <div
                  className="sub-div2 d-flex align-items-center justify-content-center"
                  style={{ borderRadius: '10px', height: '100%' }}
                >
                  <p
                    className="vertical-text fw-bold text-white"
                    style={{ letterSpacing: '1px' }}
                  >
                    Category
                  </p>
                </div>
              </div>
              <div className="col-11">
                <div className="row m-0">
                  {categories.map((x, index) => {
                    return (
                      <div
                        className={`col-md-6 mb-4 ${
                          index % 2 == 0 ? 'ps-md-0' : 'pe-md-0'
                        }`}
                      >
                        <div
                          className="sub-div d-flex align-items-center px-3"
                          style={{ position: 'relative' }}
                        >
                          {/* <img src={x.image} width={160} height={110} className='' alt="" /> */}
                          <img
                            src={require('../images/Daco_1896670 1.png')}
                            width={160}
                            height={150}
                            className=""
                            alt=""
                          />
                          <div className="ps-3 ">
                            <p
                              className="text-white fw-bold m-0"
                              style={{ fontSize: '20px' }}
                            >
                              {x.category_name && x.category_name.slice(0, 60)}
                            </p>
                            <p
                              className="text-white mt-2 m-0"
                              style={{ fontSize: '13px', lineHeight: '20px' }}
                            >
                              {x.description && x.description.slice(0, 100)}...
                            </p>
                          </div>
                          <svg
                            onClick={() => {
                              navigate(`/view_category/${x.id}`);
                            }}
                            style={{
                              position: 'absolute',
                              right: '10px',
                              bottom: '7px',
                              cursor: 'pointer',
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="white"
                            class="bi bi-arrow-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                            />
                          </svg>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Project_dashboard;
