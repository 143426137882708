import Slider from 'react-slick'; // Assuming you are using react-slick
import Slick_button_right from '../Slick_button_right';
import Slick_button_left from '../Slick_button_left';
import { useNavigate } from 'react-router-dom';

function TabSlider({ tab, setTab }) {
  const navigate = useNavigate();

  const tabs = [
    { id: 'all', label: 'All Leads', route: '/all_leads/' },
    { id: 'newleads', label: 'New Leads', route: '/all_leads/newlead' },
    { id: 'walkin', label: 'Walkin Leads', route: '/all_leads/walkin' },
    { id: 'followup', label: 'Followup Leads', route: '/all_leads/followup' },
    { id: 'prospects', label: 'Prospect Leads', route: '/all_leads/prospect' },
    {
      id: 'registered',
      label: 'Registered Leads',
      route: '/all_leads/registered',
    },
    { id: 'closed', label: 'Closed Leads', route: '/all_leads/closed' },
    {
      id: 'other',
      label: 'Other Department Leads',
      route: '/all_leads/otherdepartment',
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <Slick_button_right />,
    prevArrow: <Slick_button_left />,
    speed: 500,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider
      {...settings}
      className="px-2 mt-2 d-block d-lg-none"
    >
      {tabs.map(({ id, label, route }) => (
        <div
          key={id}
          className="p-2 text-center"
        >
          <p
            onClick={() => {
              setTab(id);
              navigate(route);
            }}
            className="py-2 px-4 justify-content-center d-flex align-items-center pb-3"
            style={{
              color: tab === id ? '#57309C' : '',
              cursor: 'pointer',
              borderBottom: tab === id ? '2px solid #57309C' : 'none',
            }}
          >
            {label}
          </p>
        </div>
      ))}
    </Slider>
  );
}

export default TabSlider;
