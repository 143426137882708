import { useState, useEffect } from 'react';
import { fetchAllLeads } from '../../API/FetchFns/AllB2CLeads';
import useLeadsQueryParams from '../../Hooks/useLeadsQueryParams';
import useLeadsQuery from '../../Hooks/useLeadsQuery';
import AllFilters from '../FilterForms/AllFilters';
import SourceWiseAll from '../../Admin_panel/All-leads/CustomerAllLeads/All/SourceWiseAll';
import MainLayout from '../../Admin_panel/All-leads/CustomerAllLeads/MainLayout';
import CustomerTable from '../../Admin_panel/All-leads/CustomerAllLeads/All/CustomerAllTable';
import PaginatedButtons from '../../Admin_panel/All-leads/CustomerAllLeads/All/PaginatedButtons';

const B2CAllLeads = ({ settab }) => {
  const USER = JSON.parse(sessionStorage.getItem('user'));

  useEffect(() => {
    settab('all');
  }, [settab]);

  const [leads, setLeads] = useState([]);

  const { queryParamsObj } = useLeadsQueryParams();

  const queryParams = {
    ...queryParamsObj,
    stage: 'all_leads',
    employee_id: USER.emp_id,
  };

  const { data, error, isError, isLoading, isFetching } = useLeadsQuery({
    queryParams,
    fetchFn: fetchAllLeads,
    fetchKey: 'AllB2CLeads',
  });

  useEffect(() => {
    setLeads(data?.data?.results);
  }, [setLeads, data]);

  data && console.log('DATA', data?.data);
  isError && console.log(error);

  const isPrevious = Boolean(data?.data?.previous);
  const isNext = Boolean(data?.data?.next);

  return (
    <>
      <AllFilters />
      <SourceWiseAll setLeads={setLeads} />
      <MainLayout loading={isLoading}>
        <CustomerTable
          leads={leads}
          count={data?.data?.count}
        />
        <PaginatedButtons
          isFetching={isFetching}
          isNext={isNext}
          isPrevious={isPrevious}
        />
      </MainLayout>
    </>
  );
};

export default B2CAllLeads;
