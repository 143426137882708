import ProfileImg from '../../../images/Group 41.png';

function OneFieldCard({ name, count }) {
  return (
    <div className="col-md-4 col-lg-3 mb-4 mb-md-0 tw-max-w-[300px]">
      <div className="d-flex align-items-center bg-white p-2 dash py-3">
        <img
          src={ProfileImg}
          width={44}
          alt=""
        />
        <div className="ms-2">
          <p className="m-0 dash_data1">{name}</p>
          <p className="m-0 dash_data d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-currency-rupee"
              viewBox="0 0 16 16"
            >
              <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
            </svg>
            {count}
          </p>
        </div>
      </div>
    </div>
  );
}

export default OneFieldCard;
