import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '../API/Axios/AxiosClient';
import { parseAsBoolean, useQueryState } from 'nuqs';
import { useNavLoc } from '../Hooks/useNavLoc';

function AllInvoices({ type }) {
  const [isGST, setIsGST] = useQueryState(
    'isGST',
    parseAsBoolean.withDefault(null),
  );

  const fetchAllInvoices = async () => {
    const response = await axiosInstance.get(
      `/FilterInvoices?inclusive=${isGST}`,
    );

    return response.data;
  };

  const { data, error, isError } = useQuery({
    queryKey: ['FetchInvoices', isGST],
    queryFn: fetchAllInvoices,
  });

  const navWithLoc = useNavLoc();

  return (
    <div>
      <div className="tw-flex tw-gap-x-3">
        <button
          className="tw-bg-blue-500 tw-p-2 tw-rounded-xl"
          type="button"
          onClick={() => {
            setIsGST(null);
          }}
        >
          All Bills
        </button>
        <button
          className="tw-bg-blue-500 tw-p-2 tw-rounded-xl"
          type="button"
          onClick={() => {
            setIsGST(true);
          }}
        >
          GST Bills
        </button>
        <button
          className="tw-bg-blue-500 tw-p-2 tw-rounded-xl"
          type="button"
          onClick={() => {
            setIsGST(false);
          }}
        >
          Non GST Bills
        </button>
      </div>
      {data && (
        <div className={`${type === 'invoices' ? '' : 'd-none'}`}>
          <table className="tw-min-w-full tw-border-collapse tw-border tw-rounded-lg tw-shadow-md">
            <thead>
              <tr className="tw-bg-gray-200">
                <th className="tw-border tw-py-2 tw-px-4 tw-font-semibold tw-text-left">
                  Name
                </th>
                <th className="tw-border tw-py-2 tw-px-4 tw-font-semibold tw-text-left">
                  Course
                </th>
                <th className="tw-border tw-py-2 tw-px-4 tw-font-semibold tw-text-left">
                  Grand Total
                </th>
                <th className="tw-border tw-py-2 tw-px-4 tw-font-semibold tw-text-left">
                  GST Amount
                </th>
                <th className="tw-border tw-py-2 tw-px-4 tw-font-semibold tw-text-left">
                  Invoice Number
                </th>
              </tr>
            </thead>
            <tbody>
              {console.log('DATA', data)}
              {data?.map((item, index) => (
                <tr
                  key={index}
                  className="tw-border-b"
                >
                  <td
                    className="tw-border tw-py-2 tw-px-4 tw-text-blue-600  tw-cursor-pointer"
                    onClick={() => {
                      console.log('ITEM', item?.customer_id?.registration_id);

                      navWithLoc(
                        `/invoice/${item?.customer?.registration_id?.customer_lead_id?.customer_lead_id}`,
                      );
                    }}
                  >
                    {item?.customer?.registration_id?.customer_lead_id?.name}
                  </td>
                  <td className="tw-border tw-py-2 tw-px-4">
                    {item?.course[0]?.course_name}
                  </td>

                  <td className="tw-border tw-py-2 tw-px-4">
                    {item.grand_total}
                  </td>
                  <td className="tw-border tw-py-2 tw-px-4">
                    {+item.grand_gst_total}
                  </td>
                  <td className="tw-border tw-py-2 tw-px-4">
                    {item?.invoice_number}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default AllInvoices;
