import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Slider from 'react-slick';
import { Chart as ChartJS } from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Terms_and_conditions from './Terms_and_conditions';

const Student_dashboard = () => {
  const [show, setshow] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);

  const navigate = useNavigate();

  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // initialSlide: 0,
    // autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [loading, setloading] = useState();

  const user = JSON.parse(sessionStorage.getItem('user'));

  const [titles, settitles] = useState([]);
  const [total_marks, settotal_marks] = useState([]);
  const [scored_marks, setscored_marks] = useState([]);

  const employeecomparision = {
    labels: titles,
    datasets: [
      {
        label: 'Total Marks',
        data: total_marks,
        fill: false,
        backgroundColor: '#1814F3',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Achieved',
        data: scored_marks,
        fill: false,
        backgroundColor: '#74C228',
        tension: 0.1,
        barThickness: 8,
      },
    ],
  };

  const [titles2, settitles2] = useState([]);
  const [total_classes, settotal_classes] = useState([]);
  const [attended_classes, setattended_classes] = useState([]);

  const attendance = {
    labels: titles2,
    datasets: [
      {
        label: 'Total Classes',
        data: total_classes,
        fill: false,
        backgroundColor: '#1814F3',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Attended Classes',
        data: attended_classes,
        fill: false,
        backgroundColor: '#74C228',
        tension: 0.1,
        barThickness: 8,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: 'bottom', // Set legend position to bottom
        labels: {
          boxWidth: 20,
          maxWidth: 350, // Adjust this value as needed
        },
      },
    },
    // Other chart options
  };

  const [value_state, setvalue_state] = useState('all');

  // To get the time in hours and minutes format
  const formatTime = timeString => {
    const [hours, minutes] = timeString.split('T')[1].split(':');
    return `${hours}:${minutes}`;
  };

  const [events, setevents] = useState([]);
  const [count, setcount] = useState(0);

  useEffect(() => {
    setloading(true);
    axios.get(`${ipaddress2}/upcomming-events/${user.id}/`).then(r => {
      console.log('Events', r.data);
      setevents(r.data);
      setloading(false);
    });
  }, [count]);

  const [load, setload] = useState();
  const [terms_status, setterms_status] = useState(false);
  const [performa_status, setperforma_status] = useState(false);

  const register_events = event_id => {
    setload(true);
    setTimeout(() => {
      complete_register(event_id);
    }, 1000);
  };

  const complete_register = event_id => {
    axios
      .post(`${ipaddress2}/register-upcoming-events/`, {
        events: event_id,
        students: user.id,
      })
      .then(r => {
        setload(false);
        setindex1(-1);
        setcount(count + 1);
        toast.success('Successfully Registered', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
      })
      .catch(err => {
        setload(false);
      });
  };

  const [index1, setindex1] = useState(-1);

  useEffect(() => {
    axios
      .get(`${ipaddress2}/student/${user.id}/test-performance/`)
      .then(r => {
        console.log('Marks graph', r.data.datasets);
        const data = r.data.datasets;

        const titles = data.map(item => item.Title);
        const totalMarks = data.map(item => item.Total_marks);
        const marksAchieved = data.map(item => item.marks_achieved);
        settitles(titles);
        settotal_marks(totalMarks);
        setscored_marks(marksAchieved);
      })
      .catch(err => {
        console.log('Students marks', err);
      });

    axios.get(`${ipaddress2}/student/${user.id}/attendance/`).then(r => {
      console.log('Attendance graph', r.data.datasets);
      const data = r.data.datasets;

      const titles = data.map(item => item.name);
      const totalMarks = data.map(item => item.Total_attendance);
      const marksAchieved = data.map(item => item.achieved_attendance);

      settitles2(titles);
      settotal_classes(totalMarks);
      setattended_classes(marksAchieved);
    });
  }, []);

  const [sessions, setsessions] = useState([]);
  const [courses, setcourses] = useState([]);
  const [count1, setcount1] = useState(0);

  useEffect(() => {
    axios.get(`${ipaddress2}/UpcomingSessionsView/${user.email}/`).then(r => {
      console.log('Sessions', r.data);
      setsessions(r.data.datasets);
    });

    axios
      .get(`${ipaddress2}/UpcomingCoursesView/?customer_id=${user.id}`)
      .then(r => {
        console.log('Upcoming Courses', r.data);
        setcourses(r.data);
      });
  }, [count1]);

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [date, setDate] = useState('');

  useEffect(() => {
    const today = new Date();

    const dayOptions = { weekday: 'long' };
    const monthOptions = { month: 'long' };
    const dateOptions = { day: 'numeric' };

    const dayName = today.toLocaleDateString('en-US', dayOptions);
    const monthName = today.toLocaleDateString('en-US', monthOptions);
    const dateNumber = today.toLocaleDateString('en-US', dateOptions);

    setDay(dayName);
    setMonth(monthName);
    setDate(dateNumber);

    fetch_student_summary();
  }, []);

  const [course_fees, setcourse_fees] = useState({});
  const [gst, setgst] = useState();

  const [load4, setload4] = useState(false);
  const [upcoming_course_id, setupcoming_course_id] = useState();
  const amount2 = 1;

  const join_course = (id, course_id) => {
    setload4(true);
    axios
      .get(`${ipaddress}/CourseFees/${course_id}`)
      .then(r => {
        console.log('Course Fees', r.data);
        setcourse_fees(r.data);
        setgst(r.data.single_shot_fees * 0.18);
        setshow3(true);
      })
      .catch(err => {
        console.log('Courses fetching Error', err);
      });
  };

  const [url2, seturl2] = useState('');

  const register = () => {
    setload2(true);
    axios
      .get(
        `${ipaddress2}/extra_course_payment/${amount2}/?course_id=${upcoming_course_id}&customer_id=${user.id}&grand_total=${course_fees.single_shot_fees}&grand_gst_total=${gst}&installment_status=${performa_status}`,
      )

      .then(r => {
        setload4(false);
        setload2(false);
        setbtn_state2(true);
        console.log('Course registered', r.data);
        seturl2(r.data.redirect_url);
        setcount1(count1 + 1);
        toast.success('Proceed with the Payment', {
          autoClose: 3000,
          theme: 'colored',
          position: 'top-center',
        });
        setcourse_fees({});
      });
  };

  const [student_summary, setstudent_summmary] = useState({});

  const fetch_student_summary = () => {
    axios.get(`${ipaddress2}/student-summary/${user.id}/`).then(r => {
      console.log('Student summary', r.data);
      setstudent_summmary(r.data);
    });
  };

  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // initialSlide: 0,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // initialSlide: 0,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [event_id, setevent_id] = useState();
  const [amount, setamount] = useState();
  const [gst_amount, setgst_amount] = useState();
  const [btn_state, setbtn_state] = useState(false);
  const [btn_state2, setbtn_state2] = useState(false);
  const [load2, setload2] = useState(false);
  const [url, seturl] = useState();
  // Register for paid events

  const generate_performa_invoice = () => {
    setload2(true);
    setTimeout(() => {
      send();
    }, 1000);
  };

  const send = () => {
    const total = Number(amount) + Number(gst_amount);
    axios
      .get(
        `${ipaddress2}/EventsPayment/${total}/?customer_id=${user.id}&event_id=${event_id}&grand_total=${amount}&grand_gst_total=${gst_amount}&acceptance_status=${terms_status}`,
      )
      .then(r => {
        console.log('Completed', r.data);
        setload2(false);
        setbtn_state(true);
        seturl(r.data.redirect_url);
      })
      .catch(err => {
        setload2(false);
        console.log('Performa generation error');
      });
  };

  const [interviews, setinterviews] = useState([]);

  useEffect(() => {
    setloading(true);
    axios.get(`${ipaddress2}/Studentinterviews/${user.id}/`).then(r => {
      const data = r.data.filter(x => x.applied_status == false);
      setinterviews(data);
      console.log('Interview details', data);
      setloading(false);
    });
  }, []);

  return (
    <div className="d-flex animate__animated animate__fadeIn">
      <Sidebar activevalue={'dashboard'} />
      <div className="w-100">
        <Navbar />
        <div
          className="main-container pt-4 px-2 px-lg-3 pb-4"
          style={{ minHeight: '100vh' }}
        >
          <div className="m-0 row">
            {/* Layout1 */}
            <div className="col-md-9 ps-md-0">
              <div
                className="row m-0 me-lg-2"
                style={{ border: '1px solid #002147' }}
              >
                <div className="col-md-8 px-5 d-flex flex-column justify-content-center">
                  <p
                    className="text-color fw-medium"
                    style={{ fontSize: '28px' }}
                  >
                    Welcome Back, {user?.student_name}!
                  </p>
                </div>
                <div className="col-md-4">
                  <img
                    src={require('../images/8609213_5853 1.png')}
                    style={{ width: '100%', height: '100%' }}
                    alt=""
                  />
                </div>
              </div>

              <div className="mt-4 row m-0">
                <div className="col-md-4 col-lg-3 ps-md-0 mb-3">
                  <div
                    className="position-relative"
                    style={{ border: '1px solid #000' }}
                  >
                    <img
                      src={require('../images/12643939_5013256.png')}
                      style={{ width: '100%' }}
                      alt=""
                    />
                    <div
                      className="py-3 text-center position-absolute w-100"
                      style={{
                        backgroundColor: 'rgba(255,255,255,1)',
                        bottom: '0px',
                        opacity: '0.9',
                      }}
                    >
                      <p className="m-0 fw-bold">Projects Enrolled</p>
                      <span
                        className="text-color fw-bold"
                        style={{ fontSize: '15px' }}
                      >
                        {student_summary?.projects_count}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-3 ps-md-0 mb-3">
                  <div
                    className="position-relative"
                    style={{ border: '1px solid #000' }}
                  >
                    <img
                      src={require('../images/11879344_Checklist.png')}
                      style={{ width: '100%' }}
                      alt=""
                    />
                    <div
                      className="py-3 text-center position-absolute w-100"
                      style={{
                        backgroundColor: 'rgba(255,255,255,1)',
                        bottom: '0px',
                        opacity: '0.9',
                      }}
                    >
                      <p className="m-0 fw-bold">Batches Joined</p>
                      <span
                        className="text-color fw-bold"
                        style={{ fontSize: '15px' }}
                      >
                        {student_summary?.batches_joined}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-3 ps-md-0 mb-3">
                  <div
                    className="position-relative"
                    style={{ border: '1px solid #000' }}
                  >
                    <img
                      src={require('../images/12643939_5013256.png')}
                      style={{ width: '100%' }}
                      alt=""
                    />
                    <div
                      className="py-3 text-center position-absolute w-100"
                      style={{
                        backgroundColor: 'rgba(255,255,255,1)',
                        bottom: '0px',
                        opacity: '0.9',
                      }}
                    >
                      <p className="m-0 fw-bold">Tests Taken</p>
                      <span
                        className="text-color fw-bold"
                        style={{ fontSize: '15px' }}
                      >
                        {student_summary?.tests_attended}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-3 ps-md-0 mb-3">
                  <div
                    className="position-relative"
                    style={{ border: '1px solid #000' }}
                  >
                    <img
                      src={require('../images/8661482_3954831.png')}
                      style={{ width: '100%' }}
                      alt=""
                    />
                    <div
                      className="py-3 text-center position-absolute w-100"
                      style={{
                        backgroundColor: 'rgba(255,255,255,1)',
                        bottom: '0px',
                        opacity: '0.9',
                      }}
                    >
                      <p className="m-0 fw-bold">Certificates Earned</p>
                      <span
                        className="text-color fw-bold"
                        style={{ fontSize: '15px' }}
                      >
                        {student_summary?.certificates_issued}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Charts and Tables */}
              {/* 
<div className="row m-0">
  <div className="col-lg-6 ps-md-0">
  <div className={`d-flex flex-column mb-5 mb-lg-0 bg-white p-3 py-3 shadow-sm mt-5`} style={{borderRadius:'10px'}}>
                  <h5 className='text-dark mb-3 mb-lg-4'>Student Mark History</h5>
                  <Line data={employeecomparision} options={options}/>
                  </div>
  </div>

  <div className="col-lg-6 pe-md-0">
  <div className={`d-flex flex-column mb-5 mb-lg-0 bg-white p-3 py-3 shadow-sm mt-3 mt-md-5`} style={{borderRadius:'10px'}}>
                  <h5 className='text-dark mb-3 mb-lg-4'>Student Attendance History</h5>
                  <Line data={attendance} options={options}/>
                  </div>
  </div>

</div> */}

              <div className={`row m-0 ${courses?.length > 0 ? '' : 'd-none'}`}>
                <div className="d-flex align-items-center justify-content-between py-3">
                  <p className="m-0 fw-bold fs-5">Upcoming Courses</p>
                </div>
                <div className="col-12 ps-md-0">
                  {courses.length > 3 ? (
                    <div className="slider-container">
                      <Slider {...settings3}>
                        {courses.map(x => {
                          return (
                            <div className="ps-md-0 p-3">
                              <div
                                class="card p-2 rounded-0"
                                style={{
                                  height: '358px',
                                  border: '0.5px solid #002147',
                                }}
                              >
                                {/* <img src={require('../images/placeholder.png')} height={170} class="card-img-top" alt="..."/> */}
                                <img
                                  src={x.image}
                                  height={160}
                                  class="card-img-top rounded-0"
                                  alt="..."
                                />
                                <div class="card-body py-0 pt-3">
                                  <h5 class="card-title fs-6 fw-bold text-color">
                                    {x.coursename}
                                  </h5>
                                  <p
                                    class="card-text text-secondary word-break"
                                    style={{ fontSize: '13px', height: '50px' }}
                                  >
                                    {x.description}
                                  </p>
                                  <p
                                    class="card-text text-color word-break d-flex align-items-center"
                                    style={{ fontSize: '13px' }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      fill="currentColor"
                                      class="bi bi-calendar"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                    </svg>
                                    <span
                                      className="ms-2"
                                      style={{ fontSize: '13px' }}
                                    >
                                      {x.date_time && x.date_time.slice(0, 10)}
                                    </span>
                                  </p>

                                  <div className="d-flex justify-content-between align-items-center border-top pt-3">
                                    <p
                                      style={{ color: '#FF2C55' }}
                                      className="m-0"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        fill="currentColor"
                                        class="bi bi-clock"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                      </svg>{' '}
                                      <span
                                        style={{
                                          fontSize: '12px',
                                          fontStyle: 'italic',
                                        }}
                                      >
                                        In Progress
                                      </span>
                                    </p>
                                    <p
                                      className="text-white py-1 px-3 fw-medium m-0"
                                      onClick={() => {
                                        setupcoming_course_id(x.id);
                                        join_course(x.id, x.course_name);
                                      }}
                                      style={{
                                        color: '#333269',
                                        backgroundColor: '#002147',
                                        fontSize: '12px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      Join Now
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  ) : (
                    <div className="row m-0">
                      {courses.map((x, index) => {
                        return (
                          <div className="col-lg-4">
                            <div
                              class="card p-2 rounded-0"
                              style={{
                                height: '358px',
                                border: '0.5px solid #002147',
                              }}
                            >
                              {/* <img src={require('../images/placeholder.png')} height={170} class="card-img-top" alt="..."/> */}
                              <img
                                src={x.image}
                                height={160}
                                class="card-img-top rounded-0"
                                alt="..."
                              />
                              <div class="card-body py-0 pt-3">
                                <h5 class="card-title fs-6 fw-bold text-color">
                                  {x.coursename}
                                </h5>
                                <p
                                  class="card-text text-secondary word-break"
                                  style={{ fontSize: '13px', height: '50px' }}
                                >
                                  {x.description}
                                </p>
                                <p
                                  class="card-text text-color word-break d-flex align-items-center"
                                  style={{ fontSize: '13px' }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    fill="currentColor"
                                    class="bi bi-calendar"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                  </svg>
                                  <span
                                    className="ms-2"
                                    style={{ fontSize: '13px' }}
                                  >
                                    {x.date_time && x.date_time.slice(0, 10)}
                                  </span>
                                </p>

                                <div className="d-flex justify-content-between align-items-center border-top pt-3">
                                  <p
                                    style={{ color: '#FF2C55' }}
                                    className="m-0"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="currentColor"
                                      class="bi bi-clock"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                    </svg>{' '}
                                    <span
                                      style={{
                                        fontSize: '12px',
                                        fontStyle: 'italic',
                                      }}
                                    >
                                      In Progress
                                    </span>
                                  </p>
                                  <p
                                    className="text-white py-1 px-3 fw-medium m-0"
                                    onClick={() => {
                                      setupcoming_course_id(x.id);
                                      join_course(x.id, x.course_name);
                                    }}
                                    style={{
                                      color: '#333269',
                                      backgroundColor: '#002147',
                                      fontSize: '12px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Join Now
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>

              <div
                className={`row m-0 mt-2 ${events.length > 0 ? '' : 'd-none'}`}
              >
                <div className="d-flex align-items-center justify-content-between py-3">
                  <p className="m-0 fw-medium fs-5">Upcoming Events</p>
                  {/* <img src={require('../images/Line 184.png')} style={{width:'70%'}} alt="" /> */}
                  <p
                    className="fw-medium m-0"
                    style={{ color: '#57309C', cursor: 'pointer' }}
                    onClick={() => {
                      navigate('/view_events');
                    }}
                  >
                    View All
                  </p>
                </div>

                {events.length > 3 ? (
                  <div className="slider-container">
                    <Slider {...settings2}>
                      {events.map((x, index) => {
                        return (
                          <div className="p-2">
                            <div
                              class="card p-2 shadow-sm mb-4 mt-1 w-100 rounded-0"
                              style={{
                                height: '300px',
                                border: '1px solid #002147',
                              }}
                            >
                              <img
                                src={x.image}
                                style={{ height: '140px' }}
                                class="card-img-top rounded-0"
                                alt="..."
                              />
                              <div class="card-body pb-0">
                                <h5 class="card-title m-0 fs-6 fw-bold">
                                  <a
                                    href={x.link}
                                    target="_blank"
                                    style={{
                                      height: '42px',
                                      cursor: 'pointer',
                                    }}
                                    className="text-decoration-none text-color"
                                  >
                                    {x.description &&
                                      x.description.slice(0, 40)}
                                  </a>
                                </h5>

                                <div className="d-flex align-items-center justify-content-between mt-3">
                                  <p
                                    className="d-flex align-items-center"
                                    style={{ color: '#002147' }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      fill="currentColor"
                                      class="bi bi-clock"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                    </svg>{' '}
                                    <span
                                      style={{ fontSize: '13px' }}
                                      className="ms-2"
                                    >
                                      {formatTime(x.date_time)}
                                    </span>
                                  </p>
                                  <p
                                    className="d-flex align-items-center"
                                    style={{ color: '#002147' }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      fill="currentColor"
                                      class="bi bi-calendar"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                    </svg>{' '}
                                    <span
                                      style={{ fontSize: '13px' }}
                                      className="ms-2"
                                    >
                                      {x.date_time && x.date_time.slice(0, 10)}
                                    </span>
                                  </p>
                                </div>

                                <div className="text-center mt-3">
                                  <a
                                    class={`btn w-100 fw-medium rounded-0 btns text-white ${
                                      x.free_status === 'free' ? '' : 'd-none'
                                    }`}
                                    style={{
                                      border: '1px solid #57309C',
                                      color: '#57309C',
                                    }}
                                    onClick={() => {
                                      setindex1(index);
                                      register_events(x.id);
                                    }}
                                  >
                                    <span
                                      class={`spinner-border spinner-border-sm ${
                                        load && index === index1 ? '' : 'd-none'
                                      }`}
                                      aria-hidden="true"
                                    ></span>
                                    <span
                                      className={`${
                                        load && index === index1
                                          ? 'ms-2'
                                          : 'd-none'
                                      }`}
                                      role="status"
                                    >
                                      Registering...
                                    </span>
                                    <span
                                      className={`${
                                        load && index === index1 ? 'd-none' : ''
                                      }`}
                                    >
                                      Register
                                    </span>
                                  </a>

                                  <a
                                    class={`btn w-100 fw-medium rounded-0 btns text-white ${
                                      x.free_status === 'cost' ? '' : 'd-none'
                                    }`}
                                    style={{
                                      border: '1px solid #57309C',
                                      color: '#57309C',
                                    }}
                                    onClick={() => {
                                      setamount(x.fees);
                                      setgst_amount(x.gst_total);
                                      setevent_id(x.id);
                                      setshow(true);
                                    }}
                                  >
                                    <span
                                      className={`${
                                        load && index === index1 ? 'd-none' : ''
                                      }`}
                                    >
                                      Register
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                ) : (
                  <div className="m-0 row">
                    {events.map((x, index) => {
                      return (
                        <div
                          className={`col-md-6 col-lg-4 ${
                            index === 0 ? 'ps-md-0' : ''
                          }`}
                        >
                          <div
                            class="card p-2 shadow-sm mb-4 mt-1 w-100 rounded-0"
                            style={{
                              height: '300px',
                              border: '1px solid #002147',
                            }}
                          >
                            <img
                              src={x.image}
                              style={{ height: '140px' }}
                              class="card-img-top rounded-0"
                              alt="..."
                            />
                            <div class="card-body pb-0">
                              <h5 class="card-title m-0 fs-6 fw-bold">
                                <a
                                  href={x.link}
                                  target="_blank"
                                  style={{ height: '42px', cursor: 'pointer' }}
                                  className="text-decoration-none text-color"
                                >
                                  {x.description && x.description.slice(0, 40)}
                                </a>
                              </h5>

                              <div className="d-flex align-items-center justify-content-between mt-3">
                                <p
                                  className="d-flex align-items-center"
                                  style={{ color: '#002147' }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    fill="currentColor"
                                    class="bi bi-clock"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                  </svg>{' '}
                                  <span
                                    style={{ fontSize: '13px' }}
                                    className="ms-2"
                                  >
                                    {formatTime(x.date_time)}
                                  </span>
                                </p>
                                <p
                                  className="d-flex align-items-center"
                                  style={{ color: '#002147' }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    fill="currentColor"
                                    class="bi bi-calendar"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                  </svg>{' '}
                                  <span
                                    style={{ fontSize: '13px' }}
                                    className="ms-2"
                                  >
                                    {x.date_time && x.date_time.slice(0, 10)}
                                  </span>
                                </p>
                              </div>

                              <div className="text-center mt-3">
                                <a
                                  class={`btn w-100 fw-medium rounded-0 btns text-white ${
                                    x.free_status === 'free' ? '' : 'd-none'
                                  }`}
                                  onClick={() => {
                                    setindex1(index);
                                    register_events(x.id);
                                  }}
                                >
                                  <span
                                    class={`spinner-border spinner-border-sm ${
                                      load && index === index1 ? '' : 'd-none'
                                    }`}
                                    aria-hidden="true"
                                  ></span>
                                  <span
                                    className={`${
                                      load && index === index1
                                        ? 'ms-2'
                                        : 'd-none'
                                    }`}
                                    role="status"
                                  >
                                    Registering...
                                  </span>
                                  <span
                                    className={`${
                                      load && index === index1 ? 'd-none' : ''
                                    }`}
                                  >
                                    Register
                                  </span>
                                </a>

                                <a
                                  class={`btn w-100 fw-medium rounded-0 btns text-white ${
                                    x.free_status === 'cost' ? '' : 'd-none'
                                  }`}
                                  onClick={() => {
                                    setamount(x.fees);
                                    setgst_amount(x.gst_total);
                                    setevent_id(x.id);
                                    setshow(true);
                                  }}
                                >
                                  <span
                                    className={`${
                                      load && index === index1 ? 'd-none' : ''
                                    }`}
                                  >
                                    Register
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            {/* Layout2 */}
            <div className="col-md-3 pe-md-0 p-0">
              <div
                className="bg-white p-3"
                style={{ border: '1px solid #002147' }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="m-0">Upcoming Events</h6>
                  <p
                    className="text-decoration-underline m-0"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate('/view_events');
                    }}
                  >
                    View all
                  </p>
                </div>

                {events.map(x => {
                  return (
                    <div
                      className="d-flex p-2 mt-3"
                      style={{ backgroundColor: '#EDEDED' }}
                    >
                      <img
                        src={x.image}
                        width={50}
                        className="rounded"
                        alt=""
                      />
                      <div className="ms-3">
                        <p className="m-0 fw-bold">
                          <a
                            href={x.link}
                            target="_blank"
                            className="text-decoration-none text-color"
                            style={{ cursor: 'pointer' }}
                          >
                            {x.description && x.description.slice(0, 26)}
                          </a>
                        </p>
                        <p className="d-flex align-items-center mt-1 m-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="gray"
                            class="bi bi-calendar"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                          </svg>{' '}
                          <span
                            style={{ fontSize: '13px' }}
                            className="ms-2 text-color"
                          >
                            {x.date_time && x.date_time.slice(0, 10)}
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* Interview Detais */}
              <div
                className="bg-white p-3 mt-4"
                style={{ border: '1px solid #002147' }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="m-0">Job Openings</h6>
                  <p
                    className="text-decoration-underline m-0"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate('/interview_details');
                    }}
                  >
                    View all
                  </p>
                </div>

                {interviews.map(x => {
                  return (
                    <div
                      className="p-2 mt-3"
                      style={{ backgroundColor: '#EDEDED', cursor: 'pointer' }}
                      onClick={() => {
                        navigate('/interview_details');
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={require('../images/Ellipse 8.png')}
                          width={40}
                          className="rounded"
                          alt=""
                        />
                        <div className="ms-3">
                          <p
                            className="fw-bold m-0 text-color"
                            style={{ fontSize: '14px' }}
                          >
                            {x.position}
                          </p>
                          <p
                            className="text-color mt-1 m-0"
                            style={{ fontSize: '12px' }}
                          >
                            {x.interview_date && x.interview_date.slice(0, 10)}
                          </p>
                        </div>
                      </div>
                      <p
                        className="mt-3 ps-2 text-color m-0 mb-1"
                        style={{ fontSize: '13px' }}
                      >
                        {x.jd && x.jd.slice(0, 30)}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Course registration modal */}
      <Modal
        show={show3}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="fs-5"
          >
            Register for Course
          </Modal.Title>
          <svg
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setshow3(false);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
          </svg>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-0">
            <div className="col-lg-6 mb-4">
              <label
                htmlFor=""
                className="fw-medium"
              >
                Course Name
              </label>
              <input
                type="text"
                value={course_fees.course_name}
                disabled="true"
                className="form-control py-2 mt-2 bg-light"
              />
            </div>
            <div className="col-lg-6 mb-4">
              <label
                htmlFor=""
                className="fw-medium"
              >
                Course Duration
              </label>
              <input
                type="text"
                value={course_fees.course_duration}
                disabled="true"
                className="form-control py-2 mt-2 bg-light"
              />
            </div>
            <div className="col-lg-6 mb-4">
              <label
                htmlFor=""
                className="fw-medium"
              >
                Single shot Fees Amount
              </label>
              <input
                type="number"
                value={course_fees.single_shot_fees}
                disabled="true"
                className="form-control py-2 mt-2 bg-light"
              />
            </div>
            <div className="col-lg-6 mb-4">
              <label
                htmlFor=""
                className="fw-medium"
              >
                Initial Payment Amount
              </label>
              <input
                type="number"
                value={amount2}
                disabled="true"
                className="form-control py-2 mt-2 bg-light"
              />
            </div>
            {/* <div className="col-lg-6 mb-4">
          <label htmlFor="" className='fw-medium'>GST Amount</label>
          <input type="number" value={gst} disabled='true' className='form-control py-2 mt-2 bg-light' />
        </div> */}
            <div className="col-lg-6 mb-4">
              <label
                htmlFor=""
                className="fw-medium"
              >
                Installment Fees Amount
              </label>
              <input
                type="number"
                value={course_fees.installment_fees}
                disabled="true"
                className="form-control py-2 mt-2 bg-light"
              />
            </div>

            {course_fees.installments &&
              course_fees.installments.map((x, index) => {
                return (
                  <div className="col-lg-6 mb-4">
                    <label
                      htmlFor=""
                      className="fw-medium"
                    >
                      Installment {index + 1}
                    </label>
                    <input
                      type="number"
                      value={x.amount}
                      disabled="true"
                      className="form-control py-2 mt-2 bg-light"
                    />
                  </div>
                );
              })}

            <p className="">
              <svg
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setperforma_status(true);
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                fill="currentColor"
                class={`bi bi-check-square ${performa_status ? 'd-none' : ''}`}
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
              </svg>
              <svg
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setperforma_status(false);
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                fill="#0B5ED7"
                class={`bi bi-check-square-fill ${
                  performa_status ? '' : 'd-none'
                }`}
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
              </svg>
              <span
                className="ms-2"
                style={{ fontSize: '13px' }}
              >
                Pay through Installment
              </span>
            </p>

            <div className="d-flex justify-content-end mt-4">
              <button
                onClick={register}
                className={`btn btn-primary px-4 ${btn_state2 ? 'd-none' : ''}`}
              >
                <span
                  class={`spinner-border spinner-border-sm ${
                    load2 ? '' : 'd-none'
                  }`}
                  aria-hidden="true"
                ></span>
                <span
                  className={`${load2 ? 'ms-2' : 'd-none'}`}
                  role="status"
                >
                  Proceeding...
                </span>
                <span className={`${load2 ? 'd-none' : ''}`}>
                  <span className="ms-2">Proceed</span>
                </span>
              </button>

              <a
                href={url2 && url2}
                target="_self"
                onClick={() => {
                  setshow3(false);
                }}
                className={`btn btn-primary px-4 ${btn_state2 ? '' : 'd-none'}`}
              >
                <span
                  class={`spinner-border spinner-border-sm ${
                    load2 ? '' : 'd-none'
                  }`}
                  aria-hidden="true"
                ></span>
                <span
                  className={`${load2 ? 'ms-2' : 'd-none'}`}
                  role="status"
                >
                  Proceeding...
                </span>
                <span className={`${load2 ? 'd-none' : ''}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-paypal"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.35.35 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91q.57-.403.993-1.005a4.94 4.94 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.7 2.7 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.7.7 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016q.326.186.548.438c.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.87.87 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.35.35 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32z" />
                  </svg>
                  <span className="ms-2">Pay Now</span>
                </span>
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Paid events registration modal */}
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="fs-5"
          >
            Register for Event
          </Modal.Title>
          <svg
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setshow(false);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
          </svg>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-0">
            <div className="col-12">
              <label
                htmlFor=""
                className="fw-medium"
              >
                Amount need to pay
              </label>
              <input
                type="number"
                value={amount}
                disabled="true"
                className="form-control py-2 mt-2 bg-light"
              />
            </div>
            <div className="col-12 mt-4">
              <label
                htmlFor=""
                className="fw-medium"
              >
                Gst Total
              </label>
              <input
                type="number"
                value={gst_amount}
                disabled="true"
                className="form-control py-2 mt-2 bg-light"
              />
            </div>
            <p className="mt-3">
              <svg
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setterms_status(true);
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                fill="currentColor"
                class={`bi bi-check-square ${terms_status ? 'd-none' : ''}`}
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
              </svg>
              <svg
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setterms_status(false);
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                fill="#0B5ED7"
                class={`bi bi-check-square-fill ${
                  terms_status ? '' : 'd-none'
                }`}
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
              </svg>
              <span
                className="ms-2"
                style={{ fontSize: '13px' }}
              >
                Agree to our{' '}
                <span
                  className="text-primary text-decoration-underline"
                  style={{ fontSize: '13px', cursor: 'pointer' }}
                  onClick={() => {
                    setshow2(true);
                    setshow(false);
                  }}
                >
                  Terms & Conditions
                </span>
              </span>
            </p>
            <div className="d-flex justify-content-end mt-4">
              <button
                onClick={generate_performa_invoice}
                disabled={terms_status ? false : true}
                className={`btn btn-primary px-4 ${btn_state ? 'd-none' : ''}`}
              >
                <span
                  class={`spinner-border spinner-border-sm ${
                    load2 ? '' : 'd-none'
                  }`}
                  aria-hidden="true"
                ></span>
                <span
                  className={`${load2 ? 'ms-2' : 'd-none'}`}
                  role="status"
                >
                  Proceeding...
                </span>
                <span className={`${load2 ? 'd-none' : ''}`}>
                  <span className="ms-2">Proceed</span>
                </span>
              </button>

              <a
                href={url && url}
                target="_self"
                onClick={generate_performa_invoice}
                disabled={terms_status ? false : true}
                className={`btn btn-primary px-4 ${btn_state ? '' : 'd-none'}`}
              >
                <span
                  class={`spinner-border spinner-border-sm ${
                    load2 ? '' : 'd-none'
                  }`}
                  aria-hidden="true"
                ></span>
                <span
                  className={`${load2 ? 'ms-2' : 'd-none'}`}
                  role="status"
                >
                  Proceeding...
                </span>
                <span className={`${load2 ? 'd-none' : ''}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-paypal"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.35.35 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91q.57-.403.993-1.005a4.94 4.94 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.7 2.7 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.7.7 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016q.326.186.548.438c.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.87.87 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.35.35 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32z" />
                  </svg>
                  <span className="ms-2">Pay Now</span>
                </span>
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Terms_and_conditions
        setshow2={setshow2}
        show2={show2}
        setshow={setshow}
      />
    </div>
  );
};

export default Student_dashboard;
