import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

function EditButton({ rowData }) {
  const navigate = useNavigate();
  const location = useLocation();
  const renderTooltip4 = value => (
    <Tooltip id="button-tooltip">{value}</Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip4('Edit')}
    >
      <button
        onClick={() => {
          if (rowData.customer_lead_id) {
            navigate(`/edit_customer_lead/${rowData.customer_lead_id}`, {
              state: {
                from: `${location.pathname}`,
              },
            });
          }

          if (rowData.client_lead_id) {
            navigate(`/edit_client_lead/${rowData.client_lead_id}`, {
              state: {
                from: `${location.pathname}`,
              },
            });
          }
        }}
      >
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    </OverlayTrigger>
  );
}

export default EditButton;
