import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import Trial from './Trial';
import Template2 from './Template2';
import TermsAndConditionsTemplate from './Terms&ConditionTemplate';
import QuoteBillTemplate from './QuoteBillTemplate';
import ThankYouCard from './ThankYouCard';
import { useQuery } from '@tanstack/react-query';
import { fetchReceipt } from '../API/FetchFns/Billings';
import { useParams } from 'react-router-dom';

const options = {
  // default is `save`
  method: 'open',
  // default is Resolution.MEDIUM = 3, which should be enough, higher values
  // increases the image quality but also the size of the PDF, so be careful
  // using values higher than 10 when having multiple pages generated, it
  // might cause the page to crash or hang.
  resolution: Resolution.MEDIUM,
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: Margin.SMALL,
    // default is 'A4'
    format: 'A4',
    // default is 'portrait'
    // orientation: 'landscape',
    orientation: 'portrait',
  },
  canvas: {
    // default is 'image/jpeg' for better size performance
    mimeType: 'image/jpeg',
    qualityRatio: 1,
  },
  // Customize any value passed to the jsPDF instance and html2canvas
  // function. You probably will not need this and things can break,
  // so use with caution.
  overrides: {
    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
    pdf: {
      compress: false,
    },
    // see https://html2canvas.hertzen.com/configuration for more options
    canvas: {
      useCORS: true,
    },
  },
};

// you can use a function to return the target element besides using React refs
const getTargetElement = () => document.getElementById('content-id');

export const MyPDFComp = () => {
  const { token } = useParams();

  const { data, isFetching, error, isError } = useQuery({
    queryKey: ['CustomerReceipt'],
    queryFn: () => fetchReceipt(token),
  });

  data && console.log('DATAA', data.data);

  return (
    <div>
      <button
        className="tw-bg-blue-400 tw-px-2 tw-py-1 tw-m-4"
        onClick={() => generatePDF(getTargetElement, options)}
      >
        Generate PDF
      </button>
      <div id="content-id">
        <Trial customerData={data?.data?.customer} />
        <Template2 />
        <QuoteBillTemplate />
        <TermsAndConditionsTemplate />
        <ThankYouCard />
      </div>
    </div>
  );
};
