import { useNavigate } from 'react-router-dom';

function LeadsDropdown({ tab, setTab }) {
  const navigate = useNavigate();

  const leadtypes = [
    {
      name: 'All Leads',
      type: '',
    },
    {
      name: 'New Leads',
      type: 'newlead',
    },
    {
      name: 'Walkin/Meetings Leads',
      type: 'walkin',
    },
    {
      name: 'Referred Leads',
      type: 'referred',
    },
    {
      name: 'Followup Leads',
      type: 'followup',
    },
    {
      name: 'Prospect Leads',
      type: 'prospect',
    },
    {
      name: 'Registered Leads',
      type: 'registered',
    },
    {
      name: 'Closed Leads',
      type: 'closed',
    },
    {
      name: 'Other Department Leads',
      type: 'otherdepartment',
    },
  ];

  const navigation = value => {
    const data = leadtypes.find(x => x.type === value);
    navigate(`/all_leads/${data.type}`);
    setTab(value);
  };
  return (
    <div className="row m-0 mb-3 tw-bg-green-40">
      <div className="col-lg-2 ps-md-4 col-md-6 ms-auto pe-md-0">
        <select
          name=""
          onChange={e => {
            console.log('this nav', e.target.value);
            navigation(e.target.value);
          }}
          value={tab}
          className="form-control text-white fw-bold shadow-none text-center"
          id=""
          style={{
            backgroundColor: '#002147',
            cursor: 'pointer',
            border: '1px solid #002147',
          }}
        >
          {leadtypes.map(x => {
            return (
              <>
                <option
                  style={{ lineHeight: '2.5em', padding: '10px' }}
                  value={x.type}
                >
                  {x.name}
                </option>
              </>
            );
          })}
        </select>
      </div>
    </div>
  );
}

export default LeadsDropdown;
