import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ipaddress } from '../App';
import { Modal } from 'react-bootstrap';

const Terms_and_conditions = ({ show2, setshow2, setshow }) => {
  const [terms, setterms] = useState([]);

  useEffect(() => {
    axios
      .get(`${ipaddress}/TermsAndConditionsView/`)
      .then(r => {
        console.log('Terms & conditions', r.data);
        setterms(r.data);
      })
      .catch(err => {
        console.log('Terms error');
      });
  }, []);
  return (
    <div>
      {/* Terms and conditions */}
      <Modal
        show={show2}
        onHide={() => {
          setshow2(false);
        }}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <Modal.Title className="text-success fs-5">
            Terms and Conditions
          </Modal.Title>
          <svg
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setshow2(false);
              setshow(true);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
          </svg>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            {terms.map((x, index) => {
              return (
                <div>
                  <p>
                    <span className="fw-bold">{index + 1}) </span>
                    {x.terms_and_conditions}
                  </p>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Terms_and_conditions;
