/* eslint-disable react/jsx-pascal-case */
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '../API/Axios/AxiosClient';
import PaymentForm from './PaymentForm';
import LeadDetailsForm from './LeadDetailsForm';
import FormHeader from './FormHeader';
import BatchDetailsForm from './BatchDetailsForm';
import { TextAreaField } from './FilterForms/CategoriesControls/TextAreaField';
import { getUser } from '../utils/GetUser';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ipaddress } from '../App';
import GSTDetailsForm from './GSTDetailsForm';
import { parseAsString, useQueryState } from 'nuqs';

function MarkAsRegister() {
  const { id } = useParams();

  const [edit, setEdit] = useQueryState(
    'edit',
    parseAsString.withDefault(null), // Parses to a number or null by default
  );

  const navigate = useNavigate();

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      lead_id: id,
      email_address: '',
      course_enquiredd: '',
      course_duration: '',
      batch_type: '',
      class_types: '',
      batch_start_time: '',
      batch_end_time: '',
      tentative_start_date: '',
      customerGST: '',
      gstNumber: '',
      companyName: '',
      companyAddress: '',
      initial_payment: 5000,
      gstExclusive: false,
      payThroughInstallments: false,
      course_fees: '',
      remainingTotal: '',
      remarks: '',
      noOfInstallments: '',
      installments: [{ amount: '', dueDate: '' }],
      dueDate: '',
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const SubmitForm = formData => {
    const registerationData = transformData(formData);
    console.log('TRANS DATA', registerationData);

    console.log('ERRORS', errors);

    if (edit !== null) {
      axiosInstance
        .patch(`/PerformaInvoiceAcceptStatus?id=${+edit}`, registerationData)
        .then(res => {
          console.log(res.data);
        })
        .catch(err => console.log(err));
    } else {
      if (
        +registerationData.course_fees ===
          +data?.course_amount?.single_shot_fees ||
        +registerationData.course_fees ===
          +data?.course_amount?.installment_fees
      ) {
        console.log('REGIST', { ...registerationData });
        axios
          .post(`${ipaddress}/MovetoRegistration/`, {
            ...registerationData,
          })
          .then(r => {
            console.log('Regstration Completed', r.data);
            toast.success('Successfully Registered', {
              autoClose: 1000,
              theme: 'colored',
              position: 'top-center',
            });

            setTimeout(() => {
              if (id.slice(0, 3) === 'CLI') {
                navigate('/leads');
              } else {
                navigate('/customerleads');
              }
            }, 2000);
          })
          .catch(err => {
            console.log('Error', err);
          });
      } else {
        console.log('DUMMY');
        axios
          .post(`${ipaddress}/Dummy/`, registerationData)
          .then(r => {
            console.log('Success', r.data);
            toast.success('Data sent to admin', {
              autoClose: 1000,
              theme: 'colored',
              position: 'top-center',
            });

            setTimeout(() => {
              if (id.slice(0, 3) === 'CLI') {
                navigate('/leads');
              } else {
                navigate('/customerleads');
              }
            }, 2000);
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  };

  const transformData = formData => {
    const transformedData = {
      emp_id: getUser(),
      customer_lead_id: id.startsWith('CUS') ? id : '',
      client_lead_id: id.startsWith('CLI') ? id : '',
      email: formData.email_address,
      Remarks: formData.remarks,
      Course: formData.course_enquiredd,
      Course_Duration: formData.course_duration,
      Batch_Type: formData.batch_type,
      Class_Type: formData.class_type,
      Batch_Start_Time: formData.batch_start_time,
      Batch_End_Time: formData.batch_end_time,
      Tentative_Start_Date: formData.tentative_start_date,

      level_lead: 'registered',

      installment_status: formData.payThroughInstallments,

      initial_payment: parseInt(formData.initial_payment, 10),

      form_url: 'https://crmclient.skilllearningacademy.com/registration_form',

      performa_url:
        'https://crmclient.skilllearningacademy.com/performa_invoice',

      installment_number: formData.payThroughInstallments
        ? formData.noOfInstallments
        : undefined,

      course_fees: formData.course_fees,

      grand_total: formData.remainingTotal,

      // grand_total: formData.grandTotal,

      grand_gst_total: formData.gstAmount,

      // installment_dates: formData.payThroughInstallments
      //   ? formData.installments.map(installment => installment.dueDate)
      //   : undefined,

      installment_days: formData.payThroughInstallments
        ? formData.noOfInstallments
        : undefined,

      // installments: formData.payThroughInstallments
      //   ? formData.installments.map(installment => installment.amount)
      //   : undefined,

      installment_list: formData.payThroughInstallments
        ? formData.installments.map(installment => ({
            fees: installment.amount,
            due_date: installment.dueDate,
          }))
        : undefined,

      gstInclusive: !formData.gstExclusive,
      due_date: formData.dueDate,
      gst_number: formData.gstNumber,
      company: formData.companyName,
      company_address: formData.companyAddress,
    };

    return transformedData;
  };

  const fetchLeadData = () =>
    axiosInstance
      .get(`/LeadDetailsView/${id}/`)
      .then(r => {
        console.log('Particular Lead Data fetched HARI', r.data);
        return r.data;
      })
      .catch(err => {
        console.log('Particular lead details fetching Error', err);
      });

  const fetchPerformaInvoiceData = () =>
    axiosInstance
      .get(`/PerformaInvoiceAcceptStatus?id=${+edit}`)
      .then(r => {
        console.log('PerformaInvoiceAcceptStatus DATA', r.data);
        return r.data;
      })
      .catch(err => {
        console.log('Particular lead details fetching Error', err);
      });

  const { data, error, isError, isFetching } = useQuery({
    queryKey: ['LeadsData'],
    queryFn: fetchLeadData,
    enabled: edit === null,
  });

  const {
    data: EditData,
    error: EditError,
    isError: EditIsError,
    isFetching: ErrorIsFetching,
  } = useQuery({
    queryKey: ['EditLeadsData'],
    queryFn: fetchPerformaInvoiceData,
    enabled: edit !== null,
  });

  isError && console.log(error);

  useEffect(() => {
    if (edit) {
      reset({
        lead_id: `${id}`,
        email_address: EditData?.customer_id?.email,
        course_enquiredd: EditData?.course[0]?.course_name,
        course_duration: EditData?.course[0]?.course_duration,
        customerGST: EditData?.gst_number !== '',
        gstNumber: EditData?.gst_number,
        companyName: EditData?.company,
        companyAddress: EditData?.company_address,
        initial_payment: +EditData?.initial_payment[0]?.amount,
        gstExclusive: EditData?.gstInclusive || false,
        payThroughInstallments: EditData?.installment_status,
        course_fees:
          +EditData?.grand_total + +EditData?.initial_payment[0]?.amount,
        remainingTotal: '',
        remarks: EditData?.customer_id?.registration_id?.Remarks,
        noOfInstallments: EditData?.PDIS?.length,
        installments: EditData?.PDIS?.map((installment, idx) => ({
          amount: +installment?.fees,
          dueDate: installment?.due_date,
        })),
        dueDate: EditData?.single_payment_due_date,
      });
    }

    if (data && !edit) {
      const today = new Date();
      const installmentInterval = data?.installment_days?.installment_days || 1;

      const installmentsWithDates = data?.course_installment_amount?.map(
        (amount, index) => {
          const dueDate = new Date(today);
          dueDate.setDate(today.getDate() + installmentInterval * index);
          const formattedDueDate = dueDate.toISOString().split('T')[0];
          return { amount: amount.amount, dueDate: formattedDueDate };
        },
      );

      reset({
        email_address: data?.email,
        course_duration: data?.course_duration,
        course_enquiredd: data?.othercourseenquired ?? data?.course_enquiredd,
        course_fees: +data?.course_amount?.single_shot_fees,
        noOfInstallments: data?.course_installment_amount.length,
        // noOfInstallments: installmentInterval,
        installments: [...installmentsWithDates],
      });
    }
  }, [reset, data, edit, id, EditData]);

  return (
    <FormHeader>
      <div className="col-12 bg-white py-3">
        {isFetching && (
          <div
            className={` tw-bg-slate-300 tw-w-full tw-absolute tw-top-0 tw-animate-pulse tw-text-center ${
              edit ? 'tw-text-red-600' : 'tw-text-yellow-400'
            }`}
          >
            Fetching Data Please Wait
          </div>
        )}
        <form onSubmit={handleSubmit(SubmitForm)}>
          <FormProvider {...methods}>
            <LeadDetailsForm data={data} />
            {edit === null && <BatchDetailsForm />}
            <GSTDetailsForm />
            <PaymentForm data={data} />
            <div className="row tw-my-5">
              <TextAreaField
                label={'Remarks'}
                className={'tw-h-[150px]'}
                {...register('remarks')}
              />
            </div>
          </FormProvider>
          <button className="tw-bg-blue-400 tw-rounded-lg tw-p-2">
            Submit
          </button>
        </form>
      </div>
    </FormHeader>
  );
}

export default MarkAsRegister;
