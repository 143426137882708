import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { ipaddress } from '../../App';
import { toast } from 'react-toastify';
import { usePopper } from 'react-popper';
import ClientForms from '../../Components/ClientForms';
import WalkinsUpdateForm from '../../Components/CLIENTFORMS/WalkinsUpdateForm';
import ENVTYPE from '../../config';

function LeadStatusButton({ rowData, isOpen, setOpenDropdownId }) {
  const [leadId, setLeadId] = useState('');
  const [clientLeadId, setClientLeadId] = useState('');
  const [stage, setStage] = useState('');
  const [courseName, setCourseName] = useState('');
  const [courseId, setCourseId] = useState('');
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show, setShow] = useState(false);
  const [showClosed, setShowClosed] = useState(false);

  const buttonRef = useRef(null);

  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes } = usePopper(buttonRef.current, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, -4] } },
      { name: 'preventOverflow', options: { boundary: 'viewport' } },
      { name: 'flip', options: { fallbackPlacements: ['top', 'right'] } },
    ],
  });

  const navigate = useNavigate();

  const user = JSON.parse(sessionStorage.getItem('user'));

  const moveToOtherDepartmentLeadsB2C = id => {
    axios
      .post(`${ipaddress}/MoveToOtherDepartmentLead/`, { customer_lead_id: id })
      .then(r => {
        console.log('Other department leads', r.data);
        toast.success('Moved to Other Department Leads', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
      });
  };

  const moveToOtherDepartmentLeadsB2B = id => {
    axios
      .post(`${ipaddress}/MoveToOtherDepartmentLead/`, {
        client_lead_id: id,
        employee_id: user.emp_id,
      })
      .then(r => {
        console.log('Other department leads', r.data);
        toast.success('Moved to Other Department Leads', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
      })
      .catch(() => {
        console.log('Client lead id', id);
      });
  };

  const handleOpenDropdown = () => {
    if (isOpen) {
      setOpenDropdownId(null);
    } else {
      if (rowData.customer_lead_id) {
        setOpenDropdownId(rowData.customer_lead_id);
      } else {
        setOpenDropdownId(rowData.client_lead_id);
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isOpen) {
        setOpenDropdownId(null);
      }
    };

    const gridContainer = document.querySelector('.ag-body-viewport');

    if (gridContainer) {
      gridContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (gridContainer) {
        gridContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isOpen, setOpenDropdownId]);

  return (
    <div className="tw-w-[max]">
      <button
        ref={buttonRef}
        onClick={handleOpenDropdown}
        className="tw-bg-blue-900 tw-text-white tw-text-xs tw-uppercase tw-px-1 tw-w-full tw-py-2 tw-rounded-md"
      >
        {getWalkinStatus(rowData)}
      </button>
      {isOpen &&
        ReactDOM.createPortal(
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="tw-bg-white  tw-flex tw-flex-col tw-gap-y-1 tw-shadow-lg tw-rounded-md tw-p-4"
          >
            <div
              ref={setArrowElement}
              style={styles.arrow}
              className="arrow"
            />

            <button
              className="tw-bg-blue-600 tw-text-white tw-text-sm tw-px-4 tw-py-2 tw-rounded-md"
              onClick={() => {
                if (rowData.customer_lead_id) {
                  setLeadId(rowData.customer_lead_id);
                } else {
                  setLeadId(rowData.client_lead_id);
                }
                setStage(rowData.level_lead_name);
                setShow2(true);
              }}
            >
              {rowData.level_lead_name !== 'walkin'
                ? ' Walkin'
                : 'Expected Walkin'}
            </button>

            {rowData.level_lead_name !== 'registered' && (
              // {rowData.level_lead_name !== 'registered' && (
              <button
                className="tw-bg-blue-600 tw-text-white tw-text-sm tw-px-4 tw-py-2 tw-rounded-md"
                onClick={() => {
                  if (rowData.customer_lead_id) {
                    setLeadId(rowData.customer_lead_id);
                  } else {
                    setLeadId(rowData.client_lead_id);
                  }
                  setStage(rowData.level_lead_name);
                  setCourseName(rowData.course_enquiredd);
                  setCourseId(rowData.course_enquired);
                  setShow1(true);
                }}
              >
                Followup
              </button>
            )}

            {rowData.level_lead_name !== 'registered' &&
              rowData.level_lead_name !== 'closed' && (
                <>
                  <button
                    className="tw-bg-blue-600 tw-text-white tw-text-sm tw-px-4 tw-py-2 tw-rounded-md"
                    onClick={() => {
                      if (rowData.customer_lead_id) {
                        setLeadId(rowData.customer_lead_id);
                      } else {
                        setLeadId(rowData.client_lead_id);
                      }
                      setShow(true);

                      setCourseName(rowData.course_enquiredd);
                      setCourseId(rowData.course_enquired);
                    }}
                  >
                    Prospects
                  </button>

                  <button
                    className="tw-bg-blue-600 tw-text-white tw-text-sm tw-px-4 tw-py-2 tw-rounded-md"
                    onClick={() => {
                      if (rowData.customer_lead_id) {
                        navigate(
                          `/mark_as_register_form/${rowData.customer_lead_id}`,
                        );
                      } else {
                        navigate(
                          `/mark_as_register_form/${rowData.client_lead_id}`,
                        );
                      }
                    }}
                  >
                    Register
                  </button>

                  <button
                    className="tw-bg-blue-600 tw-text-white tw-text-sm tw-px-4 tw-py-2 tw-rounded-md"
                    onClick={() => {
                      console.log('CLOSED', rowData.customer_lead_id);
                      setShowClosed(true);
                      if (rowData.customer_lead_id) {
                        setLeadId(rowData.customer_lead_id);
                      } else {
                        setLeadId(rowData.client_lead_id);
                      }
                      setStage(rowData.level_lead_name);
                    }}
                  >
                    Closed
                  </button>
                </>
              )}

            <button
              className="tw-bg-blue-600 tw-text-white tw-text-sm tw-px-4 tw-py-2 tw-rounded-md"
              onClick={() => {
                console.log('ZORO', rowData);
                if (rowData.customer_lead_id) {
                  moveToOtherDepartmentLeadsB2C(rowData.customer_lead_id);
                } else {
                  moveToOtherDepartmentLeadsB2B(rowData.client_lead_id);
                }
              }}
            >
              Other Department Lead
            </button>
          </div>,
          document.getElementById('portal-root'),
        )}
      <WalkinsUpdateForm
        showProspects={setShow}
        // showRegist={setshow2}
        setshow1={setShow1}
        setshow2={setShow2}
        show2={show2}
        leadID={leadId}
        data={rowData?.walkin_stages_id}
        walkinHistory={rowData?.walkin_stages_list}
      />
      <ClientForms
        show1={show1}
        setshow1={setShow1}
        show2={show2}
        setshow2={setShow2}
        lead_id={leadId}
        client_lead_id={clientLeadId}
        stage1={stage}
        setstage={setStage}
        course_name={courseName}
        course_id={courseId}
        show={show}
        setShow={setShow}
        showClosed={showClosed}
        setShowClosed={setShowClosed}
      />
    </div>
  );
}

function getWalkinStatus(rowData) {
  const leadName = rowData?.level_lead_name?.toLowerCase();

  if (leadName === 'other department lead') {
    return 'Other DEPT.';
  }

  if (leadName === 'walkin') {
    return rowData.walkin_status == null
      ? 'Walkin'
      : rowData.walkin_status
      ? 'Walked In'
      : 'Expected Walkin';
  }

  return rowData?.level_lead_name ?? '';
}

export default LeadStatusButton;
