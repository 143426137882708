import { useQuery } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryState } from 'nuqs';

import { fetchDashData } from '../API/FetchFns/Dashboard';

import RangeWrap from '../ADMIN/Cards/RangeWrap';

import SingleFieldCard from '../ADMIN/Cards/SingleFieldCard';

import ThreeSectionCard from '../ADMIN/Cards/ThreeSectionCard';

import RangeDoubleWrap from '../ADMIN/Cards/RangeDoubleWrap';

import WalkinEmployeeCard from '../ADMIN/Cards/WalkinEmployeeCard';

import FollowUpSectionEmployee from '../ADMIN/Cards/FollowUpSectionEmployee';

import DateFilter from '../ADMIN/DateFilters/DateFilter';

function EmployeeCardsMain({ employeeID }) {
  const USER = JSON.parse(sessionStorage.getItem('user'));
  console.log('USERR', employeeID);

  const params = useParams();
  const location = useLocation();
  const isAdmin = USER.designation === 'admin';

  let emp_id;
  if (isAdmin) {
    emp_id = location.pathname === '/dashboard' ? null : params.id;
  } else {
    emp_id = location.pathname === '/dashboard' ? employeeID : params?.id;
  }

  console.log('Computed emp_id:', emp_id);

  const [startDate] = useQueryState('startDate', {
    withDefault: '',
  });
  const [endDate] = useQueryState('endDate', {
    withDefault: '',
  });

  const { data, isLoading, error } = useQuery({
    queryKey: ['DashDataCount', { startDate, endDate, emp_id }],
    queryFn: () => fetchDashData({ startDate, endDate, emp_id }),
  });

  console.log('DASH DATA', data?.data);
  console.log('ERROR', error);

  return (
    <div className="main-dashboard tw-h-max">
      <DateFilter />

      <div className="tw-flex tw-gap-5 tw-flex-wrap">
        {console.log('STD', startDate, endDate)}

        <RangeWrap
          anyCount={data?.data?.allB2CTotal}
          anyName={'Total Leads'}
          endDate={endDate}
          startDate={startDate}
          navTo={`/view_employee_leads/${emp_id}/all_leads/?startDate=${startDate}&endDate=${endDate}`}
        >
          <ThreeSectionCard
            name={'All B2C Leads'}
            subNameOne={'This Month'}
            countOne={data?.data?.allB2CTotal?.customer_lead_count || 0}
            navToOne={`/view_employee_leads/${emp_id}/all_leads/?type=currentMonth`}
            subNameTwo={'Previous Month'}
            countTwo={
              data?.data?.allB2CTotal?.previous_monthcustomer_lead_count || 0
            }
            navToTwo={`/view_employee_leads/${emp_id}/all_leads/?type=previousMonth`}
            subNameThree={'Total Leads'}
            countThree={data?.data?.allB2CTotal?.all_customer_lead_count || 0}
            navToThree={`/view_employee_leads/${emp_id}/all_leads`}
          />
        </RangeWrap>

        <SingleFieldCard
          count={data?.data?.projectedWalkins?.currentMonth}
          name={'Total Projected Walkins'}
          navTo={`/view_employee_leads/${emp_id}/projected_walkins`}
        />

        <SingleFieldCard
          count={data?.data?.today_followups?.today_followups || 'NA'}
          name={'Today Followups'}
          navTo={`/view_employee_leads/${emp_id}/followup/?type=today`}
        />

        <RangeDoubleWrap
          anyNameOne={'Employee Walkins'}
          anyCountOne={
            data?.data?.allWalkins?.total_filteed_employee_walkin_count
          }
          navToOne={`/view_employee_leads/${emp_id}/walkins/`}
          anyNameTwo={'Scanned Walkins'}
          anyCountTwo={
            data?.data?.allWalkins?.total_filtered_direct_walkin_count
          }
          navToTwo={`/view_employee_leads/${emp_id}/walkins/?isScanned=true`}
          endDate={endDate}
          startDate={startDate}
        >
          <WalkinEmployeeCard
            walkinCount={data?.data?.allWalkins}
            name={'Walk-Ins/Meetings'}
          />
        </RangeDoubleWrap>

        <RangeDoubleWrap
          anyNameOne={'Pending Followups'}
          anyCountOne={data?.data?.allWalkins?.filtered_pending_followups}
          navToOne={`/view_employee_leads/${emp_id}/followup/`}
          anyNameTwo={'Upcoming Followups'}
          anyCountTwo={data?.data?.allWalkins?.filtered_upcomming_follows}
          navToTwo={`/view_employee_leads/${emp_id}/followup/?followUpType=upcomings`}
          endDate={endDate}
          startDate={startDate}
        >
          <FollowUpSectionEmployee followUpsCount={data?.data?.followups} />
        </RangeDoubleWrap>

        <RangeWrap
          anyCount={data?.data?.prospects}
          anyName={'Prospects'}
          endDate={endDate}
          startDate={startDate}
          navTo={`/view_employee_leads/${emp_id}/prospect/?startDate=${startDate}&endDate=${endDate}`}
        >
          <ThreeSectionCard
            name={'Prospects'}
            subNameOne={'This Month'}
            navToOne={`/view_employee_leads/${emp_id}/prospect/?type=currentMonth`}
            countOne={data?.data?.prospects?.currentMonth}
            subNameTwo={'Previous Month'}
            countTwo={data?.data?.prospects?.previousMonth}
            navToTwo={`/view_employee_leads/${emp_id}/prospect/?type=previousMonth`}
            subNameThree={'Total Prospects'}
            countThree={data?.data?.prospects?.total}
            navToThree={`/view_employee_leads/${emp_id}/prospect`}
          />
        </RangeWrap>

        <RangeWrap
          anyCount={data?.data?.registrations}
          anyName={'Registrations'}
          endDate={endDate}
          startDate={startDate}
          navTo={`/view_employee_leads/${emp_id}/registered/?startDate=${startDate}&endDate=${endDate}`}
        >
          <ThreeSectionCard
            name={'Registrations'}
            subNameOne={'This Month'}
            countOne={data?.data?.registrations?.currentMonth}
            navToOne={`/view_employee_leads/${emp_id}/registered/?type=currentMonth`}
            subNameTwo={'Previous Month'}
            countTwo={data?.data?.registrations?.previousMonth}
            navToTwo={`/view_employee_leads/${emp_id}/registered/?type=previousMonth`}
            subNameThree={'Total Registrations'}
            countThree={data?.data?.registrations?.total}
            navToThree={`/view_employee_leads/${emp_id}/registered`}
          />
        </RangeWrap>

        <RangeWrap
          anyCount={data?.data?.closed}
          anyName={'Closed'}
          endDate={endDate}
          startDate={startDate}
          navTo={`/view_employee_leads/${emp_id}/closed/?startDate=${startDate}&endDate=${endDate}`}
        >
          <ThreeSectionCard
            name={'Closed Leads'}
            subNameOne={'This Month'}
            countOne={data?.data?.closed?.currentMonth}
            navToOne={`/view_employee_leads/${emp_id}/closed/?type=currentMonth`}
            subNameTwo={'Previous Month'}
            countTwo={data?.data?.closed?.previousMonth}
            navToTwo={`/view_employee_leads/${emp_id}/closed/?type=previousMonth`}
            subNameThree={'Total Closed'}
            countThree={data?.data?.closed?.total}
            navToThree={`/view_employee_leads/${emp_id}/closed`}
          />
        </RangeWrap>

        <RangeWrap
          anyCount={data?.data?.other_dpt_leads}
          anyName={'Other Dept. Leads'}
          endDate={endDate}
          startDate={startDate}
          navTo={`/view_employee_leads/${emp_id}/other_department/?startDate=${startDate}&endDate=${endDate}`}
        >
          <ThreeSectionCard
            name={'Other Dept. Leads'}
            subNameOne={'This Month'}
            countOne={data?.data?.other_department?.currentMonth}
            navToOne={`/view_employee_leads/${emp_id}/other_department/?type=currentMonth`}
            subNameTwo={'Previous Month'}
            countTwo={data?.data?.other_department?.previousMonth}
            navToTwo={`/view_employee_leads/${emp_id}/other_department/?type=previousMonth`}
            subNameThree={'Total Other Dept. Leads'}
            countThree={data?.data?.other_department?.total}
            navToThree={`/view_employee_leads/${emp_id}/other_department`}
          />
        </RangeWrap>
      </div>
    </div>
  );
}

export default EmployeeCardsMain;
