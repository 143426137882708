import { useFormContext } from 'react-hook-form';
import { DateField } from './CategoriesControls/DateField';
import { useQueryState } from 'nuqs';

function DatesForm() {
  const [startDate, setStartDate] = useQueryState('startDate');
  const [endDate, setEndDate] = useQueryState('endDate');

  const { register, getValues, setValue } = useFormContext();

  const handleFilter = () => {
    setStartDate(getValues('startDate'));
    setEndDate(getValues('endDate'));
  };

  return (
    <div className=" tw-flex tw-gap-x-3 tw-items-end">
      <div>
        <label htmlFor="">Start Date</label>
        <DateField {...register('startDate')} />
      </div>
      <div>
        <label htmlFor="">End Date</label>
        <DateField {...register('endDate')} />
      </div>
      <button
        className="tw-p-2 tw-bg-slate-400 tw-rounded-lg"
        type="button"
        onClick={handleFilter}
      >
        Filter
      </button>
      <button
        className="tw-p-2 tw-bg-blue-400 tw-rounded-lg"
        type="button"
        onClick={() => {
          setValue('startDate', null);
          setValue('endDate', null);
          setEndDate(null);
          setStartDate(null);
        }}
      >
        Clear
      </button>
    </div>
  );
}

export default DatesForm;
