import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar1';
import Navbar from './Navbar1';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ipaddress2 } from '../App';

const Questions = () => {
  const [state, setstate] = useState('mcq');

  const navigate = useNavigate();

  const [mcq_questions, setmcq_questions] = useState([]);
  const [coding_questions, setcoding_questions] = useState([]);
  const [assignmentQues, setAssignmentQues] = useState([]);

  const user = JSON.parse(sessionStorage.getItem('user'));

  useEffect(() => {
    fetch_questions('mcq');
  }, []);

  const fetch_questions = type => {
    const API = `${ipaddress2}/Question_Details/${user.emp_id}/${type}/`;
    console.log('QUES', API);
    axios.get(API).then(r => {
      console.log('All coding question paapers', r.data, type);
      if (type === 'coding') {
        setcoding_questions(r.data);
      } else if (type === 'task') {
        setAssignmentQues(r.data);
      } else {
        setmcq_questions(r.data);
      }
    });
  };

  return (
    <div className="d-flex">
      <Sidebar activevalue={'questions'} />
      <div className="w-100">
        <Navbar />
        <div
          className="main-container p-3"
          style={{ backgroundColor: '#E8E8E8' }}
        >
          <h6 className="fs-5 pb-2 text-color">Created Question Papers</h6>

          <div
            className="bg-white p-3 py-lg-4"
            style={{ minHeight: '100vh' }}
          >
            <div className="d-flex pb-2">
              <h5
                onClick={() => {
                  setstate('mcq');
                  fetch_questions('mcq');
                }}
                className="py-2 px-4 d-flex align-items-center"
                style={{
                  color: state === 'mcq' ? '#ffff' : '#002147',
                  cursor: 'pointer',
                  border: '1px solid #002147',
                  backgroundColor: state === 'mcq' ? '#002147' : '',
                }}
              >
                <span className="">MCQ Question</span>
              </h5>
              <h5
                onClick={() => {
                  setstate('coding');
                  fetch_questions('coding');
                }}
                className="py-2 px-4 d-flex align-items-center"
                style={{
                  color: state === 'coding' ? '#ffff' : '#002147',
                  cursor: 'pointer',
                  border: '1px solid #002147',
                  backgroundColor: state === 'coding' ? '#002147' : '',
                }}
              >
                <span className="">Coding Question</span>
              </h5>
              <h5
                onClick={() => {
                  setstate('task');
                  fetch_questions('task');
                }}
                className="py-2 px-4 d-flex align-items-center"
                style={{
                  color: state === 'task' ? '#ffff' : '#002147',
                  cursor: 'pointer',
                  border: '1px solid #002147',
                  backgroundColor: state === 'task' ? '#002147' : '',
                }}
              >
                <span className="">Assignments</span>
              </h5>
            </div>

            {/* MCQ Questions */}
            <div className={`${state === 'mcq' ? '' : 'd-none'}`}>
              <h6
                className={`text-center py-4 ${
                  mcq_questions.length > 0 ? 'd-none' : ''
                }`}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/assign_test');
                }}
              >
                Create Question Paper...✍
              </h6>

              <div className={`${mcq_questions.length > 0 ? '' : ''} row m-0`}>
                {mcq_questions.map(x => {
                  return (
                    <div className="col-md-4 px-2 mt-4">
                      <div
                        className="bg-white p-3"
                        style={{ border: '1px solid #002147' }}
                      >
                        <p>
                          <span className="text-color fw-bold">Course : </span>{' '}
                          <span className="text-dark fw-medium">{x.title}</span>
                        </p>
                        <p>
                          <span
                            className="text-color fw-bold"
                            style={{ fontSize: '12px' }}
                          >
                            Type :{' '}
                          </span>
                          <span
                            className="text-dark fw-medium"
                            style={{ fontSize: '12px' }}
                          >
                            MCQ Question
                          </span>
                        </p>
                        <p>
                          <span
                            className="text-color fw-bold"
                            style={{ fontSize: '12px' }}
                          >
                            Created on :{' '}
                          </span>
                          <span
                            className="text-dark fw-medium"
                            style={{ fontSize: '12px' }}
                          >
                            {x.created_at && x.created_at.slice(0, 10)}
                          </span>
                        </p>
                        <button
                          className="btns text-white px-5 w-100 py-2"
                          onClick={() => {
                            navigate(`/mcq_question/${x.id}`);
                          }}
                        >
                          View Question Paper
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* 
<div className={`table-responsive ${mcq_questions.length>0 ? '':'d-none'}`}>
                <table class="table">
  <thead>
    <tr>
      <th scope="col" className='py-3'><p className='text-center m-0'>SI No</p></th>
      <th scope="col" className='py-3'><p className='text-center m-0'>Title</p></th>
      <th scope="col" className='py-3'><p className='text-center m-0'>Created On</p></th>
      <th scope="col" className='py-3'></th>
    </tr>
  </thead>
  <tbody>
    {mcq_questions.map((x,index)=>{
        return(
            <tr>
      <td scope="row" className=''><p className='text-center m-0'>{index+1}</p></td>
      <td className=''><p className='text-center m-0'>{x.title}</p></td>
      <td className=''><p className='text-center m-0'>{x.created_at && x.created_at.slice(0,10)}</p></td>
      <td className=''><div className='d-flex justify-content-center'>
        <button className='btn btn-sm btn-primary' onClick={()=>{
            navigate(`/mcq_question/${x.id}`)
        }}>View Question</button>
        </div></td>
    </tr>
        )
    })}
  </tbody>
</table>
                </div> */}
            </div>

            {/* Coding Questions */}
            <div className={`${state === 'coding' ? '' : 'd-none'}`}>
              <h6
                className={`text-center py-4 ${
                  coding_questions.length > 0 ? 'd-none' : ''
                }`}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/assign_test');
                }}
              >
                Create Question Paper...✍
              </h6>

              <div
                className={`${
                  coding_questions.length > 0 ? '' : 'd-none'
                } row m-0`}
              >
                {coding_questions.map((x, index) => {
                  return (
                    <div className="col-md-4 px-2 mt-4">
                      <div
                        className="bg-white p-3"
                        style={{ border: '1px solid #002147' }}
                      >
                        <p>
                          <span className="text-color fw-bold">Title : </span>{' '}
                          <span className="text-dark fw-medium">{x.title}</span>
                        </p>
                        <p>
                          <span
                            className="text-color fw-bold"
                            style={{ fontSize: '12px' }}
                          >
                            Type :{' '}
                          </span>
                          <span
                            className="text-dark fw-medium"
                            style={{ fontSize: '12px' }}
                          >
                            Coding Questions
                          </span>
                        </p>
                        <p>
                          <span
                            className="text-color fw-bold"
                            style={{ fontSize: '12px' }}
                          >
                            Created On :{' '}
                          </span>
                          <span
                            className="text-dark fw-medium"
                            style={{ fontSize: '12px' }}
                          >
                            {x.created_at && x.created_at.slice(0, 10)}
                          </span>
                        </p>
                        <button
                          className="btns text-white px-5 w-100 py-2"
                          onClick={() => {
                            navigate(`/coding_question/${x.id}`);
                          }}
                        >
                          View Question Paper
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* //ASSIGNMENTS */}
            <div className={`${state === 'task' ? '' : 'd-none'}`}>
              <h6
                className={`text-center py-4 ${
                  assignmentQues.length > 0 ? 'd-none' : ''
                }`}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/assign_test');
                }}
              >
                Create Question Paper...✍
              </h6>

              {console.log('ASS', assignmentQues)}
              <div
                className={`${
                  assignmentQues.length > 0 ? '' : 'd-none'
                } row m-0`}
              >
                {assignmentQues.map((x, index) => {
                  return (
                    <div className="col-md-4 px-2 mt-4">
                      <div
                        className="bg-white p-3"
                        style={{ border: '1px solid #002147' }}
                      >
                        <p>
                          <span className="text-color fw-bold">Title : </span>{' '}
                          <span className="text-dark fw-medium">{x.title}</span>
                        </p>
                        <p>
                          <span
                            className="text-color fw-bold"
                            style={{ fontSize: '12px' }}
                          >
                            Type :{' '}
                          </span>
                          <span
                            className="text-dark fw-medium"
                            style={{ fontSize: '12px' }}
                          >
                            Assignment Questions
                          </span>
                        </p>
                        <p>
                          <span
                            className="text-color fw-bold"
                            style={{ fontSize: '12px' }}
                          >
                            Created On :{' '}
                          </span>
                          <span
                            className="text-dark fw-medium"
                            style={{ fontSize: '12px' }}
                          >
                            {x.created_at && x.created_at.slice(0, 10)}
                          </span>
                        </p>
                        <button
                          className="btns text-white px-5 w-100 py-2"
                          onClick={() => {
                            navigate(`/coding_question/${x.id}`);
                          }}
                        >
                          View Question Paper
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
