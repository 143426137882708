import { useFormContext } from 'react-hook-form';
import { TextField } from './FilterForms/CategoriesControls/TextField';
import { CheckBoxField } from './FilterForms/CategoriesControls/CheckBoxField';
import { useEffect } from 'react';
import InstallmentsForm from './InstallmentsForm';
import { DateField } from './FilterForms/CategoriesControls/DateField';
import { calculateGstAmount } from '../utils/GetGSTAmount';

function PaymentForm({ data }) {
  const { register, setValue, watch } = useFormContext();
  const gstExclusive = watch('gstExclusive');

  const throughInstallments = watch('payThroughInstallments');

  console.log('INST', throughInstallments, gstExclusive);

  const courseFees = watch('course_fees');

  const remainingAmount = watch('grandTotal') - watch('initial_payment');

  useEffect(() => {
    setValue('remainingTotal', +remainingAmount);
  }, [setValue, remainingAmount]);

  useEffect(() => {
    console.log(data?.course_amount?.single_shot_fees);
    if (data) {
      const fees = throughInstallments
        ? +data?.course_amount?.installment_fees
        : data?.course_amount?.single_shot_fees;
      setValue('course_fees', +fees);
    }
  }, [setValue, data, throughInstallments]);

  useEffect(() => {
    const gstAmount = calculateGstAmount(gstExclusive, courseFees);

    console.log('GST', gstAmount);

    setValue('gstAmount', Math.ceil(+gstAmount));
    // setValue('gstAmount', Math.ceil(+gstAmount));

    setValue(
      'grandTotal',
      gstExclusive ? Math.ceil(+courseFees + +gstAmount) : +courseFees,
    );
  }, [setValue, courseFees, gstExclusive]);

  return (
    <>
      <div className="">
        <p className="tw-text-xl tw-font-bold">Course Fees Structure</p>
        <div className="tw-h-[2px] tw-w-full tw-bg-black" />
      </div>

      <div className="row tw-my-5">
        <div className="col ">
          <TextField
            isRequired={true}
            label={'Initial Amount to Pay'}
            type={'number'}
            {...register('initial_payment', {
              required: 'Please Enter The Registration Fees',
            })}
          />
        </div>
        <div className="col ">
          <TextField
            isRequired={true}
            label={'Course Fees'}
            type={'number'}
            {...register('course_fees', {
              required: 'Please Enter Course Fees',
            })}
          />
        </div>
        <div className="col">
          <TextField
            label={'Grand Total Amount'}
            type={'number'}
            disabled
            {...register('grandTotal')}
          />
        </div>
        <div className="row tw-my-5">
          <div className="col">
            <TextField
              label={'Remaining'}
              type={'number'}
              disabled
              //   value={+remainingAmount}
              {...register('remainingTotal')}
            />
          </div>
          <div className="col">
            <TextField
              label={'GST Amount'}
              disabled
              value={watch('gstAmount')}
              {...register('gstAmount')}
            />
          </div>

          <div className="col tw-flex tw-items-end">
            <CheckBoxField
              label={'Pay Through Installments'}
              {...register('payThroughInstallments')}
            />
          </div>
          <div className="col tw-flex tw-items-end">
            <CheckBoxField
              label={'GST Exclusive'}
              {...register('gstExclusive')}
            />
          </div>
        </div>
        {throughInstallments ? (
          <InstallmentsForm />
        ) : (
          <div className="row tw-w-[26%]">
            <div className="col ">
              <DateField
                isRequired={true}
                label={'Due Date'}
                {...register('dueDate', {
                  required: 'Please Enter the Due Date',
                })}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PaymentForm;
