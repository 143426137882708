import React from 'react';

function CollectionsDetails({ data }) {
  console.log('DATACOL inside', data);
  return (
    <div className="tw-container tw-mx-auto tw-p-6">
      <h1 className="tw-text-2xl tw-font-bold tw-text-gray-800 tw-mb-4">
        Collection Details
      </h1>
      <div className="tw-overflow-x-auto">
        <table className="tw-min-w-full tw-bg-white tw-shadow-md tw-rounded">
          <thead>
            <tr className="tw-bg-gray-200 tw-text-gray-600 tw-uppercase tw-text-sm tw-leading-normal">
              <th className="tw-py-3 tw-px-6 tw-text-left">Customer Name</th>
              <th className="tw-py-3 tw-px-6 tw-text-left">Date</th>
              <th className="tw-py-3 tw-px-6 tw-text-right">Amount (₹)</th>
              <th className="tw-py-3 tw-px-6 tw-text-right">Status</th>
            </tr>
          </thead>
          <tbody className="tw-text-gray-600 tw-text-sm tw-font-light">
            {data?.map((item, index) => (
              <tr
                key={index}
                className={`tw-border-b tw-border-gray-200 hover:tw-bg-gray-100 ${
                  item.status === 'over_due' ? 'tw-bg-red-200' : ''
                }`}
              >
                <td className="tw-py-3 tw-px-6 tw-text-left tw-whitespace-nowrap">
                  {item.customer_name}
                </td>
                <td className="tw-py-3 tw-px-6 tw-text-left tw-whitespace-nowrap">
                  {new Date(item.date).toLocaleDateString('en-IN')}
                </td>
                <td className="tw-py-3 tw-px-6 tw-text-right">
                  {item.amount.toLocaleString('en-IN')}
                </td>
                <td className="tw-py-3 tw-px-6 tw-text-right">
                  {item.status?.includes('_')
                    ? item.status.replace('_', ' ').toUpperCase()
                    : item.status?.toUpperCase()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CollectionsDetails;
