import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ipaddress } from '../App';
import axios from 'axios';
import Followup_form from './Lead_aading_forms/Followup_form';
import Closed_form from './Lead_aading_forms/Closed_form';
import Prospect_form from './Lead_aading_forms/Prospect_form';
import Walkinform from './Lead_aading_forms/Walkinform';
import Arrow_svg from '../Svgs/Arrow_svg';
import { GetRenderCount } from '../utils/GetRenderCount';

const Customerregistrationform = ({ setclient_data, client_data }) => {
  const user = JSON.parse(sessionStorage.getItem('user'));

  const [availablecourses, setAvailablecourses] = useState([]);
  const [lead_levels, setlead_levels] = useState([]);

  const [bach_perc, setbach_perc] = useState(true);
  const [mast_perc, setmast_perc] = useState(true);

  const [bach_degrees, setbach_degrees] = useState([]);
  const [bach_specific, setbach_specific] = useState([]);

  const [master_degrees, setmaster_degrees] = useState([]);
  const [master_specific, setmaster_specific] = useState([]);

  useEffect(() => {
    if (client_data) {
      console.log('EMAILLLLLL', client_data);
      setFormData(client_data);
      setemail(client_data.email);
      setphone(client_data.contact_number);
    }

    axios(`${ipaddress}/bachelor-degrees/`)
      .then(r => {
        console.log('Bach degrees successfully fetched', r.data);
        setbach_degrees(r.data);
      })
      .catch(err => {
        console.log('Bach degrees fetching Error', err);
      });

    axios(`${ipaddress}/MasterDegrees/`)
      .then(r => {
        console.log('Mast degrees successfully fetched', r.data);
        setmaster_degrees(r.data);
      })
      .catch(err => {
        console.log('Mast degrees fetching Error', err);
      });

    // /MUIZAPI

    axios
      .get(`${ipaddress}/AllcoursesnameView/`)
      .then(r => {
        console.log('Courses successfully fetched', r.data);
        setAvailablecourses(r.data.coursename);
      })
      .catch(err => {
        console.log('Courses fetching Error', err);
      });

    axios
      .get(`${ipaddress}/Allleadlevels/`)
      .then(r => {
        console.log('Lead levels successfully fetched', r.data);
        setlead_levels(r.data.leadlevels);
      })
      .catch(err => {
        console.log('Lead levels fetching Error', err);
      });
  }, []);

  const sourcedata = e => {
    if (e.target.value === 'walkin') {
      document.getElementById('contactedpersondiv').style.display = 'block';
    }
    if (e.target.value === 'other') {
      document.getElementById('othersource').style.display = 'block';
    }
  };

  const fetchspecifications = id => {
    axios(`${ipaddress}/bachelor-degrees/${id}/specifications/`)
      .then(r => {
        console.log('Bach specific successfully fetched', r.data);
        setbach_specific(r.data);
      })
      .catch(err => {
        console.log('Bach specific fetching Error', err);
      });
  };

  const fetchspecifications2 = id => {
    axios(`${ipaddress}/MasterDegrees/${id}/specifications/`)
      .then(r => {
        console.log('Mast specific successfully fetched', r.data);
        setmaster_specific(r.data);
      })
      .catch(err => {
        console.log('Mast specific fetching Error', err);
      });
  };

  const [formData, setFormData] = useState({
    emp_id: '',
    name: '',
    email: '',
    contact_number: '',
    whatsapp: '',
    gender: '',
    bachelor_degree: '',
    bachelor_specification: '',
    percentage_bachelor: '',
    master_degree: '',
    master_specification: '',
    percentage_master: '',
    course_enquired: '',
    upcoming_batch_id: '',
    enquiry_location: '',
    source: '',
    contacted_person_name: '',
    contacted_person_number: '',
    technical_skills_known: [],
    soft_skills_known: [],
    priority: '',
    level_lead: '',
    preferred_course: '',
    preferred_batch_type: '',
    preferred_batch_start_time: '',
    preferred_class_type: '',
    expected_registration_date: '',
    tentative_batch_start_date: '',
    othercourseenquired: '',
    remarks: '',
  });

  const toggle__to_client = () => {
    const client_data = {
      name: formData.name,
      email: formData.email,
      contact_number: formData.contact_number,
      enquiry_location: formData.enquiry_location,
      source: formData.source,
      contacted_person_name: formData.contacted_person_name,
      contacted_person_number: formData.contacted_person_number,
      priority: formData.priority,
    };
    setclient_data(client_data);

    navigate('/clientregistrationform');
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    console.log('WHAT', name, value);
    setFormData(formData => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = e => {
    const { name, checked, value } = e.target;
    setFormData(prevState => {
      const skills = prevState[name];
      if (checked) {
        skills.push(value);
      } else {
        const index = skills.indexOf(value);
        if (index > -1) {
          skills.splice(index, 1);
        }
      }
      return { ...prevState, [name]: skills };
    });
  };

  const navigate = useNavigate();

  // const[]

  const [loading, setloading] = useState(false);
  const [mail, setmail] = useState('');

  const senddetails = e => {
    e.preventDefault();

    setloading(true);

    formData.emp_id = user.emp_id;

    // if (formData.source === 'other') {
    //   formData.source = formData.othersource;
    // }

    if (formData.course_enquired === 'other') {
      formData.course_enquired = 0;
    }
    formData.technical_skills_known = technical_skills;
    formData.soft_skills_known = soft_skills;

    console.log('MUIZTRIG', formData.level_lead);

    if (formData.level_lead === 'newlead' || formData.level_lead === 'other') {
      console.log('MUIZTRIG');
      axios
        .post(`${ipaddress}/CustomerLeadsView/`, {
          ...formData,
          source:
            formData.source === 'other'
              ? formData.othersource
              : formData.source,
        })
        .then(r => {
          console.log('MUIZ Successfully registered', r.data);
          console.log('MUIZ ', formData);
          toast.success('Successfully sent', {
            autoClose: 1000,
            theme: 'colored',
            position: 'top-center',
          });
          setloading(false);

          setTimeout(() => {
            navigate('/customerleads');
          }, 2000);
          setclient_data({
            name: '',
            email: '',
            enquiry_location: '',
            source: '',
            contact_number: '',
            contacted_person_name: '',
            contacted_person_number: '',
            priority: '',
          });
        })
        .catch(err => {
          console.log('Lead registration error', err);
          console.log('CUSTOMER FORMDATA', formData);
          setloading(false);
        });
    }
  };

  const [formstate, setformstate] = useState(false);
  const [otpstate, setotpstate] = useState(false);
  const [backendotp, setbackendotp] = useState('');
  const [otp, setotp] = useState('');

  const sendotp = () => {
    // e.preventDefault()
    axios
      .post(`${ipaddress}/send-otp/`, {
        email: formData.email,
      })
      .then(r => {
        console.log('Otp sent suceessfully', r.data);
        setbackendotp(r.data.otp);
      })
      .catch(err => {
        console.log('Otp sending error', err);
      });
  };

  // Phone number verification
  const [load2, setload2] = useState();
  const [noExists, setNoExists] = useState(false);
  const [phone, setphone] = useState();

  const verify_phone = phone => {
    setphone(phone);
    if (phone.length === 10) {
      setload2(true);
      setTimeout(() => {
        verify2(phone);
      }, 1000);
    }
  };

  const verify2 = phone => {
    axios
      .post(`${ipaddress}/MobileNumberValidation/`, {
        mobile_number: phone,
        customer: 'Customer',
      })
      .then(r => {
        console.log('THISS', r.data);
        setload2(false);

        formData.contact_number = phone;

        ///IF NO UNIQUE NO that means NOEXISTS :
        if (typeof r.data == 'object') {
          console.log('THISS NO EXISTS');
          setNoExists(true);
          setFormData(prev => ({
            ...prev,
            ...r.data,
          }));
        } else {
          console.log('THISS DOESNT EXISTS', r.data);
          setNoExists(false);
        }
      })
      .catch(err => {
        setNoExists(false);
        setload2(false);
      });
  };

  // Email verification
  const [load, setload] = useState();
  const [message, setmessage] = useState();

  const verify_email = email => {
    if (email.length > 0) {
      setload(true);
      setTimeout(() => {
        verify(email);
      }, 1000);
    }
  };

  const verify = email => {
    axios.get(`${ipaddress}/EmailCheck/${email}/`).then(r => {
      console.log('Email verified', r.data);
      setload(false);

      if (r.data.message === 'mail_notexist_customerlead') {
        setmessage(true);
        formData.email = email;
      } else {
        setmessage(false);
      }
    });
  };

  const verifyotp = () => {
    console.log(otp, backendotp);
    if (otp === backendotp) {
      toast.success('OTP verified successfully', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
      setotpstate(false);
      setformstate(true);
      setotp('');
    } else {
      toast.error('Invalid OTP', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
    }
  };

  const [technical_skills, settechnical_skills] = useState(['']);

  const handleAddsubcourses = () => {
    settechnical_skills([...technical_skills, '']);
  };

  const handlesubcoursesChange = (index, event) => {
    const newCourses = technical_skills.map((course, courseIndex) =>
      courseIndex === index ? event.target.value : course,
    );
    settechnical_skills(newCourses);
  };

  const [soft_skills, setsoft_skills] = useState(['']);

  const handleAddsubcourses2 = () => {
    setsoft_skills([...soft_skills, '']);
  };

  const handlesubcoursesChange2 = (index, event) => {
    const newCourses = soft_skills.map((course, courseIndex) =>
      courseIndex === index ? event.target.value : course,
    );
    setsoft_skills(newCourses);
  };

  const [email, setemail] = useState('');

  const handle_email = email => {
    setemail(email);
    const atIndex = email.indexOf('@');

    if (atIndex !== -1 && email.length > atIndex + 1) {
      verify_email(email);
    } else {
      setload(false);
    }
  };

  const [batches, setbatches] = useState([]);
  const [course_name, setcourse_name] = useState('');

  const fetch_batch = id => {
    const data = availablecourses.filter(x => x.id == id);
    // console.log("course detail",data)
    setcourse_name(data[0].course_name);

    axios
      .get(`${ipaddress}/upcomingBatchesView/basedoncourse/?course_id=${id}`)
      .then(r => {
        console.log('Batches', r.data);
        setbatches(r.data.results);
      });
  };

  const [show, setshow] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);
  const [show4, setshow4] = useState(false);

  const [otherSource, setOtherSource] = useState('');
  useEffect(() => {
    // Clear `otherSource` if the selected source is not "Other"
    if (formData.source !== 'other') {
      setOtherSource('');
    }
  }, [formData.source]);

  return (
    <div
      className="p-2 p-md-3"
      style={{ minHeight: '100vh', backgroundColor: '#E8E8E8' }}
    >
      <div className="container-fluid row m-0 pb-4 pt-3 animate__animated animate__fadeIn">
        <div className="mb-4 d-flex align-items-center ps-md-0">
          <Link
            className="text-dark"
            to="/customerleads"
          >
            <Arrow_svg />
          </Link>
          <h5
            className="m-0 ms-2 ms-md-4"
            style={{ color: '#002147', fontWeight: 600, fontSize: '17px' }}
          >
            CUSTOMER REGISTRATION FORM
          </h5>
          <GetRenderCount />
        </div>

        <div className="mb-3 ps-md-0">
          <button
            className="btn px-4 btn-sm rounded-0 text-white py-2"
            style={{ backgroundColor: '#002147' }}
          >
            B2C Form
          </button>
          <button
            onClick={() => {
              toggle__to_client();
            }}
            className="btn px-4 btn-sm rounded-0 py-2"
            style={{ border: '1px solid #002147', color: '#002147' }}
          >
            B2B Form
          </button>
        </div>

        <div className="col-12 bg-white p-2 p-md-4">
          <form
            className=""
            onSubmit={senddetails}
          >
            {/* ---------------------------------PERSONAL DETAILS--------------------------------------------------------- */}
            <div className="row m-0 border-bottom pb-2">
              <h6 className="form-heading">Personal Details</h6>
              <div className="col-md-6 col-lg-3 mb-3">
                <label
                  for="inputEmail4"
                  className="form-label"
                >
                  Name <span className="required-field">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control shadow-none form-inputs py-2"
                  id="inputEmail4"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-md-6 col-lg-3 mb-3">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Contact Number <span className="required-field">*</span>
                </label>
                <div class="input-group form-inputs border">
                  <input
                    type="tel"
                    value={phone}
                    maxLength={10}
                    className="form-control shadow-none  py-2 border-0 bg-transparent"
                    id="inputPassword4"
                    name="contact_number"
                    onChange={e => {
                      verify_phone(e.target.value);
                    }}
                  />
                  <span
                    class="input-group-text border-0 bg-transparent"
                    id="basic-addon2"
                  >
                    <div
                      class={`spinner-border spinner-border-sm text-success ${
                        load2 ? '' : 'd-none'
                      }`}
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#198754"
                      class={`bi bi-check2-circle ${
                        !load2 && noExists != true ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                    </svg>
                  </span>
                </div>
                {console.log('THISS', noExists)}
                <p
                  className={`text-danger mt-1 ${!noExists && 'd-none'}`}
                  style={{ fontSize: '13px' }}
                >
                  *Mobile Number already exist
                </p>
              </div>

              <div className="col-md-6 col-lg-3 mb-3">
                <label
                  for="whatsapp"
                  className="form-label"
                >
                  Whatsapp Number
                </label>
                <div class="input-group form-inputs border">
                  <input
                    type="tel"
                    maxLength={10}
                    className="form-control shadow-none  py-2 border-0 bg-transparent"
                    id="whatsapp"
                    name="whatsapp"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-md-6 col-lg-3 mb-3">
                <label
                  for="inputPassword4"
                  className="form-label"
                >
                  Email
                </label>
                <div class="input-group form-inputs border">
                  <input
                    required
                    type="email"
                    value={email}
                    className="form-control shadow-none  py-2 border-0 bg-transparent"
                    id="inputPassword4"
                    name="email"
                    onChange={e => {
                      handle_email(e.target.value);
                    }}
                  />

                  <span
                    class="input-group-text border-0 bg-transparent"
                    id="basic-addon2"
                  >
                    <div
                      class={`spinner-border spinner-border-sm text-success ${
                        load ? '' : 'd-none'
                      }`}
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#198754"
                      class={`bi bi-check2-circle ${
                        !load && message == true ? '' : 'd-none'
                      }`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                    </svg>
                  </span>
                </div>
                <p
                  className={`text-danger mt-1 ${
                    message == false ? '' : 'd-none'
                  }`}
                  style={{ fontSize: '13px' }}
                >
                  *Email already exist
                </p>
              </div>

              <div className="col-md-6 col-lg-3 mb-3">
                <label
                  for="inputState"
                  className="form-label shadow-none"
                >
                  Gender
                </label>
                <select
                  id="inputState"
                  className="form-select shadow-none  py-2"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <option selected>Choose...</option>
                  <option>Male</option>
                  <option>Female</option>
                  <option>Transgender</option>
                </select>
              </div>
            </div>

            <div className={`${formstate ? '' : ''}`}>
              {/* ----------------------------------EDUCATIONAL DETAILS----------------------------------------------------------- */}
              <div className="row m-0 py-3">
                <h6 className="form-heading">Educational Details</h6>

                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Bachelor Degree{' '}
                  </label>
                  <select
                    required
                    id="inputState"
                    className="form-select shadow-none  py-2"
                    name="bachelor_degree"
                    value={formData.bachelor_degree}
                    onChange={e => {
                      setbach_perc(false);
                      fetchspecifications(e.target.value);
                      const { name, value } = e.target;

                      setFormData(prev => ({
                        ...prev,
                        [name]: value,
                      }));
                    }}
                  >
                    <option selected>Choose...</option>
                    {bach_degrees.map(x => {
                      return (
                        <>
                          <option value={x}>{x}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Bachelor Specification
                  </label>
                  <select
                    required
                    id="inputState"
                    className="form-select shadow-none  py-2"
                    onChange={e => {
                      if (e.target.value === 'other') {
                        document.getElementById('bachother').style.display =
                          'block';
                      } else {
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                        document.getElementById('bachother').style.display =
                          'none';
                      }
                    }}
                  >
                    <option selected>Choose...</option>
                    <option value="other">Other</option>
                    {bach_specific.map(x => {
                      return (
                        <>
                          <option value={x}>{x}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Percentage Achieved
                  </label>
                  <input
                    type="number"
                    className="form-control shadow-none  py-2"
                    id="inputPassword4"
                    value={formData.percentage_bachelor}
                    name="percentage_bachelor"
                    onChange={handleInputChange}
                  />
                </div>
                <div
                  className="col-md-6 col-lg-4 mb-3"
                  id="bachother"
                  style={{ display: 'none' }}
                >
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Enter your Specification
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none "
                    id="inputPassword4"
                    value={formData.bachelor_specification}
                    name="bachelor_specification"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="row m-0 border-bottom pb-3">
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Master Degree
                  </label>
                  <select
                    id="inputState"
                    className="form-select shadow-none  py-2"
                    onChange={e => {
                      setmast_perc(false);
                      fetchspecifications2(e.target.value);
                      const { name, value } = e.target;

                      setFormData(prev => ({
                        ...prev,
                        [name]: value,
                      }));
                    }}
                  >
                    <option selected>Choose...</option>
                    {master_degrees.map(x => {
                      return (
                        <>
                          <option value={x}>{x}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Master Specification
                  </label>
                  <select
                    id="inputState"
                    className="form-select shadow-none  py-2"
                    onChange={e => {
                      if (e.target.value === 'other') {
                        document.getElementById('masterother').style.display =
                          'block';
                      } else {
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                        document.getElementById('masterother').style.display =
                          'none';
                      }
                    }}
                  >
                    <option selected>Choose...</option>
                    <option value="other">Other</option>
                    {master_specific.map(x => {
                      return (
                        <>
                          <option value={x}>{x}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Percentage Achieved
                  </label>
                  <input
                    type="number"
                    className="form-control shadow-none  py-2"
                    id="inputPassword4"
                    value={formData.percentage_master}
                    name="percentage_master"
                    onChange={handleInputChange}
                  />
                </div>
                <div
                  className="col-md-6 col-lg-4 mb-3"
                  id="masterother"
                  style={{ display: 'none' }}
                >
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Enter your Specification
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none "
                    id="inputPassword4"
                    value={formData.master_specification}
                    name="master_specification"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* ----------COURSE DETAILS----------------------- */}
              <div className="row m-0 border-bottom py-3">
                <h6 className="form-heading">Course Details</h6>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Course Enquired<span className="required-field">*</span>
                  </label>
                  <select
                    required
                    id="inputState"
                    className="form-select shadow-none  py-2"
                    value={formData.course_enquired}
                    name="course_enquired"
                    onChange={e => {
                      if (e.target.value === 'other') {
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                        document.getElementById('other-course').style.display =
                          'block';
                      } else {
                        fetch_batch(e.target.value);
                        document.getElementById('other-course').style.display =
                          'none';
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: Number(value),
                        }));
                      }
                    }}
                  >
                    <option value="">Choose...</option>
                    {availablecourses.map(x => {
                      return (
                        <>
                          <option value={x.id}>{x.course_name}</option>
                        </>
                      );
                    })}
                    <option value="other">Other</option>
                  </select>
                </div>

                {formData.course_enquired ? (
                  <div className="col-md-6 col-lg-4 mb-3">
                    <label
                      for="inputState"
                      className="form-label shadow-none"
                    >
                      Batches Available
                    </label>
                    <select
                      id="inputState"
                      className={`form-select shadow-none  py-2 `}
                      value={formData.upcoming_batch_id}
                      name="upcoming_batch_id"
                      onChange={e => {
                        document.getElementById('other-course').style.display =
                          'none';
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                      }}
                    >
                      <option value="">Choose...</option>
                      {batches.map(x => {
                        return (
                          <>
                            <option value={x.id}>{x.batch_name}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                ) : (
                  <></>
                )}

                {/* Other course */}
                <div
                  className="col-md-6 col-lg-4 mb-3"
                  id="other-course"
                  style={{
                    display:
                      formData.course_enquired === 'other' ? 'block' : 'none',
                  }}
                >
                  <label
                    htmlFor="inputPassword4"
                    className="form-label"
                  >
                    Enter the course
                  </label>
                  <input
                    required={formData.course_enquired === 'other'}
                    type="text"
                    className="form-control shadow-none py-2"
                    id="inputPassword4"
                    name="othercourseenquired"
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Enquiry Location
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none  py-2"
                    id="inputPassword4"
                    value={formData.enquiry_location}
                    name="enquiry_location"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <label
                    for="source"
                    className="form-label shadow-none"
                  >
                    Source <span className="required-field">*</span>
                  </label>
                  <select
                    required
                    id="source"
                    className="form-select shadow-none  py-2"
                    value={
                      formData.source === 'other'
                        ? otherSource
                        : formData.source
                    }
                    name="source"
                    onChange={e => {
                      if (e.target.value === 'walkin') {
                        document.getElementById(
                          'contactedpersondiv',
                        ).style.display = 'block';
                        document.getElementById('other-source').style.display =
                          'none';
                        const { name, value } = e.target;
                        formData.othersource = '';
                        console.log('OTHER', formData);
                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                      } else if (e.target.value === 'other') {
                        setOtherSource(e.target.value);
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                        document.getElementById('other-source').style.display =
                          'block';
                        document.getElementById(
                          'contactedpersondiv',
                        ).style.display = 'none';
                      } else {
                        document.getElementById(
                          'contactedpersondiv',
                        ).style.display = 'none';
                        document.getElementById('other-source').style.display =
                          'none';
                        const { name, value } = e.target;
                        setFormData(prev => ({
                          ...prev,
                          other: '',
                          [name]: value,
                        }));
                      }
                    }}
                  >
                    <option value="">Choose...</option>
                    <option value="other">Other</option>
                    <option value="walkin">Walkin</option>
                    <option value="naukri">Naukri</option>
                    <option value="indeed">Indeed</option>
                    <option value="linkedin">Linkedin</option>
                    <option value="just dial">Just Dial</option>
                    <option value="whatsapp">Whatsapp</option>
                    <option value="facebook">Facebook</option>
                    <option value="instagram">Instagram</option>
                  </select>
                </div>

                <div
                  style={{ display: 'none' }}
                  id="contactedpersondiv"
                >
                  <div className="col-md-6 col-lg-4 mb-3">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Contacted Person's Name
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none  py-2"
                      id="inputPassword4"
                      onChange={handleInputChange}
                      value={formData.contacted_person_name}
                      name="contacted_person_name"
                    />
                  </div>

                  <div className="col-md-6 col-lg-3 mb-3 ">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Contacted Person's Number
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none  py-2"
                      id="inputPassword4"
                      onChange={handleInputChange}
                      value={formData.contacted_person_number}
                      name="contacted_person_number"
                    />
                  </div>
                </div>
                {/* Other source */}
                <div
                  className="col-md-6 col-lg-3 mb-3"
                  id="other-source"
                  style={{
                    display: formData.source === 'other' ? 'block' : 'none',
                  }}
                >
                  <label
                    for="othersource"
                    className="form-label"
                  >
                    Enter the source
                  </label>
                  <input
                    required={formData.source === 'other'}
                    type="text"
                    className="form-control shadow-none  py-2"
                    id="othersource"
                    name="othersource"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* --------------------------------TECHNOLOGIES KNOWN-------------------- */}
              <div className="row m-0 mb-3">
                <div
                  className={`my-3 ${
                    technical_skills.length > 1 ? 'col-12' : 'col-lg-6'
                  }`}
                >
                  <span className="form-heading fw-medium me-4">
                    Technical Skills Known
                  </span>

                  <div className="row m-0 p-0">
                    {technical_skills.map((q, qIndex) => (
                      <div
                        className={`${
                          technical_skills.length > 1
                            ? 'col-lg-3 col-md-6'
                            : 'col-md-6 '
                        } ps-md-0`}
                        key={qIndex}
                      >
                        <div>
                          <input
                            className="add-option form-control py-2 shadow-none  border-secondary-subtle my-2"
                            type="text"
                            placeholder="Type your skills..."
                            onChange={e => handlesubcoursesChange(qIndex, e)}
                          />
                        </div>
                      </div>
                    ))}

                    <div className="col-md-4 col-lg-3 d-flex align-items-center ps-md-0">
                      <button
                        type="button"
                        style={{ backgroundColor: '#002147' }}
                        className="px-3 btn btn-sm text-white py-2 rounded-0 d-flex align-items-center"
                        onClick={handleAddsubcourses}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="white"
                          className="bi bi-plus-lg"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                          />
                        </svg>
                        <span
                          className="ms-1"
                          style={{ fontSize: '12px' }}
                        >
                          Add More Skills
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`my-3 ${
                    soft_skills.length > 1 ? 'col-12' : 'col-lg-6'
                  }`}
                >
                  <span className="form-heading fw-medium me-5">
                    Soft Skills Known{' '}
                  </span>
                  <div className="row m-0 p-0">
                    {soft_skills.map((q, qIndex) => (
                      <div
                        className={`${
                          soft_skills.length > 1
                            ? 'col-lg-3 col-md-6'
                            : 'col-md-6 '
                        } ps-md-0`}
                        key={qIndex}
                      >
                        <input
                          className="add-option form-control py-2 shadow-none  border-secondary-subtle my-2"
                          type="text"
                          placeholder="Type your skills..."
                          onChange={e => handlesubcoursesChange2(qIndex, e)}
                        />
                      </div>
                    ))}

                    <div className="col-md-4 col-lg-3 ps-md-0 d-flex align-items-center">
                      <button
                        type="button"
                        className="btn btn-sm px-3 py-2 rounded-0 text-white d-flex align-items-center"
                        style={{ backgroundColor: '#002147' }}
                        onClick={handleAddsubcourses2}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="white"
                          className="bi bi-plus-lg"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                          />
                        </svg>
                        <span
                          className="ms-1"
                          style={{ fontSize: '12px' }}
                        >
                          Add More Skills
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row m-0 border-top py-3">
                <div className="col-md-6 col-lg-3 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Priority <span className="required-field">*</span>
                  </label>
                  <select
                    required
                    id="inputState"
                    className="form-select shadow-none  py-2"
                    name="priority"
                    value={formData.priority}
                    onChange={handleInputChange}
                  >
                    <option value="">Choose...</option>
                    <option value="high">High</option>
                    <option value="medium">Medium</option>
                    <option value="low">Low</option>
                  </select>
                </div>

                <div className="col-12 mb-4">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Remarks <span className="required-field">*</span>
                  </label>
                  <textarea
                    required
                    type="text"
                    rows={3}
                    className="form-control shadow-none  py-2"
                    id="inputPassword4"
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {formData.source ? (
                <div className="col-md-6 col-lg-3 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Level of Lead <span className="required-field">*</span>
                  </label>
                  <select
                    required
                    id="inputState"
                    className="form-select shadow-none  py-2"
                    name="level_lead"
                    value={formData.level_lead}
                    onChange={e => {
                      if (formData.remarks) {
                        const { name, value } = e.target;

                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));

                        if (e.target.value === 'followup') {
                          setshow(true);
                        } else if (e.target.value === 'closed') {
                          setshow2(true);
                        } else if (e.target.value === 'prospect') {
                          setshow3(true);
                        } else if (e.target.value === 'walkin') {
                          setshow4(true);
                        }
                      } else {
                        toast.warn('Fill the Remarks Field', {
                          autoClose: 2000,
                          theme: 'colored',
                          position: 'top-center',
                        });

                        formData.level_lead = 'newlead';
                      }
                    }}
                  >
                    <option value="">Choose...</option>
                    <option value="newlead">New Lead</option>
                    <option value="walkin">Walkin</option>
                    <option value="followup">Followup</option>
                    <option value="prospect">Prospect</option>
                    <option value="closed">Closed</option>
                    <option value="other">Other Department Lead</option>
                  </select>
                </div>
              ) : (
                <div className="tw-text-red-500 tw-text-lg tw-bg-slate-200 tw-w-max tw-p-2 tw-mx-3">
                  Please Enter source
                </div>
              )}

              {
                <div className="col-12 text-end mb-3 pe-md-3">
                  <button
                    onClick={() => {
                      formData.level_lead === 'followup' && setshow(true);
                      formData.level_lead === 'closed' && setshow2(true);
                      formData.level_lead === 'prospect' && setshow3(true);
                      formData.level_lead === 'walkin' && setshow4(true);
                    }}
                    disabled={loading}
                    style={{ backgroundColor: '#002147' }}
                    type="submit"
                    className="py-2 btn rounded-0 text-white fw-medium px-2 px-lg-5"
                  >
                    {loading ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
              }
            </div>
          </form>
        </div>
      </div>

      <Followup_form
        show={show}
        type={'customer'}
        setShow={setshow}
        form_value={formData}
      />
      <Closed_form
        show1={show2}
        type={'customer'}
        setshow1={setshow2}
        form_value={formData}
      />
      <Prospect_form
        show1={show3}
        type={'customer'}
        setShow1={setshow3}
        form_value={formData}
      />
      <Walkinform
        show={show4}
        type={'customer'}
        setShow={setshow4}
        form_value={formData}
      />
    </div>
  );
};

export default Customerregistrationform;
