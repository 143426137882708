import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Form } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../API/Axios/AxiosClient';

function ForgotPassword({ showModal, setShowModal }) {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');

  const [otpSent, setOtpSent] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [backendOtp, setBackendOtp] = useState('');
  const [user, setUser] = useState(null);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const sendOtp = () => {
    console.log(email);
    if (!email) {
      toast.error('Please enter your email address.', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
      return;
    }
    setOtpSent(true);
    axiosInstance
      .post(`/send_otp/`, { email })
      .then(res => {
        setBackendOtp(res.data.otp);
        setUser(res.data.emp_id);
        console.log(res.data.emp_id);
        toast.success('OTP sent successfully.', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
        setShowOtpModal(true);
      })
      .catch(err => {
        console.error(err);
        toast.error('Failed to send OTP. Try again later.', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
      })
      .finally(() => setOtpSent(false));
  };

  const validateOtp = () => {
    console.log(otp, backendOtp);
    if (+otp === backendOtp) {
      toast.success('OTP validated successfully.', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
      setOtpValidated(true);
    } else {
      toast.error('Invalid OTP. Please try again.', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
    }
  };

  const updatePassword = () => {
    if (newPassword !== retypePassword) {
      toast.error('Passwords do not match.', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
      return;
    }
    axiosInstance
      .put(`/AddEmployeeView/?empid=${user}`, {
        password: newPassword,
      })
      .then(() => {
        toast.success('Password updated successfully!', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
        setShowOtpModal(false);
        setOtpValidated(false);
      })
      .catch(err => {
        console.error(err);
        toast.error('Failed to update password. Try again.', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
      });
  };
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Button
              className="w-100"
              onClick={sendOtp}
              disabled={otpSent}
            >
              {otpSent ? 'Sending...' : 'Send OTP'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showOtpModal}
        onHide={() => setShowOtpModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Validate OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {otpValidated ? (
            <>
              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Retype Password</Form.Label>
                <Form.Control
                  type="password"
                  value={retypePassword}
                  onChange={e => setRetypePassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Button
                className="w-100"
                onClick={updatePassword}
              >
                Update Password
              </Button>
            </>
          ) : (
            <>
              <Form.Group className="mb-3">
                <Form.Label>OTP</Form.Label>
                <Form.Control
                  type="text"
                  value={otp}
                  onChange={e => setOtp(e.target.value)}
                  required
                />
              </Form.Group>
              <Button
                className="w-100"
                onClick={validateOtp}
              >
                Validate OTP
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ForgotPassword;
