import { useState, useEffect } from 'react';
import MainLayout from '../../Admin_panel/All-leads/CustomerAllLeads/MainLayout';
import ClosedFilter from '../All-leads/ClosedFilter/ClosedFilter';
import PaginatedButtons from '../All-leads/CustomerAllLeads/All/PaginatedButtons';
import AllB2BTable from '../All-leads/CustomerAllLeads/All/AllB2BTable';
import AllFilters from '../../Components/FilterForms/AllFilters';
import { fetchAllB2BLeads } from '../../API/FetchFns/AllB2BLeads';
import useLeadsQuery from '../../Hooks/useLeadsQuery';
import useLeadsQueryParams from '../../Hooks/useLeadsQueryParams';

const ClientClosedLeads = ({ settab }) => {
  useEffect(() => {
    settab('closed');
  }, [settab]);

  const [leads, setLeads] = useState([]);

  const { queryParamsObj } = useLeadsQueryParams();

  const queryParams = {
    ...queryParamsObj,
    stage: 'closed',
  };

  const { data, error, isError, isFetching, isLoading } = useLeadsQuery({
    queryParams,
    fetchFn: fetchAllB2BLeads,
    fetchKey: 'ClosedB2BLeadsAdmin',
  });

  data && console.log('CLOSEDDD', data?.data?.results);
  isError && console.log(error);

  useEffect(() => {
    setLeads(data?.data?.results);
  }, [setLeads, data]);

  const isPrevious = Boolean(data?.data?.previous);
  const isNext = Boolean(data?.data?.next);

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <AllFilters />
      <div class={` mt-3 bg-white py-3 py-md-4 `}>
        {/* <ClosedFilter setclosedleads={setLeads} /> */}
        <MainLayout loading={isLoading}>
          <AllB2BTable
            leads={leads}
            count={data?.data?.count}
          />
          <PaginatedButtons
            isFetching={isFetching}
            isNext={isNext}
            isPrevious={isPrevious}
          />
        </MainLayout>
      </div>
    </div>
  );
};

export default ClientClosedLeads;
