import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { Route, Routes } from 'react-router-dom';

import EmployeeAllLeadsData from './Employee_leads/EmployeeLeads/EmployeeAllLeadsData';
import EmployeeCardsMain from './EmployeeCardsMain';
import EmployeeDLeads from './Employee_leads/EmployeeLeads/EmployeeDLeads';

const Employee_leads = () => {
  return (
    <div className="d-flex">
      <Sidebar activevalue={'employees'} />
      <div className="w-100">
        <Navbar />

        <div
          className="main-container p-3 bg-light"
          style={{ minHeight: '100vh' }}
        >
          <div>
            <Routes>
              <Route
                path="/:lead_stage"
                element={<EmployeeAllLeadsData />}
                // element={<EmployeeDLeads />}

                // element={<Employee_new_leads />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employee_leads;
