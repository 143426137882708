import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import LeadStatusButton from './CustomAGComps/LeadStatusDropdown';
import NameCell from './CustomAGComps/NameCell';
import Lead_details from '../Components/Employee_leads/Lead_details';
import RemarksSections from './CustomAGComps/RemarksToolTip';
import ExtraButtons from './CustomAGComps/ExtraButtons';
import { useParams } from 'react-router-dom';

function TableB2B({ handleCheckboxChange, selectedLeads, tableData, count }) {
  const gridRef = useRef(null);
  const params = useParams();
  const leadStage = params['*'];

  const [rowData, setRowData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [filterCourse, setFilterCourse] = useState('');
  const [filterSource, setFilterSource] = useState('');
  const [filterEmployee, setFilterEmployee] = useState('');

  const [leadId, setLeadId] = useState();
  const [show, setShow] = useState();
  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    if (refreshData) {
      resetGrid();
      fetchRowData();
      setRefreshData(false);
    }
    fetchRowData();
  }, [refreshData, tableData]);

  const defaultColDef = {
    sortable: true,
    floatingFilter: true,
    filter: true,
    flex: 1,

    resizable: true,
    cellClass: 'default-cell',
    headerClass: 'default-header',
  };

  const getRowHeight = params => {
    const defaultHeight = 50;
    return params.data?.isExpanded ? 200 : defaultHeight;
  };

  const dateFormatter = params => {
    const date = new Date(params.value);
    return isNaN(date) ? params.value : date.toLocaleDateString();
  };

  const columnDefs = [
    {
      headerName: 'Customer Name',
      field: 'name',
      cellStyle: { width: 'max' }, // Centers the text

      cellRenderer: NameCell,
      cellRendererParams: params => ({
        rowData: params.data,
        setLeadId,
        setShow,
      }),
    },
    {
      headerName: 'Enquiry Date',
      field: 'created_at',
      cellStyle: { textAlign: 'center' }, // Centers the text
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterDate, cellValue) => {
          if (!cellValue) return -1; // Handle empty values gracefully

          // Convert cellValue to a Date object
          const cellDate = new Date(cellValue);
          const filterDateValue = new Date(filterDate);
          console.log(
            'DATES Parsed Cell VALUE:',
            cellValue,
            'DATES Parsed Cell Date:',
            cellDate.toLocaleDateString(),
            'DATES Filter Date:',
            filterDateValue.toLocaleDateString(),
          );

          // Ensure cellDate is valid
          if (isNaN(cellDate)) {
            console.warn(`Invalid date for filtering: ${cellValue}`);
            return -1; // Exclude invalid dates
          }

          // Compare dates
          if (
            cellDate.toLocaleDateString() < filterDateValue.toLocaleDateString()
          )
            return -1;
          if (
            cellDate.toLocaleDateString() > filterDateValue.toLocaleDateString()
          )
            return 1;
          return 0;
        },
        browserDatePicker: true, // Enable browser date picker
      },
    },
    {
      headerName: 'WhatsApp No',
      field: 'whatsapp',
      cellStyle: { textAlign: 'center' }, // Centers the text
    },
    {
      headerName: 'Contact No',
      field: 'created_at',
      cellStyle: { textAlign: 'center' }, // Centers the text
    },

    { headerName: 'Email', field: 'email', cellStyle: { textAlign: 'center' } },
    {
      headerName: 'Service of Interest',
      field: 'reason_for_contact',
      cellStyle: { textAlign: 'center', fontSize: 14 },
      valueFormatter: params =>
        params.value
          ? params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          : '',
    },

    {
      headerName: 'Lead Level Name',
      field: 'level_lead_name',
      cellRenderer: LeadStatusButton,
      cellRendererParams: params => ({
        rowData: params.data,
        isOpen: openDropdownId === params.data.client_lead_id,
        setOpenDropdownId,
        setRowData,
      }),
    },
    {
      headerName: 'Purpose',
      field: 'purpose',
      cellStyle: { textAlign: 'center', fontSize: 14 },
      valueFormatter: params =>
        params.value
          ? params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          : '',
    },
    {
      headerName: 'Source',
      field: 'source',
      cellStyle: { textAlign: 'center', fontSize: 14 },
      valueFormatter: params =>
        params.value
          ? params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          : '',
    },
    {
      headerName: 'Employee',
      field: 'emp_name',
      cellStyle: { textAlign: 'center' },
      valueFormatter: params =>
        params.value
          ? params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          : '',
    },
    {
      headerName: 'Assigned By',
      field: 'assigned_byy',
      cellStyle: { textAlign: 'center' },
      valueFormatter: params =>
        params.value
          ? params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          : '',
    },
    {
      headerName: 'Remarks',
      field: 'remarks',
      cellRenderer: RemarksSections,
      cellRendererParams: params => ({
        rowData: params.data,
      }),
    },
    {
      headerName: 'Priority',
      field: 'priority',
      cellStyle: { textAlign: 'center' },

      valueFormatter: params =>
        params.value
          ? params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          : '',
    },
    {
      headerName: 'Edit',
      // field: 'priority',
      cellRenderer: ExtraButtons,
      cellRendererParams: params => ({
        rowData: params.data,
        setRefreshData,
      }),
    },
  ];

  const onGridReady = useCallback(() => {
    fetchRowData();
  }, [tableData]);

  const fetchRowData = useCallback(() => {
    console.log('FETCHING DATA ');

    setRowData(tableData);
    setFilteredData(tableData);
  }, [tableData]);

  const applyFilters = () => {
    let filtered = rowData;

    if (filterCourse) {
      filtered = filtered.filter(
        row =>
          row.course_enquiredd?.toLowerCase() === filterCourse.toLowerCase(),
      );
    }
    if (filterSource) {
      filtered = filtered.filter(
        row => row.source?.toLowerCase() === filterSource.toLowerCase(),
      );
    }
    if (filterEmployee) {
      filtered = filtered.filter(
        row => row.emp_name?.toLowerCase() === filterEmployee.toLowerCase(),
      );
    }

    setFilteredData(filtered);
  };

  const handleFilterChange = (e, setter) => {
    setter(e.target.value);
  };
  const resetGrid = () => {
    gridRef.current.api.setFilterModel(null);
    setFilteredData(rowData);
  };

  useEffect(() => {
    applyFilters();
  }, [filterCourse, filterSource, filterEmployee]);

  const onRowSelected = event => {
    const selectedRow = event.node;

    if (event.node.selected) {
      console.log('Row Selected:', selectedRow);
      console.log('Row Selected:', selectedRow.data.client_lead_id);

      selectedLeads.includes(selectedRow.data.client_lead_id);
    }

    handleCheckboxChange(selectedRow.selected, selectedRow.data.client_lead_id);
  };

  const serviceInterests = Array?.from(
    new Set(rowData?.map(item => item.reason_for_contact).filter(Boolean)),
  );
  const uniqueSources = Array?.from(
    new Set(rowData?.map(item => item.source).filter(Boolean)),
  );
  const uniqueEmployees = Array?.from(
    new Set(rowData?.map(item => item.emp_name).filter(Boolean)),
  );

  console.log('TABLE OPENDROPID', openDropdownId);

  return (
    <div
      id="dataTable"
      className="ag-theme-alpine"
      style={{ height: '78vh', width: '100%' }}
      onClick={e => e.stopPropagation()}
    >
      {count && (
        <div className="tw-text-black tw-text-md">
          {' '}
          Total Leads:
          <span className="tw-mx-2 tw-text-sm">{count}</span>
        </div>
      )}
      <div
        className=""
        style={{ padding: '10px', background: '#f0f4f8' }}
      >
        <label>
          Filter by Services:
          <select
            value={filterCourse}
            onChange={e => handleFilterChange(e, setFilterCourse)}
          >
            <option value="">All Services</option>
            {serviceInterests.map(service => (
              <option
                key={service}
                value={service}
              >
                {service}
              </option>
            ))}
          </select>
        </label>

        <label style={{ marginLeft: '20px' }}>
          Filter by Source:
          <select
            value={filterSource}
            onChange={e => handleFilterChange(e, setFilterSource)}
          >
            <option value="">All Sources</option>
            {uniqueSources.map(source => (
              <option
                key={source}
                value={source}
              >
                {source}
              </option>
            ))}
          </select>
        </label>

        <label style={{ marginLeft: '20px' }}>
          Filter by Employee:
          <select
            value={filterEmployee}
            onChange={e => handleFilterChange(e, setFilterEmployee)}
          >
            <option value="">All Employees</option>
            {uniqueEmployees.map(emp => (
              <option
                key={emp}
                value={emp}
              >
                {emp}
              </option>
            ))}
          </select>
        </label>
        {/* <button
          className="tw-bg-green-300 "
          onClick={() => {
            resetGrid();
          }}
        >
          RESET
        </button> */}
        {refreshData && <div className="tw-bg-red-200">LOADING</div>}
      </div>

      {/* Ag Grid */}
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        enableBrowserTooltips
        rowData={filteredData}
        defaultColDef={defaultColDef}
        rowSelection={{
          mode: 'multiRow',
          checkboxes: true,
          // headerCheckbox: true,
          enableSelectionWithoutKeys: true,
          // enableClickSelection: true,
          selectAll: 'filtered',
        }}
        onGridReady={onGridReady}
        getRowHeight={getRowHeight}
        pagination
        paginationPageSize={20}
        onRowSelected={onRowSelected}
      />

      <Lead_details
        show={show}
        setshow={setShow}
        id={leadId}
      />
    </div>
  );
}

export default TableB2B;
