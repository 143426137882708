import { forwardRef } from 'react';

export const CheckBoxField = forwardRef(
  ({ className, label, ...other }, ref) => {
    return (
      <div className={`tw-flex tw-items-center tw-gap-3 tw-mb-4 ${className}`}>
        <input
          ref={ref}
          type="checkbox"
          className={`tw-w-5 tw-h-5 tw-border tw-rounded tw-focus:ring-2 tw-focus:ring-blue-500 tw-outline-none ${className}`}
          {...other}
        />
        <label className="tw-text-lg tw-font-medium tw-text-gray-700">
          {label}
        </label>
      </div>
    );
  },
);
