import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import axios from 'axios';
import { ipaddress2 } from '../App';
import { useNavigate } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Marks = () => {
  const renderTooltip = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      View your Queries
    </Tooltip>
  );

  const user = JSON.parse(sessionStorage.getItem('user'));

  const navigate = useNavigate();

  const [batches, setBatches] = useState([]);

  useEffect(() => {
    axios.get(`${ipaddress2}/StudentBatches/${user.email}/`).then(r => {
      console.log('Student batches', r.data);
      setBatches(r.data);
    });
  }, []);

  // To get the time in hours and minutes format
  const formatTime = timeString => {
    const [hours, minutes] = timeString.split('T')[1].split(':');
    return `${hours}:${minutes}`;
  };

  return (
    <div className="d-flex animate__animated animate__fadeIn">
      <Sidebar activevalue={'marks'} />

      <div className="w-100">
        <Navbar />

        <div
          className="bg-light p-3 main-container px-4"
          style={{ minHeight: '100vh' }}
        >
          <h5 className="pt-2 pb-3">View Your Marks</h5>

          <h6
            className={`text-center text-secondary py-4 ${
              batches.length > 0 ? 'd-none' : ''
            }`}
          >
            No batches available...🧐
          </h6>
          <div
            className={`table-responsive ${batches.length > 0 ? '' : 'd-none'}`}
          >
            <table class="w-100">
              <thead className="table-secondary">
                <tr style={{ backgroundColor: '#034592' }}>
                  <th
                    scope="col"
                    className="fw-normal text-white py-3"
                  >
                    <p className="m-0 ps-2">SI.No</p>
                  </th>
                  <th
                    scope="col"
                    className="fw-normal text-white py-3"
                  >
                    <p className="m-0">Course Name</p>
                  </th>
                  <th
                    scope="col"
                    className="fw-normal text-white py-3"
                  >
                    <p className="m-0">Batch Start Date</p>
                  </th>
                  <th
                    scope="col"
                    className="fw-normal text-white py-3"
                  >
                    <p className="m-0">Batch Start Time</p>
                  </th>
                  <th
                    scope="col"
                    className="fw-normal text-white py-3"
                  >
                    <p className="m-0">Batch End Date</p>
                  </th>
                  <th
                    scope="col"
                    className="fw-normal text-white py-3"
                  >
                    <p className="m-0">Batch End Time</p>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {console.log('BACH', batches)}
                {batches.map((x, index) => {
                  return (
                    <tr className="table-row">
                      <td
                        className="py-3"
                        scope="row"
                      >
                        <p className="m-0 ps-2">{index + 1}</p>
                      </td>
                      <td className="py-3">
                        <p className="m-0">{x.name.sub_course_name}</p>
                      </td>
                      <td className="py-3">
                        <p className="m-0">{x.start_time.slice(0, 10)}</p>
                      </td>
                      <td className="py-3">
                        <p className="m-0">{formatTime(x.start_time)}</p>
                      </td>
                      <td className="py-3">
                        <p className="m-0">{x.end_time.slice(0, 10)}</p>
                      </td>
                      <td className="py-3">
                        <p className="m-0">{formatTime(x.end_time)}</p>
                      </td>
                      <td className="py-3">
                        <div className="">
                          <button
                            onClick={() => {
                              navigate(`/view_marks/${x.id}`);
                            }}
                            className="btns text-white py-2"
                          >
                            View Marks
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marks;
