import { useQuery } from '@tanstack/react-query';
import { fetchCourses } from '../../API/FetchFns/FormsData';
import { useEffect, useState } from 'react';
import { useQueryState } from 'nuqs';

function CoursesDropdown() {
  const [filterByCat, setFilterByCat] = useQueryState('filter', {
    parse: value => (value ? JSON.parse(value) : {}),
    serialize: value => JSON.stringify(value),
  });

  const [selectedCourse, setSelectedCourse] = useState('');

  const { data, isError, isLoading, error, isFetching } = useQuery({
    queryKey: ['AllCoursesList'],
    queryFn: fetchCourses,
  });

  const courses = data?.data?.coursename || [];

  const handleSelectionChange = e => {
    const selectedValue = e.target.value;
    setSelectedCourse(selectedValue);
    setFilterByCat({
      filterBy: 'courses_enquired',
      filterOn: selectedValue,
    });
  };

  return (
    <div className="tw-bg-white  tw-flex tw-justify-center tw-items-center">
      <div className="tw-relative tw-w-full tw-max-w-sm">
        <label
          htmlFor="courses"
          className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-700"
        >
          Select a Course
        </label>
        <select
          id="courses"
          value={selectedCourse}
          onChange={handleSelectionChange}
          className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-ring-2 focus:tw-ring-emerald-400 tw-bg-white tw-shadow-sm"
        >
          <option value="">-- Choose a Course --</option>
          {courses.map((course, index) => (
            <option
              key={index}
              value={course.course_name}
            >
              {course.course_name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default CoursesDropdown;
