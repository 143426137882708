import MainLayout from './MainLayout';
import AllFilters from '../../../Components/FilterForms/AllFilters';
import CustomerAllTable from './All/CustomerAllTable';
import PaginatedButtons from './All/PaginatedButtons';
import useLeadsQuery from '../../../Hooks/useLeadsQuery';
import useLeadsQueryParams from '../../../Hooks/useLeadsQueryParams';
import { useEffect } from 'react';
import { fetchAllNewLeads } from '../../../API/FetchFns/AllB2CLeads';

const CustomerAllNewLeads = ({ settab }) => {
  useEffect(() => {
    settab('newlead');
  }, [settab]);

  const { queryParamsObj } = useLeadsQueryParams();

  const queryParams = {
    ...queryParamsObj,
    stage: 'new_leads',
  };

  const { data, error, isError, isLoading, isFetching } = useLeadsQuery({
    queryParams,
    fetchFn: fetchAllNewLeads,
    fetchKey: 'NewB2CLeadsAdmin',
  });

  data && console.log('DATA', data?.data);
  isError && console.log(error);

  const isPrevious = Boolean(data?.data?.previous);
  const isNext = Boolean(data?.data?.next);
  return (
    <>
      <AllFilters />
      <MainLayout loading={isLoading}>
        <CustomerAllTable
          leads={data?.data?.results}
          count={data?.data?.count}
        />
        <PaginatedButtons
          isFetching={isFetching}
          isNext={isNext}
          isPrevious={isPrevious}
        />
      </MainLayout>
    </>
  );
};

export default CustomerAllNewLeads;
