import CardImage from '../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png';

function EmployeeTodayCard({
  setlead_type,
  lead_type,
  name,
  count,
  lead_type_name,
}) {
  return (
    <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
      <div
        className={`py-3 px-2 px-lg-3 py-md-4 shadow-sm ${
          lead_type === lead_type_name ? 'seleceted_empcard' : 'emp-cards'
        }`}
        onClick={() => {
          setlead_type(lead_type_name);
        }}
      >
        <img
          src={CardImage}
          className="d-block mx-auto"
          style={{ width: '46%' }}
          alt=""
        />
        <p
          className="m-0 text-center mt-2 fw-bold"
          style={{ fontSize: '13px' }}
        >
          {name}
        </p>
        <p
          className="fw-medium text-center m-0"
          style={{ fontSize: '14px' }}
        >
          {count}
        </p>
      </div>
    </div>
  );
}

export default EmployeeTodayCard;
