import axios from 'axios';
import { ipaddress } from '../../App';
import ENVTYPE from '../../config';

let baseURL;
let socketType;

if (!process.env.NODE_ENV || process.env.NODE_ENV === ENVTYPE) {
  //
  console.log('ENV', 'DEV', process.env.NODE_ENV);
  baseURL = 'http://192.168.18.13:9000';

  socketType = 'ws';
  //
} else {
  //
  console.log('ENV', 'PROD');
  baseURL = 'https://crmclient.skilllearningacademy.com';
  socketType = 'wss';
  //
}

export const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(config => {
  console.log(`URL:${config.url}`, `${ipaddress}${config.url}`);
  return config;
});
