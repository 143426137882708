import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { ipaddress2 } from '../App';
import Mode_of_payment_modal from '../invoide_and_receipt/Mode_of_payment_modal';

const Installment_form = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const renderTooltip = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      Check Verified
    </Tooltip>
  );
  const renderTooltip2 = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      Check Not Verified
    </Tooltip>
  );

  const [due_date, setdue_date] = useState();
  const [amount, setamount] = useState();

  const [loading1, setloading1] = useState();

  const [invoidedata, setinvoicedata] = useState();
  const [current_invoidedata, setcurrent_invoicedata] = useState({});

  const [registrationData, setRegistrationData] = useState();

  useEffect(() => {
    axios
      .get(`${ipaddress2}/InvoiceGenerator/${id}/`)
      .then(r => {
        console.log('invoice data HARI', r.data);
        setinvoicedata(r.data);
        fetchobjectdata(r.data[0].invoice_number);
      })
      .catch(err => {
        console.log('Invoice Error', err);
      });
  }, []);

  const fetchobjectdata = id => {
    axios
      .get(`${ipaddress2}/ShowInvoice/${id}/`)
      .then(r => {
        console.log('HARI SEE THIS', r.data);
        setcurrent_invoicedata(r.data);
        console.log('REGIS', r.data.registration_fees);
        setRegistrationData(r.data.registration_fees);
      })
      .catch(err => {
        console.log('particular Invoice Error', err);
      });
  };

  console.log('REGISS', registrationData);

  const verify_cheque = (invoice_number, due_date, status) => {
    axios
      .post(
        `${ipaddress2}/CheckBounce/${invoice_number}/${due_date}/${status}/`,
      )
      .then(r => {
        console.log('Cheque verified', r.data);
        fetchobjectdata(invoice_number);
      });
  };

  const send_receipt = token => {
    setloading1(true);
    const url = `crmclient.skilllearningacademy.com/receipt/${token}/`;
    axios
      .post(`${ipaddress2}/ReceiptView/${token}/`, {
        url: url,
      })
      .then(r => {
        setloading1(false);
        console.log('Url Shared', r.data);
        toast.success('Url shared successfully', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
      })
      .catch(err => {
        setloading1(false);
        console.log('Sharing error', err, url, token);
      });
  };
  return (
    <div className="d-flex">
      <Sidebar />

      <div className="w-100">
        <Navbar />

        <div className="bg-light main-container p-3">
          <svg
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (id.slice(0, 3) === 'CUS') {
                navigate('/customerleads');
              } else {
                navigate('/leads');
              }
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-arrow-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
            />
          </svg>
          <div
            className="container my-3 d-flex justify-content-between"
            style={{
              overflowX:
                invoidedata && invoidedata.length > 5 ? 'scroll' : 'none',
            }}
          >
            {invoidedata &&
              invoidedata.map((x, index) => {
                return (
                  <>
                    <button
                      onClick={() => {
                        fetchobjectdata(x.invoice_number);
                      }}
                      className="btn text-white btn-sm btn-primary d-flex align-items-center px-3"
                    >
                      <i class="fa-solid fa-file-invoice me-2"></i>Invoice{' '}
                      {index + 1}
                    </button>
                  </>
                );
              })}
          </div>

          <div
            className={`table-responsive rounded bg-white border-0 p-3`}
            style={{ minHeight: '100vh' }}
          >
            {/* <h5 className='text-primary'>Installments</h5> */}
            <table class={`table caption-top rounded`}>
              <thead className="invoice-table">
                <tr>
                  <th
                    scope="col"
                    className="pb-3"
                  >
                    <p
                      className="fw-normal text-center m-0"
                      style={{ color: '#5A607F', fontSize: '15px' }}
                    >
                      SI No
                    </p>
                  </th>
                  <th
                    scope="col"
                    className={`pb-3 ${
                      current_invoidedata.due_dates &&
                      current_invoidedata.due_dates.length > 0
                        ? ''
                        : 'd-none'
                    }`}
                  >
                    <p
                      className="fw-normal text-center m-0"
                      style={{ color: '#5A607F', fontSize: '15px' }}
                    >
                      Installment
                    </p>
                  </th>
                  <th
                    scope="col"
                    className="pb-3"
                  >
                    <p
                      className="fw-normal text-center m-0"
                      style={{ color: '#5A607F', fontSize: '15px' }}
                    >
                      Amount
                    </p>
                  </th>
                  <th
                    scope="col"
                    className={`pb-3  ${
                      current_invoidedata.due_dates &&
                      current_invoidedata.due_dates.length > 0
                        ? ''
                        : 'd-none'
                    }`}
                  >
                    <p
                      className="fw-normal text-center m-0"
                      style={{ color: '#5A607F', fontSize: '15px' }}
                    >
                      Due Date
                    </p>
                  </th>
                  <th
                    scope="col"
                    className={`pb-3  ${
                      current_invoidedata.due_dates &&
                      current_invoidedata.due_dates.length > 0
                        ? 'd-none'
                        : ''
                    }`}
                  >
                    <p
                      className="fw-normal text-center m-0"
                      style={{ color: '#5A607F', fontSize: '15px' }}
                    >
                      GST Amount
                    </p>
                  </th>
                  <th
                    scope="col"
                    className="pb-3"
                  >
                    <p
                      className="fw-normal text-center m-0"
                      style={{ color: '#5A607F', fontSize: '15px' }}
                    >
                      Status
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {registrationData && (
                  <tr>
                    <td className="py-3">
                      <p className="text-center m-0">1</p>
                    </td>
                    <td className="py-3">
                      <p className="text-center m-0">Registration</p>
                    </td>
                    <td className="py-3">
                      <p className="text-center m-0">
                        {registrationData[0]?.amount || 'N/A'}
                      </p>
                    </td>
                    <td className="py-3">
                      <p className="text-center m-0">
                        {registrationData[0]?.created_at.slice(0, 10) || 'N/A'}
                      </p>
                    </td>
                    <td className="py-3">
                      <div className="d-flex justify-content-center">
                        {!registrationData[0]?.paid_status ? (
                          <button
                            style={{ width: '98px' }}
                            data-bs-toggle="modal"
                            data-bs-target="#mode_of_payment"
                            onClick={() => {
                              setamount(registrationData[0]?.amount);
                              setdue_date('undefined');
                              // registrationData[0]?.created_at.slice(0, 10),
                            }}
                            className="btn btn-sm btn-primary"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-paypal"
                              viewBox="0 0 16 16"
                            >
                              <path d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.35.35 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91q.57-.403.993-1.005a4.94 4.94 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.7 2.7 0 0 0-.76-.59l-.094-.061Z" />
                              <path d="M6.543 8.82a.7.7 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016q.326.186.548.438c.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.87.87 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.35.35 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32z" />
                            </svg>{' '}
                            <span
                              className="ms-1"
                              style={{ fontSize: '13px' }}
                            >
                              Pay Now
                            </span>
                          </button>
                        ) : (
                          <button
                            style={{ width: '98px', fontSize: '13px' }}
                            className="btn btn-sm btn-success px-3"
                          >
                            <span className="ms-1">Paid</span>
                          </button>
                        )}
                      </div>
                    </td>
                    <div
                      className={`${
                        registrationData[0].status === 'processing'
                          ? ''
                          : 'd-none'
                      }`}
                    >
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 250 }}
                        overlay={renderTooltip}
                      >
                        <button
                          onClick={() => {
                            verify_cheque(
                              current_invoidedata.invoice_number,
                              registrationData[0].due_date,
                              'True',
                            );
                          }}
                          className="btn btn-sm border-0"
                        >
                          {' '}
                          <span className="ms-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="green"
                              class="bi bi-check-square-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                            </svg>
                          </span>
                        </button>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 250 }}
                        overlay={renderTooltip2}
                      >
                        <button
                          onClick={() => {
                            verify_cheque(
                              current_invoidedata.invoice_number,
                              registrationData[0]?.due_date,
                              'False',
                            );
                          }}
                          className="btn btn-sm border-0"
                        >
                          {' '}
                          <span className="ms-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="red"
                              class="bi bi-x-square-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                            </svg>
                          </span>
                        </button>
                      </OverlayTrigger>
                    </div>
                    {registrationData[0]?.token && (
                      <td>
                        <button
                          onClick={() => {
                            navigate(`/receipt/${registrationData[0]?.token}`);
                          }}
                          className="text-center tw-text-white  tw-bg-black tw-px-2 tw-py-1 tw-rounded-full  m-0"
                        >
                          <i class="fa-solid fa-file"></i>
                        </button>
                      </td>
                    )}
                  </tr>
                )}
                {current_invoidedata.due_dates &&
                  current_invoidedata.due_dates.map((x, index) => {
                    return (
                      <tr>
                        <td className="py-3">
                          <p className="text-center m-0">
                            {registrationData.length > 0
                              ? index + 2
                              : index + 1}
                          </p>
                        </td>
                        <td className="py-3">
                          <p className="text-center m-0">
                            Installment {index + 1}
                          </p>
                        </td>
                        <td className="py-3">
                          <p className="text-center m-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-currency-rupee"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                            </svg>
                            {x.fees}
                          </p>
                        </td>
                        <td className="py-3">
                          <p className="text-center m-0">{x.due_date}</p>
                        </td>
                        <td className="py-3">
                          <div className="d-flex justify-content-center">
                            <button
                              style={{ width: '98px' }}
                              data-bs-toggle="modal"
                              data-bs-target="#mode_of_payment"
                              onClick={() => {
                                setdue_date(x.due_date);
                                setamount(x.fees);
                              }}
                              className={`btn btn-sm btn-primary ${
                                x.status === 'paid' || x.status === 'processing'
                                  ? 'd-none'
                                  : ''
                              }`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-paypal"
                                viewBox="0 0 16 16"
                              >
                                <path d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.35.35 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91q.57-.403.993-1.005a4.94 4.94 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.7 2.7 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.7.7 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016q.326.186.548.438c.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.87.87 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.35.35 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32z" />
                              </svg>{' '}
                              <span
                                className="ms-1"
                                style={{ fontSize: '13px' }}
                              >
                                Pay Now
                              </span>
                            </button>

                            <button
                              style={{ width: '98px', fontSize: '13px' }}
                              className={`btn btn-sm btn-success px-3 ${
                                x.status === 'paid' ? '' : 'd-none'
                              }`}
                            >
                              {' '}
                              <span className="ms-1">Paid</span>
                            </button>
                            <div
                              className={`${
                                x.status === 'processing' ? '' : 'd-none'
                              }`}
                            >
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 250 }}
                                overlay={renderTooltip}
                              >
                                <button
                                  onClick={() => {
                                    verify_cheque(
                                      current_invoidedata.invoice_number,
                                      x.due_date,
                                      'True',
                                    );
                                  }}
                                  className="btn btn-sm border-0"
                                >
                                  {' '}
                                  <span className="ms-1">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="green"
                                      class="bi bi-check-square-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                    </svg>
                                  </span>
                                </button>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 250 }}
                                overlay={renderTooltip2}
                              >
                                <button
                                  onClick={() => {
                                    verify_cheque(
                                      current_invoidedata.invoice_number,
                                      x.due_date,
                                      'False',
                                    );
                                  }}
                                  className="btn btn-sm border-0"
                                >
                                  {' '}
                                  <span className="ms-1">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="red"
                                      class="bi bi-x-square-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                    </svg>
                                  </span>
                                </button>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </td>

                        {console.log(x)}
                        {/* <td className="py-3">
                          <button
                            onClick={() => {
                              send_receipt(x.token);
                            }}
                            className="text-center tw-text-white  tw-bg-green-600 tw-px-2 tw-py-1 tw-rounded-full  m-0"
                          >
                            <i class="fa-solid fa-envelope"></i>
                          </button>
                        </td> */}
                        {console.log('PAIDY', x.status)}
                        <td
                          className={`${
                            x.status === 'paid' ? '' : 'tw-hidden'
                          } py-3`}
                        >
                          <button
                            onClick={() => {
                              navigate(`/receipt/${x.token}`);
                            }}
                            className="text-center tw-text-white  tw-bg-black tw-px-2 tw-py-1 tw-rounded-full  m-0"
                          >
                            <i class="fa-solid fa-file"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}

                {/* WITHOUT INSTALLMENT */}
                <tr
                  className={
                    current_invoidedata.due_dates &&
                    current_invoidedata.due_dates.length > 0
                      ? 'd-none'
                      : ''
                  }
                >
                  <td className="py-3">
                    <p className="text-center m-0">1</p>
                  </td>
                  <td className="py-3">
                    <p className="text-center m-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-currency-rupee"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                      </svg>
                      {current_invoidedata.grand_total}
                    </p>
                  </td>
                  <td className="py-3">
                    <p className="text-center m-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-currency-rupee"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                      </svg>
                      {current_invoidedata.grand_gst_total}
                    </p>
                  </td>
                  <td className="py-3">
                    <div className="d-flex justify-content-center">
                      <button
                        style={{ width: '98px', fontSize: '13px' }}
                        className={`btn btn-sm btn-success px-3`}
                      >
                        {' '}
                        <span className="ms-1">Paid</span>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Mode_of_payment_modal
        id={id}
        invoice_number={current_invoidedata.invoice_number}
        due_date={due_date}
        amount={amount}
        fetchinvoice={fetchobjectdata}
      />
    </div>
  );
};

export default Installment_form;
