import axios from 'axios';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ipaddress } from '../../App';
import { toast } from 'react-toastify';

function DeleteButton({ rowData, setRefreshData }) {
  const renderTooltip4 = value => (
    <Tooltip id="button-tooltip">{value}</Tooltip>
  );
  const delete_lead = id => {
    axios.put(`${ipaddress}/DeleteView/${id}/`).then(r => {
      console.log('Deleted successfully', r.data);
      toast.success('B2C lead deleted successfully', {
        autoClose: 500,
        theme: 'colored',
        position: 'top-center',
      });
    });
  };

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip4('Delete')}
    >
      <button
        onClick={() => {
          delete_lead(rowData.customer_lead_id);
          setRefreshData(true);
        }}
      >
        <i class="fa-solid fa-trash"></i>
      </button>
    </OverlayTrigger>
  );
}

export default DeleteButton;
